import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Flex } from 'components/Flex';
import { RightSideBody } from 'components/layout/RightSideBody';
import { SectionTitleNew, Span } from 'components/textElements';
import { BORDER_RADIUS, COLOR_BLACKWATER, COLOR_STATUS_DONE, COLOR_WHITE_WALKER, SCREEN_S } from 'styles/variables';
import { formatCents, formatDate, formatPercentage } from 'utils';
import { GET_USER_DATA } from '../profile/queries';
import { DownloadSalaries } from './DownloadSalaries';
import { DownloadTaxReport } from './DownloadTaxReport';
import { ChangeTaxRate } from './ChangeTaxRate';
import { GET_SALARIES } from './queries';
import TaxCardNote from './TaxCardNote';
import { IRootState } from 'reducers';

const Section = styled.div`
    background-color: ${COLOR_WHITE_WALKER};
    border-radius: ${BORDER_RADIUS};
    padding: 20px;
    width: 100%;
`;

const TaxActions = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    & div:first-child {
        & button {
            margin-bottom: 16px;
        }
    }

    && {
        @media (min-width: ${SCREEN_S}px) {
            width: 100%;
            justify-content: flex-end;
            flex-direction: row;

            & div:first-child {
                & button {
                    margin-right: 16px;
                    margin-bottom: 0;
                }
            }
        }
    }
`;

const TaxRateIndicator = styled('div')<{ taxRateUpated: boolean }>`
    font-weight: ${props => (props.taxRateUpated ? '600' : '400')};
    position: relative;
    padding-left: ${props => (props.taxRateUpated ? '18px' : '0')};
    line-height: 28px;

    &::before {
        content: '';
        position: absolute;
        background: ${COLOR_STATUS_DONE};
        border-radius: 50%;
        width: 6px;
        height: 6px;
        left: 0;
        top: calc(50% - 3px);
        display: ${props => (props.taxRateUpated ? 'block' : 'none')};
    }
`;

const SalarySummary: FC = () => {
    const { t } = useTranslation();

    const taxCardFile = useSelector((state: IRootState) => state.file.taxCard);
    const withholdingTax = useSelector((state: IRootState) => state.user.withholdingTax);
    const isIdentified = useSelector((state: IRootState) => state.user.isIdentified);

    const { data: userData, refetch: refetchUser } = useQuery(GET_USER_DATA);

    const nextTaxCardStartDate: string | undefined = userData?.userData?.nextTaxCardStartDate ? userData?.userData?.nextTaxCardStartDate : undefined;

    const { data: salaryData } = useQuery(GET_SALARIES, {
        variables: { page: { offset: 0, pageSize: 20 } }
    });

    const salariesAmount = salaryData?.salaries?.items.length;

    const [taxRateUpated, setTaxRateUpated] = useState(false);


    useEffect(() => {
        refetchUser();
    }, [refetchUser, taxCardFile]);

    useEffect(() => {
        const {
            status,
            addPercentage,
            basicPercentageLimit,
            basicPercentage,
            startDate
        } = withholdingTax || {};

        if (
            status === 'ok' &&
            (addPercentage ||
                basicPercentageLimit ||
                basicPercentage ||
                startDate)
        ) {
            refetchUser();
            setTaxRateUpated(true);
        } else {
            setTaxRateUpated(false);
        }
    }, [refetchUser, withholdingTax]);

    return (
        <RightSideBody>
            {userData && (
                <TaxCardNote
                    nextTaxCardStartDate={ nextTaxCardStartDate }
                    taxCardMissing={!userData?.userData?.taxCardDetails}
                    taxCardPending={!!userData?.userData?.taxCardUploadedAt}
                    taxCardNotValidYet={!!userData?.userData?.nextTaxCardStartDate}
                    isIdentified={isIdentified}
                />
            )}
            {!!userData?.userData?.taxCardDetails && (
                <Section style={{ marginBottom: 16 }}>
                    <SectionTitleNew
                        color={COLOR_BLACKWATER}
                        style={{ marginBottom: 20 }}
                    >
                        {t('salaries:taxCard.title')}{' '}
                        {new Date().getFullYear()}
                    </SectionTitleNew>

                    {userData?.userData?.taxCardDetails && (
                        <>
                            <Flex spread fullWidth>
                                <Span
                                    as="label"
                                    htmlFor="income-limit"
                                    color={COLOR_BLACKWATER}
                                >
                                    {t('salaries:taxCard.valid')}
                                </Span>
                                <Span
                                    as="span"
                                    id="income-limit"
                                    color={COLOR_BLACKWATER}
                                >
                                    {formatDate(
                                        userData?.userData?.taxCardDetails
                                            ?.startDate
                                    )}{' '}
                                    -{' '}
                                    {formatDate(
                                        userData?.userData?.taxCardDetails
                                            ?.endDate
                                    )}
                                </Span>
                            </Flex>

                            <Flex spread fullWidth>
                                <Span
                                    as="label"
                                    htmlFor="income-limit"
                                    color={COLOR_BLACKWATER}
                                >
                                    {t('salaries:incomeLimit')}
                                </Span>
                                <Span
                                    as="span"
                                    id="income-limit"
                                    color={COLOR_BLACKWATER}
                                >
                                    {t('form.eurs', {
                                        eurs: formatCents(
                                            userData?.userData?.taxCardDetails?.basicPercentageLimit,
                                            true
                                        )
                                    })}
                                </Span>
                            </Flex>

                            <Flex spread fullWidth>
                                <TaxRateIndicator
                                    as="label"
                                    htmlFor="taxrate"
                                    color={COLOR_BLACKWATER}
                                    taxRateUpated={taxRateUpated}
                                >
                                    {t('salaries:taxRate')}
                                </TaxRateIndicator>
                                <Span
                                    as="span"
                                    id="taxrate"
                                    color={COLOR_BLACKWATER}
                                    style={{
                                        fontWeight: taxRateUpated ? 600 : 400
                                    }}
                                >
                                    {t('form.percent', {
                                        percentage: formatPercentage(
                                            parseFloat(
                                                userData?.userData?.taxCardDetails?.basicPercentage
                                            )
                                        )
                                    })}
                                    {}
                                </Span>
                            </Flex>

                            <Flex spread fullWidth>
                                <Span
                                    as="label"
                                    htmlFor="taxrate-additional"
                                    color={COLOR_BLACKWATER}
                                >
                                    {t('salaries:taxRateAdditional')}
                                </Span>
                                <Span
                                    as="span"
                                    id="taxrate-additional"
                                    color={COLOR_BLACKWATER}
                                >
                                    {t('form.percent', {
                                        percentage: formatPercentage(
                                            parseFloat(
                                                userData?.userData?.taxCardDetails?.addPercentage
                                            )
                                        )
                                    })}
                                </Span>
                            </Flex>
                        </>
                    )}
                    <Flex fullWidth posEnd style={{ marginTop: 20 }}>
                        <ChangeTaxRate />
                    </Flex>
                </Section>
            )}
            {!!userData?.userData?.taxCardDetails && (
                <TaxActions>
                    <DownloadSalaries disabled={!salariesAmount || salariesAmount === 0} />
                    <DownloadTaxReport disabled={!salariesAmount || salariesAmount === 0} />
                </TaxActions>
            )}
        </RightSideBody>
    );
};


export default SalarySummary;
