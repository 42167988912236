import React from 'react';
import { EezyRouterLink } from '../../components/Buttons';
import { Icon } from '../../components/Icon';
import { trans } from '../../utils';
import styled from 'styled-components';
import { SCREEN_S } from 'styles/variables';

interface IProps {
    costId: number;
    invoiceId: number;
    isGroupInvoice: boolean;
    type: 'cost' | 'travel';
}

const EezyRouterLinkStyled = styled(EezyRouterLink)`
    width: 38px !important;
    min-width: auto !important;

    @media (max-width: ${SCREEN_S}px) {
        width: 44px !important;
    }
`;

const EditCost = (props: IProps) => {
    const baseLink = `/${props.isGroupInvoice ? 'group' : 'invoices'}/${props.invoiceId}`;
    return (
        <EezyRouterLinkStyled
            aria-label={trans('general.edit')}
            color="black"
            square
            style={{ display: 'inline-block', padding: 0 }}
            to={`${baseLink}/${props.type === 'cost' ? `expenses` : `travels`}/${props.costId}`}
        >
            <Icon color="black" icon={['far', 'pen']} />
        </EezyRouterLinkStyled>
    );
};

export default EditCost;
