import { IConfirmationTexts } from '../../actions/app';
import { EezyButton } from '../../components/Buttons';
import { Modal, ModalActions, ModalContent } from '../../components/modals/Modal';
import { P } from '../../components/textElements';
import { trans } from '../../utils';

interface ConfirmationModalProps {
    handleClose: () => void;
    handleSubmit: (reason?: string) => void;
    id: string;
    isOpen: boolean;
    texts: IConfirmationTexts;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
    const handleClose = () => {
        props.handleClose();
    };

    const handleSubmit = () => {
        props.handleSubmit();
        handleClose();
    };

    return (
        <Modal
            id={`modal-${props.id}`}
            isOpen={props.isOpen}
            onClose={props.handleClose}
            title={trans(props.texts.title)}
        >
            <ModalContent>
                {props.texts.paragraph && <P>{trans(props.texts.paragraph)}</P>}
                {props.texts.paragraph2 && <P>{trans(props.texts.paragraph2)}</P>}
                {props.texts.paragraph3 && <P>{trans(props.texts.paragraph3)}</P>}
                {props.texts.paragraph4 && <P>{trans(props.texts.paragraph4)}</P>}
            </ModalContent>
            <ModalActions>
                <EezyButton color="purple" onClick={handleClose}>
                    {trans('general.cancel')}
                </EezyButton>
                <EezyButton autoFocus color="purple" dark onClick={handleSubmit} type="submit">
                    {props.texts.submit ? trans(props.texts.submit) : trans('general.save')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

export default ConfirmationModal;
