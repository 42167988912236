import { gql } from '@apollo/client';

const GET_PAYMENTS_BY_DATE = gql`
    query getPaymentsByDate($yearStartTimestamp: timestamptz, $yearEndTimestamp: timestamptz) {
        transactions(
            order_by: { completed: desc }
            where: {
                _and: [
                    { status: { _eq: "COMPLETE" } }
                    {
                        _and: [
                            { completed: { _gte: $yearStartTimestamp } }
                            { completed: { _lte: $yearEndTimestamp } }
                        ]
                    }
                ]
            }
        ) {
            orderNumber
            serviceName
            lastName
            firstName
            paymentBrand
            completed
            servicePrice
            thirdParty
            serviceVat
        }
    }
`;

export default GET_PAYMENTS_BY_DATE;
