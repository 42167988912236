import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormNumberInput } from './FormNumberInput';
import Select from 'components/ui/select';

export interface IUnitInputProps {
    name: string;
    onChange: (value: number, unit: string) => void;
    value: number;
}

export const UnitElement = ({ onChange, value, name }: IUnitInputProps) => {
    const { t } = useTranslation();
    const [num, setNumber] = useState(value);
    const [selectedUnit, selectUnit] = useState('yearly');

    const handleUnitChange = (newValue: string) => {
        selectUnit(newValue);
        onChange(num, newValue);
    };

    const handleNumberChange = (newValue: number) => {
        setNumber(newValue);
        onChange(newValue, selectedUnit);
    };

    const numberEndAdornment = selectedUnit === 'yearly' ? t('form.eurs-year') : t('form.eurs-month');

    return (
        <>
            <div className="grow mt-[1px]">
                <FormNumberInput
                    isEur={true}
                    endAdornment={numberEndAdornment}
                    name={name}
                    onChange={(val) => {
                        handleNumberChange(Number(val));
                    }}
                    required
                    value={num}
                />
            </div>

            <div className="grow border-slate-300 border-b border-solid">
                <Select
                    options={[
                        {
                            label: t('form.eurs-year'),
                            value: 'yearly',
                        },
                        {
                            label: t('form.eurs-month'),
                            value: 'monthly',
                        },
                    ]}
                    triggerText={t('profile:settings.terminate-account.termination-reasons.select')}
                    onChange={handleUnitChange}
                    value={selectedUnit}
                />
            </div>
        </>
    );
};
