import React from 'react';
import { toast } from 'react-toastify';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { ClientType, IClient, IContactPerson } from '../../../../../shared/src/types/invoice';
import { EezyButton } from 'components/Buttons';
import ColorBox from 'components/ColorBox';
import ErrorBox from 'components/ErrorBox';
import LoadingSpinner from 'components/Loading';
import { P } from 'components/textElements';
import { getErrors } from 'utils/apolloErrors';
import {
    cleanRecipientBeforeSending,
    clientToClientInput,
    convertCompanyToPerson,
    convertPersonToCompany
} from 'utils/invoice/invoiceLogic';
import validators from 'utils/invoice/validators';
import {
    findSelectedContactPersonId,
    initialRecipientForm,
    IRecipientFormState,
    recipientReducer
} from 'utils/recipient/recipientLogic';
import { IDocument } from 'utils/user/userUtils';
import { formatValidationResult } from 'utils/validation';
import { ADD_RECIPIENT, GET_INVOICE_RECIPIENTS, UPDATE_INVOICE_WITH_RECIPIENT } from '../queries';
import { NEW_INVOICE_NEW_RECIPIENT, RECIPIENT_FH_ERRORS_VISIBLE } from '../queries/invoiceStateQueries';
import FillHelper from './FillHelper';
import RecipientForm from './RecipientForm';
import { closeAllFillHelpers } from './utils';
import { useTranslation } from 'react-i18next';

interface IRecipientFillHelper {
    initialFormData?: IClient;
    invoiceId?: number;
    loading?: boolean;
    showModals?: (types: string[]) => void;
}

const NewRecipientFillHelper = (props: IRecipientFillHelper) => {
    const { t } = useTranslation();

    const { client, data: localData } = useQuery(RECIPIENT_FH_ERRORS_VISIBLE);
    const showErrors = localData?.recipientFHErrorsVisible;

    const [{ recipient, selectedContactPersonIndex }, dispatch]: [
        IRecipientFormState,
        React.Dispatch<any>
    ] = React.useReducer(recipientReducer, initialRecipientForm);

    const [errors, setErrors] = React.useState<any>(null);

    React.useEffect(() => {
        if (props.initialFormData) {
            dispatch({
                payload: { recipient: props.initialFormData },
                type: 'INIT'
            });
        }
    }, [props.initialFormData]);

    React.useEffect(() => {
        setErrors(
            formatValidationResult(validators.recipient.validate(recipient))
        );
    }, [recipient]);

    const [updateInvoiceWithRecipient] = useMutation(
        UPDATE_INVOICE_WITH_RECIPIENT,
        {
            onCompleted: () => toast(t('general.saved')),
            onError: (e: ApolloError) => {
                const err = getErrors(e);
                if (err?.mustAccept && props.showModals) {
                    props.showModals(
                        errors?.mustAccept.map((t: IDocument) => t.document)
                    );
                } else {
                    toast.error(t('errors.general'));
                }
            }
        }
    );

    const [addClient, { loading: saveLoading }] = useMutation(ADD_RECIPIENT, {
        onCompleted: data => {
            if (props.invoiceId) {
                updateInvoiceWithRecipient({
                    variables: {
                        contactPersonId: findSelectedContactPersonId(
                            selectedContactPersonIndex,
                            recipient.contactPeople,
                            data?.createClient?.contactPeople
                        ),
                        id: props.invoiceId,
                        recipient: {
                            id: data?.createClient.id,
                            type: data?.createClient.type
                        }
                    }
                });
            } else {
                // User is creating new recipient on new invoice, thus the recipient
                // has to somehow be passed to NewInvoice.tsx. A bit hacky way to do it.
                client.writeQuery({
                    data: {
                        newInvoiceRecipient: {
                            __typename: 'NewInvoiceRecipient',
                            contactPersonId: findSelectedContactPersonId(
                                selectedContactPersonIndex,
                                recipient.contactPeople,
                                data?.createClient?.contactPeople
                            ),
                            id: data?.createClient.id,
                            type: data?.createClient.type
                        }
                    },
                    query: NEW_INVOICE_NEW_RECIPIENT
                });
            }
            closeAllFillHelpers(client);
        },
        onError: (e: ApolloError) => {
            const err = getErrors(e);
            if (err?.mustAccept && props.showModals) {
                props.showModals(
                    err?.mustAccept.map((t: IDocument) => t.document)
                );
            } else {
                toast.error(t('errors.general'));
            }
        },
        refetchQueries: () => [{ query: GET_INVOICE_RECIPIENTS }]
    });

    const handleChange = (val: string, name: string) => {
        dispatch({ type: 'UPDATE_RECIPIENT', payload: { [name]: val } });
    };

    const handleAddressChange = (val: string, name: string) => {
        dispatch({ type: 'UPDATE_ADDRESS', payload: { [name]: val } });
    };

    const handleTypeChange = (val: string) => {
        if (val === 'person') {
            dispatch({
                payload: {
                    ...convertCompanyToPerson(recipient),
                    type: val as ClientType
                },
                type: 'UPDATE_RECIPIENT'
            });
        } else {
            dispatch({
                payload: {
                    ...convertPersonToCompany(recipient),
                    type: val as ClientType
                },
                type: 'UPDATE_RECIPIENT'
            });
        }
    };

    const handleContactPersonsChange = (
        contactPeople: IContactPerson[],
        selectedCPIndex?: number
    ) => {
        dispatch({
            payload: {
                contactPeople,
                selectedContactPersonIndex: selectedCPIndex
            },
            type: 'UPDATE_CONTACT_PEOPLE'
        });
    };

    const handleSave = () => {
        const r = cleanRecipientBeforeSending(clientToClientInput(recipient));
        const validationErrors = formatValidationResult(
            validators.recipient.validate(r)
        );
        setErrors(validationErrors);

        if (validationErrors) {
            client.writeQuery({
                data: { recipientFHErrorsVisible: true },
                query: RECIPIENT_FH_ERRORS_VISIBLE
            });
        } else {
            client.writeQuery({
                data: { recipientFHErrorsVisible: false },
                query: RECIPIENT_FH_ERRORS_VISIBLE
            });
            addClient({
                variables: {
                    client: r
                }
            });
        }
    };

    return (
        <FillHelper
            renderMenuRight={() => {
                return (
                    <EezyButton color="purple" onClick={handleSave}>
                        {saveLoading ? (
                            <LoadingSpinner size="1em" />
                        ) : (
                            t('general.save')
                        )}
                    </EezyButton>
                );
            }}
        >
            {props.loading && <LoadingSpinner size="2em" />}
            {showErrors && errors && !props.loading && (
                <ErrorBox>{t('errors.recipient-incomplete')}</ErrorBox>
            )}

            {!props.loading && props.initialFormData && (
                <ColorBox
                    topContent={<P>{t('invoice.fillhelper.ytj-info')}</P>}
                />
            )}

            {!props.loading && (
                <RecipientForm
                    handleChange={handleChange}
                    handleAddressChange={handleAddressChange}
                    handleContactPersonsChange={handleContactPersonsChange}
                    handleSave={handleSave}
                    handleTypeChange={handleTypeChange}
                    invoiceId={props.invoiceId}
                    errors={errors}
                    formData={{ recipient, selectedContactPersonIndex }}
                    showErrors={showErrors}
                    showModals={props.showModals}
                />
            )}
        </FillHelper>
    );
};

export default React.memo(NewRecipientFillHelper);
