import styled from 'styled-components';

/*
 Colored circle background behind an icon.
 
 Usage: 
        <IconBackground
            style={{ backgroundColor: COLOR_TORTOISE }}
        >
            <Icon icon={['fas', 'euro-sign']} color={COLOR_WHITE_WALKER} />
        </IconBackground>
*/

export const IconBackground = styled.div<{ large?: boolean }>`
    align-items: center;
    border-radius: 16px;
    justify-content: center;
    position: absolute;
    display: flex;
    height: 12px;
    width: 12px;
    bottom: ${props => (props.large ? '8px' : '4px')};
    left: ${props => (props.large ? '20px' : '22px')};

    && svg {
        height: ${props => (props.large ? '11px' : '9px')};
        width: ${props => (props.large ? '11px' : '9px')};
    }
`;
