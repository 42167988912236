import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { logout } from '../../actions/auth';

interface ILogoutProps {
    logout: () => void;
}

class Logout extends React.Component<ILogoutProps> {
    componentDidMount() {
        this.props.logout();
    }

    render() {
        return null;
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        logout: () => {
            dispatch(logout());
        }
    };
};

export default connect(null, mapDispatchToProps)(Logout);
