import { Popover } from '@mui/material';
import React, { cloneElement, ReactElement, useState } from 'react';
import styled from 'styled-components';
import { SCREEN_M } from '../../styles/variables';
import { Flex } from '../Flex';

const StyledPopover = styled(Popover)<{fullWidth?: boolean}>`
    ${props => !props.fullWidth && `@media (min-width: ${SCREEN_M}px) {
        max-width: 70%;
    }`}
`;

interface IPopOverProps {
    helpButton: ReactElement;
    helpText: string | JSX.Element[];
    fullWidth?: boolean;
}
const PopOver = (props: IPopOverProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const [modalOpen, openModal] = useState(false);
    const toggleModal = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setAnchorEl(modalOpen === true ? null : e.currentTarget);
        openModal(modalOpen === true ? false : true);
    };

    return (
        <>
            {cloneElement(props.helpButton, {
                ariaPressed: modalOpen,
                onClick: toggleModal
            })}
            <StyledPopover
                anchorEl={anchorEl}
                open={modalOpen}
                onClose={toggleModal}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'top'
                }}
                fullWidth={props.fullWidth}
            >
                <Flex column style={{ padding: 10 }}>
                    <p style={{ margin: '20px', whiteSpace: 'pre-line' }}>{props.helpText}</p>
                </Flex>
            </StyledPopover>
        </>
    );
};

export default PopOver;
