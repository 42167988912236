import i18n, { TOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import HttpApi from 'i18next-http-backend';
import moment from 'moment';
import i18next from 'i18next';
import localeEn from 'moment/locale/en-gb';
import localeFi from 'moment/locale/fi';
import Numeral from 'numeral';
import { LanguageCode } from '../../../shared/src/types/invoice';
import { UiLanguage } from '../../../shared/src/types/user';
import countriesEn from './../locales/en/countries.json';
import eezyPayEn from './../locales/en/eezyPay.json';
import occupationsEn from './../locales/en/occupations.json';
import profileEn from './../locales/en/profile.json';
import salariesEn from './../locales/en/salaries.json';
import salaryCalcEn from './../locales/en/salaryCalculator.json';
import supportEn from './../locales/en/support.json';
import translationEn from './../locales/en/translation.json';
import yelEn from './../locales/en/yel.json';
import countriesFi from './../locales/fi/countries.json';
import eezyPayFi from './../locales/fi/eezyPay.json';
import occupationsFi from './../locales/fi/occupations.json';
import profileFi from './../locales/fi/profile.json';
import salariesFi from './../locales/fi/salaries.json';
import salaryCalcFi from './../locales/fi/salaryCalculator.json';
import supportFi from './../locales/fi/support.json';
import translationFi from './../locales/fi/translation.json';
import yelFi from './../locales/fi/yel.json';
import translationSv from './../locales/sv/translation.json';
import { $Dictionary } from 'i18next/typescript/helpers';

interface IMomentLocales {
    [key: string]: any;
}

export const DEFAULT_LANGUAGE = 'fi';
const MOMENT_LOCALES: IMomentLocales = {
    en: localeEn,
    fi: localeFi,
};

export const resources = {
    en: {
        countries: countriesEn,
        eezyPay: eezyPayEn,
        occupations: occupationsEn,
        profile: profileEn,
        salaries: salariesEn,
        salarycalc: salaryCalcEn,
        support: supportEn,
        translations: translationEn,
        yel: yelEn,
    },
    fi: {
        countries: countriesFi,
        eezyPay: eezyPayFi,
        occupations: occupationsFi,
        profile: profileFi,
        salaries: salariesFi,
        salarycalc: salaryCalcFi,
        support: supportFi,
        translations: translationFi,
        yel: yelFi,
    },
    sv: {
        translations: translationSv,
    },
};

i18n.use(HttpApi)
    .use(intervalPlural)
    .use(LanguageDetector)
    .init({
        debug: false,
        defaultNS: 'translations',
        fallbackLng: DEFAULT_LANGUAGE,
        interpolation: {
            escapeValue: false,
            skipOnVariables: false,
        },
        lng: navigator.language,
        ns: ['translations', 'yel'],

        resources,
        react: { useSuspense: false },
    });

Numeral.register('locale', 'fi', {
    abbreviations: {
        billion: 'b',
        million: 'm',
        thousand: 'k',
        trillion: 't',
    },
    currency: {
        symbol: '€',
    },
    delimiters: {
        decimal: ',',
        thousands: '\u00A0',
    },
    ordinal: () => {
        return '';
    },
});

Numeral.locale('fi');
moment.updateLocale('en', {
    longDateFormat: {
        ...localeEn.longDateFormat,
        l: 'D/M/YYYY',
    },
});

export default i18n;

// TODO: Remove these two helper functions and start using useTranslation hook everywhere
export const trans = (str: string, options?: TOptions & $Dictionary) => {
    const { t } = i18next;
    if (options) {
        return t(str, str, options) as string;
    } else {
        return t(str) as string;
    }
};

export const transFixed = ({
    str,
    lang,
    options,
}: {
    str: string;
    lang?: LanguageCode;
    options: TOptions & $Dictionary & { defaultValue: string };
}) => {
    if (!lang) {
        return options ? trans(str, options) : trans(str);
    }

    return i18n.getFixedT(lang, undefined, undefined)(str, options) as string;
};

export const changeLanguage = (lang: UiLanguage) => {
    if (!(lang in MOMENT_LOCALES)) {
        lang = DEFAULT_LANGUAGE;
    }

    i18n.changeLanguage(lang);
    Numeral.locale(lang);
    moment.locale(lang);
    window.document.documentElement.lang = lang;
};

export const localDateFormat = () => {
    switch (moment.locale()) {
        case 'fi':
            return 'dd.MM.yyyy';
        default:
            return 'dd/MM/yyyy';
    }
};
