import { gql } from '@apollo/client';

const GET_THIRD_PARTY_PAYMENTS = gql`
    query getPayments($searchTerm: String, $thirdParty: String) {
        transactions(
            order_by: { completed: desc }
            where: {
                _and: {
                    status: { _eq: "COMPLETE" }
                    _or: [
                        { serviceName: { _ilike: $searchTerm } }
                        { firstName: { _ilike: $searchTerm } }
                        { lastName: { _ilike: $searchTerm } }
                    ]
                    thirdParty: { _eq: $thirdParty }
                }
            }
        ) {
            orderNumber
            serviceName
            lastName
            firstName
            paymentBrand
            completed
            servicePrice
            thirdParty
        }
    }
`;

export default GET_THIRD_PARTY_PAYMENTS;
