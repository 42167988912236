import { gql } from '@apollo/client';

const GET_PAYMENTS = gql`
    query getPayments($searchTerm: String) {
        transactions(
            order_by: { completed: desc }
            where: {
                status: { _eq: "COMPLETE" }
                _or: [
                    { serviceName: { _ilike: $searchTerm } }
                    { firstName: { _ilike: $searchTerm } }
                    { lastName: { _ilike: $searchTerm } }
                ]
            }
        ) {
            orderNumber
            serviceName
            lastName
            firstName
            paymentBrand
            completed
            servicePrice
            thirdParty
        }
    }
`;

export default GET_PAYMENTS;
