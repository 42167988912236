import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';
import { UiLanguage } from '../../../../shared/src/types/user';
import { showModals } from '../../actions/auth';
import { InlineButtonLink } from '../../components/Buttons';
import { Bold, P } from '../../components/textElements';
import { COLOR_GREYHOUND, COLOR_BLUM } from '../../styles/variables';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../components/Icon';

const Li = styled.li`
    color: ${COLOR_GREYHOUND};
    margin-top: 15px;
`;

const List = styled.ul`
    list-style: disc;
    margin-left: 15px;
`;

interface IGuideModalProps {
    language: UiLanguage;
    showModals: (modals: string[]) => void;
}

const GuideModal = (props: IGuideModalProps) => {
    const { t } = useTranslation();

    return (
        <div>
            <P color={COLOR_GREYHOUND}>{t('dashboard.messages.guide.1-1')}</P>

            <div style={{ margin: '15px 0' }}>
                <ul>
                    <Li>
                        1.{' '}
                        <InlineButtonLink
                            onClick={() => {
                                props.showModals(['AUTH']);
                            }}
                        >
                            {t('dashboard.messages.guide.2-1')}
                        </InlineButtonLink>
                        {t('dashboard.messages.guide.2-2')}
                        <Link to="/support" rel="noopener noreferrer" target="_blank">
                            {t('dashboard.messages.guide.2-3')}
                        </Link>
                    </Li>
                    <Li>
                        2.{' '}
                        <Link to="/profile" rel="noopener noreferrer" target="_blank">
                            {t('dashboard.messages.guide.3')}
                        </Link>
                    </Li>
                    <Li>
                        3.{' '}
                        <Link to="/profile" rel="noopener noreferrer" target="_blank">
                            {t('dashboard.messages.guide.4')}
                        </Link>
                    </Li>
                </ul>
            </div>
            <div>
                <P color={COLOR_GREYHOUND}>{t('dashboard.messages.guide.6')}</P>
            </div>
            <div>
                <List>
                    <li>
                        <P color={COLOR_GREYHOUND}>
                            <Bold>{t('dashboard.messages.guide.7')}</Bold>
                            {t('dashboard.messages.guide.7-1')}
                        </P>
                    </li>
                    <li>
                        <P color={COLOR_GREYHOUND}>
                            <Bold>{t('dashboard.messages.guide.8')}</Bold>
                            {t('dashboard.messages.guide.8-1')}{' '}
                            <a
                                href={
                                    props.language === 'fi'
                                        ? 'https://kevytyrittajat.eezy.fi/eezypay/'
                                        : 'https://kevytyrittajat.eezy.fi/en/eezypay-en/'
                                }
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {t('dashboard.messages.guide.8-2')}
                                <Icon
                                    className="small"
                                    color={COLOR_BLUM}
                                    icon={['far', 'arrow-up-right-from-square']}
                                    style={{ marginLeft: 10 }}
                                />
                            </a>
                        </P>
                    </li>
                </List>
            </div>

            <div>
                <P>
                    <Bold>{t('dashboard.messages.guide.9')}</Bold>
                </P>
            </div>
            <div>
                <List>
                    <Li>
                        <Bold>{t('dashboard.messages.guide.9-1')}</Bold> {t('dashboard.messages.guide.9-2')}
                    </Li>
                    <Li>
                        <Bold>{t('dashboard.messages.guide.9-3')}</Bold> {t('dashboard.messages.guide.9-4')}{' '}
                        <a
                            href={
                                props.language === 'fi'
                                    ? 'https://kevytyrittajat.eezy.fi/kevytyrittajyys/kevytyrittajan-vakuutukset/'
                                    : 'https://kevytyrittajat.eezy.fi/en/light-entrepreneurship/light-entrepreneurs-insurances/'
                            }
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {t('dashboard.messages.guide.9-5')}
                            <Icon
                                className="small"
                                color={COLOR_BLUM}
                                icon={['far', 'arrow-up-right-from-square']}
                                style={{ marginLeft: 10 }}
                            />
                        </a>
                    </Li>
                    <Li>
                        <Bold>{t('dashboard.messages.guide.9-6')}</Bold>{' '}
                        {t('dashboard.messages.guide.9-7')
                            .split(props.language === 'fi' ? 'täältä' : 'here')
                            .map((part, index) => (
                                <React.Fragment key={index}>
                                    {part}
                                    {index === 0 && (
                                        <Link to="/yel">{props.language === 'fi' ? 'täältä' : 'here'}</Link>
                                    )}
                                </React.Fragment>
                            ))}{' '}
                        <a
                            href={
                                props.language === 'fi'
                                    ? 'https://kevytyrittajat.eezy.fi/kevytyrittajyys/kevytyrittajan-vakuutukset/yel-vakuutus/'
                                    : 'https://kevytyrittajat.eezy.fi/en/light-entrepreneurship/light-entrepreneurs-insurances/yel-insurance/'
                            }
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {t('dashboard.messages.guide.9-8')}
                            <Icon
                                className="small"
                                color={COLOR_BLUM}
                                icon={['far', 'arrow-up-right-from-square']}
                                style={{ marginLeft: 10 }}
                            />
                        </a>
                    </Li>
                    <Li>
                        <Bold>{t('dashboard.messages.guide.9-9')}</Bold> {t('dashboard.messages.guide.9-10')}{' '}
                        <Link to="/profile" rel="noopener noreferrer" target="_blank">
                            {t('dashboard.messages.guide.9-11')}
                        </Link>{' '}
                    </Li>
                    <Li>
                        <Bold>{t('dashboard.messages.guide.9-12')}</Bold> {t('dashboard.messages.guide.9-13')}{' '}
                        <Link to="/profile" rel="noopener noreferrer" target="_blank">
                            {t('dashboard.messages.guide.9-14')}
                        </Link>{' '}
                    </Li>
                    <Li>
                        <Bold>{t('dashboard.messages.guide.9-15')}</Bold> {t('dashboard.messages.guide.9-16')}{' '}
                        <a
                            href={
                                props.language === 'fi'
                                    ? 'https://kevytyrittajat.eezy.fi/kevytyrittajyys/nain-kevytyrittaminen-toimii/lisapalvelut/eezyexpress-palkka/'
                                    : 'https://kevytyrittajat.eezy.fi/en/light-entrepreneurship/this-is-how-our-service-works/additional-services/eezyexpress-salary/'
                            }
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {t('dashboard.messages.guide.9-17')}
                            <Icon
                                className="small"
                                color={COLOR_BLUM}
                                icon={['far', 'arrow-up-right-from-square']}
                                style={{ marginLeft: 10 }}
                            />
                        </a>
                    </Li>
                    <Li>
                        <Bold>{t('dashboard.messages.guide.9-18')}</Bold> {t('dashboard.messages.guide.9-19')}{' '}
                        <a
                            href={
                                props.language === 'fi'
                                    ? 'https://play.google.com/store/apps/details?id=com.eezyosk.app&hl=fi'
                                    : 'https://play.google.com/store/apps/details?id=com.eezyosk.app&hl=en'
                            }
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {t('dashboard.messages.guide.9-20')}
                            <Icon
                                className="small"
                                color={COLOR_BLUM}
                                icon={['far', 'arrow-up-right-from-square']}
                                style={{ marginLeft: 10 }}
                            />
                        </a>{' '}
                        {t('dashboard.messages.guide.9-21')}{' '}
                        <a
                            href={
                                props.language === 'fi'
                                    ? 'https://apps.apple.com/fi/app/eezy-kevytyritt%C3%A4j%C3%A4t/id1466922600?l=fi'
                                    : 'https://apps.apple.com/fi/app/eezy-kevytyritt%C3%A4j%C3%A4t/id1466922600?l=en'
                            }
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {t('dashboard.messages.guide.9-22')}
                            <Icon
                                className="small"
                                color={COLOR_BLUM}
                                icon={['far', 'arrow-up-right-from-square']}
                                style={{ marginLeft: 10 }}
                            />
                        </a>
                    </Li>
                    <Li>
                        <Bold>{t('dashboard.messages.guide.9-23')}</Bold> {t('dashboard.messages.guide.9-24')}{' '}
                        <a
                            href={
                                props.language === 'fi'
                                    ? 'https://vello.fi/eezykevytyrittajat/'
                                    : 'https://vello.fi/eezykevytyrittajat/'
                            }
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {t('dashboard.messages.guide.9-25')}
                            <Icon
                                className="small"
                                color={COLOR_BLUM}
                                icon={['far', 'arrow-up-right-from-square']}
                                style={{ marginLeft: 10 }}
                            />
                        </a>
                    </Li>
                </List>
            </div>
            <div>
                <P>{t('dashboard.messages.guide.10')}</P>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        language: state.user.language,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        showModals: (modals: string[]) => {
            dispatch(showModals(modals));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuideModal);
