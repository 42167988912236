import React, { HTMLProps, MouseEvent, ReactNode, useState } from 'react';
import styled from 'styled-components';
import {
    BORDER_ACTIVE_FIELD,
    BORDER_OPTIONAL_FIELD,
    BORDER_REQUIRED_FIELD,
    COLOR_GREYS_ANATOMY,
    COLOR_IMPORTANT
} from '../../styles/variables';
import { Icon } from '../Icon';
import ArrowPopover from '../modals/ArrowPopover';

export const Wrapper = styled.div`
    background: ${COLOR_GREYS_ANATOMY};
    cursor: ${(props: { editable?: boolean }) =>
        props.editable ? 'pointer' : 'default'};
    padding: 2px;
    position: relative;
    border-bottom: ${BORDER_REQUIRED_FIELD};

    &:focus {
        border-bottom: ${BORDER_ACTIVE_FIELD} !important;
    }
`;

const IconWrapper = styled.div`
    position: absolute;
    top: calc(50% - 10px);
    left: -30px;
`;

interface IPopoverInputProps extends HTMLProps<HTMLDivElement> {
    id: string;
    anchorEl?: any;
    editable?: boolean;
    onClick: (e: MouseEvent) => void;
    onClose: () => void;
    popoverContent: ReactNode;
    showArrow?: boolean;
}

interface IInputFieldProps {
    placeholder: string;
    value: any;
}

export const PopoverInputField = (props: IInputFieldProps) => {
    return (
        <div data-mf-replace='**REMOVED**' style={{ opacity: props.value ? 1 : 0.5 }}>
            {props.value || props.placeholder}
        </div>
    );
};

export const PopoverInput = (props: IPopoverInputProps) => {
    const [selectedId, setSelectedId] = useState<string | null>(null);

    const onClick = (e: any) => {
        props.onClick(e);
        setSelectedId(e.currentTarget.id);
    };

    const onClose = () => {
        props.onClose();
        setSelectedId(null);
    };

    const popoverOpen = Boolean(props.anchorEl && selectedId === props.id);
    return (
        <>
            <Wrapper
                aria-pressed={popoverOpen}
                id={props.id}
                editable={props.editable}
                onClick={onClick}
                onKeyPress={onClick}
                // WAI aria
                role="button"
                style={{
                    ...props.style,
                    borderBottom: popoverOpen
                        ? `${BORDER_ACTIVE_FIELD}`
                        : props.required
                        ? `${BORDER_REQUIRED_FIELD}`
                        : `${BORDER_OPTIONAL_FIELD}`
                }}
                tabIndex={0}
            >
                {props.children}
                {props.showArrow && (
                    <IconWrapper>
                        <Icon
                            icon={['far', 'circle-right']}
                            color={COLOR_IMPORTANT}
                        />
                    </IconWrapper>
                )}
            </Wrapper>
            <ArrowPopover
                popoverOpen={popoverOpen}
                anchorEl={props.anchorEl}
                onClose={onClose}
            >
                {props.popoverContent}
            </ArrowPopover>
        </>
    );
};
