import React from 'react';
import styled from 'styled-components';
import { FormRadio, FormRow, FormSection } from '../../components/form';
import { SCREEN_S } from '../../styles/variables';
import { trans } from '../../utils';
import {
    ICostComponentProps,
    TRAVEL_CAR_MAP,
    TRAVEL_CAR_ROUND,
    TRAVEL_CAR_ROUTE,
    TRAVEL_OTHER_ROUND,
    TRAVEL_OTHER_ROUTE
} from '../../utils/costs/costLogic';

const Wrapper = styled.div`
    margin-bottom: 30px;
    @media (min-width: ${SCREEN_S}px) {
        label {
            min-width: 110px;
        }
    }
`;

const getValueByStep = (step: number) => {
    switch (step) {
        case TRAVEL_CAR_ROUND:
        case TRAVEL_OTHER_ROUND:
            return 'round';
        case TRAVEL_CAR_ROUTE:
        case TRAVEL_OTHER_ROUTE:
            return 'route';
        case TRAVEL_CAR_MAP:
            return 'map';
        default:
            return '';
    }
};

export const TravelTypeSelection = (props: ICostComponentProps) => {
    const { dispatch, step } = props;

    const CAR_STEPS = [TRAVEL_CAR_ROUND, TRAVEL_CAR_ROUTE, TRAVEL_CAR_MAP];
    const OTHER_STEPS = [TRAVEL_OTHER_ROUND, TRAVEL_OTHER_ROUTE];

    const handleTypeChange = (val: string) => {
        let nextStep;
        if (CAR_STEPS.includes(step)) {
            if (val === 'round') {
                nextStep = TRAVEL_CAR_ROUND;
            } else if (val === 'route') {
                nextStep = TRAVEL_CAR_ROUTE;
            } else {
                nextStep = TRAVEL_CAR_MAP;
            }
        } else if (OTHER_STEPS.includes(step)) {
            if (val === 'round') {
                nextStep = TRAVEL_OTHER_ROUND;
            } else if (val === 'route') {
                nextStep = TRAVEL_OTHER_ROUTE;
            }
        }

        dispatch({ payload: { step: nextStep }, type: 'REPLACE_STEP' });
    };

    const getOptions = (s: number) => {
        const options = [
            { label: trans('costs.travel-type.round'), value: 'round' },
            { label: trans('costs.travel-type.route'), value: 'route' }
        ];
        if (CAR_STEPS.includes(s)) {
            return options;
        } else {
            return options;
        }
    };

    return (
        <Wrapper>
            <FormSection>
                <FormRow>
                    <FormRadio
                        label={trans('costs.travel')}
                        name="travel-type"
                        options={getOptions(step)}
                        onChange={val => handleTypeChange(val)}
                        required
                        value={getValueByStep(step)}
                    />
                </FormRow>
            </FormSection>
        </Wrapper>
    );
};
