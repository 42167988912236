import React, { FC, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { EezyButton } from 'components/Buttons';
import { Dropdown, RadioElement, UnitElement } from 'components/form';
import { formatCents } from 'utils';
import { splitToParagraphs } from 'utils/ui';
import { havingYel, yelLogic } from 'utils/yel';
import { yelInsuranceCompanyList } from 'utils/yel/variables';
import { shouldShowPayerSelector } from 'utils/yel/yelLogic';
import { IncomeDetails } from './IncomeDetails';
import { FormNumberInput } from 'components/form';
import { sortObjectsByLabel } from 'utils/str';
import { TolCodes } from 'utils/yel/TolCodes';
import occupationsList from 'locales/en/occupations.json';
import {
    RenewalNote,
    YelEmergency,
    YelExtraCharge,
    YelNotNeeded,
    YelPayingNote,
    YelStartDescription,
} from './YelTexts';
import { YelQuestion } from './YelQuestion';
import { Combobox } from 'components/form/Combobox';
import Select from 'components/ui/select';
import { useYelData } from 'queries/useYelData';
import useYelStore from 'stores/yelStore';
import { YelPayerType } from '../../../../shared/src/types/yel';

const YelWizard: FC = () => {
    const { t } = useTranslation();
    const [yelStoreData, updateYelStore] = useYelStore((state) => [state.yelData, state.updateYelStore]);

    const { data } = useYelData();

    const modifyOccupation = (id: number, name: string) => {
        const code = TolCodes[id as keyof typeof TolCodes];
        return `${name} [${code ? code : ''}]`;
    };

    const occupationOptions =
        Object.keys(occupationsList).map((id: string) => {
            return {
                label: t(`occupations:${id}`),
                value: modifyOccupation(parseInt(id), t(`occupations:${id}`)),
            };
        }) || [];

    const handleHasYelChange = (e: FormEvent<HTMLInputElement>) => {
        updateYelStore({ hasYel: e.currentTarget.value as havingYel.HasYELType });
    };

    const handleDateStartedChange = (date: string) => {
        updateYelStore({ dateStarted: date });
    };

    const handleIncomeChange = (income: number, unit: string) => {
        const estimatedIncome = unit === 'monthly' ? income * 12 : income;
        updateYelStore({ estimatedIncome });
    };

    const handleEstimatedInvoicingChange = (estimatedInvoicing: number) => {
        updateYelStore({ estimatedInvoicing });
    };

    const handleOccupationChange = (occupation: string) => {
        updateYelStore({ occupation });
    };

    const handleIsStartingChange = (e: FormEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value === 'true';
        updateYelStore({ isStarting: val, dateStarted: '' });
    };

    const handleWhoPaysChange = (e: FormEvent<HTMLInputElement>) => {
        updateYelStore({ whoPays: e.currentTarget.value as YelPayerType });

        if (e.currentTarget.value === 'eezy') {
            updateYelStore({ numberOfQuotas: 2 });
        }
    };

    const handleHasSameIncomeAsBeforeChange = (e: FormEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value === 'true';
        updateYelStore({ hasSameIncomeAsBefore: val });
    };

    const handleInsuranceCompanChange = (e: FormEvent<HTMLSelectElement>) => {
        const value = e.currentTarget.value;
        updateYelStore({ insuranceCompany: value });
    };

    const showSummary = () => {
        updateYelStore({ showSummary: true });
    };

    return (
        <div className="flex flex-col gap-6 p-2">
            <div>
                <YelStartDescription />
            </div>

            <YelQuestion
                helpText={t('yel:help.question-has-yel') || ''}
                label={`${t('yel:question.has-yel')}`}
            >
                <RadioElement
                    name="has-yel"
                    onChange={handleHasYelChange}
                    options={[
                        {
                            label: t('general.yes'),
                            value: 'Yes',
                        },
                        {
                            label: t('yel:has-had'),
                            value: 'HasHadBefore',
                        },
                        {
                            label: t('general.no'),
                            value: 'No',
                        },
                    ]}
                    selectedOption={yelStoreData.hasYel}
                />
            </YelQuestion>

            {(yelStoreData.hasYel === 'Yes' || yelStoreData.hasYel === 'HasHadBefore') && <RenewalNote />}
            {yelStoreData.hasYel === 'Yes' && (
                <>
                    <YelQuestion
                        className="sm:flex-col"
                        helpText={t('yel:help.question-income') || ''}
                        label={`${t('yel:question.income')}`}
                    >
                        <RadioElement
                            name="estimate-income"
                            onChange={handleHasSameIncomeAsBeforeChange}
                            options={[
                                {
                                    label: t('yel:same-income'),
                                    value: true,
                                },
                                {
                                    label: t('yel:income-is'),
                                    unitOptions: {
                                        name: 'estimated-income',
                                        onChange: handleIncomeChange,
                                        value: data.estimatedIncome,
                                    },
                                    value: false,
                                },
                            ]}
                            selectedOption={yelStoreData.hasSameIncomeAsBefore}
                        />
                    </YelQuestion>

                    {yelLogic.shouldShowIncomeDetails(yelStoreData) && <IncomeDetails />}
                </>
            )}

            {yelLogic.shouldAskStartDate(yelStoreData) && (
                <YelQuestion
                    className="sm:flex-col lg:flex-row"
                    helpText={splitToParagraphs(
                        t('yel:help.question-startdate', {
                            discountPercentage: data.collectionInfo.discountPercentage,
                            minIncome: formatCents(data.incomeLimits.min),
                            minMonthlyIncome: Math.round(data.incomeLimits.min / 100 / 12),
                        }),
                    )}
                    label={`${t('yel:question.startdate')}`}
                >
                    <RadioElement
                        name="date-started"
                        onChange={handleIsStartingChange}
                        options={[
                            {
                                label: t('yel:only-starting'),
                                value: true,
                            },
                            {
                                dateOptions: {
                                    label: t('yel:question.startdate'),
                                    onChange: handleDateStartedChange,
                                    selectedDate: yelStoreData.dateStarted,
                                },
                                label: t('yel:i-started'),
                                labelShort: t('yel:i-started-short') || '',
                                value: false,
                            },
                        ]}
                        selectedOption={yelStoreData.isStarting}
                    />
                </YelQuestion>
            )}

            {yelLogic.shouldShowExtraCharge(yelStoreData) && <YelExtraCharge />}

            {yelLogic.shouldShowYelEmergency(yelStoreData) && <YelEmergency />}

            {yelLogic.shouldAskIncome(yelStoreData) && (
                <>
                    <div className="flex gap-8 justify-between flex-col xl:flex-row">
                        <YelQuestion
                            helpText={t('yel:help.question-invoicing') || ''}
                            label={`${t('yel:question.invoicing')}`}
                        >
                            <FormNumberInput
                                isEur={true}
                                endAdornment={t('form.eurs-year').trim()}
                                name={'estimated-invoicing'}
                                onChange={(val) => {
                                    handleEstimatedInvoicingChange(Number(val));
                                }}
                                required
                                value={yelStoreData.estimatedInvoicing}
                            />
                        </YelQuestion>

                        <YelQuestion
                            containerClassName="flex-[1.5]"
                            helpText={t('yel:help.question-income') || ''}
                            label={`${t('yel:question.income')}`}
                        >
                            <UnitElement
                                name={'income'}
                                onChange={handleIncomeChange}
                                value={yelStoreData.estimatedIncome}
                            />
                        </YelQuestion>

                        <YelQuestion
                            className="*:flex-initial"
                            helpText={t('yel:help.question-occupation') || ''}
                            label={`${t('yel:question.occupation')}`}
                        >
                            <Combobox
                                defaultValue={yelStoreData.occupation || ''}
                                onChange={handleOccupationChange}
                                options={sortObjectsByLabel(
                                    occupationOptions.filter((occupation) => occupation.label !== ''),
                                )}
                                selectLabel={t('yel:select-occupation')}
                                searchLabel={t('yel:search-occupation')}
                                notFoundLabel={t('yel:not-found-occupation')}
                            />
                        </YelQuestion>
                    </div>

                    {yelLogic.shouldShowIncomeDetails(yelStoreData) && <IncomeDetails />}
                    <div className="flex"></div>
                </>
            )}

            {yelLogic.shouldAskFormerInsuranceCompany(yelStoreData) && (
                <YelQuestion label={`${t('yel:question.current-insurance-company')}`}>
                    <Dropdown
                        disabledValue="Valitse nykyinen YEL-vakuutusyhtiösi"
                        onChange={handleInsuranceCompanChange}
                        options={yelInsuranceCompanyList}
                        selectedValue={yelStoreData.insuranceCompany}
                    />
                </YelQuestion>
            )}

            {shouldShowPayerSelector({ ...yelStoreData, incomeLimits: data.incomeLimits }) && (
                <>
                    <YelQuestion className="sm:flex-row" label={`${t('yel:paying')}`}>
                        <RadioElement
                            name="who-pays"
                            onChange={handleWhoPaysChange}
                            options={[
                                {
                                    label: t('yel:payer-self'),
                                    value: 'self',
                                },
                                {
                                    label: t('yel:payer-eezy'),
                                    value: 'eezy',
                                },
                            ]}
                            selectedOption={yelStoreData.whoPays}
                        />
                    </YelQuestion>
                    <YelPayingNote />

                    {yelLogic.shouldShowNumberOfQuotas(yelStoreData) && (
                        <div>
                            <h2 className="font-bold mb-2">{t('yel:number-of-quotas')}</h2>
                            <div className="flex">
                                <div className="flex flex-col w-[150px]">
                                    <div className="border-slate-300 border-b border-solid">
                                        <Select
                                            options={yelLogic.getQuotaOptions()}
                                            triggerText={t(
                                                'profile:settings.terminate-account.termination-reasons.select',
                                            )}
                                            onChange={(value) =>
                                                updateYelStore({ numberOfQuotas: Number(value) })
                                            }
                                            value={String(yelStoreData.numberOfQuotas)}
                                        />
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <p>{t('yel:number-of-quotas-2')}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            {yelLogic.shouldShowYelNotNeeded({ ...yelStoreData, incomeLimits: data.incomeLimits }) && (
                <YelNotNeeded />
            )}

            {yelLogic.shouldShowContinue({ ...yelStoreData, incomeLimits: data.incomeLimits }) && (
                <div>
                    <EezyButton color="important" dark onClick={showSummary} style={{ padding: '0 80px' }}>
                        {t('general.continue')}
                    </EezyButton>
                </div>
            )}
        </div>
    );
};

export default YelWizard;
