import { MouseEvent, useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import {
    IClient,
    IClientChoiceInput,
    IInvoice,
    IYtjBriefCompany,
    LanguageCode
} from '../../../../shared/src/types/invoice';
import { PopoverInput } from 'components/form/PopoverInput';
import { LabelCapsSmall, PSmall } from 'components/textElements';
import { InvoiceCol } from 'styles/invoiceStyles';
import { COLOR_BLACKWATER, COLOR_DARK_GRAY, SCREEN_S } from 'styles/variables';
import { trans } from 'utils';
import { transFixed } from 'utils/i18n';
import { openFillHelper } from './fillHelpers/utils';
import { InvoiceRecipientAutocomplete } from './InvoiceRecipientAutocomplete';
import { InvoiceRecipientDetails } from './InvoiceRecipientDetails';
import { InvoiceRecipientDetailsPanel } from './InvoiceRecipientDetailsPanel';
import { SELECTED_FILL_HELPER } from './queries/invoiceStateQueries';
import Show from 'components/ui/Show';

const StyledSection = styled(InvoiceCol)`
    margin: 0 0px 24px 0;
    min-width: 295px;
    position: relative;

    @media (min-width: ${SCREEN_S}px) {
        margin: 0 10px 24px 0;
    }

    & button.recipient-details {
        display: none;
        min-width: 90px;
        position: absolute;
        right: 7px;
        top: 25px;
    }
    & button.recipient-new {
        display: none;
        font-size: 13px;
        position: absolute;
        right: 7px;
        top: 64px;
    }

    &:hover button.recipient-details,
    &:active button.recipient-new,
    &:focus-within button.recipient-details,
    &:focus-within button.recipient-new {
        display: initial;
    }

    p {
        color: ${COLOR_DARK_GRAY};
    }
`;

type IInvoiceRecipientSectionProps = {
    editable: boolean;
    invoice?: IInvoice;
    invoiceLanguage?: LanguageCode;
    loading?: boolean;
    recipient?: IClient;
    handleUpdate: (o: object) => void;
}

const InvoiceRecipientSection: React.FC<IInvoiceRecipientSectionProps> = (props: IInvoiceRecipientSectionProps) => {
    const { client, data: localData } = useQuery(SELECTED_FILL_HELPER);
    const [openRecipientSelector, setOpenRecipientSelector] = useState(false);
    const [anchorEl, setAnchorEl] = useState<
        (EventTarget & Element) | null
    >(null);

    const setFH = (id: string) => {
        openFillHelper(client, 'recipient', id);
    };

    const handleEditRecipient = () => {
        closePopover();
        if (props.recipient?.id) {
            setFH(props.recipient?.id);
        }
    };

    const changeRecipient = () => {
        setOpenRecipientSelector(true);
    };

    const handleRecipientChange = (recipient: IClientChoiceInput) => {
        closePopover();
        props.handleUpdate({ recipient });
        //  in case fill helper is already open, update the data there
        if (localData?.fillHelper.id) {
            setFH(recipient.id);
        }
    };

    const handleYtjRecipientChange = (r: IYtjBriefCompany) => {
        closePopover();
        openFillHelper(client, 'new-ytj-recipient', r.businessId);
    };

    const createNewRecipient = () => {
        closePopover();
        openFillHelper(client, 'new-recipient');
    };

    const closePopover = () => {
        setOpenRecipientSelector(false);
        setAnchorEl(null);
    };

    const togglePopover = (e: MouseEvent) => {
        if (!props.editable) {
            return;
        }
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const Popper =
        !props.recipient || openRecipientSelector ? (
            <InvoiceRecipientAutocomplete
            id={'recipient-autocomplete'}
                handleRecipientChange={handleRecipientChange}
                handleYtjRecipientChange={handleYtjRecipientChange}
                handleNewCustomer={createNewRecipient}
            />
        ) : (
            <InvoiceRecipientDetailsPanel
                handleChangeRecipient={changeRecipient}
                handleEditRecipient={handleEditRecipient}
                recipient={props.recipient}
            />
        );

    return (
        <StyledSection>
            <LabelCapsSmall as="h4" color={COLOR_BLACKWATER}>
                {transFixed({ str: 'invoice.recipient', lang: props.invoiceLanguage, options: {defaultValue: '' }})}
            </LabelCapsSmall>

            {props.editable ? (
                <PopoverInput
                    id="recipient"
                    anchorEl={anchorEl}
                    editable={props.editable}
                    onClick={togglePopover}
                    onClose={closePopover}
                    popoverContent={Popper}
                    style={{ minHeight: '120px', padding: '8px' }}
                    required
                    showArrow={!props.recipient}
                >
                    {props.recipient ? (
                        <>
                            {props.recipient.name && (
                                <PSmall data-mf-replace="**REMOVED**">
                                    {props.recipient.name}
                                </PSmall>
                            )}
                            {props.invoice?.contactPersonName && (
                                <PSmall data-mf-replace="**REMOVED**">
                                    {props.invoice.contactPersonName}
                                </PSmall>
                            )}
                        </>
                    ) : (
                        trans('invoice.form.pick-recipient')
                    )}
                    <Show when={!!(props.recipient && !props.loading)}>
                        <InvoiceRecipientDetails recipient={props.recipient} />
                    </Show>
                </PopoverInput>
            ) : (
                <div>
                    <Show when={!!props.recipient?.name}>
                        <PSmall data-mf-replace="**REMOVED**">
                            {props.recipient?.name}
                        </PSmall>
                    </Show>
                    <Show when={!!props.invoice?.contactPersonName}>
                        <PSmall data-mf-replace="**REMOVED**">
                            {props.invoice?.contactPersonName}
                        </PSmall>
                    </Show>
                    <Show when={!!props.recipient && !props.loading}>
                        <InvoiceRecipientDetails recipient={props.recipient} />
                    </Show>
                </div>
            )}
        </StyledSection>
    );
};

export default InvoiceRecipientSection;
