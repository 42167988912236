export const validateForbiddenTerms = (
    text: string,
    forbiddenTerms: string
) => {
    const terms = forbiddenTerms.toLowerCase().split('\n');
    const textInput = text.toLowerCase().replace(/([^\w+äö]+|\s+)/g, '');

    return terms.some((word: string) =>
        textInput.includes(word.replace(/([^\w+äö]+|\s+)/g, ''))
    );
};
