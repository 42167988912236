import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Flex } from '../../components/Flex';
import { Icon } from '../../components/Icon';
import { PSmall } from '../../components/textElements';
import {
    COLOR_BLUM,
    COLOR_GREYHOUND,
    COLOR_GREYJOY,
    COLOR_STATUS_DONE,
    COLOR_WHITE_WALKER
} from '../../styles/variables';
import { trans } from '../../utils';
import { IStep } from '../../utils/guider/guiderUtils';
import { ModalType } from '../ModalOpener';

interface IStepTextProps {
    $isDone?: boolean;
    $notAvailable?: boolean;
    $type?: GuiderItemType;
}

const StepText = styled.div`
    a,
    button {
        color: ${(props: IStepTextProps) =>
            props.$type === 'goal' && props.$notAvailable
                ? COLOR_GREYHOUND
                : COLOR_BLUM};
        font-size: 15px;
        line-height: 15px;
        max-width: 200px;
        text-align: start;
        text-decoration: ${(props: IStepTextProps) =>
            props.$isDone ? 'line-through' : 'none'};
        word-break: break-word;
    }
`;

const IconWrapper = styled.div`
    background-color: ${(props: { $isDone: boolean }) =>
        props.$isDone ? COLOR_STATUS_DONE : 'transparent'};
    border-radius: 10px;
    text-align: center;
    line-height: 22px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

type GuiderItemType = 'step' | 'goal';

const StepIcon = ({ isDone }: { isDone: boolean }) => {
    return (
        <IconWrapper $isDone={isDone}>
            <Icon
                className={isDone ? 'small' : ''}
                color={isDone ? COLOR_WHITE_WALKER : COLOR_GREYJOY}
                icon={isDone ? ['far', 'check'] : ['far', 'circle']}
            />
        </IconWrapper>
    );
};

const ActionIcon = ({ notAvailable }: { notAvailable: boolean }) => {
    return (
        <Icon
            color={notAvailable ? COLOR_GREYJOY : COLOR_BLUM}
            icon={['far', 'arrow-right']}
            style={{ marginRight: 10 }}
        />
    );
};

interface IProps {
    notAvailable?: boolean;
    showModals: (modals: ModalType[]) => void;
    step: IStep;
    type: GuiderItemType;
}

const GuiderItem = (props: IProps) => {
    const handleModalOpen = () => {
        if (props.step.modal) {
            props.showModals([props.step.modal]);
        }
    };
    return (
        <Flex center style={{ height: 40 }}>
            {props.type === 'step' ? (
                <StepIcon isDone={props.step.isDone || false} />
            ) : (
                <ActionIcon notAvailable={props.notAvailable || false} />
            )}

            <StepText
                $isDone={props.step.isDone}
                $notAvailable={props.notAvailable}
                $type={props.type}
            >
                {props.notAvailable || props.step.isDone ? (
                    <PSmall color={COLOR_GREYJOY}>
                        {trans(props.step.title)}
                    </PSmall>
                ) : props.step.url ? (
                    <Link to={props.step.url || ''}>
                        {trans(props.step.title)}
                    </Link>
                ) : props.step.modal ? (
                    <button onClick={handleModalOpen}>
                        {trans(props.step.title)}
                    </button>
                ) : null}
            </StepText>
        </Flex>
    );
};

export default GuiderItem;
