import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { FormNumberInput } from './FormNumberInput';

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div,
    input {
        width: 100%;
    }
    input:first-of-type {
        margin-right: 10px;
    }
    input ~ input {
        margin-left: 10px;
    }
`;

interface IHoursMinutesProps {
    color?: string;
    error?: any;
    name: string;
    onChange: (date: Date) => void;
    required?: boolean;
    style?: CSSProperties;
    value?: Date;
}

export const HoursMinutesTime = (props: IHoursMinutesProps) => {
    const handleHoursChange = (val: number | string) => {
        let v = typeof val === 'number' ? val : parseInt(val, 10);

        if (v > 24) {
            v = v % 24;
        }
        const date = props.value
            ? new Date(new Date(props.value).setHours(v))
            : new Date(new Date().setHours(v));
        props.onChange(date);
    };

    const handleMinutesChange = (val: number | string) => {
        let v = typeof val === 'number' ? val : parseInt(val, 10);

        if (v > 60) {
            v = v % 60;
        }
        const date = props.value
            ? new Date(new Date(props.value).setMinutes(v))
            : new Date(new Date().setMinutes(v));
        props.onChange(date);
    };

    return (
        <Wrapper>
            <FormNumberInput
                error={props.error}
                name={props.name}
                onChange={handleHoursChange}
                placeholder="hh"
                required={props.required}
                style={{ textAlign: 'center', ...props.style }}
                value={props.value?.getHours()}
            />
            <span>:</span>
            <FormNumberInput
                error={props.error}
                leadingZero
                name={props.name}
                onChange={handleMinutesChange}
                placeholder="mm"
                required={props.required}
                style={{ textAlign: 'center', ...props.style }}
                value={props.value?.getMinutes()}
            />
        </Wrapper>
    );
};
