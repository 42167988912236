import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import {
    COLOR_BLACKWATER,
    COLOR_GREYJOY,
    COLOR_GRIPE,
    COLOR_STATUS_WAITING,
    COLOR_WHITE_WALKER,
    PAGEWIDTH_MAX,
    SCREEN_M
} from '../styles/variables';
import { Icon } from './Icon';

export const RibbonContainerInvoiceEdit = styled.div`
    bottom: 15%;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    right: -5px;
`;

export const RibbonContainerInvoicePreview = styled.div`
    bottom: 100px;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 2;
    right: 10px;
    @media (min-width: ${SCREEN_M}px) {
        bottom: 15%;
        right: 25px;
    }
    @media (min-width: ${PAGEWIDTH_MAX}px) {
        right: calc((100vw - (${PAGEWIDTH_MAX}px)) / 2 + 15px);
    }
`;

export const Ribbon = styled.button`
    color: ${props =>
        props.color === COLOR_GRIPE ? COLOR_WHITE_WALKER : COLOR_BLACKWATER};
    background-color: ${props => props.color};
    display: block;
    font-size: 13px;
    font-weight: bold;
    height: 30px;
    letter-spacing: 0.3px;
    line-height: 30px;
    padding-right: 10px;
    position: relative;
    text-align: right;
    text-transform: uppercase;

    && svg {
        font-size: 15px;
        height: 15px;
        margin-left: 5px;
        width: 15px;
    }

    & ~ & {
        margin-top: 10px;
    }

    &:before {
        content: '';
        position: absolute;
        display: block;
        border: 15px solid;
        border-color: ${props => props.color};
        border-left-color: transparent;
        left: -30px;
        width: 0px;
        top: 0;
    }
    &:after {
        bottom: -5px;
        content: '';
        display: block;
        padding: 5px 0px 0px 5px;
        position: absolute;
        right: 0;

        background: linear-gradient(
            to top left,
            transparent 0%,
            transparent 50%,
            ${props =>
                    props.color === COLOR_GRIPE
                        ? COLOR_GREYJOY
                        : COLOR_STATUS_WAITING}
                50%
        );
    }
`;

export const AttachmentRibbon = (props: ButtonHTMLAttributes<any>) => {
    const { children, ...restProps } = props;
    return (
        <Ribbon color={COLOR_GRIPE} {...restProps}>
            {props.children}
            <Icon
                icon={['far', 'paperclip']}
                color="white"
                style={{ opacity: 0.5 }}
            />
        </Ribbon>
    );
};

export const CostRibbon = (props: ButtonHTMLAttributes<any>) => {
    const { children, ...restProps } = props;
    return (
        <Ribbon color={COLOR_STATUS_WAITING} {...restProps}>
            {props.children}
            <Icon
                icon={['fal', 'coins']}
                color="black"
                style={{ opacity: 0.75 }}
            />
        </Ribbon>
    );
};
