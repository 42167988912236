import { gql } from '@apollo/client';

const UPDATE_COST_AFTER_SEND = gql`
    mutation UpdateCost(
        $costInvoiceId: Int!
        $costId: Int!
        $cost: InputReceiptCost!
    ) {
        updateReceiptCost(
            costInvoiceId: $costInvoiceId
            costId: $costId
            cost: $cost
        )
    }
`;

export default UPDATE_COST_AFTER_SEND;
