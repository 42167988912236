import styled from 'styled-components';
import {
    COLOR_BLACKWATER,
    COLOR_BLUM,
    COLOR_GREY_FOG,
    COLOR_WHITE_GRAY,
    COLOR_WHITE_WALKER,
    FOOTER_HEIGHT_MOBILE,
    FOOTER_HEIGHT,
    SCREEN_M,
    TOPBAR_HEIGHT
} from './variables';

export const H1 = styled.h1`
    font-size: 26px;
    font-weight: bold;
    color: ${COLOR_BLACKWATER};
    padding: 10px;
    @media (min-width: ${SCREEN_M}px) {
        font-size: 28px;
    }
`;

export const H2 = styled.h2`
    color: ${COLOR_BLACKWATER};
    font-size: 14px;
    font-weight: bold;
`;

export const H3 = styled.h3`
    color: ${COLOR_BLACKWATER};
    font-weight: bold;
    font-size: 16px;

    @media (min-width: ${SCREEN_M}px) {
        font-size: 18px;
    }
`;

export const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT_MOBILE}px);

    .navBar ~ & {
        padding: 20px 10px 10px 10px;
    }

    @media (min-width: ${SCREEN_M}px) {
        min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT}px);

        .navBar ~ & {
            margin: auto;
        }
    }

    p {
        color: ${COLOR_BLACKWATER};
        line-height: 18px;
        margin: 0 0 5px 0;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`;

export const WrapperRow = styled.div`
    align-items: baseline;
    display: flex;
    flex-direction: ${(props: { $reverse?: boolean }) =>
        props.$reverse ? 'row-reverse' : 'row'};
    justify-content: space-between;
    padding: 5px 0;
    > *:not(:first-child) {
        margin-left: 10px;
    }

    h3,
    p {
        margin: 0;
    }
`;

interface IButtonProps {
    disabled?: boolean;
}

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;

    > *:not(:first-child) {
        margin-left: 10px;
    }

    @media (min-width: ${SCREEN_M}px) {
        justify-content: space-between;
    }
`;

export const PrimaryButton = styled.button`
    align-self: center;
    background-color: ${(props: IButtonProps) =>
        props.disabled ? `${COLOR_BLUM}35` : `${COLOR_BLUM}`};
    border: ${(props: IButtonProps) =>
        props.disabled ? '' : `2px solid ${COLOR_BLUM}`};
    border-radius: 4px;
    color: ${COLOR_WHITE_WALKER};
    cursor: ${(props: { disabled?: boolean }) =>
        props.disabled ? 'not-allowed' : 'pointer'};
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    width: 70%;

    @media (max-width: 380px) {
        min-height: 56px;
    }

    @media (min-width: ${SCREEN_M}px) {
        align-self: flex-end;
        width: 287px;
    }
`;

export const SecondaryButton = styled(PrimaryButton)`
    background-color: ${(props: IButtonProps) =>
        props.disabled ? `${COLOR_WHITE_GRAY}` : `${COLOR_WHITE_WALKER}`};
    color: ${(props: IButtonProps) =>
        props.disabled ? `${COLOR_GREY_FOG}` : `${COLOR_BLUM}`};
`;

export const HelpButton = styled.button`
    color: ${COLOR_BLUM};
    font-size: 13px;
    font-weight: 600;
`;

export const BorderBox = styled.div`
    border: 1px solid ${COLOR_GREY_FOG};
    border-radius: 4px;
    display: block;
    margin: 10px 0 20px 0;
    min-height: 80px;
    padding: 15px;
    width: 100%;
`;
