import { CSSProperties, ReactNode, useState } from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Fade } from '@mui/material';
import styled from 'styled-components';
import {
    COLOR_BLACKWATER,
    COLOR_BLUM,
    COLOR_STATUS_DONE
} from 'styles/variables';
import { Icon } from './Icon';

const Wrapper = styled.div`
    background-color: ${props => props.color || COLOR_STATUS_DONE};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;

    div.intro {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        p {
            margin-top: 0px;
        }
    }
    button.close {
        align-self: flex-start;
    }
    p {
        color: ${COLOR_BLACKWATER};
    }
    div.content {
        flex-direction: column;
    }
`;

interface IProps {
    color?: string;
    disableClose?: boolean;
    topContent?: ReactNode;
    children?: ReactNode;
    style?: CSSProperties;
}

const ColorBox = (props: IProps) => {
    const [visible, setVisible] = useState(true);

    if (!visible) {
        return null;
    }

    return (
        <Fade in={visible} timeout={200}>
            <Wrapper style={props.style} color={props.color}>
                {!props.disableClose && (
                    <div className="intro">
                        <button
                            className="close"
                            onClick={() => {
                                setVisible(false);
                            }}
                        >
                            <Icon
                                icon={faTimes as IconProp}
                                color={COLOR_BLUM}
                                style={{ opacity: 0.5 }}
                            />
                        </button>
                        {props.topContent}
                    </div>
                )}
                <div className="content">{props.children}</div>
            </Wrapper>
        </Fade>
    );
};

export default ColorBox;
