import { FC, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePickerYel, Dropdown } from 'components/form';
import { ButtonContainer, PrimaryButton, SecondaryButton, Wrapper } from 'styles/yelStyles';
import { QuestionWrapper } from './QuestionWrapper';
import useYelStore from 'stores/yelStore';
import { useYelData } from 'queries/useYelData';
import { useMutation } from '@tanstack/react-query';
import API from 'utils/API';
import { currentDate } from 'utils';
import { ERROR_GENERAL_YEL, getError } from 'utils/error';
import { AxiosError } from 'axios';

const YelTerminate: FC = () => {
    const { t } = useTranslation();
    const updateYelStore = useYelStore((state) => state.updateYelStore);
    const { data, setError } = useYelData();
    const [terminationDate, setTerminationDate] = useState('');
    const [terminationReason, setTerminationReason] = useState('');

    const postTermination = useMutation({
        mutationFn: async () => {
            try {
                const response = await API.put(`/yel/${data.id}/terminate`, {
                    terminationDate: terminationDate || currentDate(),
                    terminationReason,
                });

                if (response.status === 200 && response.data.status === 'do_redirect') {
                    window.location = response.data.location;
                } else {
                    setError(getError({ errorType: ERROR_GENERAL_YEL }));
                }
            } catch (error) {
                if (error instanceof AxiosError) {
                    setError(getError({ errorType: ERROR_GENERAL_YEL }));
                }

                throw error;
            }
        },
    });

    const handleDateChange = (terminationDate: string) => {
        setTerminationDate(terminationDate);
    };

    const handleReasonChange = (e: FormEvent<HTMLSelectElement>) => {
        const terminationReason = e.currentTarget.value;
        setTerminationReason(terminationReason);
    };

    const handleCancel = () => updateYelStore({ showTerminate: false });

    const handleSubmit = () => {
        postTermination.mutate();
    };

    const yelTerminationReasonList = [
        { key: 'income', value: t('yel:termination-reasons.income') },
        { key: 'stopped', value: t('yel:termination-reasons.stopped') },
        { key: 'parent', value: t('yel:termination-reasons.parent') },
    ];

    return (
        <>
            <Wrapper>
                <QuestionWrapper label={t('yel:question.termination-date') || ''} vertical>
                    <DatePickerYel
                        label={t('yel:terminate-date-label')}
                        onChange={handleDateChange}
                        selectedDate={terminationDate}
                    />
                </QuestionWrapper>

                <QuestionWrapper label={t('yel:question.termination-reason') || ''} vertical>
                    <Dropdown
                        disabledValue={t('yel:termination-default') || ''}
                        onChange={handleReasonChange}
                        options={yelTerminationReasonList}
                        selectedValue={terminationReason}
                    />
                </QuestionWrapper>
            </Wrapper>

            <Wrapper>
                <ButtonContainer>
                    <SecondaryButton onClick={handleCancel}>{t('general.cancel')}</SecondaryButton>
                    <PrimaryButton disabled={!terminationReason || !terminationDate} onClick={handleSubmit}>
                        {t('auth.continue-to-auth')}
                    </PrimaryButton>
                </ButtonContainer>
            </Wrapper>
        </>
    );
};

export default YelTerminate;
