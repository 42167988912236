import React from 'react';

import { trans } from '../../utils';
import { EezyButton } from '../Buttons';
import { Modal, ModalActions, ModalContent } from '../modals/Modal';
import { P } from '../textElements';

interface IInformationModalProps {
    id: string;
    isOpen: boolean;
    onClose: () => void;
    title: string;
    texts: string[];
}

const InformationModal = (props: IInformationModalProps) => {
    return (
        <Modal
            id={`modal-${props.id}`}
            isOpen={props.isOpen}
            onClose={() => {}}
            title={trans(props.title)}
        >
            <ModalContent>
                {props.texts.map(t => (
                    <P>{trans(t)}</P>
                ))}
            </ModalContent>
            <ModalActions>
                <EezyButton color="purple" onClick={props.onClose}>
                    {trans('menu.close')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

export default InformationModal;
