import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { UiLanguage } from '../../../../shared/src/types/user';
import { EezyButton } from 'components/Buttons';
import { DatePicker } from 'components/form';
import { FormLabel } from 'components/form/FormLabel';
import { Modal, ModalActions, ModalContent } from 'components/modals/Modal';
import { BodyP } from 'components/textElements';
import config from 'config';
import { COLOR_BLUM, COLOR_GREYHOUND } from 'styles/variables';
import { addYearsTo, formatDateISO, minDate } from 'utils';
import { salaryRangeValidators } from 'utils/salary/validators';
import { formatValidationResult } from 'utils/validation';

interface IModalProps {
    handleModalClose: () => void;
    isOpen: boolean;
    language: UiLanguage;
}

const DownloadSalariesModal = (props: IModalProps) => {
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [downloadUrl, setDownloadUrl] = useState('');
    const [errors, setErrors] = useState<any>(null);

    const { t } = useTranslation();

    const endMax = startDate ? minDate(addYearsTo(1, new Date(startDate)), new Date()) : new Date();
    const endMin = startDate ? new Date(startDate) : undefined;
    const startMax = endDate ? new Date(endDate) : new Date();
    const startMin = endDate ? addYearsTo(-1, new Date(endDate)) : undefined;

    useEffect(() => {
        setDownloadUrl(
            `${config.backendUrl3}/salary-payments.pdf` +
                `?lang=${props.language}` +
                `${startDate ? `&from=${startDate}` : ''}` +
                `${endDate ? `&to=${endDate}` : ''}`,
        );
    }, [startDate, endDate, props.language]);

    useEffect(() => {
        setErrors(
            formatValidationResult(
                salaryRangeValidators.validate({
                    endDate,
                    endMax: formatDateISO(endMax),
                    endMin: endMin ? formatDateISO(endMin) : endDate,
                    startDate,
                    startMax: formatDateISO(startMax),
                    startMin: startMin ? formatDateISO(startMin) : startDate,
                }),
            ),
        );
    }, [startDate, endDate]);

    const handleDownload = () => {
        const win = window.open(downloadUrl, '_blank');
        win?.focus();
        props.handleModalClose();
    };

    return (
        <Modal
            id="modal-download-salaries"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={t('salaries:modal.title') || ''}
            noscroll
        >
            <ModalContent>
                <div style={{ width: '60%' }}>
                    <FormLabel color={COLOR_BLUM} htmlFor="salaries-startDate">
                        {t('salaries:modal.start')}
                    </FormLabel>
                    <DatePicker
                        disableToolbar
                        icon={['far', 'calendar-alt']}
                        label={t('dates.select-startDate')}
                        maxDate={startMax}
                        minDate={startMin}
                        placeholder={t('dates.select-startDate')}
                        id="salaries-startDate"
                        onChange={(date: Date | null) => {
                            if (date) {
                                setStartDate(formatDateISO(date));
                            }
                        }}
                        value={new Date(startDate)}
                    />
                </div>

                <div style={{ marginTop: 24, width: '60%' }}>
                    <FormLabel color={COLOR_BLUM} htmlFor="salaries-endDate">
                        {t('salaries:modal.end')}
                    </FormLabel>
                    <DatePicker
                        disableToolbar
                        icon={['far', 'calendar-alt']}
                        label={t('dates.select-endDate')}
                        maxDate={endMax}
                        minDate={endMin}
                        placeholder={t('dates.select-endDate')}
                        id="salaries-endDate"
                        onChange={(date: Date | null) => {
                            if (date) {
                                setEndDate(formatDateISO(date));
                            }
                        }}
                        value={new Date(endDate)}
                    />
                </div>

                <BodyP color={COLOR_GREYHOUND} style={{ marginTop: 24 }}>
                    {t('salaries:modal.paragraph-1')}
                </BodyP>

                <BodyP color={COLOR_GREYHOUND} style={{ marginTop: 16 }}>
                    {t('salaries:modal.paragraph-2')}
                </BodyP>
            </ModalContent>

            <ModalActions>
                <EezyButton color="purple" onClick={props.handleModalClose}>
                    {t('general.cancel')}
                </EezyButton>

                {/* Link cannot be disabled, thus the button */}
                <EezyButton color="purple" dark disabled={errors} onClick={handleDownload}>
                    {t('file.download')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

const mapStateToProps = (state: any) => {
    return {
        language: state.user.language,
    };
};

export default connect(mapStateToProps)(DownloadSalariesModal);
