import { useQuery } from '@apollo/client';
import React from 'react';
import { connect } from 'react-redux';
import { IGuider, isGuiderFinished } from 'utils/guider/guiderUtils';
import { GET_INVOICES } from '../dashboard/queries';
import { GET_USER_DATA } from '../profile/queries';
import Guider from './Guider';
import { IRootState } from 'reducers';
import { useSelector } from 'react-redux';
import { IInvoice } from '../../../../shared/src/types/invoice';

const STEP_ADDRESS = 'ADDRESS';
const STEP_AUTHENTICATE = 'AUTHENTICATE';
const STEP_BANK_ACCOUNT_NUMBER = 'BANK_ACCOUNT_NUMBER';
const STEP_NEW_INVOICE = 'NEW_INVOICE';
const STEP_YEL = 'YEL';

const newUserGuider = (isStepDone: (step: string) => boolean): IGuider => ({
    finalAction: isStepDone(STEP_NEW_INVOICE) ? {
        isDone: isStepDone(STEP_YEL),
        modal: 'YEL',
        title: 'guider.newUser.yel',
    } : {
        isDone: isStepDone(STEP_NEW_INVOICE),
        title: 'guider.newUser.finalAction',
        url: '/u/invoices',
    },
    openLabel: 'guider.newUser.open-label',
    closeLabel: 'guider.newUser.close-label',
    name: 'newUserGuider',
    steps: [
        {
            isDone: isStepDone(STEP_AUTHENTICATE),
            modal: 'AUTH',
            title: 'guider.newUser.auth',
        },
        {
            isDone: isStepDone(STEP_BANK_ACCOUNT_NUMBER),
            title: 'guider.newUser.bankAccount',
            url: '/profile/salary',
        },
        {
            isDone: isStepDone(STEP_ADDRESS),
            title: 'guider.newUser.address',
            url: '/profile/contact',
        },
        ...(
            isStepDone(STEP_NEW_INVOICE)
                ? [{
                    isDone: true,
                    title: 'guider.newUser.finalAction',
                    url: '/u/invoices',
                }]
                : []
        ),
    ],
    subTitle: 'guider.newUser.subtitle',
    title: 'guider.newUser.title',
});

interface IProps {
    hasAddress: boolean;
    hasAnsweredYel: boolean;
    isIdentified: boolean;
    justLoggedIn: boolean;
}

const NewUserGuider = (props: IProps) => {
    const eezyPayTermsAccepted = useSelector<IRootState>((state) => state.eezyPay.eezyPayTermsAccepted);
    const { data: userData, loading: loadingUser } = useQuery(GET_USER_DATA);
    const { data: allInvoicesData, loading: loadingInvoices } = useQuery(GET_INVOICES);

    const isStepDone = (step: string): boolean => {
        switch (step) {
            case STEP_AUTHENTICATE:
                return props.isIdentified;
            case STEP_BANK_ACCOUNT_NUMBER:
                return !!userData?.userData?.bankAccountNumber;
            case STEP_ADDRESS:
                return props.hasAddress;
            case STEP_YEL:
                return props.hasAnsweredYel;
            case STEP_NEW_INVOICE:
                const sentInvoice = allInvoicesData?.allInvoices?.items?.find((invoice: IInvoice) => (invoice.status !== 'incomplete'));
                return (
                    sentInvoice || eezyPayTermsAccepted
                );

            default:
                return false;
        }
    };

    const guider = newUserGuider(isStepDone);
    const defaultOpen = props.justLoggedIn && !isGuiderFinished(guider);

    if (loadingUser || loadingInvoices) {
        return null;
    }

    return <Guider guider={guider} defaultOpen={defaultOpen} />;
};

const mapStateToProps = (state: any) => {
    return {
        hasAddress: !!state.user.mailingAddress?.street1 && !!state.user.mailingAddress?.town,
        hasAnsweredYel: !state.user.yelShouldAsk,
        isIdentified: state.user.isIdentified,
        justLoggedIn: state.auth.justLoggedIn,
        language: state.user.language,
    };
};

export default connect(mapStateToProps)(NewUserGuider);
