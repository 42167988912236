import React from 'react';
import styled from 'styled-components';
import { IInvoice, LanguageCode } from '../../../../shared/src/types/invoice';
import { logoRound as logo } from '../../assets/images/eezylogo';
import { Flex } from 'components/Flex';
import { LabelCapsSmall, PSmall } from 'components/textElements';
import { InvoiceCol } from 'styles/invoiceStyles';
import { COLOR_DARK_GRAY, SCREEN_S } from 'styles/variables';
import { transFixed } from 'utils/i18n';

const StyledSection = styled(InvoiceCol)`
    margin: 0 0px 24px 0;
    min-width: 295px;

    @media (min-width: ${SCREEN_S}px) {
        margin: 0 10px 24px 0;
    }

    & .logo {
        height: 65px;
        width: 65px;
    }
`;

const AddressSection = styled.p`
    line-height: 16px;
    font-size: 12px;
    margin: 5px 0 0 10px;
`;

const Wrapper = styled(Flex)`
    opacity: ${(props: { $faded: boolean }) => (props.$faded ? 0.2 : 1)};
    transition: opacity 500ms;
`;

interface IProps {
    $faded: boolean;
    invoice?: IInvoice;
    invoiceLanguage?: LanguageCode;
}

const InvoiceHeader = (props: IProps) => {
    const { invoice } = props;

    return (
        <Wrapper $faded={props.$faded}>
            <StyledSection>
                <Flex>
                    <img alt="Eezy" className="logo" src={logo} />
                    <AddressSection>
                        Eezy Kevytyrittäjät
                        <br />
                        Maistraatinportti 1
                        <br />
                        00240 Helsinki
                    </AddressSection>
                </Flex>
            </StyledSection>
            <InvoiceCol>
                {invoice?.invoiceNumber ? (
                    <Flex spread>
                        <LabelCapsSmall color="black">
                            {transFixed({ str: 'invoice.number', lang: props.invoiceLanguage, options: {defaultValue: '' }})}
                        </LabelCapsSmall>
                        <PSmall color={COLOR_DARK_GRAY}>
                            {invoice?.invoiceNumber}
                        </PSmall>
                    </Flex>
                ) : (
                    <Flex spread className="hidden-sm">
                        <LabelCapsSmall color="black">
                            {transFixed({ str: 'invoice.draftNumber', lang: props.invoiceLanguage, options: {defaultValue: '' }})}
                        </LabelCapsSmall>
                        <PSmall color={COLOR_DARK_GRAY}>
                            {invoice?.draftNumber}
                        </PSmall>
                    </Flex>
                )}
            </InvoiceCol>
        </Wrapper>
    );
};

export default InvoiceHeader;
