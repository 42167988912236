import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { BORDER_RADIUS, COLOR_WHITE_WALKER } from '../../styles/variables';

interface ITwoColumnCard {
    title?: string;
    column1?: ReactNode;
    column2?: ReactNode;
    footer?: ReactNode;
}

const Wrapper = styled.div`
    border-radius: ${BORDER_RADIUS};
    background-color: ${COLOR_WHITE_WALKER};
    padding: 20px;
`;
const Columns = styled.div`
    display: flex;
`;

const Column = styled.div`
    flex: 1;
`;

const Footer = styled.div`
    margin-top: 38px;
`;

const Title = styled.h1`
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
`;

const TwoColumnCard = (props: ITwoColumnCard) => {
    const { title, column1, column2, footer } = props;

    return (
        <Wrapper>
            <Title data-mf-replace="**REMOVED**">{title}</Title>
            <Columns>
                <Column
                    style={{
                        borderRight: '1px solid #ADA8BE',
                        paddingRight: '30px'
                    }}
                >
                    {column1}
                </Column>
                <Column style={{ paddingLeft: '30px' }}>{column2}</Column>
            </Columns>
            <Footer>{footer}</Footer>
        </Wrapper>
    );
};

export default TwoColumnCard;
