import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'react-toastify';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { FillType } from '../../../../shared/src/types/invoice';
import { confirmAction, IConfirmAction } from 'actions/app';
import { showModals } from 'actions/auth';
import { getErrors } from 'utils/apolloErrors';
import { IDocument } from 'utils/user/userUtils';
import { ADD_INVOICE } from './queries';
import { NEW_INVOICE_NEW_RECIPIENT } from './queries/invoiceStateQueries';
import InvoiceView from './InvoiceView';

type INewInvoiceProps = {
    recruitmentCode: string;
    userId: number;
    userName: string;
    showModals: (types: string[]) => void;
    confirmAction: (payload: IConfirmAction) => void;
}

const NewInvoice: React.FC<INewInvoiceProps> = (props: INewInvoiceProps) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const routeState = location.state;
    const fillType = (routeState?.fillType as FillType) || 'free';
    const [invoiceId, setInvoiceId] = useState(null);
    const { client, data: localData } = useQuery(NEW_INVOICE_NEW_RECIPIENT);

    const redirectToEdit = (data: any) => {
        setInvoiceId(data.updateInvoice.id);
    };

    const [addInvoice, { loading }] = useMutation(ADD_INVOICE, {
        onCompleted: (data) => {
            redirectToEdit(data);
        },
        onError: (e: ApolloError) => {
            const errors = getErrors(e);
            if (errors?.mustAccept) {
                props.showModals(errors?.mustAccept.map((t: IDocument) => t.document));
            } else {
                toast.error(t('errors.general'));
            }
        },
        // refetchQueries: () => {
        //     return [{ query: GET_ANKKA_INVOICES }]
        // },
        // update(cache, { data: { updateInvoice: newInvoice } }) {
        //     cache.writeQuery({
        //         data: {
        //             invoice: newInvoice,
        //         },
        //         query: GET_INVOICE,
        //         variables: { id: newInvoice.id },
        //     });
        // },
    });

    const handleInvoiceUpdate = (o: any) => {
        // Navigate to Invoice View upon selecting Recipient
        if (o.recipient) {
            addInvoice({
                variables: {
                    contactPersonId: o.contactPersonId,
                    recipientId: o.recipient.id,
                    recipientType: o.recipient.type,
                    fillType: fillType,
                },
            });
        }
    };

    useEffect(() => {
        // New recipient created on fillhelper
        if (localData?.newInvoiceRecipient) {
            const { contactPersonId, id, type } = localData.newInvoiceRecipient;
            handleInvoiceUpdate({ contactPersonId, recipient: { id, type } });
        }
        return () => {
            client.writeQuery({
                data: {
                    newInvoiceRecipient: null,
                },
                query: NEW_INVOICE_NEW_RECIPIENT,
            });
        };
    }, [client, localData]);

    useEffect(() => {
        if (invoiceId) {
            navigate(`/invoices/${invoiceId}`, { replace: true })
        }
    }, [invoiceId])

    return (
        <InvoiceView
            confirmAction={props.confirmAction}
            fillType={fillType}
            isNew={true}
            lang={i18n.language as any}
            loadingDocument={loading}
            loadingRecipient={loading}
            onUpdate={handleInvoiceUpdate}
            recruitmentCode={props.recruitmentCode}
            showModals={props.showModals}
            userId={props.userId}
            userName={props.userName}
        />
    );
};

const mapStateToProps = (state: any) => {
    return {
        recruitmentCode: state.user.recruitmentCode,
        userId: state.user.id,
        userName: state.user.firstName + ' ' + state.user.lastName,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        confirmAction: (payload: IConfirmAction) => {
            dispatch(confirmAction(payload));
        },
        showModals: (modals: string[]) => {
            dispatch(showModals(modals));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewInvoice);
