import React from 'react';
import styled from 'styled-components';
import { InfoTooltip } from '../components/ToolTip';
import { Icon } from '../components/Icon';
import { COLOR_GREYJOY } from '../styles/variables';

interface IInfoTooltipStyledProps {
    tooltip: string;
    tooltipBackground?: string;
    tooltipColor?: string;
}

const IconWrapper = styled.div`
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
`;

export const InfoTooltipStyled = (props: IInfoTooltipStyledProps) => {
    return (
        <InfoTooltip
            title={props.tooltip}
            placement="top"
            enterTouchDelay={0}
            arrow
    // @ts-ignore // TODO: check is it working! In the future needs rewrite in to Styled-components
            backgroundColor={props.tooltipBackground}
            textColor={props.tooltipColor}
        >
            <IconWrapper>
                <Icon
                    icon={['fas', 'info-circle']}
                    color={COLOR_GREYJOY}
                    style={{ width: '15px' }}
                />
            </IconWrapper>
        </InfoTooltip>
    );
};
