import React, { CSSProperties } from 'react';
import { FormRow, IGeneralFormInputProps } from '.';
import { Flex } from '../Flex';
import { FormLabel } from './FormLabel';
import { FormNumberInput } from './FormNumberInput';

interface IFormNumberProps extends IGeneralFormInputProps {
    allowNegative?: boolean;
    disableRounding?: boolean;
    endAdornment?: string;
    inputStyle?: CSSProperties;
    isEur?: boolean;
    onChange?: (val: number | string, name: string ) => void;
    placeholder?: string;
    value?: number;
}

export const FormNumber = (props: IFormNumberProps) => {
    return (
        <FormRow style={props.style}>
            <FormLabel
                error={props.error}
                disabled={props.disabled}
                helpLink={props.helpLink}
                helpTexts={props.helpTexts}
                htmlFor={props.name}
                value={props.value}
                warning={props.warning}
            >
                {props.label}
            </FormLabel>

            <Flex center>
                <FormNumberInput
                    allowNegative={props.allowNegative}
                    disableRounding={props.disableRounding}
                    endAdornment={props.endAdornment}
                    error={props.error}
                    isEur={props.isEur}
                    name={props.name}
                    onChange={props.onChange}
                    placeholder={props.placeholder}
                    required={props.required}
                    style={props.inputStyle}
                    value={props.value}
                />
            </Flex>
        </FormRow>
    );
};
