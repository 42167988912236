import React, { ReactNode } from 'react';
import styled from 'styled-components';
import PopOver from '../../components/modals/PopOver';
import { H2, HelpButton } from '../../styles/yelStyles';
import { trans } from '../../utils';

const RowWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 5px 5px 0;
    h2 {
        margin-right: 10px;
    }
`;

const QuestionLabelRow = ({
    children,
    helpText,
    helpTextFullWidth
}: {
    children: ReactNode;
    helpText?: string | JSX.Element[];
    helpTextFullWidth?: boolean;
}) => {
    const helpButton = <HelpButton>{trans('general.help')}</HelpButton>;
    return (
        <RowWrapper>
            <H2>{children}</H2>
            {helpText && (
                <PopOver
                    helpButton={helpButton}
                    helpText={helpText}
                    fullWidth={helpTextFullWidth}
                />
            )}
        </RowWrapper>
    );
};

export default QuestionLabelRow;
