import { useMutation } from '@apollo/client';
import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { IInvoice, IInvoiceItem, ItemType } from '../../../../shared/src/types/invoice';
import pencilIcon from '../../assets/images/pencil.svg';
import { EezyButton } from '../../components/Buttons';
import { Icon } from '../../components/Icon';
import { Line2 } from '../../components/Lines';
import { Label } from '../../components/textElements';
import { ErrorTooltip, WarningTooltip } from '../../components/ToolTip';
import { SimpleTable } from '../../styles/invoiceStyles';
import {
    COLOR_BLACKWATER,
    COLOR_BLUM,
    COLOR_IMPORTANT,
    COLOR_LILA,
    COLOR_STATUS_WAITING,
} from '../../styles/variables';
import { formatCents } from '../../utils';
import { useTranslation } from 'react-i18next';
import { notEmpty } from '../../utils/calc';
import { defaultQuantityUnit, defaultVat } from '../../utils/invoice/invoiceLogic';
import { IInvoiceTemplate } from '../../utils/invoice/invoiceTemplates';
import { formatValidationResult } from '../../utils/validation';
import { ADD_INVOICE_ITEM, GET_INVOICE } from './queries';
import { InvoiceDates } from './tableData';

const Line = styled.span`
    background: #fff;
    padding: 0 15px 0 0;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
`;

interface IInvoiceRowTypeSelectionProps {
    invoice?: IInvoice;
    invoiceErrors: ReturnType<typeof formatValidationResult>;
    handleInvoiceSpread: (panel: number, openSecondMenu?: boolean, openSubObjectId?: number) => void;
    template: IInvoiceTemplate;
    hasContract?: boolean;
}

const InvoiceRowTypeSelection = (props: IInvoiceRowTypeSelectionProps) => {
    const { t } = useTranslation();
    const { handleInvoiceSpread, invoiceErrors } = props;
    const rows = props.invoice?.invoiceItems || [];

    const possibleWorkItemVats = rows
        .filter((t: IInvoiceItem) => t.itemType === 'work')
        .map((t: IInvoiceItem) => t.vat)
        .filter(notEmpty);

    const [addInvoiceItem] = useMutation(ADD_INVOICE_ITEM, {
        onCompleted: (data) => {
            const newItem = data?.updateInvoiceItem?.invoiceItems.slice(-1)?.[0];
            if (!newItem) return;
            handleInvoiceSpread(4, true, newItem.id);
        },
        onError: () => toast.error(t('errors.general')),
        update(cache, { data: { updateInvoiceItem: invoiceItems } }) {
            const invoiceData: {
                invoice: IInvoice;
            } | null = cache.readQuery({
                query: GET_INVOICE,
                variables: { id: props.invoice?.id },
            });
            if (invoiceData?.invoice) {
                cache.writeQuery({
                    data: {
                        invoice: {
                            ...invoiceData.invoice,
                            invoiceItems: invoiceItems.invoiceItems,
                        },
                    },
                    query: GET_INVOICE,
                    variables: { id: props.invoice?.id },
                });
            }
        },
    });

    const handleAddInvoiceItem = (itemType: ItemType) => {
        addInvoiceItem({
            variables: {
                invoiceId: props.invoice?.id,
                itemType,
                quantityUnit: defaultQuantityUnit(itemType),
                vat: defaultVat(props.template, possibleWorkItemVats, props.invoice?.recipient?.type),
            },
        });
    };

    return (
        <div>
            {rows.length ? (
                <Title>
                    <Line>{`${t('invoice.rows')} (${rows.length})`}</Line>
                </Title>
            ) : null}
            {rows.length ? (
                <SimpleTable>
                    <tbody>
                        {rows.map((item: IInvoiceItem, index: number) => {
                            const negativeWarning = (item.totalPriceWithVat || 0) < 0;
                            const emptyDescription = !item.description?.trim().length;
                            const dateError =
                                !!item?.startDate &&
                                !!item?.endDate &&
                                (item?.startDate || 0) > (item?.endDate || 0);
                            const rowError = dateError
                                ? t('invoice.errors.item-invalid-date')
                                : emptyDescription
                                ? t('invoice.errors.item-description-empty')
                                : '';
                            return (
                                <tr key={index}>
                                    <td data-mf-replace="**REMOVED**">
                                        {rowError && (
                                            <ErrorTooltip title={rowError} arrow placement="bottom">
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        marginLeft: '-18px',
                                                    }}
                                                >
                                                    <Icon
                                                        icon={['far', 'exclamation-circle']}
                                                        className="small"
                                                        color={COLOR_IMPORTANT}
                                                        style={{
                                                            transform: 'translateX(-6px)',
                                                        }}
                                                    />
                                                </span>
                                            </ErrorTooltip>
                                        )}
                                        {item.description}{' '}
                                        {item.itemType === 'work' && (
                                            <InvoiceDates item={item} editable={false} />
                                        )}
                                    </td>
                                    <td>
                                        <WarningTooltip
                                            title={
                                                negativeWarning ? (
                                                    <React.Fragment>
                                                        <b>{t('invoice.warning-minus-title')}</b>
                                                        <br />
                                                        {t('invoice.warning-minus-text')}
                                                    </React.Fragment>
                                                ) : (
                                                    ''
                                                )
                                            }
                                            arrow
                                            placement="bottom-end"
                                        >
                                            <span
                                                style={{
                                                    color: negativeWarning
                                                        ? COLOR_STATUS_WAITING
                                                        : COLOR_BLUM,
                                                    whiteSpace: 'pre',
                                                }}
                                            >
                                                {negativeWarning && (
                                                    <Icon
                                                        icon={['far', 'exclamation-circle']}
                                                        className="small"
                                                        color={COLOR_STATUS_WAITING}
                                                    />
                                                )}{' '}
                                                {formatCents(item.totalPriceWithVat, true)} <span>€</span>
                                            </span>
                                        </WarningTooltip>
                                    </td>
                                    <td style={{ lineHeight: '2.2' }}>
                                        <EezyButton
                                            color="purple"
                                            type="button"
                                            square
                                            onClick={() => handleInvoiceSpread(4, true, item.id)}
                                            style={{
                                                height: 28,
                                                lineHeight: 0,
                                                minWidth: 20,
                                                padding: '0 5px 0 5px',
                                            }}
                                        >
                                            <img
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                src={pencilIcon}
                                                alt={t('general.edit')!}
                                            />
                                        </EezyButton>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>{t('invoice.sum')}</td>
                            <td>
                                <ErrorTooltip
                                    title={
                                        invoiceErrors?.totalWithVat ? t('errors.negativeInvoiceTotal') : ''
                                    }
                                    arrow
                                    placement="bottom-end"
                                >
                                    <span
                                        style={{
                                            color: invoiceErrors?.totalWithVat ? COLOR_IMPORTANT : COLOR_BLUM,
                                        }}
                                    >
                                        {invoiceErrors?.totalWithVat && (
                                            <Icon
                                                icon={['far', 'exclamation-triangle']}
                                                className="small"
                                                color={COLOR_IMPORTANT}
                                            />
                                        )}{' '}
                                        {formatCents(props.invoice?.totalWithVat, true)} <span>€</span>
                                    </span>
                                </ErrorTooltip>
                            </td>
                        </tr>
                    </tfoot>
                </SimpleTable>
            ) : null}

            <Line2 style={{ marginBottom: '15px' }} />

            <Label as="h4" color={COLOR_BLACKWATER} style={{ marginBottom: '10px' }}>
                {t('invoice.row-type-title')}
            </Label>

            <EezyButton
                aria-label={t('invoice.tableColumns.add-work')!}
                color="purple"
                dark
                disabled={!props.invoice}
                hasIcon
                compact
                onClick={() => handleAddInvoiceItem('work')}
                style={{ minWidth: 'auto', margin: '0 10px 10px 0' }}
            >
                <Icon color={COLOR_LILA} icon={['far', 'plus']} />
                {t('invoice.tableColumns.work')}
            </EezyButton>

            <EezyButton
                aria-label={t('invoice.tableColumns.add-travel')!}
                color="purple"
                disabled={!props.invoice}
                hasIcon
                compact
                onClick={() => {
                    handleAddInvoiceItem('travel');
                }}
                style={{ margin: '0 10px 10px 0' }}
            >
                <Icon color={COLOR_BLUM} icon={['far', 'plus']} />
                {t('invoice.tableColumns.travel')}
            </EezyButton>

            <EezyButton
                aria-label={t('invoice.tableColumns.add-material')!}
                color="purple"
                disabled={!props.invoice}
                hasIcon
                compact
                onClick={() => handleAddInvoiceItem('material')}
                style={{ margin: '0 10px 10px 0' }}
            >
                <Icon color={COLOR_BLUM} icon={['far', 'plus']} />
                {t('invoice.tableColumns.material')}
            </EezyButton>
        </div>
    );
};

export default InvoiceRowTypeSelection;
