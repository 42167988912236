export const TolCodes = {
    70: '',
    101: '',
    92: '',
    159: '',
    161: '',
    158: '',
    1: '',
    83: '',
    74: '74901',
    125: '',
    52: '',
    47: '',
    59: '',
    127: '',
    149: '',
    150: '',
    48: '',
    126: '',
    164: '',
    170: '',
    165: '',
    43: '',
    110: '',
    144: '',
    140: '',
    143: '',
    167: '',
    185: '',
    38: '',
    27: '',
    89: '',
    84: '',
    119: '',
    155: '',
    152: '',
    153: '',
    195: '',
    29: '',
    64: '',
    8: '',
    6: '',
    12: '',
    208: '10320',
    211: '',
    128: '',
    16: '43220',
    169: '',
    55: '',
    66: '',
    182: '',
    178: '86904',
    5: '81100',
    98: '',
    168: '86901',
    131: '96040',
    132: '96040',
    105: '',
    147: '',
    22: '',
    197: '',
    46: '',
    72: '74102',
    160: '86909',
    129: '',
    11: '',
    49: '85510',
    90: '74300',
    19: '90020',
    156: '85510',
    45: '',
    123: '',
    187: '',
    151: '',
    71: '',
    61: '',
    96: '96022',
    23: '',
    26: '',
    24: '',
    134: '96040',
    175: '',
    124: '96022',
    108: '',
    25: '',
    117: '85520',
    81: '79900',
    57: '',
    63: '',
    56: '96022',
    177: '',
    58: '',
    162: '81100',
    148: '86909',
    116: '90030',
    21: '90010',
    176: '',
    191: '',
    30: '',
    17: '01620',
    15: '',
    54: '01620',
    80: '',
    31: '',
    91: '',
    67: '',
    113: '',
    193: '',
    13: '',
    76: '59110',
    163: '',
    136: '71123',
    20: '',
    73: '79900',
    102: '',
    174: '',
    60: '',
    35: '',
    41: '90030',
    137: '',
    82: '',
    50: '',
    209: '90010',
    18: '',
    173: '',
    51: '',
    77: '69102',
    172: '85510',
    189: '85510',
    130: '85510',
    118: '',
    100: '85510',
    37: '85520',
    205: '',
    202: '',
    7: '',
    9: '',
    34: '',
    97: '',
    122: '49320',
    120: '',
    146: '',
    106: '',
    85: '43341',
    115: '',
    111: '',
    78: '',
    93: '09900',
    68: '',
    88: '09900',
    87: '09900',
    95: '',
    44: '74201',
    4: '96040',
    171: '',
    75: '96021',
    199: '',
    145: '',
    141: '',
    53: '62010',
    114: '62010',
    40: '',
    2: '73111',
    109: '82191',
    14: '74300',
    10: '81100',
    65: '71110',
    192: '68320',
    112: '90030',
    142: '',
    154: '',
    86: '',
    39: '',
    62: '',
    79: '',
    99: '',
    33: '',
    184: '',
    36: '43210',
    32: '',
    180: '',
    42: '',
    28: '',
    104: '',
    166: '',
    157: '',
    94: '',
    3: '',
    135: '',
    139: '',
    210: '59110',
    212: '',
    103: '',
    69: '93190',
    107: '85510',
    213: '',
    214: '',
    215: '',
    216: ''
};
