import { useEffect, useState } from 'react';
import { ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ThunkDispatch } from 'redux-thunk';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { FillType, IContract, IInvoiceKeys } from '../../../../shared/src/types/invoice';
import { UiLanguage } from '../../../../shared/src/types/user';
import { confirmAction, IConfirmAction } from 'actions/app';
import { customError, showModals } from 'actions/auth';
import { AAVA_INVOICE_LIMIT } from 'constants/invoices';
import { getErrors } from 'utils/apolloErrors';
import { ERROR_NO_INVOICE } from 'utils/error';
import { getLocalStorage, IDocument } from 'utils/user/userUtils';
import { SEND_COST_INVOICE } from '../costs/queries';
import { GET_AAVA_INVOICES, GET_ANKKA_INVOICES, GET_INVOICES } from '../dashboard/queries';
import { GET_USER_DATA } from '../profile/queries';
import InvoiceView from './InvoiceView';
import { GET_INVOICE, SEND_INVOICE, UPDATE_INVOICE, UPDATE_INVOICE_WITH_RECIPIENT } from './queries';
import { INVOICE_COPY_META } from './queries/invoiceStateQueries';
import { useAppLocation } from 'utils/hooks/useAppLocation';
import ModalOpener from 'containers/ModalOpener';
import GET_CONTRACTS from './queries/getContracts';

export const checkContract = (data: IContract[]): boolean => {
    const now = moment();

    if (data) {
        const validContracts = data.filter((contract) => {
            const startDate = moment(contract.startDate || moment());
            const endDate = moment(contract.endDate || moment());

            return now.isBetween(startDate, endDate, undefined, '[]');
        });

        return validContracts.length > 0;
    }

    return false;
};

interface IInvoiceProps {
    confirmAction: (payload: IConfirmAction) => void;
    lang: UiLanguage;
    noInvoiceError: () => void;
    recruitmentCode: string;
    showModals: (types: string[]) => void;
    userId: number;
    userName: string;
}

const Invoice = (props: IInvoiceProps) => {
    const { t } = useTranslation();
    const params = useParams();
    const id = parseInt(params?.invoiceId || '', 10);
    const isGroupInvoice = window.location.href.includes('group');
    const { state } = useAppLocation();
    const [sideErrors, setSideErrors] = useState<string[]>([]);
    const [expressErrors, setExpressErrors] = useState<string[]>([]);
    const [hasContract, setHasContract] = useState<boolean>(false);

    const { client, data: copyMetaData } = useQuery(INVOICE_COPY_META);

    const { data: userRes } = useQuery(GET_USER_DATA);

    const {
        data: invoiceData,
        loading,
        refetch: refetchInvoice,
    } = useQuery(GET_INVOICE, {
        onError: () => {
            props.noInvoiceError();
        },
        variables: { id, isGroupInvoice },
    });

    const invoice = invoiceData?.invoice;

    const navigate = useNavigate()

    const [fetchContracts] = useLazyQuery(GET_CONTRACTS);

    // Refetch Ankkanet invoice when ever invoice changes to make sure info is fresh
    useEffect(() => {
        if (invoice?.id) {
            refetchInvoice();
        }

        if (invoice) {
            if (invoice.recipient?.id) {
                fetchContracts({
                    fetchPolicy: 'network-only',
                    variables: {
                        clientId: parseInt(invoice.recipient.id),
                        clientType: invoice.recipient.type,
                    },
                    onCompleted: (data) => {
                        if (checkContract(data.contracts)) {
                            setHasContract(true);
                        } else {
                            setHasContract(false);
                        }
                    },
                    onError: (error) => {
                        console.error('ERROR', error);
                    },
                });
            }
        }
    }, [invoice]);

    const [updateInvoice, { loading: updateLoading }] = useMutation(UPDATE_INVOICE, {
        onError: (e: ApolloError) => {
            const errors = getErrors(e);
            if (errors?.mustAccept) {
                props.showModals(errors?.mustAccept.map((t: IDocument) => t.document));
            } else {
                toast.error(t('errors.general'));
            }
        },
    });

    const handleSendErrors = (e: ApolloError) => {
        const errors = getErrors(e);
        if (errors?.mustAccept) {
            props.showModals(errors.mustAccept.map((t: IDocument) => t.document));
        } else if (errors?.basicInfo || errors?.express || errors?.costs || errors?.provisions) {
            if (errors.basicInfo || errors.costs || errors.provisions) {
                setSideErrors(errors.basicInfo || errors.costs || errors.provisions);
            }
            if (errors.express) {
                setExpressErrors(errors.express);
            }
        } else {
            toast.error(t('errors.general'));
        }
    };

    const [updateInvoiceWithRecipient, { loading: loadingRecipient }] = useMutation(
        UPDATE_INVOICE_WITH_RECIPIENT,
        {
            onError: (e: ApolloError) => {
                const errors = getErrors(e);
                if (errors?.mustAccept) {
                    props.showModals(errors?.mustAccept.map((t: IDocument) => t.document));
                } else if (errors?.basicInfo || errors?.express) {
                    if (errors?.basicInfo) {
                        setSideErrors(errors?.basicInfo);
                    }
                    if (errors?.express) {
                        setExpressErrors(errors?.express);
                    }
                } else {
                    toast.error(t('errors.general'));
                }
            },
        },
    );

    const [sendInvoice, { loading: sendingLoading }] = useMutation(SEND_INVOICE, {
        onCompleted: (result) => {
            const newId = result.sendInvoiceById?.id;
            if (newId) {
                navigate(`/invoices/${newId}`, { replace: true });
                return;
            }
            setSideErrors([]);
            setExpressErrors([]);
        },
        onError: handleSendErrors,
        // Refetching invoice right after sending updates the status
        // and makes the invoice non-editable in the UI.
        // In case of error the invoice is refetched again.
        // refetchQueries: () => [
        //     { query: GET_INVOICE, variables: { id } },
        //     { query: GET_ANKKA_INVOICES },
        //     {
        //         query: GET_AAVA_INVOICES,
        //         variables: {
        //             page: { offset: 0, pageSize: AAVA_INVOICE_LIMIT },
        //         },
        //     },
        // ],
    });

    const [sendCostInvoice, { loading: costInvoiceSendingLoading }] = useMutation(SEND_COST_INVOICE, {
        onCompleted: () => {
            if (invoiceData?.invoice.costInvoice?.status === 'turned_back') {
                toast(t('costs.sent-corrections'));
            } else {
                toast(t('costs.sent-costinvoice'));
            }
        },
        onError: (e: ApolloError) => {
            const errors = getErrors(e);
            const errorMsg = errors?.costs?.includes('MISSING_RECEIPT_FILE')
                ? 'errors.cost-invoice-validation.missing-receipt-file'
                : 'errors.general';
            toast.error(t(errorMsg));
        },
        refetchQueries: () => [
            { query: GET_INVOICES },
            {
                query: GET_INVOICE,
                variables: {
                    id,
                    isGroupInvoice,
                },
            },
        ],
        variables: { id: invoiceData?.invoice.costInvoice?.id },
    });

    useEffect(() => {
        // When invoice id is changed
        setSideErrors([]);
        setExpressErrors([]);
    }, [params.invoiceId]);

    useEffect(() => {
        const key = `invoice-error-${id}`;
        const storedErrors = getLocalStorage(key);
        if (storedErrors) {
            localStorage.removeItem(key);
            handleSendErrors(storedErrors);
        }
    }, []);

    const fillType = (invoice?.fillType as FillType) || 'guided';

    const handleInvoiceUpdate = (o: IInvoiceKeys) => {
        if ('recipient' in o) {
            updateInvoiceWithRecipient({
                variables: {
                    id,
                    ...o,
                    contactPersonId: null,
                },
            });

            if (o.recipient && o.recipient.id && o.recipient.type) {
                fetchContracts({
                    fetchPolicy: 'network-only',
                    variables: {
                        clientId: parseInt(o.recipient.id),
                        clientType: o.recipient.type,
                    },
                    onCompleted: (data) => {
                        if (checkContract(data.contracts)) {
                            setHasContract(true);
                        } else {
                            setHasContract(false);
                        }
                    },
                });
            }
        } else {
            updateInvoice({
                optimisticResponse: {
                    updateInvoice: {
                        __typename: 'Invoice',
                        ...invoice,
                        ...o,
                    },
                },
                variables: {
                    id,
                    ...o,
                },
            });
        }
        cleanCopyData();
    };

    const handleInvoiceSending = (messageToEezy: string) => {
        sendInvoice({
            variables: {
                id,
                messageToEezy,
                hasContract,
            },
        });
        cleanCopyData();
    };

    const cleanCopyData = () => {
        if (copyMetaData?.copyMeta) {
            client.writeQuery({
                data: {
                    copyMeta: null,
                },
                query: INVOICE_COPY_META,
            });
        }
    };

    return (
        <ModalOpener>
            <InvoiceView
                confirmAction={props.confirmAction}
                expressErrors={expressErrors}
                invoice={invoice}
                lang={props.lang}
                loadingCostInvoice={costInvoiceSendingLoading}
                loadingDocument={loading || sendingLoading || costInvoiceSendingLoading}
                loadingUpdate={updateLoading || loadingRecipient}
                loadingRecipient={loadingRecipient}
                metaData={copyMetaData?.copyMeta?.id === id ? copyMetaData?.copyMeta : undefined}
                onUpdate={handleInvoiceUpdate}
                onSend={handleInvoiceSending}
                onValidationError={handleSendErrors}
                onCostInvoiceSend={sendCostInvoice}
                recruitmentCode={props.recruitmentCode}
                refetchInvoice={refetchInvoice}
                routeState={state}
                showModals={props.showModals}
                sideErrors={sideErrors}
                userData={userRes?.userData}
                userId={props.userId}
                fillType={fillType}
                userName={props.userName}
                hasContract={hasContract}
            />
        </ModalOpener>
    );
};

const mapStateToProps = (state: any) => {
    return {
        lang: state.user.language,
        recruitmentCode: state.user.recruitmentCode,
        userId: state.user.id,
        userName: state.user.firstName + ' ' + state.user.lastName,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        confirmAction: (payload: IConfirmAction) => {
            dispatch(confirmAction(payload));
        },
        noInvoiceError: () => {
            dispatch(customError(ERROR_NO_INVOICE));
        },
        showModals: (modals: string[]) => {
            dispatch(showModals(modals));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
