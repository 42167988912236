import React from 'react';

const GdprEn = () => {
    return (
        <div>
            <div>
                <p>
                    Contract of processing of personal data is made between you
                    and Eezy. The meaning of the agreement is to make the
                    purposes and terms of processing your client’s personal data
                    clearer. When gathering information from your client for
                    invoicing, you form a client data register, meaning that you
                    are the data Controller. When filling out your client’s
                    information to Eezy’s online service, you give their
                    information to us and we use the information for example for
                    invoicing purposes. This makes us a data processor.
                    According to the general data protection regulation (GDPR)
                    the processing of personal data must be regulated with a
                    contract and this contract defines the terms for processing
                    personal data.
                </p>
            </div>
            <div>
                <p>
                    You must agree the contract to use our service, but
                    remember, that as a data Controller, you should also create
                    a privacy policy, in case you store your clients´
                    information for example on your computer. You can create a
                    privacy policy here:{' '}
                    <a
                        href="https://kevytyrittajat.eezy.fi/privacy_policy/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        https://kevytyrittajat.eezy.fi/privacy_policy/
                    </a>
                </p>
            </div>
            <div>
                <p>You as a Service user (Controller)</p>

                <p>AND</p>

                <p>Eezy Kevytyrittäjät Oy</p>
            </div>
            <div>
                <p>
                    <b>ATTACHMENTS</b>
                </p>
                <p>Annex 1 Annex on the processing of personal data</p>
                <p>Annex 2 Subcontractors</p>
            </div>

            <div>
                <p>
                    <b>1. PARTIES</b>
                </p>
                <div className="p1">
                    <p>1.1 Service user ("You") hereinafter "Client"</p>

                    <p>1.2 Eezy Kevytyrittäjät Oy</p>
                    <p>Business ID: 2197002-2</p>
                    <p>Maistraatinportti 1, 00240 Helsinki</p>
                    <p>hereinafter "Processor"</p>

                    <p>
                        Client and Processor hereinafter, together "Parties" and
                        separately "Party".
                    </p>
                </div>
            </div>
            <div>
                <p>
                    <b>2. BACKGROUND</b>
                </p>
                <div>
                    <p>
                        2.1 Processor and Client have made an agreement that the
                        Processor will offer a so-called invoicing service to
                        the Client. By this agreement the Client can perform the
                        agreed work without establishing a company while the
                        Processor will take care of the invoicing and statutory
                        obligations related to earned income as agreed with the
                        Client.
                    </p>

                    <p>
                        2.2 This agreement of processing personal data
                        (’Agreement’) defines the terms of Processor’s methods
                        of processing of personal data on behalf of the Client.
                    </p>

                    <p>
                        2.3 Parties complement this Agreement by drafting an
                        Annex on the processing of personal data ("Annex 1").
                        Upon entry into force of this Agreement the parties have
                        drafted Annex 1 and Annex 2. Annexes, that are drafted
                        after this Agreement has entered into force, shall be
                        added as Annex. If there is a conflict between this
                        Agreement and Annex 1, the Annex 1 shall prevail.
                    </p>
                    <p>
                        2.4 Parties agree that the Processor acts as a Processor
                        as defined in law and Client acts as a Controller.
                    </p>

                    <p>
                        2.5 If there is a conflict between this Agreement, its
                        Annexes, Data Regulation or Model Contract Clauses, the
                        following order shall prevail;
                    </p>

                    <div className="p1">
                        <p>
                            i) Data Regulations, including Model Contractual
                            Clauses
                        </p>
                        <p>ii) This Agreement, including Annexes.</p>
                    </div>
                    <p>
                        2.6 If there is a conflict between this Agreement and
                        the Finnish version, the Finnish version prevails.
                    </p>
                </div>
            </div>
            <div>
                <p>
                    <b>3. DEFINITIONS</b>
                </p>
                <p>
                    <i>Personal Data</i>
                </p>
                <p className="p1">
                    personal data means any information relating to an
                    identified natural person that the Processor purposefully
                    processes for upkeeping client register and fulfilling the
                    statutory obligations arising from assignment agreement. In
                    this context, processing means any operation that the
                    Processor performs on personal data such as collection,
                    recording, organization, structuring, storage, adaptation or
                    alteration, retrieve, consultation, use, disclosure by
                    transmission, dissemination or otherwise making available,
                    alignment or combination, restriction, erasure, or
                    destruction;
                </p>

                <p>
                    <i>Model Contractual Clauses</i>
                </p>
                <p className="p1">
                    means EU Commission’s Decision 2010/87/EU (including its
                    adaptions) on adequate model contractual clauses concerning
                    the personal data transfers to third countries.
                </p>

                <p>
                    <i>Service user</i>
                </p>
                <p className="p1">
                    means a natural person on who’s Personal data the Processor
                    processes on Controller’s behalf.
                </p>

                <p>
                    <i>General Data Protection Regulation</i>
                </p>
                <p className="p1">
                    means EU´s General Data Protection Regulation 2016/679/EU.
                </p>

                <p>
                    <i>Personal Data Breach</i>
                </p>
                <p className="p1">
                    means a breach of security leading to the accidental,
                    contrary to this Agreement, against the regulation or
                    unlawful destruction, loss, alteration, unauthorized
                    disclosure of, or access to, personal data transmitted,
                    stored or otherwise processed;
                </p>

                <p>
                    <i>Data Regulations</i>
                </p>
                <p className="p1">
                    means all Regulations applied in Finland for the protection
                    of Personal Data, including legal acts, such as Directive
                    95/46/EU, Directive 2002/58/EU and General Data Protection
                    Regulation 2016/679/EU.
                </p>
            </div>
            <div>
                <p>
                    <b>4. CLIENT’S RIGHTS AND OBLIGATIONS</b>
                </p>
                <div className="p1">
                    <p>
                        4.1 Client shall control Personal Data according to Data
                        Regulations and good data processing practice.
                    </p>

                    <p>
                        4.2 Client’s written guidance to Processor for the
                        processing of Personal Data is primarily given by this
                        Agreement.
                    </p>
                </div>
            </div>

            <div>
                <p>
                    <b>5. PROCESSOR’S OBLIGATIONS</b>
                </p>
                <div className="p1">
                    <p>
                        5.1 General Principles applied in processing of Personal
                        Data
                    </p>
                    <div className="p1">
                        <p>Processor shall:</p>

                        <p>
                            5.1.1 control Personal Data according to Data
                            Regulations and good data processing practice.
                        </p>

                        <p>
                            5.1.2 process Personal Data according to written
                            guidance given by the Client, unless the Regulation
                            regulating the Processor orders otherwise. The
                            Processor shall inform the Client about the
                            Regulation within reasonable time before the start
                            of processing of Personal Data, unless Data
                            Regulations state otherwise. Processor shall inform
                            the Client without undue delay, if the Processor
                            sees Client’s written guidance violating the Data
                            Regulations.
                        </p>

                        <p>
                            5.1.3 ensure, that the Processor’s personnel, who
                            has access to Personal Data, is committed to obey
                            their duty of secrecy.
                        </p>

                        <p>
                            5.1.4 implement actions mentioned on 5.2 of the
                            Agreement
                        </p>

                        <p>
                            5.1.5 obey the requirements related to
                            subcontractors mentioned on clause 8 of this
                            Agreement.
                        </p>

                        <p>
                            5.1.6 assist the Client on Data requests with the
                            best knowledge and information the Processor retain,
                            when the information is not available for the
                            Client. Processor has a right to charge the Client
                            of the costs of obeying the obligations arising out
                            of section 5.1.6 of the Agreement.
                        </p>

                        <p>
                            5.1.7 on the choice of the Client to delete or
                            return the Personal Data to the Client as mentioned
                            on section 11.2 of this Agreement.
                        </p>

                        <p>
                            5.1.8 give to the Client all the necessary
                            information that is relevant for demonstration of
                            the fulfillment of obligations arising out of the
                            Regulations and Agreement. The Client is obligated
                            to keep such information confidential. The Processor
                            has a right to charge the costs arising out of
                            obeying section 5.1.9 of this Agreement from the
                            Client; and
                        </p>

                        <p>
                            5.1.9 allow the Client to order audits as mentioned
                            in section 9 of this Agreement.
                        </p>
                    </div>

                    <p>5.2 DATA SECURITY</p>

                    <p>
                        The Processor shall perform adequate technical and
                        organizational actions to protect Personal Data from
                        unlawful access and erasure, adaptation, disclosure,
                        transmission, or other unlawful handling.
                    </p>
                </div>
            </div>

            <div>
                <p>
                    <b>6. NOTIFICATION OF PERSONAL DATA BREACH</b>
                </p>
                <div className="p1">
                    <p>
                        6.1 The Processor shall notify the Client of all
                        personal data breaches without undue delay, but within
                        four (4) days when the Processor has become aware of the
                        breach. The notification shall include;
                    </p>

                    <div className="p1">
                        <p>
                            (a) description of the breach, including information
                            on which registered groups and Client registers the
                            breach concerned. Additionally, the number of groups
                            and registers issued;
                        </p>
                        <p>
                            (b) name and personal information of the person who
                            is investigating the breach;
                        </p>
                        <p>
                            (c) description of the consequences and/or the
                            potential consequences of the breach; and
                        </p>
                        <p>
                            (d) description of the methods that the Processor
                            has started due to the breach and the methods the
                            Processor has implemented to mitigate the
                            consequences arising from the breach.
                        </p>
                    </div>
                    <p>
                        6.2 If all the mentioned information is not available at
                        the same time, the information can be given in parts.
                    </p>

                    <p>
                        6.3 The Processor must document Personal Data breaches
                        and give such document to the Client at request.
                    </p>

                    <p>
                        6.4 When the Processor becomes aware of the breach, the
                        Processor must make sure of the level of protection of
                        Personal Data and implement, in collaboration with the
                        Client, adequate measures to protect Personal Data.
                    </p>
                </div>
            </div>

            <div>
                <p>
                    <b>7. TRANSFER OF PERSONAL DATA</b>
                </p>
                <p className="p1">
                    Primarily, the Processor shall not transfer Personal Data
                    outside EU or ETA unless it is necessary for the
                    implementation of the purpose or procession of Personal
                    Data. In this case, the Processor obeys Data Regulations.
                </p>
            </div>

            <div>
                <p>
                    <b>8. SUBCONTRACTORS</b>
                </p>
                <div className="p1">
                    <p>
                        8.1 The Processor has a right to use subcontractors for
                        processing of Personal Data. The subcontracts shall be
                        accepted by the Client. By signing this Agreement, the
                        Client approves the use of subcontractors mentioned in
                        Processor’s website.
                    </p>

                    <p>
                        8.2 The Processor has a right to reduce the number of
                        subcontracts without a separate notice.
                    </p>

                    <p>
                        8.3 The Processor must inform the Client about new
                        subcontractor who is processing Personal Data as stated
                        on this Agreement. The Processor shall inform the Client
                        at least one (1) week before the new subcontractor
                        starts processing Personal Data. The Processor can
                        change or add a subcontractor only if the new
                        subcontractor obeys Data Regulations and on the
                        evaluation of the Processor, the level data protection
                        does not lower because of the change/addition. The
                        Client can prohibit the use of new subcontractor only if
                        the Client has reasonable doubt of the new
                        subcontractor’s capability to obey Data Regulations. If
                        the Client does not provide a written opposition of the
                        use of new subcontractor within one (1) week from the
                        announcement of the Processor, the Processor can use the
                        new subcontractor for the procession of Personal Data.
                        If the Client opposes on the change/addition of
                        subcontractor, it is possible that the Processor is
                        unable to offer the agreed service.
                    </p>

                    <p>
                        8.4 The Processor should, by proper way, to strive that
                        the new subcontractors obey the obligation in this
                        Agreement including Data Protection and confidentiality.
                        The Processor is responsible of the subcontractors’
                        actions as their own.
                    </p>
                </div>
            </div>

            <div>
                <p>
                    <b>9. AUDITS</b>
                </p>
                <div className="p1">
                    <p>
                        9.1 Parties agree that on the request of the Client,
                        third party appointed or in writing approved by the
                        Processor can audit the Processor on the compliance of
                        the obligations of this Agreement, so the Client can
                        ascertain that the Processor has fulfilled its
                        obligation arising from this Agreement. The Client has a
                        right to request an audit, as stated on paragraph 9.1
                        between every twelve (12) months.
                    </p>

                    <p>
                        9.2 The Client shall cover the costs arising from the
                        audit to the Processor, the Client and remunerations and
                        costs to third party. The Processor shall assist the
                        Client and third party by reasonable means.
                    </p>

                    <p>
                        9.3 If deficiencies are detected in the audit, the
                        Processor shall fix the deficiencies without undue
                        delay, but not later than thirty (30) days after the
                        written notification by the Client, unless otherwise
                        agreed by the Parties. Essential deficiencies that
                        create a risk for Data Protection must be fixed without
                        undue delay.
                    </p>
                </div>
            </div>

            <div>
                <p>
                    <b>10. LIMITS OF LIABILITY</b>
                </p>
                <div className="p1">
                    <p>
                        10.1 The Processors liability on the breach of this
                        Agreement and Data Regulations determines as following;
                    </p>

                    <div className="p1">
                        <p>
                            10.1.1 The Processor is liable for the Client only
                            if;
                        </p>
                        <div className="p1">
                            <p>
                                10.1.1.1 The Processor has breached the Written
                                Guidelines in accordance with the Data
                                Regulation and given by the Client while
                                processing of Personal Data on Client’s behalf;
                                or
                            </p>

                            <p>
                                10.1.1.2 The Processor has breached the Data
                                Regulations concerning Processors applicable to
                                the Processor while the Processor is processing
                                personal data on Client’s behalf. (Paragraphs
                                10.1.1.1 and 10.1.1.2 together ‘’Violation’’).
                            </p>
                        </div>
                        <p>
                            10.1.2 The Processor is not liable for lost profit
                            or any other indirect damages. The Processor’s
                            liability on Violations to the Client caused by
                            direct damages is limited as follows;
                        </p>
                        <div className="p1">
                            <p>
                                10.1.2.1 The Processor liability on remuneration
                                the Client covers to the Registered is limited
                                only to damages caused directly by the
                                Processor.
                            </p>

                            <p>
                                10.1.2.2 The Processor is liable for the
                                administrative penalties imposed to the Client
                                only to an extent that the administrative
                                sanctions are caused by direct actions by the
                                Processor; and
                            </p>

                            <p>
                                10.1.2.3 The paragraphs 10.1.2.1 and 10.1.2.2
                                concerning the Processor, the maximum amount of
                                liability is limited per Client to an amount of
                                charged service fee. However, the Processors
                                liability towards the Client is limited to an
                                amount of 5,000 €.
                            </p>
                        </div>

                        <p>
                            10.1.3 The Processor is not liable for damages or
                            administrative penalties when the damages or
                            administrative penalties are cause directly or
                            indirectly by any action or omission of the Client.
                        </p>
                    </div>
                </div>
            </div>

            <div>
                <p>
                    <b>11. DURATION AND EXPIRY OF CONTRACT</b>
                </p>
                <div className="p1">
                    <p>
                        11.1 This Agreement enters into force, when both Parties
                        have signed it. This Agreement is concluded for an
                        indefinite period.
                    </p>

                    <p>
                        11.2 The Processor has a right to process Personal Data
                        only during the term of this Agreement. On the written
                        request by the Client, the Processor shall return all
                        processed Personal Data to the Client or third party
                        appointed by the Client, or erase Personal Data
                        possessed by the Processor, unless Data Regulations or
                        other applicable law require to store Personal Data. If
                        the Client requests that the Personal Data shall be
                        returned to the Client or third party, the Client is
                        liable to cover the costs to the Processing arising from
                        the return or transmission of Personal Data.
                    </p>
                </div>
            </div>

            <p>
                <b>ANNEX 1</b>
            </p>
            <div className="p1">
                <p>
                    <b>ANNEX ON THE PROCESSING OF PERSONAL DATA</b>
                </p>

                <p>
                    <b>1. BACKGROUND AND THE PURPOSE OF THIS ANNEX</b>
                </p>
                <div className="p1">
                    <p>
                        1.1 This Annex 1 has been created for the Annex of the
                        Agreement signed by the Client and the Processor. This
                        Annex is an integral part of the Agreement.
                    </p>

                    <p>
                        1.2 In this Annex the Parties agree on the following
                        facts;
                    </p>
                    <div className="p1">
                        <p>
                            (a) The character and purpose of the procession of
                            Personal Data;
                        </p>
                        <p>(b) Personal Data to be processed;</p>
                        <p>(c) Registered group and type of procession;</p>
                        <p>
                            (d) Technical and Organizational actions stated in
                            paragraph 5.2 of the Agreement; and
                        </p>
                        <p>(e) Retention time of Personal Data</p>
                    </div>

                    <p>
                        1.3 The Client commits to process Personal Data on
                        behalf of the Client under the terms set on this Annex.
                    </p>
                </div>
                <p>
                    <b>
                        2. THE CHARACTER AND PURPOSE OF THE PROCESSION OF
                        PERSONAL DATA
                    </b>
                </p>
                <div className="p1">
                    <p>
                        The Processor processes Client’s Personal Data to
                        provide a service as agree. The Processor can, for
                        example collect, record, organize, structure, storage,
                        anonymize, adapt or alterate, compare, erasure or
                        destruct, or make a backup of Personal Data.
                    </p>

                    <p>
                        The Processor processes Personal Data to execute
                        invoicing and processes related to it, based on the
                        contractual relationship between the Client and its
                        Client.
                    </p>
                </div>
                <p>
                    <b>3. PERSONAL DATA TO BE PROCESSED AND REGISTERED GROUP</b>
                </p>
                <div className="p1">
                    <p>
                        The Processor processes the following categories of
                        Personal Data:
                    </p>

                    <div className="p1">
                        <p>First and Last name</p>

                        <p>Language</p>

                        <p>
                            Contact information (Postal address, home and mobile
                            telephone number, email address)
                        </p>

                        <p>Other relevant information for invoicing purpose</p>

                        <p>Changes made for above mentioned details</p>

                        <p>
                            Registered group consists Client’s private customers
                            and company clients contact persons.
                        </p>
                    </div>
                </div>
                <p>
                    <b>4. DATA PROTECTION</b>
                </p>
                <div className="p1">
                    <p>
                        Personal Data shall always be processed in accordance
                        with the guidelines and Data Regulations.
                    </p>

                    <p>The Processor shall obey the following guidelines:</p>

                    <p>
                        The persons processing Personal Data must have read this
                        document.
                    </p>

                    <p>
                        Persons participating for the processing Personal Data
                        have committed on confidentiality clause.
                    </p>

                    <p>
                        Personal Data shall not be transferred and copied to any
                        other system than subcontractors’ system without
                        Client’s permission.
                    </p>

                    <p>
                        If the Processor uploads Personal Data on his/her
                        computer or to elsewhere from above mentioned data
                        storages, the Personal Data shall be storage only the
                        time necessary and after that it shall be erased.
                    </p>

                    <p>
                        Personal Data shall be processed with care and prudence
                        at all times.
                    </p>

                    <p>
                        The Processor shall report to the Client all actions and
                        situations that are against the Data Protection
                        requirements.
                    </p>

                    <p>
                        Additionally, the Processor shall, on its own
                        responsibility area, to take actions to limit and
                        minimize disorders, problems, changes or other action
                        causing damage to Personal Data.
                    </p>

                    <p>
                        The Processor shall have determined, updated and
                        adequate Data Protection mechanisms, that the Data
                        Protection of the service provided can be ensured. The
                        Processor commits on updating Data Protection and
                        regular evaluations of data risks concerning the
                        service. The evaluation must be kept once a year at
                        least.
                    </p>
                </div>
                <p>
                    <b>5. RETENTION TIME OF PERSONAL DATA</b>
                </p>

                <p className="p1">
                    Personal Data is processed as long as it is needed for the
                    purpose of providing the service, unless otherwise stated by
                    law.
                </p>
            </div>
            <p>
                <b>ANNEX 2</b>
            </p>
            <div className="p1">
                <p>
                    <b>SUBCONTRACTORS</b>
                </p>

                <p>
                    Up to date list of subcontractors can be found at Eezy's
                    website (link):{' '}
                    <a
                        href="https://kevytyrittajat.eezy.fi/en/subcontractors"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        https://kevytyrittajat.eezy.fi/en/subcontractors/
                    </a>
                </p>
            </div>
        </div>
    );
};

export default GdprEn;
