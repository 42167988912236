import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Hidden } from '@mui/material';
import styled from 'styled-components';
import { Flex } from 'components/Flex';
import { Icon } from 'components/Icon';
import { RightSideBody } from 'components/layout/RightSideBody';
import { LabelStrong, P, SectionTitleNew, SingleText, UniqueTitle } from 'components/textElements';
import { BORDER_RADIUS, COLOR_BLUM, COLOR_WHITE_WALKER, SCREEN_S } from 'styles/variables';
import { isWeekend } from 'utils/support/supportUtils';
import ExceptionSchedule from './ExceptionSchedule';
import SupportClosed from './SupportClosed';
import { useCustomerServiceOpen } from 'routes/SupportWrapper';

const ContactP = styled.p`
    font-weight: normal;
    font-size: 15px;
    letter-spacing: 0.15px;
    line-height: 32px;

    && svg {
        font-size: 15px;
        height: 15px;
        margin-right: 8px;
        width: 15px;
        transform: scaleX(-1);
    }
    a {
        color: ${COLOR_BLUM};
    }

    @media (max-width: ${SCREEN_S}px) {
        border-radius: 100px;
        font-size: 13px;
        padding: 0 10px;
        a,
        svg {
            color: ${COLOR_BLUM};
        }
        & ~ & {
            margin-top: 16px;
        }
    }
`;

const Section = styled.div`
    & ~ & {
        margin-top: 24px;
    }
`;

const Wrapper = styled(Flex).attrs(() => ({
    useReverseWrap: true
}))`
    width: 100%;
    background-color: ${COLOR_WHITE_WALKER};
    border-radius: ${BORDER_RADIUS};
    padding: 20px;

    @media (max-width: ${SCREEN_S}px) {
        & > div {
            width: 100%;
        }
    }
`;

const ContactNote = () => {
    const { t } = useTranslation();

    return (
        <div style={{ margin: '30px 0' }}>
            <P color={COLOR_BLUM} style={{ margin: 0 }}>
                {t('support:contact.note1')}
            </P>
            <P color={COLOR_BLUM} style={{ marginTop: 10 }}>
                {t('support:contact.note2')}
            </P>          
        </div>
    );
}

const AppointmentBox = () => {
    const { t } = useTranslation();

    return (
<div style={{ margin: '30px 0' }}>
            <P color={COLOR_BLUM} style={{ margin: '10px 0' }}>
                {t('support:contact.appointment.description1')}
            </P>
            <P color={COLOR_BLUM} style={{ margin: '10px 0' }}>
                {t('support:contact.appointment.description2')}
            </P>
            <P color={COLOR_BLUM} style={{ margin: '10px 0' }}>
                {t('support:contact.appointment.description3')}
            </P>
            <P color={COLOR_BLUM} style={{ marginTop: 20 }}>
                <a
                    href="https://vello.fi/eezykevytyrittajat/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {t('support:contact.appointment.linkText')}
                </a>
            </P>
        </div>
    );
}


const ContactPage: FC = () => {
    const { t } = useTranslation();
    const { customerServiceOpen } = useCustomerServiceOpen();

    return (
        <RightSideBody>
            <Hidden mdUp>
                <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <UniqueTitle>{t('support:contact.title')}</UniqueTitle>
                </Flex>
                <ContactNote />                

                <Flex column style={{ marginBottom: 10 }}>
                    <LabelStrong color={COLOR_BLUM}>
                        {t('support:contact.times.title')}
                    </LabelStrong>
                    <Flex spread style={isWeekend() ? { opacity: 0.8 } : {}}>
                        <LabelStrong color={COLOR_BLUM}>
                            {t('support:contact.times.weekdays')}
                        </LabelStrong>
                        <SingleText color={COLOR_BLUM}>
                            {t('support:contact.times.weekdays-time')}
                        </SingleText>
                    </Flex>
                    <Flex spread style={!isWeekend() ? { opacity: 0.8 } : {}}>
                        <LabelStrong color={COLOR_BLUM}>
                            {t('support:contact.times.weekends')}
                        </LabelStrong>
                        <SingleText color={COLOR_BLUM}>
                            {t('support:contact.times.weekends-time')}
                        </SingleText>
                    </Flex>
                    
                </Flex>
                <AppointmentBox />
            </Hidden>

            {!customerServiceOpen && (
                <Flex fullWidth>
                    <SupportClosed style={{ marginBottom: 32 }} />
                </Flex>
            )}

            <Hidden mdUp>
                <ExceptionSchedule style={{ marginBottom: 32 }} />
            </Hidden>

            <Wrapper>
                <Flex column style={{ flexGrow: 1 }}>
                    <SectionTitleNew
                        color={COLOR_BLUM}
                        style={{ marginBottom: 24 }}
                    >
                        {t('support:contact.service.title')}
                    </SectionTitleNew>
                    <Section>
                        <LabelStrong color={COLOR_BLUM}>
                            {t('support:contact.service.1')}
                        </LabelStrong>
                        <ContactP>
                            <Icon icon={['fas', 'phone']} color={COLOR_BLUM} />
                            <a href="tel:0942421565">09 4242 1565</a>
                        </ContactP>
                        <ContactP>
                            <Icon
                                icon={['fal', 'envelope-open-text']}
                                color={COLOR_BLUM}
                            />
                            <a href="mailto:startti.kevytyrittajat@eezy.fi">
                                startti.kevytyrittajat@eezy.fi
                            </a>
                        </ContactP>
                    </Section>

                    <Section>
                        <LabelStrong color={COLOR_BLUM}>
                            {t('support:contact.service.2')}
                        </LabelStrong>
                        <ContactP>
                            <Icon icon={['fas', 'phone']} color={COLOR_BLUM} />
                            <a href="tel:0942475630">09 4247 5630</a>
                        </ContactP>
                        <ContactP>
                            <Icon
                                icon={['fal', 'envelope-open-text']}
                                color={COLOR_BLUM}
                            />
                            <a href="mailto:info.kevytyrittajat@eezy.fi">
                                info.kevytyrittajat@eezy.fi
                            </a>
                        </ContactP>
                    </Section>
                </Flex>

                <Flex
                    column
                    style={{
                        flexGrow: 1,
                        marginBottom: 30
                    }}
                >
                    <SectionTitleNew
                        color={COLOR_BLUM}
                        style={{ marginBottom: 24 }}
                    >
                        {t('support:contact.salary.title')}
                    </SectionTitleNew>

                    <Section>
                        <LabelStrong color={COLOR_BLUM}>
                            {t('support:contact.salary.1')}
                        </LabelStrong>
                        <ContactP>
                            <Icon icon={['fas', 'phone']} color={COLOR_BLUM} />
                            <a href="tel:0942475635">09 4247 5635</a>
                        </ContactP>
                        <ContactP>
                            <Icon
                                icon={['fal', 'envelope-open-text']}
                                color={COLOR_BLUM}
                            />
                            <a href="mailto:palkat.kevytyrittajat@eezy.fi">
                                palkat.kevytyrittajat@eezy.fi
                            </a>
                        </ContactP>
                    </Section>

                    <Section>
                        <LabelStrong color={COLOR_BLUM}>
                            {t('support:contact.salary.2')}
                        </LabelStrong>
                        <ContactP>
                            <Icon icon={['fas', 'phone']} color={COLOR_BLUM} />
                            <a href="tel:0942475640">09 4247 5640</a>
                        </ContactP>
                        <ContactP>
                            <Icon
                                icon={['fal', 'envelope-open-text']}
                                color={COLOR_BLUM}
                            />
                            <a href="mailto:express.kevytyrittajat@eezy.fi">
                                express.kevytyrittajat@eezy.fi
                            </a>
                        </ContactP>
                    </Section>
                </Flex>
            </Wrapper>
            <Hidden mdDown>
                <ExceptionSchedule style={{ margin: '18px 0' }} />
                <Wrapper>
                    <ContactNote />
                </Wrapper>
                <Wrapper style={{ marginTop: '18px' }}>
                    <AppointmentBox />
                </Wrapper>
            </Hidden>
        </RightSideBody>
    );
};

export default ContactPage;
