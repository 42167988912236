import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import {
    IInvoiceItem,
    IInvoiceItemKeys
} from '../../../../../shared/src/types/invoice';
import { IDropdownOption } from '../../../components/form/AutocompleteDropdown';
import InvoiceTableDataInput, { DataInputType } from '../InvoiceTableDataInput';

interface IInvoiceTableDataProps {
    allowNegative?: boolean;
    editable: boolean;
    endAdornment?: string;
    readOnlyContent: ReactNode;
    handleFocus?: () => void;
    handleUpdate: (changedProperty: IInvoiceItemKeys) => void;
    disableRounding?: boolean;
    error?: any;
    isEur?: boolean;
    item: IInvoiceItem;
    name: keyof IInvoiceItem;
    options?: IDropdownOption[];
    placeholder?: string;
    style?: CSSProperties;
    type: DataInputType;
    warning?: string | ReactElement;
}

const InvoiceTableData = (props: IInvoiceTableDataProps) => {
    const value = props.item[props.name];
    if (props.editable) {
        return (
            <InvoiceTableDataInput
                allowNegative={props.allowNegative}
                endAdornment={props.endAdornment}
                handleFocus={props.handleFocus}
                handleUpdate={props.handleUpdate}
                disableRounding={props.disableRounding}
                error={props.error}
                isEur={props.isEur}
                name={props.name}
                options={props.options}
                placeholder={props.placeholder}
                style={props.style}
                type={props.type}
                value={value ? value.toString() : undefined}
                warning={props.warning}
            />
        );
    } else {
        return <>{props.readOnlyContent}</>;
    }
};

export default InvoiceTableData;
