import { ApolloQueryResult, useQuery } from '@apollo/client';
import React, { ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { makeStyles } from 'tss-react/mui';

import {
    IClientChoiceInput,
    IInvoice,
    IInvoiceKeys,
    ISpreadState,
    IYtjBriefCompany,
} from '../../../../shared/src/types/invoice';
import { UiLanguage } from '../../../../shared/src/types/user';
import {
    handleInvoiceSpreadClientSet,
    setExpenseReimbursementWarningHidden as setERWarningHidden,
    setTravelWarningHidden as setTWarningHidden,
} from '../../actions/invoice';
import {
    AccordionPanel,
    AccordionPanelDetails,
    AccordionPanelSummary,
    AccordionPanelSummarySmall,
    AccordionTypography,
} from '../../components/AccordionPanel';
import { EezyButton } from '../../components/Buttons';
import { Icon } from '../../components/Icon';
import LoadingSpinner from '../../components/Loading';
import { Label } from '../../components/textElements';
import { ErrorTooltip } from '../../components/ToolTip';
import {
    COLOR_GREYJOY,
    COLOR_IMPORTANT,
    COLOR_STATUS_DONE,
    COLOR_WHITE_WALKER,
} from '../../styles/variables';
import { isMobile, trans } from '../../utils';
import {
    getTemplate,
    isCostInvoiceEditable,
    isEditable,
    isExpenseReimbursementMissing,
} from '../../utils/invoice/invoiceLogic';
import { IInvoiceTemplate } from '../../utils/invoice/invoiceTemplates';
import { dismissWarning, isWarningHidden, WarningType } from '../../utils/user/userUtils';
import { formatValidationResult } from '../../utils/validation';
import ExpenseReimbursementWarning from './ExpenseReimbursementWarning';
import { openFillHelper } from './fillHelpers/utils';
import InvoiceAttachmentAdd from './InvoiceAttachmentAdd';
import InvoiceAttachmentList from './InvoiceAttachmentList';
import InvoiceDescriptionPanel from './InvoiceDescriptionPanel';
import InvoiceLanguageEdit from './InvoiceLanguageEdit';
import InvoiceOtherFields from './InvoiceOtherFields';
import InvoicePaymentPanel from './InvoicePaymentPanel';
import { InvoiceRecipientAutocomplete } from './InvoiceRecipientAutocomplete';
import { InvoiceRecipientDetailsPanel } from './InvoiceRecipientDetailsPanel';
import InvoiceRowDetails from './InvoiceRowDetails';
import InvoiceRowTypeSelection from './InvoiceRowTypeSelection';
import InvoiceSalary from './InvoiceSalary';
import InvoiceTypeEdit from './InvoiceTypeEdit';
import { SELECTED_FILL_HELPER } from './queries/invoiceStateQueries';

const useStyles = makeStyles()(() => ({
    icon: {
        color: '#707070',
    },
    iconWrapper: {
        borderRadius: '10px',
        height: '20px',
        lineHeight: '20px',
        textAlign: 'center',
        width: '20px',
    },
    root: {
        width: '100%',
    },
}));

interface IInvoiceSpreadProps {
    expanded: number;
    handleAttachmentClicked: (index: number) => void;
    handleAttachmentDelete: (index: number) => void;
    handleCreateTempCostInvoice: () => void;
    handleInvoiceItemUpdate?: (o: object) => void;
    handleInvoiceSend: () => void;
    handleInvoiceSpread: (panel: number, openSecondMenu?: boolean, openSubObjectId?: number) => void;
    handleInvoiceSpreadClientSet: (clientJustSet: boolean) => void;
    handleInvoiceUpdate: (changedProperty: IInvoiceKeys) => void;
    handleRecipientUpdate: (changedProperty: IInvoiceKeys) => void;
    handleExpandChange: (panel: number) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => void;
    invoice?: IInvoice;
    invoiceErrors: ReturnType<typeof formatValidationResult>;
    language: UiLanguage;
    loading?: boolean;
    refetchInvoice?: () => Promise<ApolloQueryResult<any>>;
    setTypeModalOpen: (val: boolean) => void;
    showModals: (modals: string[]) => void;
    spreadState: ISpreadState;
    template: IInvoiceTemplate;
    expenseReimbursementWarningHidden: boolean;
    setExpenseReimbursementWarningHidden: (expenseReimbursementWarningHidden: boolean) => void;
    travelWarningHidden: boolean;
    setTravelWarningHidden: (travelWarningHidden: boolean) => void;
    userId: number;
    hasContract?: boolean;
}

const InvoiceSpread = (props: IInvoiceSpreadProps) => {
    const {
        expanded,
        handleExpandChange,
        handleInvoiceUpdate,
        handleRecipientUpdate,
        invoice,
        invoiceErrors,
        setTypeModalOpen,
        spreadState,
        handleInvoiceSpread,
        language,
        showModals,
        expenseReimbursementWarningHidden,
        setExpenseReimbursementWarningHidden,
        setTravelWarningHidden,
        hasContract,
    } = props;

    const { classes } = useStyles();

    const [openRecipientSelector, setOpenRecipientSelector] = useState(false);
    const { client } = useQuery(SELECTED_FILL_HELPER);

    const [showEarlierStages, setShowEarlierStages] = useState(false);

    const recipientId = invoice?.recipient?.id;
    useEffect(() => {
        setOpenRecipientSelector(false);
    }, [recipientId]);

    useEffect(() => {
        setTravelWarningHidden(isWarningHidden(WarningType.Travel, invoice?.id));
        setExpenseReimbursementWarningHidden(isWarningHidden(WarningType.ExpenseReimbursement, invoice?.id));
    }, [invoice?.id, setTravelWarningHidden, setExpenseReimbursementWarningHidden]);

    const handleInvoiceSpreadProxy: IInvoiceSpreadProps['handleInvoiceSpread'] = (
        panel,
        openSecondMenu,
        id,
    ) => {
        setShowEarlierStages(false);
        handleInvoiceSpread(panel, openSecondMenu, id);
    };

    const changeRecipient = () => {
        setOpenRecipientSelector(true);
    };

    const selectedTemplate = getTemplate(invoice?.template);

    const handleClose = () => {
        handleInvoiceSpreadProxy(expanded, false);
    };

    const invoiceRowErrors = [
        {
            id: 'allItemsHaveDescription',
            message: trans('invoice.errors.item-missing-description'),
            valid: invoice?.invoiceItems?.every((item) => (item?.description?.length || 0) > 0),
        },
        {
            id: 'itemDates',
            message: trans('invoice.errors.invalid-item-dates'),
            valid: invoice?.invoiceItems?.every(
                (item) =>
                    !item?.startDate || !item?.endDate || (item?.startDate || 0) <= (item?.endDate || 0),
            ),
        },
        {
            id: 'negativeSum',
            message: trans('errors.invoice-total-negative'),
            valid: (invoice?.totalWithVat || 0) >= 0,
        },
        {
            id: 'workRowExists',
            message: trans('invoice.errors.work-row-missing'),
            valid: !!invoice?.invoiceItems?.find((item) => item.itemType === 'work'),
        },
    ];
    const invoiceRowsNextButtonError = invoiceRowErrors.find((e) => !e.valid);

    return (
        <div className={classes.root}>
            {/* 0. Previous stages */}
            <HidePanel hide={expanded < 3 || showEarlierStages}>
                <AccordionPanel
                    square
                    expanded={false}
                    onChange={() => setShowEarlierStages(true)}
                    disabled={showEarlierStages}
                >
                    <AccordionPanelSummarySmall>
                        <Icon
                            icon={['far', 'ellipsis-v']}
                            style={{ marginTop: '3px', height: 20, width: 20 }}
                        />
                        <AccordionTypography style={{ fontSize: '12px', lineHeight: 2.2 }}>
                            {trans('invoice.show-previous-stages')}
                        </AccordionTypography>
                    </AccordionPanelSummarySmall>
                </AccordionPanel>
            </HidePanel>

            {/* 1. Client's info  */}
            <HidePanel hide={expanded > 2 && !showEarlierStages}>
                <AccordionPanel
                    square
                    expanded={expanded === 1}
                    onChange={handleExpandChange(1)}
                    disabled={expanded === 1}
                >
                    <AccordionPanelSummary>
                        <CheckIcon checked={!invoiceErrors?.recipient} classes={classes} />
                        <AccordionTypography style={{ fontWeight: expanded === 1 ? 600 : 400 }}>
                            {trans('invoice.clients-info')}
                        </AccordionTypography>
                    </AccordionPanelSummary>
                    <AccordionPanelDetails>
                        <div>
                            {!invoice?.recipient || openRecipientSelector ? (
                                <InvoiceRecipientAutocomplete
                                    id={'guided-recipient-autocomplete'}
                                    handleNewCustomer={() => {
                                        props.handleInvoiceSpreadClientSet(true);
                                        setOpenRecipientSelector(false);
                                        openFillHelper(client, 'new-recipient');
                                    }}
                                    handleRecipientChange={(recipient: IClientChoiceInput) => {
                                        // After changing client, don't go to the first invalid
                                        // section but instead go to the next
                                        // handleExpandChange(2);
                                        handleRecipientUpdate({
                                            recipient,
                                        });
                                        setOpenRecipientSelector(false);
                                    }}
                                    handleYtjRecipientChange={(r: IYtjBriefCompany) => {
                                        openFillHelper(client, 'new-ytj-recipient', r.businessId);
                                    }}
                                />
                            ) : (
                                <InvoiceRecipientDetailsPanel
                                    handleChangeRecipient={changeRecipient}
                                    handleEditRecipient={() => {
                                        openFillHelper(client, 'recipient', invoice?.recipient?.id);
                                    }}
                                    recipient={invoice.recipient}
                                />
                            )}
                            <EezyButton
                                disabled={!!invoiceErrors?.recipient}
                                color="important"
                                dark
                                onClick={() => handleInvoiceSpreadProxy(2)}
                                style={{ marginTop: '10px' }}
                            >
                                {trans('invoice.button-next')}
                            </EezyButton>
                        </div>
                    </AccordionPanelDetails>
                </AccordionPanel>
            </HidePanel>

            {/* 2. Invoice type and occupation  */}
            <HidePanel hide={expanded > 3 && !showEarlierStages}>
                <AccordionPanel
                    square
                    expanded={expanded === 2}
                    onChange={handleExpandChange(2)}
                    disabled={expanded === 2}
                >
                    <AccordionPanelSummary>
                        <CheckIcon checked={!!invoice?.occupation} classes={classes} />
                        <AccordionTypography style={{ fontWeight: expanded === 2 ? 600 : 400 }}>
                            {trans('invoice.type-title')}
                        </AccordionTypography>
                    </AccordionPanelSummary>
                    <AccordionPanelDetails>
                        <InvoiceLanguageEdit invoice={invoice} client={client} />
                        <InvoiceTypeEdit
                            editable={true}
                            invoice={invoice}
                            selectedTemplate={selectedTemplate}
                            setTypeModalOpen={setTypeModalOpen}
                        />
                    </AccordionPanelDetails>
                    {invoice && (
                        <AccordionPanelDetails>
                            <InvoiceOtherFields
                                column={true}
                                editable={true}
                                invoice={invoice}
                                handleUpdate={handleInvoiceUpdate}
                                template={props.template}
                            />
                        </AccordionPanelDetails>
                    )}

                    <AccordionPanelDetails>
                        <EezyButton
                            style={{ margin: '14px 0 5px 0' }}
                            disabled={!invoice?.occupation}
                            color="important"
                            dark
                            onClick={() => handleInvoiceSpreadProxy(3)}
                        >
                            {trans('invoice.button-next')}
                        </EezyButton>
                    </AccordionPanelDetails>
                </AccordionPanel>
            </HidePanel>

            {/* 3. Invoice duedate  */}
            <HidePanel hide={expanded > 4 && !showEarlierStages}>
                <AccordionPanel
                    square
                    expanded={expanded === 3}
                    onChange={handleExpandChange(3)}
                    disabled={expanded === 3}
                >
                    <AccordionPanelSummary>
                        <CheckIcon
                            checked={!invoiceErrors?.dueDate && !invoiceErrors?.paymentTerm}
                            classes={classes}
                        />
                        <AccordionTypography style={{ fontWeight: expanded === 3 ? 600 : 400 }}>
                            {trans('invoice.duedate')}
                        </AccordionTypography>
                    </AccordionPanelSummary>
                    <AccordionPanelDetails>
                        <div>
                            <InvoicePaymentPanel
                                handleChange={handleInvoiceUpdate}
                                invoice={invoice}
                                invoiceErrors={invoiceErrors}
                            />
                            <EezyButton
                                disabled={!!invoiceErrors?.dueDate || !!invoiceErrors?.paymentTerm}
                                color="important"
                                dark
                                onClick={() => handleInvoiceSpreadProxy(4)}
                                style={{ marginTop: '40px' }}
                            >
                                {trans('invoice.button-next')}
                            </EezyButton>
                        </div>
                    </AccordionPanelDetails>
                </AccordionPanel>
            </HidePanel>

            {/* 4. Invoice rows  */}
            <HidePanel hide={expanded > 5 && !showEarlierStages}>
                {expanded === 4 && spreadState?.openSecondMenu ? (
                    <AccordionSecondMenu
                        active={expanded === 4 && spreadState?.openSecondMenu}
                        closeMenu={() => {
                            handleClose();
                        }}
                        headerTitle={trans('invoice.rows')}
                        expanded={spreadState?.openPanelId}
                    >
                        <InvoiceRowDetails
                            language={language}
                            invoiceId={invoice?.id}
                            rowId={spreadState?.openSubObjectId}
                            handleInvoiceSpread={handleInvoiceSpreadProxy}
                            showModals={showModals}
                        />
                    </AccordionSecondMenu>
                ) : (
                    <AccordionPanel
                        square
                        expanded={expanded === 4}
                        onChange={handleExpandChange(4)}
                        disabled={expanded === 4}
                    >
                        <AccordionPanelSummary>
                            <CheckIcon checked={!invoiceRowsNextButtonError} classes={classes} />
                            <AccordionTypography
                                style={{
                                    fontWeight: expanded === 4 ? 600 : 400,
                                }}
                            >
                                {trans('invoice.rows')}
                            </AccordionTypography>
                        </AccordionPanelSummary>
                        <AccordionPanelDetails>
                            <InvoiceRowTypeSelection
                                invoice={invoice}
                                invoiceErrors={invoiceErrors}
                                handleInvoiceSpread={handleInvoiceSpreadProxy}
                                template={selectedTemplate}
                                hasContract={hasContract}
                            />
                            <EezyButton
                                style={{ margin: '30px 0 5px 0' }}
                                disabled={!!invoiceRowsNextButtonError}
                                color="important"
                                dark
                                onClick={() => handleInvoiceSpreadProxy(5)}
                            >
                                {trans('invoice.button-next')}
                            </EezyButton>
                            {!!invoiceRowsNextButtonError && (
                                <ErrorTooltip
                                    title={
                                        invoiceRowsNextButtonError ? invoiceRowsNextButtonError.message : ''
                                    }
                                    arrow
                                    placement="top"
                                    enterTouchDelay={0}
                                >
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            marginLeft: '10px',
                                            verticalAlign: '-17px',
                                        }}
                                    >
                                        <Icon icon={['far', 'exclamation-circle']} color={COLOR_IMPORTANT} />
                                    </span>
                                </ErrorTooltip>
                            )}
                        </AccordionPanelDetails>
                    </AccordionPanel>
                )}
            </HidePanel>

            {/* 5. Invoice attachments and description  */}
            <HidePanel hide={expanded > 6 && !showEarlierStages}>
                <AccordionPanel
                    square
                    expanded={expanded === 5}
                    onChange={handleExpandChange(5)}
                    disabled={expanded === 5}
                >
                    <AccordionPanelSummary>
                        <CheckIcon
                            checked={
                                expanded > 5 || (invoice?.files?.length || 0) > 0 || !!invoice?.description
                            }
                            classes={classes}
                        />
                        <AccordionTypography style={{ fontWeight: expanded === 5 ? 600 : 400 }}>
                            {trans('invoice.attachments-and-description')}
                        </AccordionTypography>
                    </AccordionPanelSummary>
                    {invoice?.id && (
                        <AccordionPanelDetails>
                            <InvoiceDescriptionPanel
                                handleInvoiceUpdate={handleInvoiceUpdate}
                                invoice={invoice}
                            />
                            <InvoiceAttachmentAdd
                                editable={isEditable(invoice, false)}
                                refetchInvoice={props.refetchInvoice}
                                invoice={invoice}
                            />
                        </AccordionPanelDetails>
                    )}
                    <AccordionPanelDetails>
                        <InvoiceAttachmentList
                            files={invoice?.files}
                            handleAttachmentClicked={props.handleAttachmentClicked}
                            handleDeleteFile={props.handleAttachmentDelete}
                        />
                    </AccordionPanelDetails>
                    <AccordionPanelDetails>
                        <EezyButton
                            disabled={false}
                            color="important"
                            dark
                            onClick={() => handleInvoiceSpreadProxy(6)}
                        >
                            {trans('invoice.button-next')}
                        </EezyButton>
                    </AccordionPanelDetails>
                </AccordionPanel>
            </HidePanel>

            {/* 6. Extra services: Invoice costs & express  */}
            <AccordionPanel
                square
                expanded={expanded === 6}
                onChange={handleExpandChange(6)}
                disabled={expanded === 6}
            >
                <AccordionPanelSummary>
                    <CheckIcon checked={expanded > 6} classes={classes} />
                    <AccordionTypography style={{ fontWeight: expanded === 6 ? 600 : 400 }}>
                        {trans('invoice.additional-services')}
                    </AccordionTypography>
                </AccordionPanelSummary>
                <AccordionPanelDetails>
                    <InvoiceSalary
                        editable={isEditable(invoice, false)}
                        handleAddCostInvoice={props.handleCreateTempCostInvoice}
                        invoice={invoice}
                    />
                    <EezyButton
                        disabled={false}
                        color="important"
                        dark
                        onClick={() => handleInvoiceSpreadProxy(7)}
                        style={{ marginBottom: '9px', marginTop: '16px' }}
                    >
                        {trans('invoice.button-next')}
                    </EezyButton>
                </AccordionPanelDetails>
            </AccordionPanel>

            {/* 7. Send invoice */}
            <AccordionPanel
                square
                expanded={expanded === 7}
                onChange={handleExpandChange(7)}
                disabled={expanded === 7}
            >
                <AccordionPanelSummary>
                    <ArrowIcon direction="right" className={classes.icon} />
                    <AccordionTypography style={{ fontWeight: expanded === 7 ? 600 : 400 }}>
                        {trans('invoice.send-invoice')}
                    </AccordionTypography>
                </AccordionPanelSummary>
                <AccordionPanelDetails>
                    <Label color="black">{trans('invoice.send-invoice-title')}</Label>
                    <EezyButton
                        color="important"
                        dark
                        fullWidth
                        hasIcon
                        iconAlignment="right"
                        onClick={props.handleInvoiceSend}
                        style={{ marginTop: '10px' }}
                    >
                        {props.loading ? <LoadingSpinner size="1em" /> : trans('invoice.send-invoice')}
                        <Icon color="white" icon={['far', 'arrow-right']} />
                    </EezyButton>

                    {invoice &&
                        isMobile() &&
                        isCostInvoiceEditable(invoice) &&
                        isExpenseReimbursementMissing(invoice) &&
                        !expenseReimbursementWarningHidden && (
                            <ExpenseReimbursementWarning
                                language={props.language}
                                onClose={() => {
                                    setExpenseReimbursementWarningHidden(true);
                                    dismissWarning(WarningType.ExpenseReimbursement, invoice?.id);
                                }}
                            />
                        )}
                </AccordionPanelDetails>
            </AccordionPanel>
        </div>
    );
};

interface IAccordionSecondMenu {
    active: boolean;
    headerTitle: string;
    closeMenu: () => void;
    children?: ReactNode;
    expanded: number;
}

const AccordionSecondMenu = (props: IAccordionSecondMenu) => {
    const { active, headerTitle, closeMenu, children } = props;
    return active ? (
        <AccordionPanel square expanded={active}>
            <AccordionPanelSummary onClick={() => closeMenu()}>
                <ArrowIcon direction="left" />
                <AccordionTypography>{headerTitle}</AccordionTypography>
            </AccordionPanelSummary>
            <AccordionPanelDetails>{children}</AccordionPanelDetails>
        </AccordionPanel>
    ) : null;
};

interface IHidePanelProps {
    hide?: boolean;
    children?: ReactNode;
}

const HidePanel: React.FC<IHidePanelProps> = ({ hide, children }) => {
    return hide ? null : <React.Fragment>{children}</React.Fragment>;
};

interface ICheckIconTypes {
    classes?: Record<'icon' | 'iconWrapper' | 'root', string>;
    checked?: boolean;
}

const CheckIcon = ({ classes, checked }: ICheckIconTypes) => {
    return (
        <div
            className={checked ? classes?.iconWrapper : classes?.iconWrapper}
            style={{
                backgroundColor: checked ? COLOR_STATUS_DONE : COLOR_WHITE_WALKER,
            }}
        >
            {checked ? (
                <Icon className="small" color={COLOR_WHITE_WALKER} icon={['far', 'check']} />
            ) : (
                <Icon color={COLOR_GREYJOY} icon={['far', 'circle']} />
            )}
        </div>
    );
};

interface IArrowIcon {
    className?: string;
    direction?: string;
}

const ArrowIcon = ({ className, direction }: IArrowIcon) => (
    <Icon
        className={className}
        icon={direction === 'right' ? ['far', 'arrow-right'] : ['far', 'arrow-left']}
    />
);

const mapStateToProps = (state: any) => {
    return {
        expenseReimbursementWarningHidden: state.invoice?.expenseReimbursementWarningHidden,
        spreadState: state.invoice.spreadState,
        travelWarningHidden: state.invoice?.travelWarningHidden,
        userId: state.user?.id,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        handleInvoiceSpreadClientSet: (clientJustSet: boolean) => {
            dispatch(handleInvoiceSpreadClientSet(clientJustSet));
        },
        setExpenseReimbursementWarningHidden: (expenseReimbursementWarningHidden: boolean) => {
            dispatch(setERWarningHidden(expenseReimbursementWarningHidden));
        },
        setTravelWarningHidden: (travelWarningHidden: boolean) => {
            dispatch(setTWarningHidden(travelWarningHidden));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSpread);
