import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { EezyButton } from 'components/Buttons';
import { Icon } from 'components/Icon';
import { useTranslation } from 'react-i18next';
import { SCREEN_M } from 'styles/variables';
import { LabelValueType } from 'containers/eezypay/types/SalesPageStatisticTypes';
import { DefaultMinDatePicker } from 'containers/eezypay/validation/constants';

function scrollElementToView(el: Element, container: Element) {
    if (!el || !container) {
        return;
    }

    setTimeout(() => {
        if (window.innerWidth >= SCREEN_M) {
            const y = (el as HTMLElement).offsetTop;
            container!.scrollTop = y; // Prevent screen jump on desktop
        } else {
            el.scrollIntoView({ block: 'nearest', inline: 'start' });
        }
    }, 0);
}

interface IButtonPeriodPicker {
    label?: string;
    value?: [Date, Date];
    icon?: IconProp;
    views?: ('year' | 'month')[];
    disabled?: boolean;
    onChange?: (value: [Date, Date, string]) => void;
    onChangePeriod?: (period: string) => void;
    maxDate?: Date;
    minDate?: Date;
}

const HiddenDatePickerWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
`;

const DatePickerUnderlay = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;

    @media (max-width: ${SCREEN_M}px) {
        background: rgba(0, 0, 0, 0.5);
    }
`;

const DatePickerPopup = styled.div`
    position: absolute;
    opacity: 0;
    z-index: 1;

    padding: 24px 9px 24px 24px;
    background: #fff;
    border: 1px solid #e4e4e7;
    border-radius: 1rem;

    display: flex;
    gap: 24px;

    @media (max-width: ${SCREEN_M}px) {
        max-width: 90vw;
        flex-direction: column;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        gap: 0;

        box-shadow:
            0px 11px 15px -7px rgba(0, 0, 0, 0.2),
            0px 24px 38px 3px rgba(0, 0, 0, 0.14),
            0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    }

    .view-separator {
        width: 1px;
        min-height: 100%;
        background: #e4e4e7;

        @media (max-width: ${SCREEN_M}px) {
            width: 100%;
            height: 1px;
            margin-top: 10px;
            margin-bottom: 32px;
        }
    }

    .calendar-options {
        li {
            font-size: 14px;
            line-height: 40px;
            transition: all 0.2s ease;
        }
        li:hover,
        li.active {
            cursor: pointer;
            color: #7d52d5;
        }

        @media (max-width: ${SCREEN_M}px) {
            ul {
                display: flex;
                gap: 28px;
                overflow-x: auto;
                align-items: center;
            }
            li {
                display: inline-block;
            }
        }
    }

    .MuiPickersCalendarHeader-root {
        margin-top: 0 !important;
    }
`;

const CalendarCellNode = styled.div`
    width: 100%;
    min-width: 127px;
    height: 40px;
    border-radius: 999px;
    font-weight: 500;
    cursor: pointer;
    color: #351f65;

    &.active {
        background: #351f65;
        color: #fff;
    }
`;

const YearPickerView = (props: {
    minDate: Date;
    maxDate?: Date;
    value?: Date;
    onChange: (value: Date) => void;
}) => {
    const el = useRef<HTMLDivElement>(null);
    const numberOfYears = moment(props.maxDate).year() - props.minDate.getFullYear() + 1;
    const currentYear = props.value?.getFullYear() ?? new Date().getFullYear();
    const maxNodesInView = 4; // UI Design to show 4 nodes
    const years = Array.from({ length: numberOfYears })
        .fill(null)
        .map((_, i) => moment(props.minDate).add(i, 'year').year());

    const onSelect = (year: number) => {
        const date = moment().set('year', year).startOf('year').toDate();
        props.onChange(date);
    };

    useEffect(() => {
        // el.current!.scrollTop = el.current!.scrollHeight;
        const activeNode = el.current!.querySelector('.active');
        scrollElementToView(activeNode!, el.current!);
    }, []);

    return (
        <div ref={el} className="overflow-y-scroll pr-4 relative" style={{ maxHeight: 40 * maxNodesInView }}>
            {years.map((i) => (
                <CalendarCellNode
                    key={i}
                    className={[
                        'flex flex-col items-center justify-center text-sm',
                        currentYear == i ? 'active' : '',
                    ].join(' ')}
                    onClick={() => onSelect(i)}
                >
                    {i}
                </CalendarCellNode>
            ))}
        </div>
    );
};

const MonthPickerView = (props: {
    minDate: Date;
    maxDate?: Date;
    value?: Date;
    onChange: (value: Date) => void;
}) => {
    const el = useRef<HTMLDivElement>(null);

    const [data, setData] = useState(new Date());
    const [step, setStep] = useState('year'); // year | month

    const currentActiveDate = moment(props.value).format('YYYY-MM');
    const maxNodesInView = 4; // UI Design to show 4 nodes

    useEffect(() => {
        const activeNode = el.current!.querySelector('.active');
        scrollElementToView(activeNode!, el.current!);
    }, [step]);

    const list = (): LabelValueType<Date>[] => {
        if (step == 'year') {
            const numberOfYears = moment(props.maxDate).year() - props.minDate.getFullYear() + 1;

            return Array.from({ length: numberOfYears })
                .fill(null)
                .map((_, i) => {
                    const d = moment(props.minDate).add(i, 'year');

                    return {
                        id: d.format('YYYY'),
                        label: d.year().toString(),
                        value: d.toDate(),
                    };
                });
        } else {
            const numberOfMonths =
                moment(props.maxDate).year() == data.getFullYear() ? data.getMonth() + 1 : 12;
            return Array.from({ length: numberOfMonths })
                .fill(null)
                .map((_, i) => {
                    const d = moment().set({ year: data.getFullYear(), month: i });

                    return {
                        id: d.format('YYYY-MM'),
                        label: d.format('MMMM'),
                        value: d.startOf('month').toDate(),
                    };
                });
        }
    };

    const headerLabel = () => {
        return moment(props.value).format('MMMM YYYY');
    };

    const onSelectNode = (node: LabelValueType<Date>) => {
        if (step == 'year') {
            setData(moment(data).set({ year: node.value.getFullYear() }).toDate());
            setStep('month');
        } else if (step == 'month') {
            const result = moment(data).set({ month: node.value.getMonth() }).startOf('month').toDate();
            setData(result);
            props.onChange(result);
        } else {
            console.log('[Period Picker] Unknown step value');
        }
    };

    return (
        <div>
            <div className="capitalize text-center text-sm mb-4 pr-4 whitespace-nowrap">
                {headerLabel()}
                <Icon icon={['fas', 'chevron-down']} className="ml-2" style={{ transform: 'scale(0.75)' }} />
            </div>
            <div ref={el} className="overflow-auto pr-4 relative" style={{ maxHeight: 40 * maxNodesInView }}>
                {list().map((i) => (
                    <CalendarCellNode
                        key={i.id}
                        className={[
                            'flex flex-col items-center justify-center text-sm capitalize',
                            currentActiveDate.startsWith(i.id) ? 'active' : '',
                        ].join(' ')}
                        onClick={() => onSelectNode(i)}
                    >
                        {i.label}
                    </CalendarCellNode>
                ))}
            </div>
        </div>
    );
};

const ButtonPeriodPicker = (props: IButtonPeriodPicker) => {
    const elPopup = useRef<HTMLDivElement>(null);
    const [value, setValue] = useState(props.value?.[0]);
    const [isOpen, setIsOpen] = useState(false);
    const [periodMode, setPeriodMode] = useState('year');

    const { t } = useTranslation();

    const modes: LabelValueType<string>[] = [
        { label: t('statistic.date-period.year'), value: 'year' },
        { label: t('statistic.date-period.month'), value: 'month' },
    ].filter((i) => !props.views || props.views.includes(i.value as any));

    const elPicker = useRef<HTMLDivElement>(null);

    const open = () => {
        setIsOpen(true);
    };

    const close = () => {
        setIsOpen(false);
    };

    const onSelect = (v: Date) => {
        const period = periodMode.toLowerCase() as any;

        const from = moment(v).startOf(period).toDate();
        const to = moment(v).endOf(period).toDate();

        props.onChange?.([from, to, period]);
        setIsOpen(!isOpen);
    };

    const onChange = (v: Date) => {
        setValue(v);

        onSelect(v);
    };

    useEffect(() => {
        if (isOpen) {
            const element = elPopup.current!;
            const rect = element.getBoundingClientRect();
            const parent = element.parentElement;
            const parentRect = parent!.getBoundingClientRect();

            element.style.opacity = '1';

            if (window.innerWidth >= SCREEN_M) {
                if (parentRect.x + rect.width > window.innerWidth) {
                    element.style.left = window.innerWidth - (parentRect.x + rect.width) + 'px';
                } else {
                    element.style.left = '0';
                }

                if (parentRect.y + rect.height > window.innerHeight) {
                    element.style.bottom = parentRect.height + 'px';
                } else {
                    element.style.top = parentRect.height + 'px';
                }
            }
        }
    }, [isOpen]);

    return (
        <div className="relative inline-block flex-1">
            <HiddenDatePickerWrapper>
                {isOpen ? (
                    <>
                        <DatePickerUnderlay onClick={close} />
                        <DatePickerPopup ref={elPopup}>
                            {modes.length > 1 ? (
                                <>
                                    <div className="calendar-options">
                                        <ul>
                                            {modes.map((i) => (
                                                <li
                                                    key={i.value}
                                                    className={periodMode == i.value ? 'active' : ''}
                                                    onClick={() => setPeriodMode(i.value)}
                                                >
                                                    {t(i.label)}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="view-separator" />
                                </>
                            ) : null}
                            <div ref={elPicker}>
                                {periodMode == 'month' ? (
                                    <MonthPickerView
                                        key="month"
                                        minDate={props.minDate ?? DefaultMinDatePicker}
                                        maxDate={props.maxDate}
                                        value={value}
                                        onChange={onChange}
                                    />
                                ) : (
                                    <YearPickerView
                                        minDate={props.minDate ?? DefaultMinDatePicker}
                                        maxDate={props.maxDate}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            </div>
                        </DatePickerPopup>
                    </>
                ) : null}
            </HiddenDatePickerWrapper>
            <EezyButton
                disabled={props.disabled}
                className="v2-btn w-full relative"
                color="purple"
                hasIcon={true}
                onClick={open}
            >
                <Icon icon={props.icon ?? ['far', 'calendar']} />
                <span>{props.label}</span>
            </EezyButton>
        </div>
    );
};

export default ButtonPeriodPicker;
