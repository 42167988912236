import React from 'react';

const TermsFi = () => {
    return (
        <div>
            <div>
                <p>
                    <b>Sisällys</b>
                </p>
                <ul>
                    <li>
                        <a href="#point1">1. Yleistä palvelun käyttämisestä</a>
                    </li>
                    <li>
                        <a href="#point2">2. Palveluntarjoaja</a>
                    </li>
                    <li>
                        <a href="#point3">
                            3. Työnsuorittajan, Eezyn ja toimeksiantajan väliset sopimussuhteet
                        </a>
                    </li>
                    <li>
                        <a href="#point4">4. Henkilötietojen käsittely ja tietoturva</a>
                    </li>
                    <li>
                        <a href="#point5">5. Palvelun käyttäminen</a>
                    </li>
                    <ul>
                        <li>
                            <a href="#point5.1">5.1 Palvelun käyttämistä koskevat oikeudet ja rajoitukset</a>
                        </li>
                        <li>
                            <a href="#point5.2">5.2 Palvelun muutokset ja irtisanominen</a>
                        </li>
                        <li>
                            <a href="#point5.3">5.3 Rekisteröityminen</a>
                        </li>
                        <li>
                            <a href="#point5.4">5.4 Laskutus ja palkanmaksu</a>
                        </li>
                        <li>
                            <a href="#point5.5">5.5. Vakuutukset</a>
                        </li>
                        <li>
                            <a href="#point5.6">5.6 Palvelun hinta</a>
                        </li>
                    </ul>
                    <li>
                        <a href="#point6">6. Immateriaalioikeudet</a>
                    </li>
                    <li>
                        <a href="#point7">7. Vastuunrajoitukset</a>
                    </li>
                    <li>
                        <a href="#point8">8. Ehtojen muutokset ja ilmoitukset</a>
                    </li>
                    <li>
                        <a href="#point9">9. Sovellettava lainsäädäntö ja riitojenratkaisu</a>
                    </li>
                </ul>
            </div>

            <div id="point1">
                <p>
                    <b>1. Yleistä palvelun käyttämisestä</b>
                </p>
                <div className="p1">
                    <p>
                        Käyttämällä Eezy Kevytyrittäjät Oy:n ("Eezy") laskutuspalvelua ("Palvelu")
                        työnsuorittaja hyväksyy kulloinkin voimassa olevat Eezyn käyttöehdot ("Ehdot") ja
                        sitoutuu noudattamaan niitä sekä Palvelussa erikseen annettavia ehtoja ja ohjeita.
                        Työnsuorittajan tulee hyväksyä nämä Ehdot rekisteröitymisen yhteydessä.
                    </p>
                    <p>
                        Nämä Ehdot soveltuvat kaikkiin työnsuorittajan toimiin Palvelussa. Työnsuorittajan on
                        sallittua käyttää Palvelua ainoastaan kulloinkin voimassa olevien Ehtojen ja
                        soveltuvan lainsäädännön sekä hyvän tavan mukaisesti. Ehdot muodostavat sopimuksen
                        työnsuorittajan ja Eezyn välillä, ja määrittelevät työnsuorittajan ja Eezyn Palvelua
                        koskevat oikeudet ja velvollisuudet.
                    </p>
                    <p>
                        Eezyn laskutuspalvelua voivat käyttää vain yksityishenkilöt ("työnsuorittajat"),
                        joilla ei ole omaa toiminimeä ja Y-tunnusta.
                    </p>
                    <p>
                        Eezyn laskutuspalvelua ei saa käyttää tilanteissa, joissa toimeksiantajan ja
                        työnsuorittajan välillä on tai heidän välilleen muodostuu työsuhde.
                    </p>
                </div>
            </div>

            <div id="point2">
                <p>
                    <b>2. Palveluntarjoaja</b>
                </p>
                <div className="p1">
                    <p>Eezy Kevytyrittäjät Oy</p>
                    <p>Y-tunnus: 2197002-2</p>
                    <p>Maistraatinportti 1</p>
                    <p>00240 Helsinki</p>
                    <p>puh. 09 4247 5630</p>
                    <p>info.kevytyrittajat@eezy.fi</p>
                </div>
            </div>

            <div id="point3">
                <p>
                    <b>3. Työnsuorittajan, Eezyn ja toimeksiantajan väliset sopimussuhteet</b>
                </p>
                <div className="p1">
                    <p>
                        Eezyn ja työnsuorittajan välille ei muodostu työsopimuslain (55/2001) 1 luvun 1 §:n
                        mukaista työsuhdetta eikä työnsuorittaja ole Eezyn työntekijä eläkelainsäädännön
                        kannalta.
                    </p>
                    <p>
                        Kun työnsuorittaja tekee rekisteröitymisen jälkeen työtä, jonka hän laskuttaa Eezyn
                        kautta näiden Ehtojen mukaisesti, on hän verotuksellisesti palkansaaja eikä hän ole
                        kirjanpitovelvollinen.
                    </p>
                    <p>
                        Työnsuorittaja hankkii itse toimeksiantonsa ja sopii suoraan toimeksiantajansa kanssa
                        työsuorituksistaan ja niiden hinnoista. Työnsuorittajan tulee sopia työsuorituksista
                        ja mm. hinnoitella työ siitä lähtökohdasta, että toimeksiantaja ei ole työnsuorittajan
                        työnantaja eikä sopijapuolien välille muodostu työsuhdetta. Selvyyden vuoksi todetaan,
                        että toimeksiantajalla tarkoitetaan työnsuorittajan asiakasta.
                    </p>
                    <p>
                        Eezy ei ole osapuoli työsuoritusta koskevassa sopimuksessa, joka on työnsuorittajan ja
                        toimeksiantajan välinen. Työnsuorittajalla ei ole oikeutta tehdä toimeksianto-,
                        alihankinta-, osamaksu- tai luottosopimuksia Eezyn nimissä eikä tehdä hankintoja
                        laskulla Eezyn nimiin. Eezyn yritystunnuksia ei voi käyttää arvonlisäverottomiin
                        hankintoihin EU:n alueelta.
                    </p>
                    <p>
                        Työnsuorittajan tulee huolehtia, että toimeksiantaja ja mahdolliset kolmannet
                        osapuolet ovat tietoisia Eezyn ja työnsuorittajan välisestä sopimussuhteesta sekä
                        näiden Ehtojen sisällöstä.
                    </p>
                    <p>
                        Eezyn lähetettyä laskun toimeksiantajalle, ei työnsuorittajalla ole oikeutta laskuttaa
                        korvausta työstään muuta kautta tai periä saatavaansa toimeksiantajalta, ellei Eezyn
                        kanssa nimenomaisesti toisin sovita tai Eezy ilmoita, että laskun perintä Eezyn ja sen
                        yhteistyökumppaneiden toimesta on päättynyt.
                    </p>
                </div>
            </div>

            <div id="point4">
                <p>
                    <b>4. Henkilötietojen käsittely ja tietoturva</b>
                </p>
                <div className="p1">
                    <p>
                        Eezy käsittelee Palvelun välityksellä saatavia, työnsuorittajaa ja toimeksiantajia
                        koskevia henkilötietoja kulloinkin voimassa olevan henkilötietolainsäädännön
                        sallimalla ja velvoittamalla tavalla sekä Eezyn ja työnsuorittajan välisen
                        henkilötietojen käsittelysopimuksen mukaisesti. Eezyn asiakkuuteen ja muuhun
                        asialliseen yhteyteen perustuvien rekistereiden tietosuojaselosteissa kuvataan
                        henkilötietojen käsittelyä tarkemmin.
                    </p>

                    <p>
                        Eezy sitoutuu pitämään kaikki työnsuorittajan luottamuksellisiksi katsottavat tiedot
                        ehdottoman salaisina ja olemaan käyttämättä niitä muuten kuin Eezyn ja työnsuorittajan
                        välisen yhteistyön edellyttämällä tavalla.
                    </p>

                    <p>
                        Löydät palvelun tietosuojaselosteen{' '}
                        <a
                            href="https://kevytyrittajat.eezy.fi/tietosuojaseloste/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            verkkosivuiltamme.
                        </a>
                    </p>
                </div>
            </div>

            <div id="point5">
                <p>
                    <b>5. Palvelun käyttäminen</b>
                </p>

                <div className="p1" id="point5.1">
                    <p>
                        <b>5.1 Palvelun käyttämistä koskevat oikeudet ja rajoitukset</b>
                    </p>
                    <div className="p1">
                        <p>
                            Eezy myöntää työnsuorittajalle näissä Ehdoissa määritellyn rajoitetun,
                            peruutettavissa olevan, ei-yksinomaisen ja henkilökohtaisen oikeuden käyttää
                            Palvelua ja sen sisältöä ainoastaan näiden Ehtojen ja Palvelussa mahdollisesti
                            erikseen annettavien ehtojen ja ohjeiden mukaisesti. Työnsuorittajalla on oikeus
                            käyttää Palvelua ja sen sisältöä ainoastaan työnsuorittajan omaan käyttöön.
                        </p>
                        <p>
                            Työnsuorittajan tulee toiminnassaan noudattaa Suomessa voimassaolevaa
                            lainsäädäntöä ja viranomaisohjeistuksia. Työnsuorittajan on noudatettava
                            toimintaansa mahdollisesti soveltuvia pakottavia lain määräyksiä kuten
                            työturvallisuusmääräyksiä.
                        </p>
                        <p>
                            Työnsuorittaja ei saa käyttää Palvelua (a) esiintyäkseen Eezyn edustajana; (b)
                            otsikko- tai tunnistetietojen väärentämiseen tai käsittelemiseen tavalla, jolla
                            pyritään peittämään Palvelun kautta välitetyn sisällön alkuperä; (c) muunlaiseen
                            väärien tietojen antamiseen; (d) muiden käyttäjien henkilötietojen tallentamiseen
                            tai keräämiseen; (e) roskapostin tai muun luvattoman tietoliikenteen välittämiseen
                            tai (f) muihin lain tai hyvän tavan vastaisiin toimiin.
                        </p>
                    </div>
                </div>

                <div className="p1" id="point5.2">
                    <p>
                        <b>5.2 Palvelun muutokset ja irtisanominen</b>
                    </p>
                    <div className="p1">
                        <p>
                            Palvelu sisältöineen tarjotaan ”sellaisena kuin se on”. Eezy on oikeutettu milloin
                            tahansa muokkaamaan tai muuttamaan Palvelua tai sen sisältöä ilman
                            korvausvelvollisuutta.
                        </p>
                        <p>
                            Eezyllä on siten oikeus mm. muuttaa tai muokata Palvelussa annettua informaatiota
                            tai siellä tarjottavia tuotteita tai hintoja, lisätä ja poistaa osia Palvelusta
                            sekä muuttaa Palvelun maksuttomia osioita maksullisiksi tai päinvastoin.
                        </p>
                        <p>
                            Eezy ilmoittaa palvelussa tapahtuvista olennaisista mm. hinnoitteluun liittyvistä
                            muutoksista työnsuorittajalle 14 vuorokautta ennen muutosten voimaantuloa.
                            Ilmoitusaika ei koske lakimuutoksista tai viranomaisohjeistuksista johtuvia
                            pakollisia muutoksia.
                        </p>
                        <p>
                            Eezyllä on myös oikeus milloin tahansa keskeyttää tai lopettaa Palvelu, sen
                            sisällön tai minkä tahansa osion tarjoaminen kokonaan taikka rajoittaa Palvelun
                            tai sen osioiden käyttämistä.
                        </p>
                        <p>
                            Jos työnsuorittaja on toiminut näiden Ehtojen, Palvelussa erikseen annettujen
                            ehtojen tai ohjeiden taikka lakien tai hyvien tapojen vastaisesti, Eezyllä on
                            oikeus estää työnsuorittajan pääsy Palveluun ja kieltää Palvelun käyttäminen
                            jatkossa.
                        </p>
                    </div>
                </div>

                <div className="p1" id="point5.3">
                    <p>
                        <b>5.3 Rekisteröityminen</b>
                    </p>
                    <div className="p1">
                        <p>
                            Edellä kuvatun käyttöoikeuden saaminen edellyttää työnsuorittajan
                            rekisteröitymistä Palveluun.
                        </p>
                        <p>
                            Rekisteröityessään Palveluun, työnsuorittajan tulee antaa itsestään oikeat
                            pyydetyt tiedot. Väärien tietojen antaminen on kielletty, ja se saattaa johtaa
                            seuraamuksiin kuten palvelun käytön estämiseen.
                        </p>
                        <p>
                            Rekisteröityessään työnsuorittaja valitsee itselleen käyttäjätunnuksen
                            (sähköpostiosoite) ja salasanan. Työnsuorittaja on vastuussa salasanan
                            huolellisesta säilyttämisestä ja salassapidosta ja vastaa kaikesta
                            käyttäjätunnuksillaan tapahtuvasta asioinnista Palvelussa. Työnsuorittajan tulee
                            olla välittömästi yhteydessä Eezyyn, jos työnsuorittaja havaitsee tai epäilee,
                            että hänen tunnuksensa ovat paljastuneet tai että hänen tunnuksiaan käytetään
                            väärin. Eezyllä on tällaisissa tilanteissa oikeus jäädyttää tai sulkea
                            työnsuorittajan tunnukset ilmoittamatta asiasta etukäteen työnsuorittajalle.
                        </p>
                    </div>
                </div>

                <div className="p1" id="point5.4">
                    <p>
                        <b>5.4 Laskutus ja palkanmaksu</b>
                    </p>
                    <div className="p1">
                        <p>
                            Työnsuorittajan palkanmaksu perustuu työnsuorittajan Eezyn kautta laskuttamaan
                            arvonlisäverottomaan laskutussummaan.
                        </p>
                        <p>
                            Palkka maksetaan työnsuorittajalle sen jälkeen, kun toimeksiantaja on maksanut
                            laskun Eezyn tilille. Eezy ei maksa työnsuorittajille palkkaennakoita, mutta
                            palkanmaksupäivä voidaan sopia edellä mainittua aikaisemmaksi esim.
                            EezyExpress-palkasta sovittaessa.
                        </p>
                        <p>
                            Laskutuksella saadusta arvonlisäverottomasta summasta lasketaan ja suoritetaan
                            työnsuorittajan verokortin tai Verohallinnolta saatujen tietojen mukainen
                            ennakonpidätys. Eezyn palvelumaksu ja soveltuvat sivukulut lasketaan
                            arvonlisäverottomasta laskutussummasta ja vähennetään työnsuorittajan
                            nettoansioista.
                        </p>
                        <p>
                            Palkanmaksua varten työnsuorittajan on toimitettava Eezylle verokortti, ellei Eezy
                            ole saanut tietoja automaattisesti Verohallinnolta. Jos työnsuorittaja ei ole
                            toimittanut voimassa olevaa verokorttia palkanmaksuhetkellä, Eezy pidättää
                            Verohallinnon ohjeistuksen mukaisesti työnsuorittajan palkasta 60 %
                            ennakonpidätyksen.
                        </p>
                        <p>
                            Työnsuorittaja vastaa kaikista Palveluunsa antamien tietojen oikeellisuudesta mm.
                            laskutustietoja täyttäessä ja kulukorvauksia hakiessa.
                        </p>
                        <p>
                            Eezy pidättää oikeuden olla hyväksymättä laskuja, jotka ovat näiden Ehtojen ja
                            Eezyn käytäntöjen vastaisia.
                        </p>
                        <p>
                            Mikäli Eezy on maksanut työnsuorittajalle palkkaa ennen kuin toimeksiantaja on
                            maksanut laskun Eezylle, eikä toimeksiantaja maksa laskua kokonaisuudessaan
                            kohtuullisessa ajassa, tai Eezy joutuu jostain syystä palauttamaan saamansa maksun
                            toimeksiantajalle kokonaan tai osittain, on työnsuorittaja velvollinen
                            palauttamaan saamansa suorituksen Eezylle vastaavalta osin.
                        </p>
                        <p>
                            Eezyllä on oikeus poistaa palvelusta laskut, joihin ei ole saatu täyttä suoritusta
                            vuoden kuluttua laskupäivästä.
                        </p>
                        <p>
                            Työnsuorittajan on määriteltävä oma laskutushintansa siten, että siitä määräytyvä
                            bruttopalkka on mahdollisten soveltuvien määräysten mukainen ja vastaa samalla
                            alalla toimivien yrittäjien yleistä ansiotasoa.
                        </p>
                        <p>
                            Mikäli Eezyllä on saatavia työnsuorittajalta, on Eezyllä oikeus kuitata ne
                            palkanmaksun yhteydessä työnsuorittajalle maksettavasta nettopalkasta.
                        </p>
                        <p>
                            Työnantajan sairausvakuutusmaksun maksamisvelvollisuuden selvittämiseksi
                            työnsuorittajan tulee ilmoittaa Eezylle totuudenmukaisesti, onko hän
                            YEL-vakuuttamisvelvollinen, ja päivittää tieto välittömästi tilanteen muuttuessa.
                            Jos Eezy vähentää työnsuorittajan palkkalaskelmalla ja maksaa Verohallinnolle
                            liikaa sairausvakuutusmaksuja työnsuorittajan antaman virheellisen tiedon takia,
                            ei Eezyllä ole velvollisuutta korjata laskelmia, ilmoituksia tai maksuja, eikä
                            korvata työnsuorittajalle tämän palkkalaskelmalla huomioituja liiallisia
                            vähennyksiä. Jos Eezy maksaa Verohallinnolle liian vähän sairausvakuutusmaksuja
                            työnsuorittajan antaman virheellisen tiedon takia, on työnsuorittaja velvollinen
                            korvaamaan Eezylle kaikki Eezyn maksettavaksi tulevat maksut mahdollisine
                            korotuksineen ja korkoineen sekä muut asiassa Eezylle aiheutuvat kulut.
                        </p>
                    </div>
                </div>

                <div className="p1" id="point5.5">
                    <p>
                        <b>5.5 Vakuutukset</b>
                    </p>
                    <div className="p1">
                        <p>
                            Työnsuorittaja ei ole vakuutettu lakisääteisillä vakuutuksilla vaan kulloinkin
                            voimassa olevilla Eezyn vakuutuksilla, joihin voivat kuulua mm. työajan
                            tapaturmavakuutus, toiminnan vastuuvakuutus ja konsulttivakuutus. Edellytyksenä
                            vakuutusten voimassaololle on työnsuorittajan rekisteröityminen Palveluun.
                        </p>
                        <p>
                            Toiminnan vastuuvakuutus ei korvaa vahinkoja, joista työnsuorittaja ei ole
                            korvausvastuussa sillä perusteella, että ne on aiheutettu vahingonkorvauslain 3
                            luvun 1 §:n 1 momentin tarkoittamana työntekijään rinnastettavana itsenäisenä
                            yrittäjänä.
                        </p>
                        <p>
                            Vahinkotapauksissa työnsuorittaja on vastuussa toiminnan vastuuvakuutuksen
                            vakuutuskorvauksen omavastuuosuuden suorittamisesta vakuutusyhtiölle.
                        </p>
                        <p>Työnsuorittajan tulee ottaa YEL-vakuutus lainmukaisten edellytysten täyttyessä.</p>
                    </div>
                </div>

                <div className="p1" id="point5.6">
                    <p>
                        <b>5.6 Palvelun hinta</b>
                    </p>
                    <div className="p1">
                        <p>
                            Eezyn palvelumaksu on 7 prosenttia arvonlisäverottomasta laskutussummasta, ellei
                            työnsuorittajan kanssa ole toisin sovittu.
                        </p>
                        <p>
                            Selvyyden vuoksi todetaan, että edellä mainittu palvelumaksu ei sisällä
                            työnsuorittajan erikseen ostamia lisäpalveluita, jotka veloitetaan kulloinkin
                            voimassa olevan{' '}
                            <a
                                href="https://kevytyrittajat.eezy.fi/hinnasto/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                hinnaston
                            </a>{' '}
                            mukaan.
                        </p>
                        <p>
                            Eezy pidättää palvelumaksunsa Työnsuorittajalle tapahtuvan palkanmaksun
                            yhteydessä.
                        </p>
                    </div>
                </div>
            </div>

            <div id="point6">
                <p>
                    <b>6. Immateriaalioikeudet</b>
                </p>
                <div className="p1">
                    <p>
                        Palvelu ja sen sisältö, kuten tekstit, kuvat, tuotetiedot, tavaramerkit ja tunnukset
                        ovat Eezyn tai sen yhteistyökumppaneiden omaisuutta, joka on suojattu
                        tekijänoikeuslaeilla ja kansainvälisillä tekijänoikeussopimuksilla, sekä muulla
                        immateriaalioikeuslainsäädännöllä ja -rekisteröinneillä.
                    </p>
                    <p>
                        Kaikki Palveluun ja sisältöön liittyvät immateriaalioikeudet (kuten tekijänoikeudet,
                        rekisteröidyt ja rekisteröimättömät tavaramerkki- ja mallioikeudet, domain-nimet,
                        patentit, tietokantaoikeudet sekä liikesalaisuudet) ja niiden käytöstä seuraava
                        goodwill-arvo kuuluvat Eezylle tai sen yhteistyökumppaneille. Eezy ei myönnä
                        työnsuorittajalle mitään suoria tai epäsuoria oikeuksia mihinkään
                        immateriaalioikeuksiin.
                    </p>
                    <p>
                        Työnsuorittajalle kuuluvat immateriaalioikeudet (kuten tekijänoikeudet, rekisteröidyt
                        ja rekisteröimättömät tavaramerkki- ja mallioikeudet, patentit ja oikeudet
                        keksintöihin) eivät siirry Eezylle eikä Eezy saa niihin käyttöoikeuksia Palvelun
                        käyttämisen perusteella.
                    </p>
                </div>
            </div>

            <div id="point7">
                <p>
                    <b>7. Vastuunrajoitukset</b>
                </p>
                <div className="p1">
                    <p>
                        Työnsuorittajan on sovittava mahdolliset työsuoritustaan koskevat erimielisyydet
                        suoraan toimeksiantajansa kanssa.
                    </p>
                    <p>
                        Eezy ei ole vastuussa mistään suorista, epäsuorista, välittömistä tai välillisistä
                        vahingoista tai erityisvahingoista, mukaan lukien liiketoiminnan, sopimuksen, tuoton
                        tai tietojen menettämisestä tai liiketoiminnan keskeytymisestä aiheutuvat vahingot,
                        jotka johtuvat työnsuorittajan Eezyn kautta laskutetusta työstä taikka näistä
                        Ehdoista, riippumatta siitä, perustuvatko vahingot sopimukseen, oikeudenloukkaukseen,
                        huolimattomuuteen tai muuhun perusteeseen, vaikka Eezylle olisi etukäteen ilmoitettu
                        tällaisten vahinkojen mahdollisuudesta. Kaikissa tapauksissa Eezyn vastuu rajoittuu
                        aina korkeintaan pakottavan lainsäädännön mukaiseen vastuuseen.
                    </p>
                    <p>
                        Työnsuorittaja vapauttaa Eezyn ja kaikki sen työntekijät, johtajat ja sen lukuun
                        toimivat vastuusta kaikkien sellaisten kanteiden ja vaateiden osalta, jotka aiheutuvat
                        työnsuorittajan toimeksiannoista ja Palvelun käytöstä.
                    </p>
                </div>
            </div>

            <div id="point8">
                <p>
                    <b>8. Ehtojen muutokset ja ilmoitukset</b>
                </p>
                <div className="p1">
                    <p>
                        Eezy pidättää oikeuden muuttaa näitä Ehtoja harkintansa mukaan ilmoittamalla siitä
                        työnsuorittajalle sähköpostitse tai Palvelun välityksellä. Ehtojen muutokset tulevat
                        voimaan, kun niistä on ilmoitettu. Palvelun käyttämistä ilmoitushetken jälkeen
                        pidetään ilmoituksena siitä, että työnsuorittaja hyväksyy Ehtoihin tehdyt muutokset.
                    </p>
                    <p>
                        Eezy voi sisällyttää Palveluun ilmoituksia. Eezy voi myös lähettää työnsuorittajalle
                        Palvelua koskevia ilmoituksia työnsuorittajan ilmoittamaan sähköpostiosoitteeseen tai
                        puhelinnumeroon. Ilmoitukset katsotaan vastaanotetuiksi seitsemän (7) päivän kuluttua
                        siitä, kun Eezy on ne lähettänyt tai laittanut esille. Palvelun käytön jatkaminen
                        tarkoittaa, että työsuorittajan katsotaan vastaanottaneen kaikki ilmoitukset
                        lähetystavasta riippumatta.
                    </p>
                    <p>
                        Työnsuorittajalle kuuluvat immateriaalioikeudet (kuten tekijänoikeudet, rekisteröidyt
                        ja rekisteröimättömät tavaramerkki- ja mallioikeudet, patentit ja oikeudet
                        keksintöihin) eivät siirry Eezylle eikä Eezy saa niihin käyttöoikeuksia Palvelun
                        käyttämisen perusteella.
                    </p>
                </div>
            </div>

            <div id="point9">
                <p>
                    <b>9. Sovellettava lainsäädäntö ja riitojenratkaisu</b>
                </p>
                <div className="p1">
                    <p>
                        Palvelun käyttämiseen ja näihin Ehtoihin sovelletaan Suomen lakia, lukuun ottamatta
                        sen lainvalintasäännöksiä.
                    </p>
                    <p>
                        Osapuolet pyrkivät ratkaisemaan erimielisyydet ensisijaisesti neuvotteluteitse. Mikäli
                        sovintoratkaisuun ei päästä, erimielisyydet ratkaistaan Helsingin käräjäoikeudessa.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermsFi;
