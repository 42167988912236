import { CircularProgress, CircularProgressProps } from '@mui/material';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { COLOR_WHITE_WALKER } from '../styles/variables';

const StyledSpinner = styled(CircularProgress)`
    && {
        align-self: center;
        color: ${props => (props.color ? props.color : COLOR_WHITE_WALKER)};

        & svg {
            float: none !important;
            margin: 0 !important;
        }
    }
`;

interface ILoadingSpinnerProps {
    color?: string;
    size?: CircularProgressProps['size'];
    style?: CSSProperties;
}

const LoadingSpinner = (props: ILoadingSpinnerProps) => {
    const { color, size, style } = props;
    return <StyledSpinner size={size} style={{ ...style, color }} />;
};

export default LoadingSpinner;
