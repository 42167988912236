import React from 'react';
import { round } from '../../utils/calc';
import {
    ICostComponentProps,
    isRoundTripStep
} from './../../utils/costs/costLogic';

export const TravelDistanceCalculator = (props: ICostComponentProps) => {
    const { dispatch } = props;

    const handleMapsDistanceUpdate = React.useCallback(
        (totalKMs: number) => {
            dispatch({
                payload: { totalKMs },
                type: 'SAVE_MAPS_DISTANCE'
            });
        },
        [dispatch]
    );

    React.useEffect(() => {
        const directions = isRoundTripStep(props.step)
            ? props.travelRoundDirections?.directions
            : props.travelRouteDirections?.directions;

        if (!directions) {
            return;
        }
        if (
            directions.status !== 'NOT_FOUND' &&
            directions.routes?.length > 0
        ) {
            const distanceM = directions.routes[0].legs.reduce(
                (total: number, leg: any) => total + leg.distance.value,
                0
            );
            handleMapsDistanceUpdate(round(distanceM / 1000));
        } else {
            handleMapsDistanceUpdate(0);
        }
    }, [
        props.travelRoundDirections,
        props.travelRouteDirections,
        handleMapsDistanceUpdate,
        props.step
    ]);

    return null;
};
