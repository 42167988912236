import { useMutation } from '@apollo/client';
import React from 'react';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../components/modals/ConfirmationModal';
import { trans } from '../../utils';
import { GET_ANKKA_INVOICES } from '../dashboard/queries';
import { DELETE_INVOICE } from './queries';
import { useNavigate } from 'react-router-dom';

interface IDeleteInvoiceProps {
    invoiceId: number;
    open: boolean;
    toggleOpen: (open: boolean) => void;
}
export const DeleteInvoice = (props: IDeleteInvoiceProps) => {
    const navigate = useNavigate();
    const [deleteInvoice] = useMutation(DELETE_INVOICE, {
        onCompleted: () => {
            navigate('/invoices');
        },
        onError: () => toast.error(trans('errors.general')),
        refetchQueries: [{ query: GET_ANKKA_INVOICES }],
        variables: { invoiceId: props.invoiceId },
    });

    const onDel = React.useCallback(
        (e: any) => {
            if (e.keyCode === 46 && e.srcElement.tagName !== 'INPUT' && e.srcElement.tagName !== 'TEXTAREA') {
                props.toggleOpen(true);
            }
        },
        [props],
    );

    React.useEffect(() => {
        document.addEventListener('keydown', onDel, false);
        return () => {
            document.removeEventListener('keydown', onDel, false);
        };
    }, [onDel]);

    return (
        <ConfirmationModal
            handleSubmit={() => {
                deleteInvoice();
            }}
            handleClose={() => props.toggleOpen(false)}
            id={`delete-${props.invoiceId}`}
            isOpen={props.open}
            texts={{
                submit: 'form.delete',
                title: 'invoice.confirm-delete',
            }}
        />
    );
};
