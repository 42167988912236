import styled from 'styled-components';
import { Switch, SwitchProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { COLOR_BLACKWATER, COLOR_BLUM, COLOR_IMPORTANT } from 'styles/variables';
import { useTranslation } from 'react-i18next';

const styleBase = {
    checked: {},
    focusVisible: {},
    root: {
        height: 30,
        padding: 0,
        width: 110,
    },
};

const primaryStyles = makeStyles()({
    ...styleBase,
    switchBase: {
        '&.Mui-checked': {
            '+ .MuiSwitch-track': {
                backgroundColor: `rgba(0, 0, 0, 0.2) !important`,
                color: COLOR_IMPORTANT,
                opacity: '1 !important',
            },
            transform: 'translateX(65px) !important',
        },
        color: COLOR_BLACKWATER,
        padding: 3,
    },
    thumb: {
        backgroundColor: `${COLOR_BLUM}`,
        borderRadius: '100px',
        height: 24,
        width: 40,
    },
    track: {
        backgroundColor: `rgba(0, 0, 0, 0.2)`,
        borderRadius: 26 / 2,
        opacity: 1,
    },
});

const secondaryStyles = makeStyles()({
    ...styleBase,
    switchBase: {
        '&$checked': {
            '& + $track': {
                backgroundColor: `${COLOR_BLACKWATER}`,
                opacity: 0.2,
            },
            transform: 'translateX(65px)',
        },
        padding: 3,
    },
    thumb: {
        backgroundColor: `${COLOR_BLACKWATER}`,
        borderRadius: '100px',
        height: 24,
        width: 40,
    },
    track: {
        borderRadius: 26 / 2,
        opacity: 0.2,
    },
});

const Wrapper = styled.div`
    border-radius: 15px;
    &:focus-within {
        box-shadow: 0 0 5px 0 #f7726f;
    }
`;

const SwitchButton = ({ ...props }: SwitchProps) => {
    const { t } = useTranslation();
    const { checked, color, onChange, ...restProps } = props;
    const secondary = color === 'secondary';
    const { classes } = secondary ? secondaryStyles() : primaryStyles();
    return (
        <Wrapper style={{ position: 'relative', opacity: props.disabled ? 0.2 : 1 }}>
            <Switch
                disableRipple
                checked={checked || false}
                onChange={onChange}
                classes={{
                    checked: classes?.checked,
                    root: classes?.root,
                    switchBase: classes?.switchBase,
                    thumb: classes?.thumb,
                    track: classes?.track,
                }}
                {...restProps}
            />
            {props.checked && (
                <span
                    style={{
                        color: secondary ? 'black' : COLOR_BLUM,
                        fontWeight: 600,
                        left: 10,
                        position: 'absolute',
                        top: 3,
                    }}
                >
                    {t('general.yes')}
                </span>
            )}
            {!props.checked && (
                <span
                    style={{
                        color: secondary ? 'black' : COLOR_BLUM,
                        fontWeight: 600,
                        left: 80,
                        position: 'absolute',
                        top: 3,
                    }}
                >
                    {t('general.no')}
                </span>
            )}
        </Wrapper>
    );
};

export default SwitchButton;
