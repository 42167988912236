import { gql } from '@apollo/client';

const ACCEPT_COWORKER_INVITATION = gql`
    mutation Coworkers($personId: Int!) {
        acceptInvitation(personId: $personId)
    }
`;

export default ACCEPT_COWORKER_INVITATION;

