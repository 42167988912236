import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { INotification } from '../../../../shared/src/types/notifications';
import { EezyButton } from 'components/Buttons';
import { Modal, ModalActions, ModalContent } from 'components/modals/Modal';
import { getModalTitle } from 'utils/notifications/notifications';
import { ExpenseNotificationModal } from './ExpenseNotificationModal';
import GuideModal from './GuideModal';
import { useTranslation } from 'react-i18next';

// Use this wrapper to override some styles of the content html if needed
const Wrapper = styled.div`
    * {
        margin-bottom: 10px;
    }
    div {
        margin-bottom: 15px;
    }
    ol {
        margin-left: 20px;
    }
    h1 {
        margin: 20px 0;
    }
    h2 {
        margin: 15px 0;
    }
    h3 {
        margin: 10px 0;
    }
`;

interface IModalProps {
    handleModalClose: () => void;
    isOpen: boolean;
    notification?: INotification;
}

const NotificationModal = (props: IModalProps) => {
    const { t } = useTranslation();
    const { notification } = props;

    if (!notification) {
        return null;
    }
    return (
        <Modal
            id={`modal-${notification.id}`}
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={notification.title || getModalTitle(notification)}
        >
            <ModalContent>
                {notification.content ? (
                    <Wrapper
                        dangerouslySetInnerHTML={{
                            __html: notification.content
                        }}
                    />
                ) : notification.actionType === 'expense' ? (
                    <ExpenseNotificationModal notification={notification} />
                ) : notification.actionType === 'guide' ? (
                    <GuideModal />
                ) : null}
            </ModalContent>
            <ModalActions style={{ justifyContent: 'center' }}>
                <EezyButton color="purple" onClick={props.handleModalClose}>
                    {t('menu.close')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

export default connect()(NotificationModal);
