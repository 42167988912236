import { FC, useEffect, useState } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import queryString from 'query-string';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Toast } from 'components/Toast';
import { ApolloError, useApolloClient, useQuery } from '@apollo/client';
import NewUserGuider from 'containers/guider/NewUserGuider';
import InvoiceSendingStatusListener from 'containers/invoice/InvoiceSendingStatusListener';
import VersionMismatch from 'containers/error/VersionMismatch';
import FileUploadError from 'containers/error/FileUploadError';
import MaintenanceBreak from 'containers/error/MaintenanceBreak';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import Footer from 'components/Footer';
import GET_DOCUMENTS_USER_MUST_ACCEPT from './dashboard/queries/getDocumentsUserMustAccept';
import { getErrors } from 'utils/apolloErrors';
import { dispatch } from 'utils/store';
import { showModals } from 'actions/auth';
import { IDocument } from 'utils/user/userUtils';

const LoginProtectedComponent: FC = (props) => {
    const location = useLocation();
    const client = useApolloClient();
    const navigate = useNavigate();

    const isLoggedIn = useSelector((state: IRootState) => state.auth.loggedIn);
    const userId = useSelector((state: IRootState) => state.user.id);

    const [allDocumentsAccepted, setAllDocumentsAccepted] = useState(false);

    useEffect(() => {
        // Add the next param in the url if the user is redirected to login
        if (!isLoggedIn) {
            client?.resetStore(); //TODO: test client;
            const urlParams = queryString.parse(location.search);
            const path = location.pathname;
            if (path !== '/' && path !== '/logout' && !urlParams.next) {
                navigate(`/login?next=${path}`);
            } else {
                navigate('/login');
            }
        }
    }, [isLoggedIn]);

    const [token, setToken] = useState(sessionStorage.getItem('jwt'));
    useEffect(() => {
        const interval = setInterval(() => {
            if (!token) {
                setToken(sessionStorage.getItem('jwt'));
            } else {
                clearInterval(interval);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [token]);

    const {
        data: documents,
        startPolling,
        stopPolling,
    } = useQuery(GET_DOCUMENTS_USER_MUST_ACCEPT, {
        skip: !isLoggedIn,
        onCompleted: (response) => {
            dispatch(showModals(response.userMustAccept.map((t: IDocument) => t.document)));

            if (response.userMustAccept.length === 0) {
                setAllDocumentsAccepted(true);
            }
        },
        onError: (e: ApolloError) => {
            const errors = getErrors(e);
            if (errors?.mustAccept) {
                dispatch(showModals(errors.mustAccept.map((t: IDocument) => t.document)));
            }
        },
    });

    useEffect(() => {
        if (!token) {
            startPolling(500);
        } else {
            stopPolling();
        }
    }, [token]);

    return userId && isLoggedIn && allDocumentsAccepted ? (
        <>
            <Toast />
            <MaintenanceBreak />
            <FileUploadError />
            <VersionMismatch />
            <InvoiceSendingStatusListener />
            <NewUserGuider />
            <Outlet />
            <Footer />
        </>
    ) : (
        <></>
    );
};

export const LoginProtected = withApollo(LoginProtectedComponent);
