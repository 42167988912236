import styled from 'styled-components';
import {
    COLOR_BLACKWATER,
    COLOR_DARK_GRAY,
    COLOR_GREYHOUND,
    COLOR_IMPORTANT
} from '../../styles/variables';

interface ITextProps {
    color?: string;
    disabled?: boolean;
    noWrap?: boolean;
}

export const BodyP = styled.p`
    color: ${(props: ITextProps) => props.color || COLOR_DARK_GRAY};
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.15px;

    overflow: ${(props: ITextProps) => (props.noWrap ? 'hidden' : 'unset')};
    text-overflow: ${(props: ITextProps) =>
        props.noWrap ? 'ellipsis' : 'unset'};
    white-space: ${(props: ITextProps) => (props.noWrap ? 'nowrap' : 'unset')};
`;

export const BodyPSmall = styled.p`
    color: ${(props: ITextProps) => props.color || COLOR_DARK_GRAY};
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.15px;
`;

export const PSmall = styled.p`
    color: ${(props: ITextProps) => props.color || COLOR_BLACKWATER};
    font-size: 15px;
    line-height: 28px;
`;

export const P = styled.p`
    color: ${(props: ITextProps) => props.color || COLOR_BLACKWATER};
    font-size: 15px;
    line-height: 20px;
    margin-top: 15px;
    opacity: ${(props: ITextProps) => (props.disabled ? '0.2' : '1')};

    & + & {
        margin-top: 30px;
    }
`;

export const PLight = styled(P)`
    font-size: 12px;
    font-weight: 300;
`;

export const Span = styled.span`
    color: ${(props: ITextProps) => props.color || COLOR_BLACKWATER};
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.3px;
    line-height: 28px;
`;

export const Bold = styled.span`
    color: ${(props: ITextProps) => props.color || COLOR_BLACKWATER};
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.15px;
    line-height: 28px;
`;

export const SingleText = styled.p`
    align-items: center;
    color: ${(props: ITextProps) => props.color || COLOR_GREYHOUND};
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    line-height: 30px;
    display: flex;
    letter-spacing: 0.15px;
`;

export const SingleTextHalf = styled.p`
    color: ${(props: ITextProps) => props.color || COLOR_GREYHOUND};
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0.15px;

    & + & {
        margin-top: 15px;
    }
`;

export const SmallP = styled.p`
    color: ${(props: ITextProps) => props.color || COLOR_GREYHOUND};
    font-size: 12px;
    letter-spacing: 0.15px;
    line-height: 15px;
`;

interface IValidationMessageProps {
    color?: string;
    $error?: boolean;
    $warning?: boolean;
}

export const ValidationMessage = styled.div`
    &,
    a {
        color: ${(props: IValidationMessageProps) =>
            props.$error
                ? COLOR_IMPORTANT
                : props.color
                ? props.color
                : COLOR_BLACKWATER};
    }
    font-size: 13px;
    letter-spacing: 0.15px;
    line-height: 16px;
    margin-top: 5px;
`;
