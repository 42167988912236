import { MutationFunctionOptions } from '@apollo/client';
import React from 'react';
import { IUserBasicData } from '../../../../shared/src/types/user';
import { FormCheckmark } from '../../components/form/FormCheckmark';
import { trans } from '../../utils';

interface IProps {
    userData: IUserBasicData;
    updateUserData: (
        options?: MutationFunctionOptions<any, Record<string, any>> | undefined
    ) => Promise<any>;
}

export const ProfileSettingsNotifyInvoicePaid = (props: IProps) => {
    const handleNotifyUpdate = () => {
        props.updateUserData({
            optimisticResponse: {
                updateUserData: {
                    __typename: 'User',
                    ...props.userData,
                    notifyWhenInvoicePaid: !props.userData
                        ?.notifyWhenInvoicePaid
                }
            },
            variables: {
                notifyWhenInvoicePaid: !props.userData?.notifyWhenInvoicePaid
            }
        });
    };

    return (
        <FormCheckmark
            id="email-notification"
            label={trans('profile:settings.payment-email-notification')}
            checked={props.userData?.notifyWhenInvoicePaid}
            name="email-notification"
            onChange={handleNotifyUpdate}
            required
            value={'notifyWhenInvoicePaid'}
        />
    );
};
