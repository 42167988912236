import { gql } from '@apollo/client';

const UPDATE_INVOICE_WITH_RECIPIENT = gql`
    mutation UpdateInvoice(
        $id: Int!
        $contactPersonId: Int
        $recipient: ClientChoice
    ) {
        updateInvoice(
            invoice: {
                id: $id
                contactPersonId: $contactPersonId
                recipient: $recipient
            }
        ) {
            id
            contactPersonId
            contactPersonName
            recipient {
                active
                address {
                    country
                    street1
                    street2
                    town
                    zipCode
                }
                businessId
                contactPeople {
                    active
                    firstName
                    id
                    lastName
                }
                einvoiceOvt
                operator
                email
                firstName
                id
                invoiceLanguage
                invoicingMethod
                lastName
                name
                type
            }
            template
        }
    }
`;

export default UPDATE_INVOICE_WITH_RECIPIENT;
