import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Fade } from '@mui/material';
import styled from 'styled-components';
import { ISalary } from '../../../../shared/src/types/salary';
import { EezyButton } from 'components/Buttons';
import { EmptyListPlaceholder } from 'components/EmptyListPlaceholder';
import { Flex } from 'components/Flex';
import { List } from 'components/layout/List';
import LoadingSpinner from 'components/Loading';
import { TextDivider } from 'components/TextDivider';
import { Bold, UniqueTitle } from 'components/textElements';
import { BORDER_RADIUS, COLOR_BLUM, COLOR_WHITE_WALKER, SCREEN_M } from 'styles/variables';
import { getMonthName, isMobile } from 'utils';
import { useSelectedListItem } from 'utils/hooks';
import { filterSalaries, getMonthList, IMonthYear } from 'utils/salary/salaryLogic';
import SalarySearch from '../salaries/SalarySearch';
import { GET_SALARIES, GET_UNPAID_SALARIES } from './queries';
import SalaryListItem from './SalaryListItem';
import SalarySummary from './SalarySummary';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducers';

const ITEM_DELAY = 20;
const FADE_TIMEOUT = 200;

const PAGE_SIZE = 20;
const MIN_ITEMS_TO_SHOW_SCROLL = 6;

const Wrapper = styled.div<{ hasNoSalaries: boolean, showScroll?: boolean }>`
    border-radius: ${BORDER_RADIUS};
    background-color: ${COLOR_WHITE_WALKER};
    padding: ${props =>
        props.showScroll ? '0 35px 0 15px' : '0 15px'};
    @media (min-width: ${SCREEN_M}px) {
        background-color: ${props =>
            !props.hasNoSalaries ? COLOR_WHITE_WALKER : 'transparent'};
    }
`;

const SalaryList: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const language = useSelector((state: IRootState) => state.user.language);
    const searchQuery = useSelector((state: IRootState) => state.salary.searchQuery);

    const { data: salaryData, fetchMore, loading } = useQuery(GET_SALARIES, {
        notifyOnNetworkStatusChange: true,
        variables: {
            page: { offset: 0, pageSize: PAGE_SIZE },
            searchTerm: searchQuery
        }
    });
    const { data: unpaidSalaryData } = useQuery(GET_UNPAID_SALARIES, {
        variables: { page: {} }
    });

    const selectedId = useSelectedListItem();

    const monthList = getMonthList(salaryData?.salaries?.items || []);

    const listSalaries = (items: ISalary[], delay: number) => {
        return (
            <ul>
                {items.map((salary, index) => {
                    const handleClick = () => {
                        navigate(`/salaries/${salary.id}`);
                    };
                    const selected = selectedId === salary.id;
                    return (
                        <li
                            className={selected ? 'selected' : ''}
                            key={`${index}-salaries-${salary.id}`}
                            tabIndex={0}
                            onClick={handleClick}
                            onKeyPress={handleClick}
                        >
                            <Fade
                                in={true}
                                timeout={FADE_TIMEOUT}
                                style={{
                                    transitionDelay: `${ITEM_DELAY * index +
                                        delay}ms`
                                }}
                            >
                                <div>
                                    <SalaryListItem
                                        salary={salary}
                                        key={salary.id}
                                        language={language}
                                        selected={selected}
                                    />
                                </div>
                            </Fade>
                        </li>
                    );
                })}
            </ul>
        );
    };

    const salariesWithoutDate = unpaidSalaryData?.unpaidSalaries?.items || [];

    const loadMoreSalaries = () => {
        fetchMore({
            updateQuery: (prev: any, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return prev;
                }
                return {
                    ...prev,
                    salaries: {
                        ...prev.salaries,
                        items: [
                            ...prev.salaries.items,
                            ...fetchMoreResult.salaries.items
                        ]
                    }
                };
            },
            variables: {
                page: {
                    offset: salaryData?.salaries.items.length,
                    pageSize: PAGE_SIZE
                }
            }
        });
    };

    let animationCounter = 0;
    const showLoadMore = salaryData?.salaries.items.length < salaryData?.salaries?.total;
    const paidSalariesAmount = salaryData?.salaries?.items.length;
    const salariesAmount = paidSalariesAmount + unpaidSalaryData?.unpaidSalaries?.items.length;
    const hasNoSalaries = salariesAmount === 0 && !loading && searchQuery === '';
    const numberOfVisibleSalaries = searchQuery === ''
        ? (salariesWithoutDate.length + salaryData?.salaries?.items.length)
        : salaryData?.salaries?.items.length
    const showScroll = !isMobile() && (numberOfVisibleSalaries >= MIN_ITEMS_TO_SHOW_SCROLL);

    return (
        <>
            <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                <SalarySummary />
            </Box>
            <Wrapper hasNoSalaries={hasNoSalaries} showScroll={showScroll}>
                {hasNoSalaries ? (
                    <EmptyListPlaceholder text={t('salaries:noSalaries')} />
                ) : (
                    <Fade in={true} timeout={FADE_TIMEOUT}>
                        <Flex style={{ padding: '27px 0 23px 0' }}>
                            <UniqueTitle>{t('salaries:title')}</UniqueTitle>
                        </Flex>
                    </Fade>
                )}
                <Fade
                    in={true}
                    timeout={FADE_TIMEOUT}
                    style={{ transitionDelay: '100ms' }}
                >
                    {!hasNoSalaries ? (
                        <div style={{ marginBottom: 4 }}>
                            <SalarySearch />
                        </div>
                    ) : (
                        <div />
                    )}
                </Fade>
                {loading && (
                    <Flex justifyCenter style={{ marginTop: 60 }}>
                        <LoadingSpinner color={COLOR_BLUM} />
                    </Flex>
                )}
                <List
                    className={showScroll ? 'show-scroll' : 'hide-scroll'}
                    style={{ top: 180, paddingBottom: hasNoSalaries ? 0 : 20 }}
                >
                    {salariesWithoutDate &&
                        salariesWithoutDate.length > 0 &&
                        searchQuery === '' && (
                            <>
                                <div>
                                    <TextDivider>
                                        <Bold color={COLOR_BLUM}>
                                            {t('salaries:paymentInProcess')}
                                        </Bold>
                                    </TextDivider>
                                </div>
                                {listSalaries(salariesWithoutDate, 0)}
                            </>
                        )}

                    {monthList.map(({ month, year }: IMonthYear, index) => {
                        const s = filterSalaries(
                            salaryData?.salaries?.items,
                            month,
                            year
                        );
                        animationCounter += s.length;
                        const delay =
                            salariesWithoutDate.length * ITEM_DELAY +
                            (animationCounter - s.length) * ITEM_DELAY;
                        return (
                            <Fragment key={`${index}-${month}-${year}`}>
                                <Fade
                                    in={true}
                                    timeout={FADE_TIMEOUT}
                                    style={{
                                        transitionDelay: `${delay}ms`
                                    }}
                                >
                                    <div>
                                        <TextDivider>
                                            <Bold color={COLOR_BLUM}>
                                                {getMonthName(month)} {year}
                                            </Bold>
                                        </TextDivider>
                                    </div>
                                </Fade>

                                {listSalaries(s, delay)}
                            </Fragment>
                        );
                    })}

                    {showLoadMore && (
                        <div className="content-wrapper">
                            <EezyButton
                                color="purple"
                                dark
                                fullWidth
                                square
                                onClick={loadMoreSalaries}
                            >
                                {loading ? (
                                    <LoadingSpinner size="1em" />
                                ) : (
                                    t('general.load-more')
                                )}
                            </EezyButton>
                        </div>
                    )}
                </List>
            </Wrapper>
        </>
    );
};

export default SalaryList;
