import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { SCREEN_M } from '../../styles/variables';
import QuestionLabelRow from './QuestionLabelRow';

interface IStyleProps {
    vertical?: boolean;
}

const Wrapper = styled.div`
    display: flex;
    margin-bottom: 10px;

    ${(props: IStyleProps) => props.vertical && 'flex-direction: column;'}

    > * {
        flex: 1;

        &:not(:first-child) {
            ${(props: IStyleProps) =>
                props.vertical ? 'margin: 5px 0' : 'margin-left: 15px'};
        }
    }

    &.responsive-direction {
        /* Puts elements on top of each other on narrow screen */
        @media (max-width: 492px) {
            flex-direction: column;
            && > *:not(:first-child) {
                margin: 5px 0 0 0;
            }
        }
    }
    &.responsive-direction-m {
        /* Puts elements next to each other on wide screen */
        @media (min-width: ${SCREEN_M}px) {
            flex-direction: row;
            & > *:not(:first-child) {
                margin: 0 0 0 5px;
            }
        }
    }
`;

interface IQuestionWrapperProps {
    children: ReactNode;
    helpText?: string | JSX.Element[];
    helpTextFullWidth?: boolean;
    label?: string;
    vertical?: boolean;
}

export const QuestionWrapper = (
    props: IQuestionWrapperProps & { className?: string }
) => {
    return (
        <>
            {props.label && (
                <QuestionLabelRow helpText={props.helpText} helpTextFullWidth={props.helpTextFullWidth}>
                    {props.label}
                </QuestionLabelRow>
            )}
            <Wrapper className={props.className} vertical={props.vertical}>
                {props.children}
            </Wrapper>
        </>
    );
};
