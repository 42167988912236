import React, { ReactNode } from 'react';

type Props = {
    when: boolean;
    children: ReactNode;
};

const Show: React.FC<Props> = (props: Props) => {
    if (!props.when) {
        return <></>;
    }

    return <>{props.children}</>;
};

export default Show;
