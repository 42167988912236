import React, { FormEvent, KeyboardEvent } from 'react';
import styled from 'styled-components';
import {
    BORDER_RADIUS,
    COLOR_EEZY_GRAY_200,
    COLOR_EEZY_GRAY_300,
    COLOR_EEZY_GRAY_400,
    COLOR_EEZY_GRAY_500,
    COLOR_EEZY_GRAY_800,
} from 'styles/variables';
import { isMobile } from 'utils';
import { Icon } from './Icon';
import { InfoTooltip } from './ToolTip';
import { useTranslation } from 'react-i18next';

const StyledSearchField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 56px;
    border-radius: ${BORDER_RADIUS};
    padding: 0 20px;
    gap: 16px;
    border: 1px solid ${COLOR_EEZY_GRAY_200};
    color: ${COLOR_EEZY_GRAY_500};

    &:hover {
        border-color: ${COLOR_EEZY_GRAY_300};
        color: ${COLOR_EEZY_GRAY_800};
    }

    & > input:nth-child(2) {
        background-color: transparent;
        font-size: 14px;
        font-weight: 400;
        min-width: 100px;
        width: 100%;
        line-height: 56px;
        caret-color: initial;

        &::placeholder {
            color: ${COLOR_EEZY_GRAY_500};
        }
        &:focus::placeholder {
            color: transparent;
        }
    }

    &.focus {
        border-color: ${COLOR_EEZY_GRAY_400};
    }
`;

const ClearButton = styled.button`
    > svg {
        vertical-align: middle;
    }
`;

interface ISearchFieldProps {
    onChange: (query: string, fireImmediately?: boolean) => void;
    placeholder: string;
    query: string;
    tooltip?: string;
}

const SearchField = (props: ISearchFieldProps) => {
    const { t } = useTranslation();
    const [focused, setFocused] = React.useState(false);
    const [hovered, setHovered] = React.useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);
    const onMouseOver = () => setHovered(true);
    const onMouseOut = () => setHovered(false);

    const handleChange = (e: FormEvent<HTMLInputElement>) => {
        props.onChange(e.currentTarget.value);
    };

    const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        const code = e.key;
        if (code === 'Enter') {
            // enter pressed, fire search immediately
            props.onChange(e.currentTarget.value, true);
        }
    };

    const handleEmpty = () => {
        props.onChange('', true);
    };

    const renderSearchField = () => (
        <StyledSearchField className={focused ? 'focus' : ''}>
            <div>
                <Icon icon={['far', 'search']} color="inherit" className="small" />
            </div>
            <input
                aria-label={props.placeholder}
                onChange={handleChange}
                onKeyDown={handleEnter}
                placeholder={props.placeholder}
                type="text"
                value={props.query}
                onFocus={onFocus}
                onBlur={onBlur}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
            />
            {props.query && (
                <ClearButton onClick={handleEmpty}>
                    <Icon icon={['far', 'close']} color="inherit" />
                </ClearButton>
            )}
        </StyledSearchField>
    );

    return (
        <>
            {props.tooltip ? (
                <InfoTooltip
                    title={props.tooltip}
                    arrow
                    placement={isMobile() ? 'top' : 'right'}
                    open={(focused && isMobile()) || hovered}
                >
                    {renderSearchField()}
                </InfoTooltip>
            ) : (
                renderSearchField()
            )}
        </>
    );
};

export default SearchField;
