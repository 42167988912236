import { gql } from "@apollo/client";

const GET_UNPAID_SALARIES = gql`
    query Salaries($page: Page) {
        unpaidSalaries(page: $page) {
            count
            items {
                id
                paymentDate
                paidAmount
                status
                invoiceIds
                url
            }
            total
        }
    }
`;

export default GET_UNPAID_SALARIES;
