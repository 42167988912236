import { gql } from '@apollo/client';

const UPDATE_COST = gql`
    mutation UpdateCost(
        $invoiceId: Int!
        $costId: Int!
        $cost: InputReceiptCost!
    ) {
        updateReceiptCost(invoiceId: $invoiceId, costId: $costId, cost: $cost)
    }
`;

export default UPDATE_COST;
