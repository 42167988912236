import { useTranslation } from 'react-i18next';
import { FormEvent } from 'react';
import { Hidden } from '@mui/material';
import { EezyButton, InlineButtonLink } from 'components/Buttons';
import { FormInput, FormRadio, FormRow, FormSection, FormSelect, SplitRow } from 'components/form';
import { IDropdownOption } from 'components/form/AutocompleteDropdown';
import { FormLabel } from 'components/form/FormLabel';
import { PLight } from 'components/textElements';
import { getCountryCodeOptions } from 'utils/profile/contact';
import { communicationsLanguegOptions, referralOptions } from 'utils/user/userUtils';

interface IFormProps {
    errors?: any;
    handleChange: (val: string | number, name: string) => void;
    handleSubmit: (e: FormEvent<HTMLButtonElement>) => void;
    formData: any;
    showTerms: () => void;
    validateEmail: (val: string) => void;
}

const RegistrationForm = (props: IFormProps) => {
    const { t } = useTranslation();
    const { formData, errors, handleChange, handleSubmit, validateEmail } = props;

    const langOptions = communicationsLanguegOptions().map((o: IDropdownOption) => {
        return { label: t(o.label), value: o.value };
    });

    const refOptions = referralOptions.map((o: IDropdownOption) => {
        return { label: t(o.label), value: o.value };
    });

    const handleCountryCodeChange = (val: string) => {
        handleChange(parseInt(val, 10), 'phoneCountryCode');
    };

    return (
        <form>
            <FormSection>
                <FormInput
                    data-mf-replace="**REMOVED**"
                    autoComplete="given-name"
                    error={errors?.firstName}
                    label={t('login.firstName') || ''}
                    name="firstName"
                    onChange={handleChange}
                    disabled={formData.authenticated}
                    required
                    value={formData.firstName}
                    placeholder={t('general.writeHere') || ''}
                />
                <FormInput
                    data-mf-replace="**REMOVED**"
                    autoComplete="family-name"
                    disabled={formData.authenticated}
                    error={errors?.lastName}
                    label={t('login.lastName') || ''}
                    name="lastName"
                    required
                    onChange={handleChange}
                    value={formData.lastName}
                    placeholder={t('general.writeHere') || ''}
                />
                <FormInput
                    data-mf-replace="**REMOVED**"
                    autoComplete="email"
                    error={errors?.email}
                    label={t('login.email') || ''}
                    name="email"
                    onChange={handleChange}
                    required
                    type="email"
                    value={formData.email}
                    placeholder={t('general.writeHere') || ''}
                    onBlur={validateEmail}
                />
                <FormRow>
                    <FormLabel
                        error={errors?.phoneCountryCode || errors?.phone}
                        htmlFor="phone"
                        value={formData.phone}
                    >
                        {t('profile:contact.phone')}
                    </FormLabel>
                    <SplitRow>
                        <FormSelect
                            error={errors?.phoneCountryCode}
                            getOptionLabel={(opt) => `+${opt.value}`}
                            name="phoneCountryCode"
                            onChange={handleCountryCodeChange}
                            options={getCountryCodeOptions()}
                            required
                            showIcon
                            style={{ maxWidth: 120 }}
                            value={formData.phoneCountryCode.toString()}
                        />
                        <FormInput
                            data-mf-replace="**REMOVED**"
                            error={errors?.phone}
                            name="phone"
                            required
                            onChange={handleChange}
                            value={formData.phone}
                        />
                    </SplitRow>
                </FormRow>
                <FormRadio
                    error={errors?.language}
                    label={t('login.language') || ''}
                    name="language"
                    onChange={handleChange}
                    onTwoLines
                    options={langOptions}
                    value={formData.language}
                />
            </FormSection>
            <FormSection style={{ marginTop: '15px' }}>
                <FormInput
                    error={errors?.bonusCode}
                    label={t('login.bonusCode') || ''}
                    name="bonusCode"
                    value={formData.bonusCode}
                    placeholder={t('general.writeHere') || ''}
                    onChange={handleChange}
                    optional
                />
                <FormSelect
                    error={errors?.referralInfo}
                    label={t('login.referralInfo') || ''}
                    name="referralInfo"
                    onChange={handleChange}
                    options={refOptions}
                    showIcon
                    optional
                    value={formData.referralInfo || undefined}
                    clearable
                />
                {formData.referralInfo === 'other' && (
                    <FormInput
                        error={errors?.referralOther}
                        placeholder={t('login.referral-option-other-placeholder') || ''}
                        name="referralOther"
                        value={formData.referralOther}
                        onChange={handleChange}
                    />
                )}
            </FormSection>

            <FormSection style={{ marginTop: '30px' }}>
                <Hidden smDown>
                    <EezyButton
                        color="purple"
                        dark
                        hasIcon
                        iconAlignment="right"
                        style={{ alignSelf: 'center' }}
                        onClick={handleSubmit}
                        type="submit"
                    >
                        {t('login.register-submit')}
                    </EezyButton>
                </Hidden>
                <Hidden smUp>
                    <EezyButton
                        color="purple"
                        dark
                        fullWidth
                        style={{ alignSelf: 'flex-end' }}
                        onClick={handleSubmit}
                        type="submit"
                    >
                        {t('login.register-submit')}
                    </EezyButton>
                </Hidden>
            </FormSection>

            <FormSection style={{ marginTop: '15px' }}>
                <PLight>
                    {t('login.register-terms1')}
                    <InlineButtonLink onClick={props.showTerms} type="button">
                        {t('login.register-terms2')}
                    </InlineButtonLink>
                    .
                </PLight>
            </FormSection>
        </form>
    );
};

export default RegistrationForm;
