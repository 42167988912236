import { FC, MouseEvent, ReactNode, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Hidden } from '@mui/material';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';
import {
    FillType,
    IBriefInvoice,
    IInvoice,
    IInvoiceKeys,
    LanguageCode,
} from '../../../../shared/src/types/invoice';
import { IUserBasicData, UiLanguage } from '../../../../shared/src/types/user';
import {
    setExpenseReimbursementWarningHidden as setERWarningHidden,
    setTravelWarningHidden as setTWarningHidden,
} from '../../actions/invoice';
import { EezyButton, EezyLink } from 'components/Buttons';
import ErrorPointer from 'components/ErrorPointer';
import { Flex } from 'components/Flex';
import { FormTextArea } from 'components/form';
import { PopoverInput } from 'components/form/PopoverInput';
import { Icon } from 'components/Icon';
import { WhiteDocumentArea } from 'components/layout/WhiteDocumentArea';
import LoadingSpinner from 'components/Loading';
import { PenButton } from 'components/PenButton';
import { ExpressTag } from 'components/Tag';
import { LabelCapsSmall, P, PSmall, TitleCapsBold } from 'components/textElements';
import { ValidationMessageBlock } from 'components/ValidationMessageBlock';
import config from 'config';
import { InvoiceCol, InvoiceError, ResponsiveUpperPart } from 'styles/invoiceStyles';
import { COLOR_BLACKWATER, COLOR_BLUM, COLOR_DARK_GRAY, COLOR_IMPORTANT, SCREEN_S } from 'styles/variables';
import { formatDate, formatPercentage } from 'utils';
import { transFixed } from 'utils/i18n';
import {
    getStatus,
    isCostInvoiceEditable,
    isDownloadable,
    isExpenseReimbursementMissing,
    shouldShowInterestPercentage,
    shouldShowNotificationDays,
} from 'utils/invoice/invoiceLogic';
import { IInvoiceTemplate } from 'utils/invoice/invoiceTemplates';
import { dismissWarning, isWarningHidden, WarningType } from 'utils/user/userUtils';
import { formatValidationResult } from 'utils/validation';
import ExpenseReimbursementWarning from './ExpenseReimbursementWarning';
import { openFillHelper } from './fillHelpers/utils';
import InvoiceFooter from './InvoiceFooter';
import InvoiceHeader from './InvoiceHeader';
import InvoiceOtherFields from './InvoiceOtherFields';
import InvoicePaymentPanel from './InvoicePaymentPanel';
import InvoiceRecipientSection from './InvoiceRecipientSection';
import InvoiceRows from './InvoiceRows';
import PaymentInfo from './PaymentInfo';
import { GROUP_INVOICE_TOTAL_CHANGED, INVOICE_ERRORS_VISIBLE } from './queries/invoiceStateQueries';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducers';
import { IUser } from 'reducers/userReducer';
import { IInvoiceBriefStatus } from 'utils/invoice/invoiceStatuses';

const InputWrapper = styled.div`
    position: relative;
`;

const IconWrapper = styled.div`
    position: absolute;
    top: calc(50%);
    left: -30px;
    display: none;
`;

const Fader = styled(Flex).attrs({
    spread: true,
})`
    opacity: ${(props: { $faded: boolean }) => (props.$faded ? 0.2 : 1)};
    transition: opacity 500ms;
`;

const ContractorSection = styled(Flex)`
    flex-direction: row;
    margin: 0 0 24px 0;
    min-width: 295px;
    opacity: ${(props: { $faded: boolean }) => (props.$faded ? 0.2 : 1)};
    transition: opacity 500ms;

    @media (min-width: ${SCREEN_S}px) {
        margin: 0 10px 24px 0;
    }
`;

interface IInvoiceDocumentProps {
    allowDownload?: boolean;
    children?: ReactNode;
    editable: boolean;
    invoice?: IInvoice;
    invoiceBrief?: IBriefInvoice;
    invoiceErrors: ReturnType<typeof formatValidationResult>;
    loading?: boolean;
    loadingRecipient?: boolean;
    loadingUpdate?: boolean;
    handleInvoiceItemUpdate?: (o: object) => void;
    handleInvoiceUpdate: (changedProperty: IInvoiceKeys) => void;
    template: IInvoiceTemplate;
    userData?: IUserBasicData;
    fillType?: FillType;
    hasContract?: boolean;
    handleAddCostInvoice?: () => void;
}

const InvoiceDocument: FC<IInvoiceDocumentProps> = ({
    allowDownload,
    children,
    editable,
    invoice,
    invoiceBrief,
    invoiceErrors,
    loading,
    loadingRecipient,
    loadingUpdate,
    handleInvoiceItemUpdate,
    handleInvoiceUpdate,
    template,
    userData,
    fillType,
    hasContract,
    handleAddCostInvoice,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();

    const expenseReimbursementWarningHidden: boolean | undefined = useSelector(
        (state: IRootState) => state.invoice?.expenseReimbursementWarningHidden,
    );
    const language: UiLanguage = useSelector((state: IRootState) => state.user.language);
    const user: IUser = useSelector((state: IRootState) => state.user);

    const [description, setDescription] = useState(invoice?.description);
    const [paymentTermEl, setPaymentTermEl] = useState<(EventTarget & Element) | null>(null);
    const [dueDateEl, setDueDateEl] = useState<(EventTarget & Element) | null>(null);
    const [preview, setPreview] = useState(false);
    const [previewDisabled, setPreviewDisabled] = useState(false);
    const [invoiceLanguage, setInvoiceLanguage] = useState<LanguageCode | undefined>();
    const [status, setStatus] = useState<IInvoiceBriefStatus | null>(getStatus(invoiceBrief, invoice));

    const { data: groupInvoiceQuery } = useQuery(GROUP_INVOICE_TOTAL_CHANGED);
    const showGroupInvoiceWarning = groupInvoiceQuery?.groupInvoiceTotalChanged;

    const { client, data: localData } = useQuery(INVOICE_ERRORS_VISIBLE);
    const showErrors = localData?.invoiceErrorsVisible;

    const showArrows = true;

    useEffect(() => {
        dispatch(setTWarningHidden(isWarningHidden(WarningType.Travel, invoice?.id)));
        dispatch(setERWarningHidden(isWarningHidden(WarningType.ExpenseReimbursement, invoice?.id)));
    }, [invoice?.id, dispatch]);

    // Clear warning for group invoice total changes when leaving component
    useEffect(() => {
        return () => {
            if (invoice?.isGroupInvoice) {
                client.writeQuery({
                    data: { groupInvoiceTotalChanged: false },
                    query: GROUP_INVOICE_TOTAL_CHANGED,
                });
            }
        };
    }, [client, invoice?.isGroupInvoice]);

    useEffect(() => {
        const noPreviewStatuses = ['completed', 'overdued', 'credited', 'paid', 'waiting_for_dispatch'];
        setPreviewDisabled(!invoice?.status || noPreviewStatuses.includes(invoice.status));
        if (!preview) {
            setInvoiceLanguage(undefined);
            return;
        }
        setInvoiceLanguage(invoice?.recipient?.invoiceLanguage);
    }, [invoice, preview]);

    useEffect(() => {
        setDescription(invoice?.description);
    }, [invoice]);

    useEffect(() => {
        if (invoiceBrief && invoice) {
            setStatus(getStatus(invoiceBrief, invoice));
        }
    }, [invoiceBrief, invoice]);

    const closePopover = (name: string) => {
        switch (name) {
            case 'paymentTerms':
                setPaymentTermEl(null);
                break;
            case 'dueDate':
                setDueDateEl(null);
                break;
        }
    };

    const togglePopover = (e: MouseEvent, name: string) => {
        if (!editable) {
            return;
        }
        switch (name) {
            case 'paymentTerms':
                setPaymentTermEl(paymentTermEl ? null : e.currentTarget);
                break;
            case 'dueDate':
                setDueDateEl(dueDateEl ? null : e.currentTarget);
                break;
        }
    };

    const PaymentTerms = () => {
        return (
            <InvoicePaymentPanel
                handleChange={handleInvoiceUpdate}
                invoice={invoice}
                invoiceErrors={invoiceErrors}
            />
        );
    };

    const nameOnInvoice = () =>
        (user.companyName ? `${user.companyName + ' / '}` : '') +
        (userData?.firstNameForInvoice || user.firstName) +
        ' ' +
        user.lastName;

    if (loading) {
        return (
            <Flex column fullWidth className="spinner" style={{ marginTop: 20 }}>
                <LoadingSpinner color={COLOR_BLUM} />
            </Flex>
        );
    }

    return (
        <WhiteDocumentArea>
            {children && children}
            {showErrors && invoiceErrors && editable && (
                <InvoiceError aria-live="assertive">
                    {invoiceErrors.totalWithVat?.type === 'number.min'
                        ? t('errors.invoice-total-negative')
                        : t('errors.invoice-data-missing')}
                </InvoiceError>
            )}
            {!status ? (
                <div style={{ height: 30 }} />
            ) : (
                <Fader $faded={preview}>
                    <TitleCapsBold color={status?.iconColor} style={{ minHeight: 30 }}>
                        {status?.titleLong || status?.title}
                        {status?.eezyExpress && <ExpressTag style={{ marginLeft: 10 }} />}
                    </TitleCapsBold>
                    {allowDownload && invoice?.url && isDownloadable(invoice?.status) && (
                        <EezyLink
                            color="purple"
                            hasIcon
                            href={invoice?.url}
                            rel="noopener noreferrer"
                            target="_blank"
                            style={{ minWidth: 100 }}
                        >
                            <Icon color={COLOR_BLUM} icon={['far', 'arrow-to-bottom']} /> {t('file.download')}
                        </EezyLink>
                    )}
                </Fader>
            )}
            <Hidden mdDown>
                {!previewDisabled ? (
                    <InvoiceHeader $faded={!preview} invoice={invoice} invoiceLanguage={invoiceLanguage} />
                ) : null}
            </Hidden>
            <ResponsiveUpperPart style={{ marginTop: 10, minHeight: 120 }}>
                <InvoiceRecipientSection
                    editable={editable && fillType !== 'guided'}
                    invoice={invoice}
                    invoiceLanguage={invoiceLanguage}
                    loading={loadingRecipient}
                    recipient={invoice?.recipient}
                    handleUpdate={handleInvoiceUpdate}
                />
                <InvoiceCol
                    onFocus={() => {
                        openFillHelper(client, 'invoice');
                    }}
                >
                    <Hidden mdUp={!previewDisabled}>
                        {invoice?.invoiceNumber ? (
                            <Flex spread>
                                <LabelCapsSmall color="black">{t('invoice.number')}</LabelCapsSmall>
                                <PSmall color={COLOR_DARK_GRAY}>{invoice?.invoiceNumber}</PSmall>
                            </Flex>
                        ) : (
                            <Flex spread className="hidden-sm">
                                <LabelCapsSmall color="black">{t('invoice.draftNumber')}</LabelCapsSmall>
                                <PSmall color={COLOR_DARK_GRAY}>{invoice?.draftNumber}</PSmall>
                            </Flex>
                        )}
                    </Hidden>
                    {!editable && (
                        <Flex spread className="hidden-sm">
                            <LabelCapsSmall color="black">
                                {transFixed({
                                    str: 'invoice.date',
                                    lang: invoiceLanguage,
                                    options: { defaultValue: '' },
                                })}
                            </LabelCapsSmall>
                            <PSmall color={COLOR_DARK_GRAY}>
                                {invoice?.invoiceDate && formatDate(invoice.invoiceDate)}
                            </PSmall>
                        </Flex>
                    )}
                    {editable && fillType !== 'guided' ? (
                        <>
                            <Flex spread>
                                {showArrows && invoiceErrors?.paymentTerm && (
                                    <ErrorPointer style={{ marginTop: 2 }} />
                                )}
                                <LabelCapsSmall color="black" htmlFor="invoice-payment-term">
                                    {transFixed({
                                        str: 'invoice.paymentTerm',
                                        lang: invoiceLanguage,
                                        options: { defaultValue: '' },
                                    })}
                                </LabelCapsSmall>
                                <PopoverInput
                                    anchorEl={paymentTermEl}
                                    editable={editable}
                                    id="paymentTerm"
                                    onClick={(e) => {
                                        togglePopover(e, 'paymentTerms');
                                    }}
                                    onClose={() => {
                                        closePopover('paymentTerms');
                                    }}
                                    popoverContent={
                                        <div style={{ width: '350px' }}>
                                            <PaymentTerms />
                                        </div>
                                    }
                                    required
                                    showArrow={false}
                                    style={{
                                        textAlign: 'center',
                                        width: '40%',
                                    }}
                                >
                                    {!R.isNil(invoice?.paymentTerm) &&
                                        transFixed({
                                            str: 'invoice.paymentTerm_interval',
                                            lang: invoiceLanguage,
                                            options: {
                                                defaultValue: '',
                                                count: invoice?.paymentTerm,
                                                postProcess: 'interval',
                                            },
                                        })}
                                </PopoverInput>
                            </Flex>
                            <Flex spread style={{ marginTop: '5px' }}>
                                {showArrows && invoiceErrors?.dueDate && (
                                    <ErrorPointer style={{ marginTop: 2 }} />
                                )}
                                <Flex center>
                                    <LabelCapsSmall color="black" htmlFor="invoice-due-date">
                                        {transFixed({
                                            str: 'general.duedate',
                                            lang: invoiceLanguage,
                                            options: { defaultValue: '' },
                                        })}
                                    </LabelCapsSmall>
                                    {invoice?.collectionRequested && (
                                        <Icon
                                            icon={['far', 'user-cowboy']}
                                            className="small"
                                            color={COLOR_IMPORTANT}
                                            style={{ marginLeft: 10 }}
                                            title={t('invoice.debtCollection.on') || ''}
                                        />
                                    )}
                                </Flex>
                                <PopoverInput
                                    anchorEl={dueDateEl}
                                    editable={editable}
                                    id="dueDate"
                                    onClick={(e) => {
                                        togglePopover(e, 'dueDate');
                                    }}
                                    onClose={() => {
                                        closePopover('dueDate');
                                    }}
                                    popoverContent={
                                        <div style={{ width: '350px' }}>
                                            <PaymentTerms />
                                        </div>
                                    }
                                    required
                                    style={{
                                        textAlign: 'center',
                                        width: '40%',
                                    }}
                                >
                                    {invoice?.dueDate && formatDate(invoice?.dueDate)}
                                </PopoverInput>
                            </Flex>
                        </>
                    ) : (
                        <Flex spread>
                            {invoice?.dueDate ? (
                                <>
                                    <div>
                                        <LabelCapsSmall color="black">
                                            {transFixed({
                                                str: 'general.duedate',
                                                lang: invoiceLanguage,
                                                options: { defaultValue: '' },
                                            })}
                                        </LabelCapsSmall>
                                        {invoice?.collectionRequested && (
                                            <Icon
                                                icon={['far', 'user-cowboy']}
                                                color={COLOR_IMPORTANT}
                                                style={{ marginLeft: 10 }}
                                                title={t('invoice.debtCollection.on') || ''}
                                            />
                                        )}
                                    </div>
                                    <PSmall color={COLOR_DARK_GRAY}>
                                        {invoice?.dueDate && formatDate(invoice.dueDate)}
                                    </PSmall>
                                </>
                            ) : (
                                <>
                                    <LabelCapsSmall color="black">
                                        {transFixed({
                                            str: 'invoice.paymentTerm',
                                            lang: invoiceLanguage,
                                            options: { defaultValue: '' },
                                        })}
                                    </LabelCapsSmall>
                                    <PSmall color={COLOR_DARK_GRAY}>
                                        {invoice?.paymentTerm !== undefined &&
                                            transFixed({
                                                str: 'invoice.paymentTerm_interval',
                                                lang: invoiceLanguage,
                                                options: {
                                                    defaultValue: '',
                                                    count: invoice?.paymentTerm,
                                                    postProcess: 'interval',
                                                },
                                            })}
                                    </PSmall>
                                </>
                            )}
                        </Flex>
                    )}

                    {shouldShowNotificationDays(invoice) && (
                        <Flex spread>
                            <LabelCapsSmall color="black">{t('invoice.notificationTime')}</LabelCapsSmall>
                            <PSmall color={COLOR_DARK_GRAY}>
                                {t('dates.x-days', {
                                    days: invoice?.notificationDays,
                                })}
                            </PSmall>
                        </Flex>
                    )}

                    {shouldShowInterestPercentage(invoice) && (
                        <Flex spread>
                            <LabelCapsSmall color="black">{t('invoice.interestRate')}</LabelCapsSmall>
                            <PSmall color={COLOR_DARK_GRAY}>
                                {t('form.percent', {
                                    percentage: formatPercentage(invoice?.interestPercentage || 0),
                                })}
                            </PSmall>
                        </Flex>
                    )}

                    {invoice && template && (
                        <InvoiceOtherFields
                            column={false}
                            editable={editable && invoice?.fillType === 'free'}
                            invoice={invoice}
                            invoiceLanguage={invoiceLanguage}
                            handleUpdate={handleInvoiceUpdate}
                            template={template}
                        />
                    )}
                </InvoiceCol>
            </ResponsiveUpperPart>
            <Flex
                style={{
                    borderBottom: previewDisabled ? 'none' : `1px solid ${COLOR_BLACKWATER}`,
                    borderTop: `1px solid ${COLOR_BLACKWATER}`,
                    marginTop: 30,
                    minHeight: 90,
                }}
                onFocus={() => {
                    openFillHelper(client, 'invoice');
                }}
            >
                <Hidden mdDown>
                    {userData && !previewDisabled ? (
                        <ContractorSection $faded={!preview}>
                            {userData?.invoiceLogoId && (
                                <div>
                                    <img
                                        aria-label={'logo'}
                                        alt={'logo'}
                                        src={`${config.backendUrl3}/logo/${userData.invoiceLogoId}/picture.png`}
                                        style={{
                                            height: '102px',
                                            marginRight: 10,
                                            width: '102px',
                                        }}
                                    />
                                </div>
                            )}
                            <div>
                                <LabelCapsSmall>
                                    {transFixed({
                                        str: 'invoice.contractor',
                                        lang: invoiceLanguage,
                                        options: { defaultValue: '' },
                                    })}
                                </LabelCapsSmall>
                                <div data-mf-replace="**REMOVED**">
                                    {nameOnInvoice()}
                                    <br />
                                    {userData?.phoneCountryCode && '+' + userData?.phoneCountryCode + ' '}
                                    {userData?.phone}
                                </div>
                            </div>
                        </ContractorSection>
                    ) : null}
                </Hidden>
                <InvoiceCol>
                    {editable && fillType === 'free' ? (
                        <InputWrapper>
                            <LabelCapsSmall color="black" htmlFor="invoice-description">
                                {transFixed({
                                    str: 'invoice.description.label',
                                    lang: invoiceLanguage,
                                    options: { defaultValue: '' },
                                })}
                                {invoice && (
                                    <PenButton
                                        onClick={() => {
                                            openFillHelper(client, 'invoice');
                                        }}
                                    />
                                )}
                            </LabelCapsSmall>
                            <FormTextArea
                                data-mf-replace="**REMOVED**"
                                disabled={!invoice?.id}
                                name="invoice-description"
                                onChange={(value: string) => {
                                    handleInvoiceUpdate({
                                        description: value,
                                    });
                                }}
                                placeholder={t('invoice.description.placeholder') || ''}
                                rowsMin={3}
                                showBorder
                                style={{
                                    padding: '5px 8px',
                                }}
                                value={description || ''}
                                variant="plain"
                            />
                            <IconWrapper className="icon-wrapper">
                                <Icon icon={['far', 'circle-right']} color={COLOR_IMPORTANT} />
                            </IconWrapper>
                        </InputWrapper>
                    ) : invoice?.description ? (
                        <>
                            <LabelCapsSmall color="black" htmlFor="invoice-description">
                                {transFixed({
                                    str: 'invoice.description.label',
                                    lang: invoiceLanguage,
                                    options: { defaultValue: '' },
                                })}
                            </LabelCapsSmall>
                            <PSmall
                                data-mf-replace="**REMOVED**"
                                color={COLOR_DARK_GRAY}
                                style={{
                                    whiteSpace: 'pre-wrap',
                                    wordWrap: 'break-word',
                                }}
                            >
                                {invoice?.description}
                            </PSmall>
                        </>
                    ) : null}
                </InvoiceCol>
            </Flex>
            <Flex column style={{ marginTop: 30 }}>
                <InvoiceRows
                    editable={editable && fillType === 'free'}
                    errors={invoiceErrors}
                    handleInvoiceItemUpdate={handleInvoiceItemUpdate}
                    invoice={invoice}
                    invoiceLanguage={invoiceLanguage}
                    language={language}
                    template={template}
                    loadingUpdate={loadingUpdate}
                    hasContract={hasContract}
                />
            </Flex>
            {invoice &&
                isCostInvoiceEditable(invoice) &&
                isExpenseReimbursementMissing(invoice) &&
                !expenseReimbursementWarningHidden && (
                    <Fader $faded={preview}>
                        <ExpenseReimbursementWarning
                            language={language}
                            onClose={() => {
                                dispatch(setERWarningHidden(true));
                                dismissWarning(WarningType.ExpenseReimbursement, invoice?.id);
                            }}
                            handleAddCostInvoice={handleAddCostInvoice}
                        />
                    </Fader>
                )}
            <Hidden mdDown>
                {!previewDisabled ? (
                    <Flex posEnd style={{ paddingTop: 15 }}>
                        <EezyButton color={'purple'} hasIcon square onClick={() => setPreview(!preview)}>
                            {preview ? (
                                <>
                                    <Icon icon={['far', 'eye-slash']} />
                                    {t('invoice.manual.hide')}
                                </>
                            ) : (
                                <>
                                    <Icon icon={['far', 'eye']} />
                                    {t('invoice.preview')}
                                </>
                            )}
                        </EezyButton>
                    </Flex>
                ) : null}
            </Hidden>
            <Hidden mdUp>
                <div style={{ marginTop: 15, fontSize: 12 }}>{t('invoice.preview-mobile-notification')}</div>
            </Hidden>
            {invoice?.template && invoice?.recipient?.businessId && (
                <P style={{ textAlign: 'center' }}>
                    {t(`invoice.vatTexts.${invoice.template}`, {
                        businessId: invoice.recipient.businessId,
                    })}
                </P>
            )}
            {invoice?.paymentInformation?.referenceNumber && invoice && (
                // reference number is added when invoice is sent, so the
                // payment info is shown on sent invoices
                <PaymentInfo
                    dueDate={invoice?.dueDate}
                    reference={invoice?.paymentInformation?.referenceNumber}
                    total={invoice?.paymentInformation?.totalWithVat}
                />
            )}
            {showGroupInvoiceWarning && (
                <ValidationMessageBlock
                    type="warning"
                    style={{ marginTop: 30 }}
                    title={t('invoice.groupInvoice.warning.title') || ''}
                >
                    {t('invoice.groupInvoice.warning.message')}
                </ValidationMessageBlock>
            )}
            <Hidden mdDown>
                {!previewDisabled ? (
                    <InvoiceFooter $faded={!preview} invoice={invoice} invoiceLanguage={invoiceLanguage} />
                ) : null}
            </Hidden>
        </WhiteDocumentArea>
    );
};

export default InvoiceDocument;
