// APP
export const SHOW_CONFIRMATION = 'APP/SHOW_CONFIRMATION';
export const HIDE_CONFIRMATION = 'APP/HIDE_CONFIRMATION';
export const SHOW_INTRO = 'APP/SHOW_INTRO';
export const SAVE_CAR = 'APP/SAVE_CAR';

// AUTH
export const AUTH_FAILURE = 'AUTH/FAILURE';
export const AUTH_FAILURE_HIDE = 'AUTH/FAILURE_HIDE';
export const SHOW_MODALS = 'AUTH/SHOW_MODALS';
export const HIDE_MODAL = 'AUTH/HIDE_MODAL';

export const AUTH_ACTION_FAILURE = 'AUTH/ACTION_FAILURE';
export const AUTH_ACTION_BEGIN = 'AUTH/ACTION_BEGIN';

export const RECENTLY_IDENTIFIED = 'AUTH/RECENTLY_IDENTIFIED';

export const VERIFICATION_CODE_REQUIRED = 'AUTH/VERIFICATION_CODE_REQUIRED';

// FILE
export const FILE_UPLOAD_BEGIN = 'FILE/UPLOAD_BEGIN';
export const FILE_UPLOAD_FAILURE = 'FILE/UPLOAD_FAILURE';
export const FILE_UPLOAD_FAILURE_HIDE = 'FILE/HIDE_ERROR';
export const ADD_COST_FILE = 'FILE/ADD_COST_FILE';
export const DELETE_COST_FILE_ID = 'FILE/DELETE_COST_FILE_ID';
export const ADD_TAX_CARD = 'FILE/ADD_TAX_CARD';
export const ADD_INVOICE_ATTACHMENT = 'FILE/ADD_INVOICE_ATTACHMENT';
export const ADD_INVOICE_LOGO = 'FILE/ADD_INVOICE_LOGO';
export const DELETE_INVOICE_ATTACHMENT = 'FILE/DELETE_INVOICE_ATTACHMENT';
export const ADD_MESSAGE_ATTACHMENT = 'FILE/ADD_MESSAGE_ATTACHMENT';
export const DELETE_MESSAGE_ATTACHMENT = 'FILE/DELETE_MESSAGE_ATTACHMENT';

// INVOICE
export const INVOICE_SEARCH_QUERY = 'INVOICE_SEARCH_QUERY';
export const INVOICE_SPREAD_STATE = 'INVOICE_SPREAD_STATE';
export const INVOICE_SPREAD_STATE_CLIENT_SET = 'INVOICE_SPREAD_STATE_CLIENT';
export const INVOICE_SET_TRAVEL_WARNING_HIDDEN = 'INVOICE_SET_TRAVEL_WARNING_HIDDEN';
export const INVOICE_SET_EXPENSE_REIMBURSEMENT_WARNING_HIDDEN =
    'INVOICE_SET_EXPENSE_REIMBURSEMENT_WARNING_HIDDEN';

// REFETCH
export const TRIGGER_REFETCH = 'TRIGGER_REFETCH';
export const RESET_REFETCH = 'RESET_REFETCH';

// LOGIN
export const LOGIN_SUCCESS = 'LOGIN/SUCCESS';
export const LOGIN_SHOW = 'LOGIN/SHOW';

// Salary
export const SALARY_SEARCH_QUERY = 'SALARY_SEARCH_QUERY';

// PASSWORD REQUEST
export const PASSWORD_REQUESTED_FAILURE = 'PWD_REQUEST/FAILURE';
export const PASSWORD_REQUESTED = 'PWD_REQUEST/SUCCESS';

// USER
export const FETCH_USER_BEGIN = 'USER/FETCH_USER_BEGIN';
export const FETCH_USER_SUCCESS = 'USER/FETCH_USER_SUCCESS';
export const USER_ASYNC_FAILURE = 'USER/ASYNC_FAILURE';
export const USER_SET_STATE = 'USER/SET_STATE';
export const USER_RESET = 'USER/RESET';
export const YEL_SELF_REPORTED = 'USER/YEL_SELF_REPORTED';
export const FETCH_WITHHOLDING_TAX_SUCCESS = 'USER/FETCH_WITHHOLDING_TAX_SUCCESS';
export const USER_SET_COMPANY_NAME = 'USER/SET_COMPANY_NAME';
export const USER_SET_TWO_FACTOR_AUTHENTICATION = 'USER/SET_TWO_FACTOR_AUTHENTICATION';

// YEL
export const YEL_ASYNC_BEGIN = 'YEL/ASYNC_BEGIN';
export const YEL_ASYNC_DONE = 'YEL/ASYNC_DONE';
export const YEL_AUTH_SUCCESS = 'YEL/AUTH_SUCCESS';
export const YEL_FETCH_SUCCESS = 'YEL/FETCH_SUCCESS';
export const YEL_WITHDRAWAL_SUCCESS = 'YEL/WITHDRAWAL_SUCCESS';

export const YEL_SET_ESTIMATED_INCOME = 'YEL/SET_ESTIMATED_INCOME';
export const YEL_SET_OCCUPATION = 'YEL/SET_OCCUPATION';
export const YEL_SET_ESTIMATED_INVOICING = 'YEL/SET_ESTIMATED_INVOICING';
export const YEL_SET_NEW_ESTIMATED_INCOME = 'YEL/SET_NEW_ESTIMATED_INCOME';
export const YEL_SET_INCOME_CHANGE_REASON = 'YEL/SET_INCOME_CHANGE_REASON';
export const YEL_SET_NEW_ESTIMATED_INCOME_DATE = 'YEL/SET_NEW_ESTIMATED_INCOME_DATE';
export const YEL_SET_INSURANCE_INFO = 'YEL/SET_INSURANCE_INFO';
export const YEL_SET_HAS_YEL = 'YEL/SET_HAS_YEL';
export const YEL_SET_IS_STARTING = 'YEL/SET_IS_STARTING';
export const YEL_SET_NUMBER_OF_QUOTAS = 'YEL/SET_NUMBER_OF_QUOTAS';
export const YEL_SET_WHO_PAYS = 'YEL/SET_WHO_PAYS';
export const YEL_SET_STATE = 'YEL/SET_STATE';
export const YEL_JUST_AUTHORIZED = 'YEL/JUST_AUTHORIZED';
export const YEL_JUST_TERMINATED = 'YEL/JUST_TERMINATED';
export const YEL_JUST_UPDATED = 'YEL/JUST_UPDATED';
export const YEL_JUST_WITHDREW = 'YEL/JUST_WITHDREW';

// COWORKER
export const SELECTED_COWORKER = 'SELECTED_COWORKER';
export const DELETE_SELECTED_COWORKER = 'DELETE_SELECTED_COWORKER';

// EezyPay
export const SALES_PAGE_SEARCH_QUERY = 'SALES_PAGE_SEARCH_QUERY';
export const TRANSACTIONS_SEARCH_QUERY = 'TRANSACTIONS_SEARCH_QUERY';
export const SUBSCRIPTION_END_DATE = 'SUBSCRIPTION_END_DATE';
export const EEZYPAY_USER_DATA_LOADING = 'EEZYPAY_USER_DATA_LOADING';
export const EEZYPAY_USER_DATA_LOADING_COMPLETE = 'EEZYPAY_USER_DATA_LOADING_COMPLETE';
export const EEZYPAY_USER_DATA_LOADING_ERROR = 'EEZYPAY_USER_DATA_LOADING_ERROR';
