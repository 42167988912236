import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import {
    COLOR_BLUM,
    COLOR_IMPORTANT,
    COLOR_WHITE_WALKER
} from '../../styles/variables';

const WrapperLabel = styled.label`
    cursor: pointer;
    background-color: ${props =>
        props.color ? props.color : COLOR_WHITE_WALKER};
    border: 1px solid ${COLOR_BLUM};
    border-color: ${props => (props.color ? props.color : COLOR_BLUM)};
    border-radius: 4px;
    color: ${props => (props.color ? COLOR_WHITE_WALKER : COLOR_BLUM)};
    font-size: 14px;
    font-weight: 600;
    height: 30px;
    letter-spacing: -0.5px;
    line-height: 28px;
    min-width: 110px;
    position: relative;
    text-align: center;
    text-transform: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;

    &:focus,
    &:focus-within {
        box-shadow: 0 0 5px 0 ${COLOR_IMPORTANT};
    }

    &&.disabled {
        cursor: default;
        opacity: 0.3;
    }

    && svg {
        font-size: 15px;
        height: 15px;
        margin-right: 10px;
        width: 15px;
    }

    input {
        height: 0px;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
    }
`;

interface IFileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    accept: string;
    children: ReactNode;
    disabled?: boolean;
    color?: string;
    style?: CSSProperties;
}

export const FileInput = (props: IFileInputProps) => {
    const { children, ...restProps } = props;
    return (
        <WrapperLabel
            color={props.color}
            style={props.style}
            className={props.disabled ? 'disabled' : ''}
        >
            <input {...restProps} type="file" accept={props.accept} />
            {props.children}
        </WrapperLabel>
    );
};

export default FileInput;
