import { getError, IClientError } from '../utils/error';
import {
    AUTH_ACTION_BEGIN,
    AUTH_ACTION_FAILURE,
    AUTH_FAILURE,
    AUTH_FAILURE_HIDE,
    HIDE_MODAL,
    LOGIN_SHOW,
    LOGIN_SUCCESS,
    PASSWORD_REQUESTED,
    PASSWORD_REQUESTED_FAILURE,
    RECENTLY_IDENTIFIED,
    VERIFICATION_CODE_REQUIRED,
    SHOW_MODALS,
} from './../actionTypes';

export interface IAuth {
    error: IClientError | null;
    justLoggedIn: boolean;
    loading: boolean;
    loggedIn: boolean | null;
    passwordRequested: boolean | null;
    recentlyIdentified: boolean;
    visibleModals: string[];
    verificationCodeRequired: boolean;
}

const initialState: IAuth = {
    error: null,
    justLoggedIn: false,
    loading: false,
    loggedIn: true,
    passwordRequested: null,
    recentlyIdentified: false,
    visibleModals: [],
    verificationCodeRequired: false,
};

// eslint-disable-next-line
export default (state = initialState, action: any) => {
    switch (action.type) {
        case AUTH_FAILURE:
            return {
                ...state,
                error: getError(action.payload),
                loading: false,
            };
        case AUTH_FAILURE_HIDE:
            return {
                ...state,
                error: null,
                passwordRequested: false,
            };
        case VERIFICATION_CODE_REQUIRED:
            return {
                ...state,
                verificationCodeRequired: true,
            };
        case LOGIN_SHOW:
            return {
                ...state,
                error: null,
                loading: false,
                loggedIn: false,
            };
        case AUTH_ACTION_BEGIN:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                error: null,
                justLoggedIn: true,
                loading: false,
                loggedIn: true,
                verificationCodeRequired: false,
            };
        case AUTH_ACTION_FAILURE:
            return {
                ...state,
                error: getError(action.payload),
                loading: false,
                loggedIn: false,
            };
        case SHOW_MODALS:
            return {
                ...state,
                visibleModals: action.payload,
            };
        case HIDE_MODAL:
            return {
                ...state,
                visibleModals: state.visibleModals.filter((t) => t !== action.payload),
            };
        case PASSWORD_REQUESTED: {
            return {
                ...state,
                error: null,
                loading: false,
                loggedIn: false,
                passwordRequested: true,
            };
        }
        case PASSWORD_REQUESTED_FAILURE:
            return {
                ...state,
                passwordRequested: false,
            };
        case RECENTLY_IDENTIFIED:
            return {
                ...state,
                recentlyIdentified: true,
            };
        default:
            return state;
    }
};
