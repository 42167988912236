import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ThunkDispatch } from 'redux-thunk';
import { hideFileError } from '../../actions/file';
import { IClientError } from '../../utils/error';

/*
    Component that handles the file upload error logic:
    toasts the error and hides it.
*/

interface IProps {
    hideError: () => void;
    uploadError: IClientError;
}

const FileUploadError = (props: IProps) => {
    React.useEffect(() => {
        if (props.uploadError) {
            toast.error(props.uploadError.title, {
                toastId: 'fileUploadError'
            });
            props.hideError();
        }
    }, [props]);

    return null;
};

const mapStateToProps = (state: any) => {
    return {
        uploadError: state.file.error
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        hideError: () => {
            dispatch(hideFileError());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadError);
