import React from 'react';
import { Link } from 'react-router-dom';
import { EezyButton } from '../../components/Buttons';
import { Icon } from '../../components/Icon';
import { Line2 } from '../../components/Lines';
import { Tools, ToolsHeader } from '../../components/TogglingTools';
import { COLOR_BLUM, COLOR_GREYJOY } from '../../styles/variables';
import { trans } from '../../utils';
import { formatUserId, submitVisitorVariable } from '../../utils/giosg';
import { isAnkkaId } from '../../utils/invoice/invoiceLogic';

interface IProps {
    invoiceId?: number;
    recruitmentCode: string;
    userId: number;
}

interface IVisitorsVariables {
    invoice?: string;
    recruitmentCode: string;
    userId: string;
}

export const ChatTools = (props: IProps) => {
    const submitChatInfo = () => {
        const visitorVariable: IVisitorsVariables = {
            recruitmentCode: props.recruitmentCode,
            userId: formatUserId(props.userId)
        };
        if (props.invoiceId && !isAnkkaId(props.invoiceId)) {
            visitorVariable.invoice = props.invoiceId
                ? `https://eezy-1.aavaohjelmistot.fi/aava/?#sales_orders/${props.invoiceId}`
                : 'new';
        }
        submitVisitorVariable(visitorVariable);
    };

    return (
        <>
            <Line2
                color={COLOR_GREYJOY}
                style={{ margin: '25px 0 15px', opacity: 0.4 }}
            />
            <Tools
                header={
                    <ToolsHeader titleTransKey={'menu.customerSupport'}>
                        <EezyButton
                            color='purple'
                            hasIcon
                            style={{ backgroundColor: 'transparent' }}
                        >
                            <Link
                                to="/support/new-message"
                                state={ (props.invoiceId && !isAnkkaId(props.invoiceId)) ?
                                    {
                                        relatedObject: {
                                            id: props.invoiceId,
                                            type: 'invoice'
                                        }
                                    } : {}
                                }
                                className="unstyled"
                            >
                                <Icon
                                    icon={['far', 'envelope']}
                                    color={COLOR_BLUM}
                                />
                                {trans('invoice.manual.new-message')}
                            </Link>
                        </EezyButton>
                    </ToolsHeader>
                }
            />
            {/* CHAT OPEN - shown only when Giosg chat open */}
            <Tools
                className='chat-open'
                header={
                    <ToolsHeader
                        titleTransKey={'invoice.manual.chat-open-title'}
                    >
                        <EezyButton
                            className='open-chat'
                            color='purple'
                            hasIcon
                            onClick={submitChatInfo}
                            style={{ backgroundColor: 'transparent' }}
                        >
                            <Icon
                                icon={['far', 'comment-dots']}
                                color={COLOR_BLUM}
                            />
                            {trans('invoice.manual.open-chat')}
                        </EezyButton>
                    </ToolsHeader>
                }
                style={{ display: 'none' }}
            />
            {/* CHAT CLOSED - shown only when Giosg chat closed */}
            <Tools
                className='chat-closed'
                header={
                    <ToolsHeader
                        disabled
                        titleTransKey={'invoice.manual.chat-closed-title'}
                    >
                        <EezyButton
                            className='open-chat'
                            disabled
                            color='purple'
                            hasIcon
                            style={{ backgroundColor: 'transparent' }}
                        >
                            <Icon
                                icon={['far', 'comment-dots']}
                                color={COLOR_BLUM}
                            />
                            {trans('invoice.manual.open-chat')}
                        </EezyButton>
                    </ToolsHeader>
                }
                style={{ display: 'none' }}
            />
        </>
    );
};
