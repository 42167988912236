import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ThunkDispatch } from 'redux-thunk';
import { ApolloError, useMutation } from '@apollo/client';
import { Hidden } from '@mui/material';
import { IAddress } from '../../../../shared/src/types/address';
import { IUserBasicData, IUserDataInput, UiLanguage } from '../../../../shared/src/types/user';
import { IStrongAuthData, strongAuth } from 'actions/auth';
import { setUserAddress } from 'actions/user';
import { EezyButton } from 'components/Buttons';
import { ManualCard } from 'components/cards';
import { Flex } from 'components/Flex';
import { FormInput, FormRow, FormSelect, SplitRow } from 'components/form';
import { FormLabel } from 'components/form/FormLabel';
import { Icon } from 'components/Icon';
import { BodyP } from 'components/textElements';
import { COLOR_BLUM, COLOR_GREY_FOG } from 'styles/variables';
import { getErrorKey } from 'utils/apolloErrors';
import { getCountriesOptions, getCountryCodeOptions } from 'utils/profile/contact';
import { contactValidator } from 'utils/profile/validators';
import { sortObjectsByLabel } from 'utils/str';
import { EMAIL_REGEX, formatValidationResult } from 'utils/validation';
import { UPDATE_USER } from '../eezypay/queries';
import { UPDATE_USER_DATA } from './queries';
import API from 'utils/API';
import {
    didEmailVerificationFailed,
    RenderEmailValidationMessage,
    VERIFY_EMAIL_TIMEOUT_MS,
} from '../login/Register';
import { useQueryClient } from '@tanstack/react-query';

interface IProps {
    id: number;
    defaultOpen?: boolean;
    firstName: string;
    isIdentified: boolean;
    language: UiLanguage;
    lastName: string;
    userData?: IUserBasicData;
    setUserAddress: (address: IAddress) => void;
    strongAuth: (data: IStrongAuthData) => void;
    refetchUserData: () => void;
}

const INITIAL_USER: IUserBasicData = {
    address: {},
    bankAccountNumber: '',
    email: '',
    finnishCitizen: true,
    id: 0,
    longTermStorageConsent: false,
    notifyWhenInvoicePaid: false,
    phone: '',
    phoneCountryCode: 358,
    salaryPaymentRuleId: 0,
    servicePercentage: '',
    yelInsured: false,
};

const ProfileContact = (props: IProps) => {
    const queryClient = useQueryClient();
    const [formData, setFormData] = useState<IUserBasicData>(INITIAL_USER);
    const [closeF, setCloseF] = useState<any>(null);
    const [editEmailMode, setEditEmailMode] = useState(false);

    const [email1, setEmail1] = useState('');
    const [email2, setEmail2] = useState('');
    const [errors, setErrors] = useState<any>(null);
    const [emailVerifyError, setEmailVerifyError] = useState<string>();

    const { t } = useTranslation();

    const validateEmail = async (val: string) => {
        if (EMAIL_REGEX.test(val)) {
            try {
                // @ts-ignore
                const result = await API.get('/auth/verify-email', {
                    params: { email: val },
                    timeout: VERIFY_EMAIL_TIMEOUT_MS,
                });
                const status = result?.data?.status;
                let validationFailedStatus;
                if (didEmailVerificationFailed(status)) {
                    validationFailedStatus = result.data.status;
                }
                if (validationFailedStatus) {
                    setEmailVerifyError(validationFailedStatus);
                    setErrors({
                        ...errors,
                        email: {
                            path: ['email'],
                            type: validationFailedStatus,
                        },
                    });
                } else {
                    setErrors({ ...errors, email: undefined });
                    setEmailVerifyError(undefined);
                }
            } catch (error) {
                setErrors({ ...errors, email: undefined });
                setEmailVerifyError(undefined);
            }
        } else {
            setErrors({
                ...errors,
                email: {
                    path: ['email'],
                    type: 'string.pattern.base',
                },
            });
        }
    };

    // Local version of update is needed as we want to close the card only if the mutation
    // is completed, and there are currently no other ways to call onCompleted
    const [updateUserData, { loading }] = useMutation(UPDATE_USER_DATA, {
        onCompleted: () => {
            props.setUserAddress(formData.address);
            if (closeF) {
                closeF();
            }
            toast(t('general.saved'));
            handleReset();
            props.refetchUserData();
        },
        onError: (e: ApolloError) => {
            if ((getErrorKey(e) || '').includes('email_already_exists')) {
                toast.error(t('profile:contact.email.error'));
            } else {
                toast.error(t('errors.general'));
            }
        },
    });

    const [updateUserInHasura] = useMutation(UPDATE_USER, {
        context: { clientName: 'eezyPayHasura' },
    });

    const mapPropsToForm = (userData: any) => {
        const { __typename, address, ...data } = userData;
        const { __typename: typename2, ...addressData } = address;
        return {
            ...INITIAL_USER,
            ...data,
            address: { ...addressData, country: 'FI' },
        };
    };

    useEffect(() => {
        if (props.userData) {
            setFormData(mapPropsToForm(props.userData));
        }
    }, [props.userData]);

    const handleAuthentication = () => {
        props.strongAuth({ action: 'profile', lang: props.language });
    };

    const handleSave = (callback: () => void) => {
        // callback-fn (that closes the card) is available here but needs to be called in
        // onCompleted of the mutation, thus the hack
        setCloseF(() => () => callback());
        let validationErrors = formatValidationResult(
            contactValidator.validate({ ...formData, email1, email2 }),
        );
        if (!validationErrors?.email && emailVerifyError) {
            validationErrors = {
                ...validationErrors,
                email: {
                    // @ts-ignore //TODO: check is it working!
                    path: ['email'],
                    type: emailVerifyError,
                },
            };
        }
        if (validationErrors) {
            toast.error(t('profile:contact.error'));
            setErrors(validationErrors);
        } else {
            const data: IUserDataInput = {
                address: formData.address,
                homeCountry: formData.homeCountry,
                email: email1 && email1 === email2 ? email1 : formData.email,
                finnishCitizen: formData.homeCountry === 'FI',
                phone: formData.phone,
                phoneCountryCode: formData.phoneCountryCode,
            };
            setErrors(null);
            updateUserData({ variables: data });
            updateUserInHasura({
                variables: {
                    id: props.id,
                    email: email1 && email1 === email2 ? email1 : formData.email,
                    phone: `+${formData.phoneCountryCode} ${formData.phone}`,
                },
            });
        }
        // The user might be missing an address, which results an error, thus we want to invalidate the query
        // if the user updates the
        queryClient.invalidateQueries({ queryKey: ['yelData'] });
    };

    const handleReset = () => {
        setEmail1('');
        setEmail2('');
        setEditEmailMode(false);
        setErrors(null);
        if (props.userData) {
            setFormData(mapPropsToForm(props.userData));
        }
    };

    const handleChange = (val: string, name: string) => {
        const newData = { ...formData, [name]: val };
        setFormData(newData);
    };

    const handleAddressChange = (val: string, name: string) => {
        const newData = {
            ...formData,
            address: { ...formData.address, [name]: val },
        };
        setFormData(newData);
    };

    const handleEmailChange = (val: string, name: string) => {
        if (name === 'email1') {
            setEmail1(val);
        } else if (name === 'email2') {
            setEmail2(val);
        }
    };

    const handleCountryCodeChange = (val: string) => {
        setFormData({ ...formData, phoneCountryCode: parseInt(val, 10) });
    };

    return (
        <ManualCard
            defaultOpen={props.defaultOpen}
            editableContent={
                <Flex column>
                    {/* Name */}
                    <SplitRow>
                        <FormInput
                            disabled
                            label={t('profile:contact.firstName') || ''}
                            name="firstName"
                            onBlur={handleChange}
                            style={{
                                borderBottom: `1px solid ${COLOR_GREY_FOG}`,
                                flexGrow: 1,
                                maxWidth: 355,
                            }}
                            value={props.firstName}
                        />
                        <EezyButton
                            color={props.isIdentified ? 'green' : 'purple'}
                            hasIcon={props.isIdentified}
                            disabled={props.isIdentified}
                            dark
                            onClick={handleAuthentication}
                            style={{ opacity: 1 }}
                            width={120}
                        >
                            {props.isIdentified ? (
                                <>
                                    <Icon icon={['far', 'check']} color="white" />{' '}
                                    {t('profile:contact.authenticated')}
                                </>
                            ) : (
                                t('profile:contact.authenticate')
                            )}
                        </EezyButton>
                    </SplitRow>
                    <FormInput
                        disabled
                        label={t('profile:contact.lastName') || ''}
                        name="lastName"
                        onBlur={handleChange}
                        style={{
                            borderBottom: `1px solid ${COLOR_GREY_FOG}`,
                            maxWidth: 355,
                        }}
                        value={props.lastName}
                    />

                    <FormRow style={{ maxWidth: 355 }}>
                        <FormSelect
                            error={errors && errors['homeCountry']}
                            label={t('profile:contact.address.homecountry') || ''}
                            showIcon
                            name="homeCountry"
                            onChange={handleChange}
                            options={sortObjectsByLabel(getCountriesOptions())}
                            required
                            style={{
                                borderColor: COLOR_BLUM,
                                color: COLOR_BLUM,
                            }}
                            value={formData.homeCountry}
                        />
                    </FormRow>

                    {/* Email */}
                    <SplitRow>
                        <FormInput
                            disabled
                            label={t('profile:contact.email.label') || ''}
                            name="email"
                            onBlur={handleChange}
                            style={{
                                borderBottom: `1px solid ${COLOR_GREY_FOG}`,
                                flexGrow: 1,
                                maxWidth: 355,
                            }}
                            type="email"
                            value={formData.email}
                        />
                        {!editEmailMode && (
                            <EezyButton
                                color="purple"
                                dark
                                onClick={() => setEditEmailMode(true)}
                                width={120}
                            >
                                {t('profile:contact.email.change')}
                            </EezyButton>
                        )}
                    </SplitRow>
                    {editEmailMode && (
                        <>
                            {errors && <RenderEmailValidationMessage errors={errors} />}
                            <FormInput
                                error={errors?.email1 || errors?.email}
                                label={t('profile:contact.email.new1') || ''}
                                name="email1"
                                required
                                onChange={handleEmailChange}
                                style={{ maxWidth: 355 }}
                                type="email"
                                value={email1}
                                onBlur={validateEmail}
                            />
                            <FormInput
                                error={errors?.email2 || (email2 && email2 !== email1)}
                                label={t('profile:contact.email.new2') || ''}
                                name="email2"
                                required
                                onChange={handleEmailChange}
                                style={{ maxWidth: 355 }}
                                type="email"
                                value={email2}
                            />
                        </>
                    )}

                    {/* Phone */}
                    <FormRow>
                        <FormLabel error={errors?.phoneCountryCode || errors?.phone} value={formData.phone}>
                            {t('profile:contact.phone')}
                        </FormLabel>
                        <SplitRow style={{ maxWidth: 355 }}>
                            <FormSelect
                                error={errors?.phoneCountryCode}
                                getOptionLabel={(opt) => `+${opt.value}`}
                                name="phoneCountryCode"
                                onChange={handleCountryCodeChange}
                                options={getCountryCodeOptions()}
                                required
                                showIcon
                                style={{ maxWidth: 100 }}
                                value={(
                                    formData.phoneCountryCode || INITIAL_USER.phoneCountryCode
                                ).toString()}
                            />
                            <FormInput
                                error={errors?.phone}
                                name="phone"
                                required
                                onChange={handleChange}
                                value={formData.phone}
                            />
                        </SplitRow>
                    </FormRow>

                    {/* Address */}
                    <FormInput
                        error={errors && errors['address.street1']}
                        label={
                            formData.finnishCitizen
                                ? t('profile:contact.address.street1') || ''
                                : t('profile:contact.address.fistreet1') || ''
                        }
                        name="street1"
                        onBlur={handleAddressChange}
                        required
                        style={{ marginTop: 30, maxWidth: 355 }}
                        value={formData.address?.street1}
                    />

                    <SplitRow style={{ maxWidth: 355 }}>
                        <FormInput
                            error={errors && errors['address.zipCode']}
                            label={t(`profile:contact.address.zipCode`) || ''}
                            name="zipCode"
                            onBlur={handleAddressChange}
                            required
                            style={{ maxWidth: 100 }}
                            value={formData.address?.zipCode}
                        />
                        <FormInput
                            error={errors && errors['address.town']}
                            label={t(`profile:contact.address.town`) || ''}
                            name="town"
                            onBlur={handleAddressChange}
                            required
                            value={formData.address?.town}
                        />
                    </SplitRow>
                </Flex>
            }
            onClose={handleReset}
            onSave={handleSave}
            route="contact"
            saveLoading={loading}
            title={t('profile:contact.title')}
            viewContent={
                <Flex column>
                    <BodyP noWrap>
                        <b data-mf-replace="**REMOVED**">
                            {props.firstName} {props.lastName}
                        </b>
                    </BodyP>
                    {props.isIdentified && (
                        <Hidden xsDown>
                            <BodyP>{t('profile:contact.isIdentified')}</BodyP>
                        </Hidden>
                    )}
                    <Hidden xsDown>
                        <BodyP style={{ marginTop: 10 }}>
                            <b data-mf-replace="**REMOVED**">
                                {props.userData?.phoneCountryCode &&
                                    '+' + props.userData?.phoneCountryCode + ' '}
                                {props.userData?.phone}
                            </b>
                        </BodyP>
                        <BodyP data-mf-replace="**REMOVED**">{props.userData?.email}</BodyP>
                        <BodyP style={{ marginTop: 10 }}>
                            <b data-mf-replace="**REMOVED**">{props.userData?.address.street1}</b>
                        </BodyP>
                        <BodyP data-mf-replace="**REMOVED**">
                            {props.userData?.address.zipCode} {props.userData?.address.town}
                        </BodyP>
                    </Hidden>

                    <Hidden smUp>
                        <BodyP data-mf-replace="**REMOVED**" noWrap style={{ marginTop: 10 }}>
                            {props.userData?.phoneCountryCode && '+' + props.userData?.phoneCountryCode + ' '}
                            {props.userData?.phone}, {props.userData?.email}
                        </BodyP>
                        <BodyP data-mf-replace="**REMOVED**" noWrap>
                            {props.userData?.address.street1}, {props.userData?.address.zipCode}{' '}
                            {props.userData?.address.town}
                        </BodyP>
                    </Hidden>
                </Flex>
            }
        />
    );
};

const mapStateToProps = (state: any) => {
    return {
        firstName: state.user.firstName,
        id: state.user.id,
        isIdentified: state.user.isIdentified,
        language: state.user.language,
        lastName: state.user.lastName,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        setUserAddress: (address: IAddress) => {
            dispatch(setUserAddress(address));
        },
        strongAuth: (data: IStrongAuthData) => {
            dispatch(strongAuth(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContact);
