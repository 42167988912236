import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { UiLanguage } from '../../../../shared/src/types/user';
import { IInvoice, IYelWithdrawal } from '../../../../shared/src/types/yel';
import { HighlightBox } from 'components/Balloon';
import { EezyButton, InlineButtonLink } from 'components/Buttons';
import { Icon } from 'components/Icon';
import { Line } from 'components/Lines';
import { LabelStrong, UniqueTitleLeft } from 'components/textElements';
import { COLOR_BLACKWATER, COLOR_BLUM, COLOR_IMPORTANT, COLOR_WHITE_GRAY } from 'styles/variables';
import { BorderBox, H3, Wrapper, WrapperRow } from 'styles/yelStyles';
import { formatCents, formatDate, formatPercentage } from 'utils';
import { yelStatus } from 'utils/yel';
import { eloDocumentUrl } from 'utils/yel/variables';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducers';
import { useSelector } from 'react-redux';
import { useYelData } from 'queries/useYelData';
import useYelStore from 'stores/yelStore';

const HighlightRow = styled(WrapperRow)`
    background-color: ${COLOR_WHITE_GRAY};
    border-radius: 4px;
    margin: 3px 0;
`;

const YelOk: FC = () => {
    const { t } = useTranslation();

    const { data } = useYelData();
    const [yelStoreData, updateYelData] = useYelStore((state) => [state.yelData, state.updateYelStore]);
    const language: UiLanguage = useSelector((state: IRootState) => state.user.language);

    const {
        accountBalance,
        discountOfficialEndDate,
        eloAccountName,
        eloAccountNumber,
        insurancePercentage,
        invoices,
    } = data.collectionInfo;

    const yelIsActive = yelStatus.isActive(data.status);
    const ownManagedInsurance = data.whoPays === 'self';

    const nextInvoice = invoices.find((invoice) => !invoice.isEstimate);

    const TopBox = () => (
        <>
            <UniqueTitleLeft>{t('yel:yel-insurance')}</UniqueTitleLeft>
            <HighlightBox>
                {yelStatus.isActivationRequested(data.status) && (
                    <>
                        {ownManagedInsurance ? (
                            <LabelStrong color={COLOR_BLACKWATER}>{t('yel:own-insurance-title')}</LabelStrong>
                        ) : yelStoreData.justAuthorized ? (
                            <LabelStrong color={COLOR_BLACKWATER}>{t('auth.thanks-order')}</LabelStrong>
                        ) : (
                            <LabelStrong color={COLOR_BLACKWATER}>{t('yel:order-pending-title')}</LabelStrong>
                        )}
                        {ownManagedInsurance ? (
                            <p>{t('yel:own-insurance-description')}</p>
                        ) : (
                            <p>{t('yel:order-pending')}</p>
                        )}
                        {yelStoreData.justAuthorized && (
                            <p>
                                <Link to="/">{t('menu.back-frontpage')}</Link>
                            </p>
                        )}
                    </>
                )}
                {yelStoreData.justTerminated && <p>{t('auth.thanks-termination')}</p>}

                {yelStoreData.justUpdated && <p>{t('auth.thanks-update')}</p>}

                {yelStoreData.justWithdrew && <p>{t('auth.thanks-withdrawal')}</p>}

                <a
                    href={
                        language === 'fi'
                            ? 'https://www.elo.fi/fi-fi/ota-yhteytta/vakuutusten-myyntipalvelu-apunasi'
                            : 'https://www.elo.fi/en/contact-us/sales-service'
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {t('yel:elo-link')}
                    <Icon
                        className="small"
                        color={COLOR_BLUM}
                        icon={['far', 'arrow-up-right-from-square']}
                        style={{ marginLeft: 10 }}
                    />
                </a>
            </HighlightBox>
        </>
    );

    if (ownManagedInsurance) {
        return <TopBox />;
    }

    return (
        <Wrapper>
            <TopBox />
            <WrapperRow>
                <LabelStrong color={COLOR_BLACKWATER}>{t('yel:collection-account-amount')}:</LabelStrong>
                <p>
                    {t('form.eurs', {
                        eurs: formatCents(accountBalance),
                    })}
                </p>
            </WrapperRow>

            {nextInvoice && (
                <WrapperRow>
                    <H3>
                        {t('invoice.invoice')} ({formatDate(nextInvoice.duedate)}):
                    </H3>
                    {nextInvoice.paidCents > 0 ? (
                        <p>
                            {t('form.eurs', {
                                eurs: formatCents(nextInvoice.paidCents + nextInvoice.notPaidCents),
                            })}
                        </p>
                    ) : (
                        <p>
                            {t('form.eurs', {
                                eurs: formatCents(nextInvoice.amountCents),
                            })}
                        </p>
                    )}
                </WrapperRow>
            )}
            {nextInvoice && nextInvoice.paidCents > 0 && (
                <WrapperRow>
                    <H3>{t('yel:paid-by-eezy')}:</H3>
                    <p>
                        {t('form.eurs', {
                            eurs: formatCents(nextInvoice.paidCents),
                        })}
                    </p>
                </WrapperRow>
            )}

            {nextInvoice && nextInvoice.paidCents === 0 && nextInvoice.notPaidCents === 0 && (
                <HighlightRow>
                    <H3>{t('yel:collection-amount-to-be-saved')}:</H3>
                    <p>
                        {t('form.eurs', {
                            eurs: formatCents(nextInvoice.amountCents - accountBalance),
                        })}
                    </p>
                </HighlightRow>
            )}

            <Line />

            {yelStatus.yelIsOn(data.status) &&
                invoices &&
                invoices.map((invoice: IInvoice, key: any) => {
                    if (!invoice.isEstimate) {
                        return null;
                    }
                    return (
                        <WrapperRow key={`${key}-${invoice.invoiceNumber}`}>
                            <H3>
                                {t('yel:next-invoice')} ({formatDate(invoice.duedate)}):
                            </H3>
                            <p>
                                {t('form.eurs', {
                                    eurs: formatCents(invoice.estimatedAmountCents),
                                })}
                            </p>
                        </WrapperRow>
                    );
                })}

            {yelStatus.yelIsOn(data.status) &&
                data.withdrawals &&
                data.withdrawals.map((w: IYelWithdrawal, key) => {
                    return (
                        <HighlightRow key={`${key}-${w.id}`}>
                            <H3>
                                {t('yel:withdrawal')} {formatDate(w.createdAt)}{' '}
                                {w.state === 'requested' && `(${t('general.waiting')})`}
                                {w.state === 'rejected' && `(${t('general.rejected')})`}
                            </H3>
                            <p>
                                {t('form.eurs', {
                                    eurs: formatCents(w.requestedAmount),
                                })}
                            </p>
                        </HighlightRow>
                    );
                })}

            {nextInvoice &&
                nextInvoice.paidCents === 0 &&
                nextInvoice.notPaidCents === 0 &&
                nextInvoice.amountCents - accountBalance > 0 && <p>{t('yel:note-sms')}</p>}

            {nextInvoice && nextInvoice.notPaidCents > 0 && (
                <BorderBox>
                    <WrapperRow>
                        <H3>{t('yel:to-be-paid')}</H3>
                        <p>
                            <b>
                                {t('form.eurs', {
                                    eurs: formatCents(nextInvoice.notPaidCents),
                                })}
                            </b>
                        </p>
                    </WrapperRow>
                    <WrapperRow>
                        <p>{t('yel:not-enough-savings')}</p>
                    </WrapperRow>
                    <Line />
                    <Wrapper>
                        <p>
                            {t('form.recipient')}: {eloAccountName}
                        </p>
                        <p>
                            {t('general.accountNumber')}: {eloAccountNumber}
                        </p>
                        <p>
                            {t('general.referenceNumber')}: {nextInvoice.referenceNumber}
                        </p>
                        <p>
                            {t('general.duedate')}: {formatDate(nextInvoice.duedate)}
                        </p>
                        <p>
                            {t('form.sum')}:{' '}
                            {t('form.eurs', {
                                eurs: formatCents(nextInvoice.notPaidCents),
                            })}
                        </p>
                    </Wrapper>
                </BorderBox>
            )}

            <WrapperRow $reverse>
                <EezyButton
                    color="purple"
                    disabled={!accountBalance}
                    onClick={() => updateYelData({ showWithdrawMoney: true })}
                >
                    {t('yel:withdraw-money')}
                </EezyButton>
            </WrapperRow>

            {yelStatus.yelIsOn(data.status) && data.insuranceInfo && data.collectionInfo && (
                <BorderBox>
                    <WrapperRow>
                        <H3>{t('yel:insurance-data')}:</H3>
                    </WrapperRow>
                    <Wrapper>
                        <p>{t('yel:insurance-company')}: Elo</p>
                        <p>
                            {t('yel:insurance-number')}: {data.insuranceInfo.insuranceId}
                        </p>
                        {data.insuranceInfo.startDate && (
                            <p>
                                {t('yel:insurance-startdate')}: {formatDate(data.insuranceInfo.startDate)}
                            </p>
                        )}
                        {data.insuranceInfo.terminationDate && yelStatus.hasTerminatedYEL(data.status) && (
                            <p>
                                {t('yel:insurance-terminationdate')}:{' '}
                                {formatDate(data.insuranceInfo.terminationDate)}
                            </p>
                        )}

                        <p>
                            {t('yel:income')}:{' '}
                            {t('form.eurs', {
                                eurs: formatCents(data.estimatedIncome),
                            })}
                        </p>

                        <p>
                            {t('yel:term.long')}:{' '}
                            {t('form.percent', {
                                percentage: formatPercentage(insurancePercentage),
                            })}
                        </p>

                        {discountOfficialEndDate && (
                            <p style={{ marginTop: '20px' }}>
                                {t('yel:discount-valid-until', {
                                    date: formatDate(discountOfficialEndDate),
                                })}
                            </p>
                        )}

                        <a href={eloDocumentUrl(language)} rel="noopener noreferrer" target="_blank">
                            {t('yel:elo-document-anchor')}
                        </a>
                        <Line />
                        <p style={{ textAlign: 'end' }}>
                            <Link to="/yel/edit">
                                <Icon icon={['fal', 'pen']} />
                                {t('yel:change-income')}
                            </Link>
                        </p>
                    </Wrapper>
                </BorderBox>
            )}

            {!yelStatus.hasTerminatedYEL(data.status) && (
                <WrapperRow $reverse>
                    <InlineButtonLink
                        color={COLOR_IMPORTANT}
                        disabled={!yelIsActive}
                        onClick={() => updateYelData({ showTerminate: true })}
                    >
                        {t('yel:terminate-insurance-and-collection')}
                    </InlineButtonLink>
                </WrapperRow>
            )}
        </Wrapper>
    );
};

export default YelOk;
