import React, { ReactNode } from 'react';
import { LabelValueType } from 'containers/eezypay/types/SalesPageStatisticTypes';
import { useTranslation } from 'react-i18next';
import TabHead from './TabHead';

interface ITabHeaders {
    items: LabelValueType<any>[];
    activeValue: any;
    extraNode?: ReactNode;
    onChange?: (value: any) => void;
}

const TabHeaders = (props: ITabHeaders) => {
    const { t } = useTranslation();
    return (
        <div
            className="border-solid flex flex-col md:flex-row justify-between gap-8"
            style={{ borderColor: '#E4E4E7' }}
        >
            <div className="flex gap-7 items-start">
                {props.items.map((i) => (
                    <TabHead
                        key={i.id ?? i.value}
                        active={props.activeValue == i.value}
                        onClick={props.onChange && (() => props.onChange!(i.value))}
                    >
                        <span>{t(i.label)}</span>
                    </TabHead>
                ))}
            </div>
            {props.extraNode}
        </div>
    );
};

export default TabHeaders;
