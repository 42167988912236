import { useQuery } from '@apollo/client';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import Drawer from '../containers/nav/Drawer';
import { GET_CONTENT } from '../containers/support/queries';
import { Box } from '@mui/material';
import Support from 'containers/support/Support';

type ContextType = { customerServiceOpen: boolean };

export const SupportWrapper = () => {
    const { data: contentData } = useQuery(GET_CONTENT, {
        variables: { identifier: 'openingHours', lang: 'fi' },
    });

    const [customerServiceOpen, setCustomerServiceOpen] = useState(false);

    useEffect(() => {
        if (!contentData?.content) {
            return;
        }
        const content = JSON.parse(contentData.content);
        const weekday = new Date().getDay();
        const now = moment();
        // First try to find if there finds hours for specific date, if not, find by weekday
        let currentDay = content.fields.days.find((d: any) => {
            const date = moment(d.fields.start);
            return date.isSame(now, 'd');
        });
        if (!currentDay) {
            currentDay = content.fields.days.find((d: any) => {
                return d.fields.weekday === weekday;
            });
        }
        if (!currentDay) {
            return;
        }
        const start = currentDay.fields.start.slice(-5);
        const end = currentDay.fields.end.slice(-5);
        const timeFormat = 'hh:mm';

        const startTime = moment(start, timeFormat);
        const endTime = moment(end, timeFormat);

        setCustomerServiceOpen(now.isBetween(startTime, endTime));
    }, [contentData]);

    return (
        <Drawer>
            <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                <Outlet context={{ customerServiceOpen }} />
            </Box>
            <Box sx={{ display: { md: 'block', xs: 'none' } }}>
                <Support customerServiceOpen={customerServiceOpen}>
                    <Outlet context={{ customerServiceOpen }} />
                </Support>
            </Box>
        </Drawer>
    );
};

export const useCustomerServiceOpen = () => {
    return useOutletContext<ContextType>();
};
