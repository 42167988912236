import { createApolloClient } from 'utils/ApolloClient';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IRootState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';
import { IWithholdingTax, UiLanguage } from '../../../../shared/src/types/user';
import { fetchWithholdingTaxSuccess } from 'actions/user';
import { EezyButton } from 'components/Buttons';
import { Flex } from 'components/Flex';
import { Icon } from 'components/Icon';
import { Toast } from 'components/Toast';
import {
    COLOR_BLUM,
    COLOR_IMPORTANT,
    COLOR_STATUS_DONE,
    COLOR_WHITE_WALKER,
    SCREEN_S
} from 'styles/variables';
import { isMobile } from 'utils';
import GET_WITHHOLDING_TAX from 'containers/profile/queries/getWithholdingTax';

const ToastMsg = styled.div`
    @media (max-width: ${SCREEN_S}px) {
        flex-direction: column;
    }
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

const timeoutToAvoidFlickering = 2000;
const WITHHOLDING_TAX_STATUS_TOAST_CONTAINER_ID = 'withholding-tax-status-toast';

let taxApiMessage: string;
let isDataError: boolean = false;
let isTechError: boolean = false;

const WithholdingTax: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();
    const { t } = useTranslation();

    const isIdentified: boolean = useSelector((state: IRootState) => state.user.isIdentified);
    const isLoggedIn: boolean | null = useSelector((state: IRootState) => state.auth.loggedIn);
    const justLoggedIn: boolean = useSelector((state: IRootState) => state.auth.justLoggedIn);
    const language: UiLanguage = useSelector((state: IRootState) => state.user.language);
    const recentlyIdentified: boolean = useSelector((state: IRootState) => state.auth.recentlyIdentified);
    const withholdingTax: IWithholdingTax | undefined = useSelector(
        (state: IRootState) => state.user.withholdingTax
    );

    useEffect(() => {
        if (isLoggedIn && isIdentified && (justLoggedIn || recentlyIdentified)) {
            createApolloClient(dispatch)
                .query({
                    query: GET_WITHHOLDING_TAX
                })
                .then((res: any) => {
                    return (
                        res?.data?.userWithholdingTax &&
                        dispatch(fetchWithholdingTaxSuccess(res.data.userWithholdingTax))
                    );
                });
        }
    }, [isLoggedIn, isIdentified, justLoggedIn, recentlyIdentified]);

    useEffect(() => {
        const { status, addPercentage, basicPercentageLimit, basicPercentage, text, startDate, error } =
            withholdingTax || {};

        if (
            status === 'ok' &&
            (addPercentage || basicPercentageLimit || basicPercentage || startDate) &&
            text !== 'no_change'
        ) {
            taxApiMessage = t('withholdingTax.successMessage', { percentage: basicPercentage });
            isTechError = false;
            isDataError = false;
        } else if (
            status === 'error' &&
            (error?.reason === 'tax_card_save_failed' ||
                error?.reason === 'api_call_failed' ||
                error?.reason === 'bad_request' ||
                error?.reason === 'request_recently_sent')
        ) {
            taxApiMessage = t('withholdingTax.technicalErrorMessage', { percentage: basicPercentage });
            isTechError = true;
        } else if (status === 'error' && error?.reason === 'no_data') {
            taxApiMessage = t('withholdingTax.noDataErrorMessage', { percentage: basicPercentage });
            isDataError = true;
        }

        if (status && text !== 'no_change' && taxApiMessage) {
            setTimeout(() => {
                toast(
                    <ToastMsg>
                        <Flex style={{ margin: '10px 0' }}>
                            <Icon
                                color={isTechError || isDataError ? COLOR_IMPORTANT : COLOR_STATUS_DONE}
                                icon={
                                    isTechError || isDataError
                                        ? ['fas', 'triangle-exclamation']
                                        : ['far', 'rotate']
                                }
                                style={{ margin: 'auto 14px auto 0' }}
                            />
                            {taxApiMessage}
                        </Flex>
                        {!isDataError && !isTechError && (
                            <Flex style={{ margin: 10 }}>
                                <EezyButton color="purple" onClick={() => undefined}>
                                    {t('menu.close')}
                                </EezyButton>
                                <EezyButton
                                    onClick={() => {
                                        navigate('/salaries');
                                    }}
                                    color="important"
                                    style={{
                                        backgroundColor: COLOR_BLUM,
                                        color: COLOR_WHITE_WALKER
                                    }}
                                >
                                    <Flex
                                        style={{
                                            marginLeft: 22,
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {t('general.show')}
                                        <Icon
                                            color={COLOR_WHITE_WALKER}
                                            icon={['far', 'arrow-right']}
                                            style={{ marginLeft: 15, marginRight: 5 }}
                                        />
                                    </Flex>
                                </EezyButton>
                            </Flex>
                        )}
                        {isDataError && (
                            <a
                                href={
                                    language === 'en'
                                        ? 'https://www.vero.fi/en/e-file/mytax/'
                                        : 'https://www.vero.fi/sahkoiset-asiointipalvelut/omavero/'
                                }
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <EezyButton
                                    color="important"
                                    style={{
                                        backgroundColor: COLOR_BLUM,
                                        color: COLOR_WHITE_WALKER,
                                        margin: '0 10px 10px 10px'
                                    }}
                                >
                                    {t('withholdingTax.noDataErrorButton')}
                                </EezyButton>
                            </a>
                        )}
                    </ToastMsg>,
                    {
                        autoClose: isTechError ? 6000 : false,
                        closeOnClick: true,
                        containerId: WITHHOLDING_TAX_STATUS_TOAST_CONTAINER_ID,
                        position: isMobile() ? 'bottom-center' : 'top-center'
                    }
                );
            }, timeoutToAvoidFlickering);
        }
    }, [withholdingTax, language]);

    return (
        <>
            <Toast
                style={{ width: isMobile() ? '96%' : 641, margin: isMobile() ? '0 2%' : '' }}
                containerId={WITHHOLDING_TAX_STATUS_TOAST_CONTAINER_ID}
                isLarge
                position={isMobile() ? 'bottom-center' : 'top-center'}
                hideIcon
            />
        </>
    );
};

export default WithholdingTax;
