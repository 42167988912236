import { useMutation } from '@apollo/client';
import React from 'react';
import { toast } from 'react-toastify';
import { IBriefInvoice, IInvoice } from '../../../../shared/src/types/invoice';
import { EezyButton } from '../../components/Buttons';
import ColorBox from '../../components/ColorBox';
import { HelpLabel } from '../../components/form';
import LoadingSpinner from '../../components/Loading';
import { P, RowTitle } from '../../components/textElements';
import { COLOR_BLACKWATER, COLOR_LILA_MEDIUM } from '../../styles/variables';
import { trans } from '../../utils';
import { showCollectionInfoBox } from '../../utils/invoice/invoiceLogic';
import { REQUEST_COLLECTION } from './queries';

interface IProps {
    briefInvoice?: IBriefInvoice;
    invoice?: IInvoice;
}

export const CollectionInfo = (props: IProps) => {
    const [requestCollection, { loading }] = useMutation(REQUEST_COLLECTION, {
        onCompleted: () => toast(trans('general.saved')),
        onError: () => toast.error(trans('errors.general')),
        variables: { invoiceId: props.invoice?.id }
    });

    const handleRequestCollection = () => {
        requestCollection();
    };

    if (
        !props.invoice ||
        !showCollectionInfoBox(props.invoice, props.briefInvoice)
    ) {
        return null;
    }

    /*
        If debt collection has been requested through phone or email,
        collectionRequested is null, but briefInvoice has inDebtCollection: true.
    */
    const inDebtCollection =
        props.invoice?.collectionRequested ||
        props.briefInvoice?.inDebtCollection;

    return (
        <ColorBox
            color={COLOR_LILA_MEDIUM}
            disableClose
            style={{ marginBottom: 30, padding: '45px 30px' }}
        >
            <RowTitle color={COLOR_BLACKWATER}>
                {trans(
                    `invoice.collection.${
                        inDebtCollection ? 'on' : 'off'
                    }.title`
                )}
            </RowTitle>
            {inDebtCollection ? (
                <P color="white">{trans('invoice.collection.on.1')}</P>
            ) : (
                <>
                    <P color="white">{trans('invoice.collection.off.1')}</P>
                    <P color="white">{trans('invoice.collection.off.2')}</P>
                    <P color="white">{trans('invoice.collection.off.3')}</P>
                </>
            )}

            <div style={{ marginTop: 30 }}>
                <HelpLabel
                    dark={true}
                    helpTexts={[
                        'invoice.debtCollection.help1',
                        'invoice.debtCollection.help2',
                        'invoice.debtCollection.help3',
                        'invoice.debtCollection.help4',
                        'invoice.debtCollection.help5',
                        'invoice.debtCollection.help6',
                        'invoice.debtCollection.help7',
                        'invoice.debtCollection.help8',
                        'invoice.debtCollection.help9',
                        'invoice.debtCollection.help10'
                    ]}
                    labelComponent={
                        <EezyButton
                            color="purple"
                            disabled={inDebtCollection}
                            onClick={handleRequestCollection}
                            transparent
                        >
                            {loading ? (
                                <LoadingSpinner size="1em" />
                            ) : (
                                trans(
                                    `invoice.collection.${
                                        inDebtCollection ? 'on' : 'off'
                                    }.button`
                                )
                            )}
                        </EezyButton>
                    }
                />
            </div>
        </ColorBox>
    );
};
