import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ClientType, IYtjBriefCompany, LanguageCode } from '../../../../shared/src/types/invoice';
import { PSmall } from 'components/textElements';
import { COLOR_BLACKWATER, COLOR_DARK_GRAY, COLOR_GREYHOUND } from 'styles/variables';
import { useTranslation } from 'react-i18next';

const RecipientOption = styled.div`
    width: 100%;
    div {
        p {
            color: ${COLOR_BLACKWATER};
            font-size: 14px;
            font-weight: 600;
            letter-spacing: -0.5px;
        }
        & > span {
            color: ${COLOR_GREYHOUND};
            font-size: 13px;
        }
        span.highlight {
            background-color: rgba(223, 25, 149, 0.25);
        }
    }
`;

const StyledPSmall = styled(PSmall)`
    color: ${COLOR_DARK_GRAY};
    font-size: 15px;
`;

export interface IRecipientOption {
    active?: boolean;
    businessId: string;
    country?: string;
    einvoiceOvt?: string;
    operator?: string;
    email: string;
    firstName?: string;
    id?: string;
    invoiceLanguage?: LanguageCode;
    invoicingMethod?: string;
    lastName?: string;
    name: string;
    street1?: string;
    street2?: string;
    town?: string;
    type?: ClientType;
    zipCode?: string;
}

type CompanyOptionProps = {
    inputValue: string;
    companyName: string;
    businessId: string;
    isYtj: boolean;
}

const CompanyOption: FC<CompanyOptionProps> = ({
    inputValue,
    companyName,
    businessId,
    isYtj
}) => {
    const companyMatches = match(companyName, inputValue);
    const bIdMatches = match(businessId, inputValue);
    const companyParts = parse(companyName, companyMatches);
    const businessIdParts = parse(businessId, bIdMatches);

    const { t } = useTranslation();

    return (
        <div>
            <StyledPSmall>
                {companyParts.map((part, index) => (
                    <span
                        key={`company-${index}`}
                        className={part.highlight ? 'highlight' : ''}
                    >
                        {part.text}
                    </span>
                ))}
            </StyledPSmall>
            {businessIdParts.map((part, index) => (
                <span
                    key={`businessId-${index}`}
                    className={part.highlight ? 'highlight' : ''}
                >
                    {part.text}
                    {isYtj && '| ' + t('invoice.ytj-title')}
                </span>
            ))}
        </div>
    );
};

const PersonOption = ({
    inputValue,
    name,
    email
}: {
    inputValue: string;
    name: string;
    email: string;
}) => {
    const nameMatches = match(name, inputValue);
    const emailMatches = email && inputValue ? match(email, inputValue) : [];

    const nameParts = parse(name, nameMatches);
    const emailParts = parse(email, emailMatches);

    return (
        <div>
            <StyledPSmall>
                {nameParts.map((part, index) => (
                    <span
                        key={`name-${index}`}
                        className={part.highlight ? 'highlight' : ''}
                    >
                        {part.text}
                    </span>
                ))}
            </StyledPSmall>
            {emailParts.map((part, index) => (
                <span
                    key={`email-${index}`}
                    className={part.highlight ? 'highlight' : ''}
                >
                    {part.text}
                </span>
            ))}
        </div>
    );
};

interface IOptionInput {
    opt: IRecipientOption;
    inputValue: string;
}

export const OwnRecipientOption = ({ opt, inputValue }: IOptionInput) => {
    return (
        <RecipientOption>
            {opt.type === 'company' && (
                <CompanyOption
                    companyName={opt.name}
                    businessId={opt.businessId}
                    inputValue={inputValue}
                    isYtj={false}
                />
            )}

            {opt.type === 'person' && (
                <PersonOption
                    name={opt.name}
                    email={opt.email}
                    inputValue={inputValue}
                />
            )}
        </RecipientOption>
    );
};

interface IYtjOptionInput {
    opt: IYtjBriefCompany;
    inputValue: string;
}

export const YtjRecipientOption = ({ opt, inputValue }: IYtjOptionInput) => {
    return (
        <RecipientOption>
            <CompanyOption
                inputValue={inputValue}
                companyName={opt.name}
                businessId={opt.businessId}
                isYtj={true}
            />
        </RecipientOption>
    );
};
