import React, { ReactNode } from 'react';
import BackButtonWithTitle from 'components/BackButtonWithTitle';
import styled from 'styled-components';

interface INestedPageWrapper {
    name: string;
    children: ReactNode;
}

const PageNameHeader = styled.h1`
    font-size: 28px;
    font-weight: bold;
    color: #351f67;
`;

const OverwriteButtonStyleWrapper = styled.div`
    svg + div {
        font-weight: 500;
    }
`;

const NestedPageWrapper = (props: INestedPageWrapper) => {
    return (
        <div className="bg-white px-6 md:px-14 py-10 flex gap-12 flex-col items-stretch">
            <OverwriteButtonStyleWrapper>
                <BackButtonWithTitle />
                <PageNameHeader className="mt-4 md:mt-5">{props.name}</PageNameHeader>
            </OverwriteButtonStyleWrapper>

            {props.children}
        </div>
    );
};

export default NestedPageWrapper;
