import styled from 'styled-components';
import { COLOR_BLACKWATER, COLOR_BLUM, COLOR_WHITE_WALKER } from '../../styles/variables';

export const UniqueTitle = styled.h1`
    color: ${(props: { color?: string }) => (props.color ? props.color : COLOR_BLUM)};
    font-family: euclid;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0.3px;
    line-height: 40px;
    text-align: center;
`;

export const UniqueTitleLeft = styled(UniqueTitle)`
    text-align: left;
`;

export const UniqueTitleCenter = styled(UniqueTitle)`
    width: 100%;
`;

export const SpreadDataTitle = styled.h3`
    color: ${(props: { color?: string }) => (props.color ? props.color : COLOR_BLACKWATER)};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-transform: uppercase;
`;

export const SectionTitle = styled.h3`
    color: ${(props: { color?: string }) => (props.color ? props.color : COLOR_BLUM)};
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
`;

export const SectionTitleNew = styled.h3`
    color: ${(props: { color?: string }) => (props.color ? props.color : COLOR_BLUM)};
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 40px;
`;

export const RowTitle = styled.h4`
    color: ${(props: { color?: string }) => (props.color ? props.color : COLOR_BLUM)};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
`;

export const TitleCapsThin = styled.h1`
    color: ${(props) => props.color || COLOR_BLUM};
    font-size: 13px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
`;

export const TitleCapsBold = styled.h1`
    color: ${(props) => props.color || COLOR_BLUM};
    font-size: 13px;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
`;

export const LabelStrong = styled.label`
    color: ${(props: { color?: string }) => (props.color ? props.color : COLOR_WHITE_WALKER)};
    font-size: 15px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.15px;
    line-height: 32px;
`;

export const LabelThin = styled(LabelStrong)`
    font-weight: normal;
    line-height: normal;
`;

export const Label = styled.label`
    color: ${(props: { color?: string }) => (props.color ? props.color : COLOR_WHITE_WALKER)};
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: 28px;
`;

export const LabelSmall = styled(Label)`
    font-size: 12px;
`;

export const LabelMedium = styled.label`
    color: ${(props) => (props.color ? props.color : COLOR_WHITE_WALKER)};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.15px;
`;

export const LabelCapsSmall = styled.label`
    color: ${(props) => (props.color ? props.color : COLOR_BLACKWATER)};
    font-size: 13px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
`;
