import { trans } from '..';
import { IDropdownOption } from './../../components/form/AutocompleteDropdown';

export const WHOLE_NAME = 'wholeName';

export const getNameOptions = (firstName: string): IDropdownOption[] => {
    const wholeName: IDropdownOption = {
        label: trans('profile:marketing.name.option'),
        value: WHOLE_NAME
    };
    const options: IDropdownOption[] = firstName.split(' ').map(o => ({
        label: o,
        value: o
    }));
    return [wholeName, ...options];
};
