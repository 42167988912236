import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as R from 'ramda';
import { trans } from '..';
import { ISalary, SalaryStatus } from '../../../../shared/src/types/salary';
import { COLOR_STATUS_DONE, COLOR_STATUS_WAITING } from 'styles/variables';

export const getSalaryStatus = (
    salary: ISalary
): {
    color: string;
    hideTotal?: boolean;
    icon: IconProp;
    title: string;
} => {
    switch (salary.status) {
        case 'paid':
            return {
                color: COLOR_STATUS_DONE,
                icon: ['fal', 'coins'],
                title: trans(`salaries:status.${salary.status}`)
            };
        case 'countable':
            return {
                color: COLOR_STATUS_WAITING,
                hideTotal: true,
                icon: ['fal', 'clock'],
                title: trans(`salaries:status.${salary.status}`)
            };
        case 'unaccepted':
            return {
                color: COLOR_STATUS_WAITING,
                hideTotal: true,
                icon: ['fal', 'clock'],
                title: trans(`salaries:status.${salary.status}`)
            };
        default:
            return {
                color: COLOR_STATUS_WAITING,
                icon: ['fal', 'clock'],
                title: trans(`salaries:status.${salary.status}`)
            };
    }
};

export interface IMonthYear {
    month: number;
    year: number;
}

export const getMonthList = (salaries: ISalary[]): IMonthYear[] => {
    const months: IMonthYear[] = [];

    salaries.forEach((s: ISalary) => {
        if (!s.paymentDate) {
            return;
        }
        const m = new Date(s.paymentDate).getMonth();
        const y = new Date(s.paymentDate).getFullYear();

        if (!months.find(my => R.equals(my, { month: m, year: y }))) {
            months.push({ month: m, year: y });
        }
    });
    return months;
};

export const filterSalaries = (
    salaries: ISalary[],
    month: number,
    year: number
) => {
    return salaries.filter(
        (s: ISalary) =>
            s.paymentDate &&
            new Date(s.paymentDate).getFullYear() === year &&
            new Date(s.paymentDate).getMonth() === month
    );
};

export const allowSalaryPdfDisplay = (status: SalaryStatus) => {
    switch (status) {
        case 'unaccepted':
            return false;
        case 'countable':
            return false;
        case 'precounted':
            return true;
        case 'counted':
            return true;
        case 'accepted_for_pay':
            return true;
        case 'paid':
            return true;
        default:
            return false;
    }
};

export const allowSalaryPdfDownload = (status: SalaryStatus) => {
    switch (status) {
        case 'unaccepted':
            return false;
        case 'countable':
            return false;
        case 'precounted':
            return false;
        case 'counted':
            return false;
        case 'accepted_for_pay':
            return true;
        case 'paid':
            return true;
        default:
            return false;
    }
};
