import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducers';
import moment from 'moment';
import styled from 'styled-components';
import { DatePickerYel, FormNumberInput, FormSelect, UnitElement } from 'components/form';
import LoadingSpinner from 'components/Loading';
import { UniqueTitle } from 'components/textElements';
import { COLOR_IMPORTANT } from 'styles/variables';
import { ButtonContainer, PageWrapper, PrimaryButton, SecondaryButton } from 'styles/yelStyles';
import { centsToEur, currentDate, formatCents, formatPercentage } from 'utils';
import { transFixed } from 'utils/i18n';
import { yelLogic } from 'utils/yel';
import { ERROR_GENERAL_YEL, IClientError, getError } from 'utils/error';
import { sortObjectsByLabel } from '../../utils/str';
import occupationsList from '../../locales/en/occupations.json';
import { TolCodes } from '../../utils/yel/TolCodes';
import { useYelData } from 'queries/useYelData';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from 'utils/API';
import { AxiosError } from 'axios';
import { YelQuestion } from './YelQuestion';

const ErrorMessage = styled.p`
    && {
        color: ${COLOR_IMPORTANT};
    }

    && + div .hasValue {
        border-color: ${COLOR_IMPORTANT};
    }
`;

const Label = styled.div`
    font-size: 14px;
    font-weight: 600;
`;

const Value = styled.div`
    font-size: 15px;
`;

const inputStyle = {
    flexGrow: 1,
    marginBottom: 20,
    marginTop: 0,
    width: '100%',
};

const YelEdit: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const queryClient = useQueryClient();

    const { loading, data, setError } = useYelData();

    const isLoggedIn: boolean | null = useSelector((state: IRootState) => state.auth.loggedIn);
    const error: IClientError | null = useSelector((state: IRootState) => state.auth.error);

    const [estimatedIncome, setEstimatedIncome] = useState(0);
    const [estimatedIncomeStartDate, setEstimatedIncomeStartDate] = useState('');
    const [estimatedInvoicing, setEstimatedInvoicing] = useState(0);
    const [incomeChangeReason, setIncomeChangeReason] = useState('');
    const [occupation, setOccupation] = useState('');

    const [showDateError, setShowDateError] = useState<boolean>(false);
    const [showIncomeError, setShowIncomeError] = useState<boolean>(false);

    const postNewIncome = useMutation({
        mutationFn: async () => {
            try {
                const response = await API.put(`/yel/${data.id}`, {
                    estimatedIncome: estimatedIncome || data.estimatedIncome,
                    estimatedIncomeStartDate,
                    estimatedInvoicing,
                    incomeChangeReason,
                    occupation,
                });

                if (response.status === 200 && response.data.status === 'do_redirect') {
                    window.location = response.data.location;
                } else {
                    setError(getError({ errorType: ERROR_GENERAL_YEL }));
                }
            } catch (error) {
                if (error instanceof AxiosError) {
                    setError(getError({ errorType: ERROR_GENERAL_YEL }));
                }

                throw error;
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['yelData'] });
        },
    });

    const handleCancel = () => {
        navigate('/yel');
    };

    const handleSubmit = () => {
        postNewIncome.mutate();
    };

    const handleIncomeChange = (value: number, unit: string) => {
        if (
            (value * 12 < data.incomeLimits.min && unit === 'monthly') ||
            (value * 12 > data.incomeLimits.max && unit === 'monthly') ||
            (value < data.incomeLimits.min && unit === 'yearly') ||
            (value > data.incomeLimits.max && unit === 'yearly')
        ) {
            setShowIncomeError(true);
        } else {
            setShowIncomeError(false);
        }
        const estimatedIncome = unit === 'monthly' ? value * 12 : value;
        setEstimatedIncome(estimatedIncome);
    };
    const handleEstimatedInvoicingChange = (estimatedInvoicing: number) => {
        setEstimatedInvoicing(estimatedInvoicing);
    };

    const handleOccupationChange = (occupation: string) => {
        setOccupation(occupation);
    };

    const handleIncomeChangeReason = (reason: string) => {
        setIncomeChangeReason(reason);
    };

    const handleDateChange = (estimatedIncomeDate: string) => {
        if (moment(estimatedIncomeDate).isBefore(currentDate())) {
            setShowDateError(true);
        } else {
            setShowDateError(false);
        }
        setEstimatedIncomeStartDate(estimatedIncomeDate);
    };

    const modifyOccupation = (id: number, name: string) => {
        const code = TolCodes[id as keyof typeof TolCodes];
        return `${name} [${code ? code : ''}]`;
    };

    const occupationOptions =
        Object.keys(occupationsList).map((id: string) => {
            return {
                label: t(`occupations:${id}`),
                value: modifyOccupation(parseInt(id), t(`occupations:${id}`)),
            };
        }) || [];

    if (!isLoggedIn || error) {
        return <PageWrapper></PageWrapper>;
    } else if (loading) {
        return (
            <PageWrapper>
                <LoadingSpinner />
            </PageWrapper>
        );
    }

    return (
        <PageWrapper>
            <div className="flex flex-col gap-4 bg-white rounded-lg m-8 p-14">
                <UniqueTitle>{t('yel:income-change')}</UniqueTitle>
                <div className="grid grid-cols-2 gap-4">
                    <Label>{t('yel:current-income')}</Label>
                    <Value className="justify-self-end">
                        {formatCents(data.estimatedIncome)} {t('form.eurs-year')}
                    </Value>
                    <Label>
                        {t('yel:payment')} (
                        {t('form.percent', {
                            percentage: formatPercentage(data.collectionInfo.insurancePercentage),
                        })}
                        )
                    </Label>
                    <Value className="justify-self-end">
                        {formatCents(
                            yelLogic.calculateYelPayment(
                                data.estimatedIncome,
                                data.collectionInfo.insurancePercentage,
                                data.incomeLimits,
                            ),
                        )}{' '}
                        {t('form.eurs-year')}
                    </Value>
                </div>

                <YelQuestion
                    helpText={t('yel:help.question-income') || ''}
                    label={t('yel:question.new-income') || ''}
                    className="sm:flex-col"
                >
                    <div>
                        {showIncomeError && (
                            <ErrorMessage role="alert">
                                {t('yel:income-error', {
                                    min: formatCents(data.incomeLimits.min),
                                    max: formatCents(data.incomeLimits.max),
                                })}
                            </ErrorMessage>
                        )}
                    </div>
                    <div style={{ display: 'flex' }}>
                        <UnitElement
                            name={'income'}
                            onChange={handleIncomeChange}
                            value={centsToEur(data.estimatedIncome * 100 || estimatedIncome)}
                        />
                    </div>
                </YelQuestion>

                {showDateError && <ErrorMessage role="alert">{t('errors.date-not-in-future')}</ErrorMessage>}
                <YelQuestion>
                    <DatePickerYel
                        label={t('general.starting-from')}
                        onChange={handleDateChange}
                        selectedDate={estimatedIncomeStartDate || ''}
                    />
                </YelQuestion>

                <YelQuestion
                    helpText={t('yel:help.question-invoicing') || ''}
                    label={`${t('yel:question.invoicing')}`}
                >
                    <FormNumberInput
                        isEur={true}
                        endAdornment={t('form.eurs-year').trim()}
                        name={'estimated-invoicing'}
                        onChange={(val) => {
                            handleEstimatedInvoicingChange(Number(val));
                        }}
                        required
                        value={estimatedInvoicing || data.estimatedInvoicing}
                    />
                </YelQuestion>
                <YelQuestion
                    helpText={t('yel:help.question-occupation') || ''}
                    label={`${t('yel:question.occupation')}`}
                >
                    <FormSelect
                        id={data.occupation}
                        name="occupation"
                        onChange={handleOccupationChange}
                        options={sortObjectsByLabel(
                            occupationOptions.filter((occupation) => occupation.label !== ''),
                        )}
                        required
                        showIcon
                        noWrap
                        selectStyle={inputStyle}
                        value={occupation || data.occupation || ''}
                    />
                </YelQuestion>
                <div className="grid grid-cols-2 gap-4">
                    <Label>{t('yel:new-income')}</Label>
                    <Value className="justify-self-end">
                        {formatCents(estimatedIncome)} {t('form.eurs-year')}
                    </Value>
                    <Label>
                        {t('yel:payment-estimate')} (
                        {t('form.percent', {
                            percentage: formatPercentage(data.collectionInfo.insurancePercentage),
                        })}
                        )
                    </Label>
                    <Value className="justify-self-end">
                        {estimatedIncome &&
                            formatCents(
                                yelLogic.calculateYelPayment(
                                    estimatedIncome,
                                    data.collectionInfo.insurancePercentage,
                                    data.incomeLimits,
                                ),
                            )}{' '}
                        {t('form.eurs-year')}
                    </Value>
                </div>
                <YelQuestion label={t('yel:income-change-reason.title') || ''}>
                    <FormSelect
                        name="reason"
                        showIcon
                        onChange={handleIncomeChangeReason}
                        options={[
                            {
                                label: t('yel:income-change-reason.time-changed'),
                                value: transFixed({
                                    str: 'yel:income-change-reason.time-changed',
                                    lang: 'fi',
                                    options: { defaultValue: '' },
                                }),
                            },
                            {
                                label: t('yel:income-change-reason.business-turnover'),
                                value: transFixed({
                                    str: 'yel:income-change-reason.business-turnover',
                                    lang: 'fi',
                                    options: { defaultValue: '' },
                                }),
                            },
                            {
                                label: t('yel:income-change-reason.activity-changed'),
                                value: transFixed({
                                    str: 'yel:income-change-reason.activity-changed',
                                    lang: 'fi',
                                    options: { defaultValue: '' },
                                }),
                            },
                            {
                                label: t('yel:income-change-reason.task-changed'),
                                value: transFixed({
                                    str: 'yel:income-change-reason.task-changed',
                                    lang: 'fi',
                                    options: { defaultValue: '' },
                                }),
                            },
                        ]}
                        required
                        value={incomeChangeReason}
                    />
                </YelQuestion>
                <ButtonContainer>
                    <SecondaryButton onClick={handleCancel}>{t('general.stop')}</SecondaryButton>
                    <PrimaryButton
                        disabled={
                            !(
                                (data.estimatedIncome || estimatedIncome) &&
                                estimatedIncomeStartDate &&
                                incomeChangeReason
                            ) ||
                            showDateError ||
                            showIncomeError
                        }
                        onClick={handleSubmit}
                    >
                        {t('auth.continue-to-auth')}
                    </PrimaryButton>
                </ButtonContainer>
            </div>
        </PageWrapper>
    );
};

export default YelEdit;
