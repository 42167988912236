import { ReactNode, FC } from 'react';
import styled from 'styled-components';
import { COLOR_BLUM, SCREEN_M } from 'styles/variables';

// SOME TEXT AND HORIZONTAL LINE AFTER THAT -----------

const Divider = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    font-weight: 600;
    margin-right: 0;
    padding: 20px 0;
    span {
        flex-grow: 0;
        padding-right: 10px;
    }
    div {
        height: 1px;
        background-color: ${COLOR_BLUM};
        flex-grow: 1;
        margin-left: 10px;
    }

    @media (min-width: ${SCREEN_M}px) {
        margin-right: 20px;
        padding: 20px 10px 20px 10px;
    }
`;

type TextDividerProp = {
    children: ReactNode
}

export const TextDivider: FC<TextDividerProp>  = ({ children }) => {
    return (
        <Divider>
            {children}
            <div></div>
        </Divider>
    );
};
