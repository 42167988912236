import React from 'react';
import { Outlet } from 'react-router-dom';
import Drawer from '../containers/nav/Drawer';
import styled from '@emotion/styled';

const PageWrapper = styled.div`
    margin: -8px;
`;

const StatisticsWrapper = () => {
    return (
        <Drawer>
            <PageWrapper>
                <Outlet />
            </PageWrapper>
        </Drawer>
    );
};

export default StatisticsWrapper;
