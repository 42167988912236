import { useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { Hidden } from '@mui/material';
import { toast } from 'react-toastify';
import { EezyButton } from 'components/Buttons';
import { Flex } from 'components/Flex';
import { FormButton, FormInput } from 'components/form';
import LoadingSpinner from '../../components/Loading';
import { ValidationMessage } from 'components/textElements';
import { getErrorKey } from 'utils/apolloErrors';
import { changePasswordValidator } from 'utils/user/validators';
import { formatValidationResult } from 'utils/validation';
import CHANGE_PASSWORD from './queries/changePassword';
import { useTranslation } from 'react-i18next';

export const ProfileSettingsPassword = () => {
    const [changePasswordMode, setChangePasswordMode] = useState(false);
    const [password, setPassword] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [errors, setErrors] = useState<any>(null);

    const { t } = useTranslation();

    const [changePassword, { loading: changePasswordLoading }] = useMutation(
        CHANGE_PASSWORD,
        {
            onCompleted: () => {
                handleReset();
                toast(t('general.saved'));
            },
            onError: (e: ApolloError) => {
                if (
                    getErrorKey(e) === 'aava:change_password.invalid_password'
                ) {
                    toast.error(
                        t('profile:settings.password.errorCurrent')
                    );
                    setErrors({ password: true });
                } else {
                    toast.error(t('errors.general'));
                }
            }
        }
    );

    const validatePassword = () => {
        return formatValidationResult(
            changePasswordValidator.validate({ password, password1, password2 })
        );
    };

    const handleSavePassword = () => {
        const validationErrors = validatePassword();
        if (validationErrors) {
            setErrors(validationErrors);
            toast.error(t('profile:settings.password.error'));
        } else {
            setErrors(null);
            changePassword({
                variables: {
                    currentPassword: password,
                    newPassword: password1,
                    newPasswordConfirm: password2
                }
            });
        }
    };

    const handlePasswordChange = (val: string, name: string) => {
        if (name === 'password1') {
            setPassword1(val);
        } else if (name === 'password2') {
            setPassword2(val);
        } else if (name === 'password') {
            setPassword(val);
        }
        if (errors) {
            setErrors(validatePassword());
        }
    };

    const handleReset = () => {
        setPassword('');
        setPassword1('');
        setPassword2('');
        setChangePasswordMode(false);
        setErrors(null);
    };

    return <>
        <FormButton
            dark
            label={t('profile:settings.password.change-password') || ''}
            name="change-pwd"
            onClick={() => setChangePasswordMode(!changePasswordMode)}
            style={{ marginTop: 30 }}
            width={120}
        >
            {!changePasswordMode
                ? t('profile:settings.password.change')
                : t('general.cancel')}
        </FormButton>

        {changePasswordMode && (
            <>
                <FormInput
                    error={errors?.password}
                    label={t('profile:settings.password.current') || ''}
                    name="password"
                    required
                    onChange={handlePasswordChange}
                    style={{ marginTop: 50, maxWidth: 355 }}
                    type="password"
                    value={password}
                />
                {errors?.password && (
                    <ValidationMessage $error>
                        {t('profile:settings.password.errorCurrent')}
                    </ValidationMessage>
                )}

                <FormInput
                    error={errors?.password1}
                    label={t('profile:settings.password.new1') || ''}
                    name="password1"
                    required
                    onChange={handlePasswordChange}
                    style={{ maxWidth: 355 }}
                    type="password"
                    value={password1}
                />
                {errors?.password1 && (
                    <ValidationMessage $error>
                        {t('profile:settings.password.error1')}
                    </ValidationMessage>
                )}
                <FormInput
                    error={
                        errors?.password2 ||
                        (password2 && password2 !== password1)
                    }
                    label={t('profile:settings.password.new2') || ''}
                    name="password2"
                    required
                    onChange={handlePasswordChange}
                    style={{ maxWidth: 355 }}
                    type="password"
                    value={password2}
                />
                {errors?.password2 && (
                    <ValidationMessage $error>
                        {t('profile:settings.password.error2')}
                    </ValidationMessage>
                )}
                <Hidden smUp>
                    <Flex column style={{ marginTop: 30 }}>
                        <EezyButton
                            color="important"
                            dark
                            fullWidth
                            onClick={handleSavePassword}
                        >
                            {changePasswordLoading ? (
                                <LoadingSpinner size="1em" color="white" />
                            ) : (
                                t('general.save')
                            )}
                        </EezyButton>
                    </Flex>
                </Hidden>
                <Hidden smDown>
                    <Flex posEnd style={{ marginTop: 30 }}>
                        <EezyButton
                            color="important"
                            dark
                            onClick={handleSavePassword}
                            width={120}
                        >
                            {changePasswordLoading ? (
                                <LoadingSpinner size="1em" color="white" />
                            ) : (
                                t('general.save')
                            )}
                        </EezyButton>
                    </Flex>
                </Hidden>
            </>
        )}
    </>;
};
