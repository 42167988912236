import React from 'react';
import { trans } from '../../utils';

interface IProps {
    titleTransKey: string;
    turnBackReason?: string[];
    children?: React.ReactNode;
}

const TurnBackReason = (props: IProps) => {
    return (
        <div>
            <p>
                <b>{trans(props.titleTransKey)}</b>
            </p>
            {props.turnBackReason &&
                props.turnBackReason.map((r, i) => {
                    return <p key={i}>{r}</p>;
                })}
            {props.children && props.children}
        </div>
    );
};

export default TurnBackReason;
