import React from 'react';
import styled from 'styled-components';
import { IInvoice, LanguageCode } from '../../../../shared/src/types/invoice';
import BarCodeIcon from '../../assets/images/barcode.svg';
import { Flex } from 'components/Flex';
import { InvoiceCol } from 'styles/invoiceStyles';
import { addDaysTo, formatCents, formatDate } from 'utils';
import { transFixed } from 'utils/i18n';

const Row = styled(Flex)``;
const ColTitle = styled.div`
    width: 40%;
`;
const ColValue = styled.div`
    width: 60%;
`;
const BarCode = styled.div`
    background: url(${BarCodeIcon});
    width: 60px;
    height: 34px;
    margin: 10px auto;
`;
const Contact = styled.div`
    font-size: 12px;
    text-align: center;
`;
const Wrapper = styled.div`
    opacity: ${(props: { $faded: boolean }) => (props.$faded ? 0.2 : 1)};
    transition: opacity 500ms;
`;

interface IProps {
    $faded: boolean;
    invoice?: IInvoice;
    invoiceLanguage?: LanguageCode;
}

const getApproximateDueDate = (invoice: IInvoice): string => {
    return formatDate(addDaysTo(invoice.paymentTerm || 7, new Date()));
};

const InvoiceFooter = (props: IProps) => {
    const invoice = props.invoice;
    if (!invoice) {
        return null;
    }
    const recipient = invoice.recipient;
    return (
        <Wrapper $faded={props.$faded}>
            <Flex
                style={{
                    borderBottom: '1px solid black',
                    borderTop: '1px solid black',
                    fontSize: 12,
                    marginTop: 20,
                    padding: '20px 0'
                }}
            >
                <InvoiceCol style={{ maxWidth: '50%' }}>
                    <Row>
                        <ColTitle>
                            {transFixed({ str: 'invoice.footer.bank-account', lang: props.invoiceLanguage, options: {defaultValue: '' }})}
                        </ColTitle>
                        <ColValue>
                            <b>IBAN</b> FI4710233000240130
                        </ColValue>
                    </Row>
                    <Row>
                        <ColTitle>
                            {transFixed({ str: 'invoice.footer.receiver', lang: props.invoiceLanguage, options: {defaultValue: '' }})}
                        </ColTitle>
                        <ColValue>
                            <b>Eezy Kevytyrittäjät Oy</b>
                        </ColValue>
                    </Row>
                    {recipient && (
                        <Row>
                            <ColTitle>
                                {transFixed({ str: 'invoice.footer.customer', lang: props.invoiceLanguage, options: {defaultValue: '' }})}
                            </ColTitle>
                            <ColValue data-mf-replace='**REMOVED**'>
                                <b>{recipient.name}</b>
                                {recipient.address?.street1 && (
                                    <p>{recipient.address?.street1}</p>
                                )}
                                {recipient.address?.street2 && (
                                    <p>{recipient.address?.street2}</p>
                                )}
                                {(recipient.address?.zipCode ||
                                    recipient.address?.town) && (
                                    <p>
                                        {recipient.address?.zipCode}{' '}
                                        {recipient.address?.town}
                                    </p>
                                )}
                            </ColValue>
                        </Row>
                    )}
                </InvoiceCol>
                <InvoiceCol style={{ maxWidth: '50%' }}>
                    <Row>
                        <ColTitle>
                            <b>SWIFT/BIC</b>
                        </ColTitle>
                        <ColValue>NDEAFIHH</ColValue>
                    </Row>
                    <Row>
                        {transFixed({ str: 'invoice.footer.reminder', lang: props.invoiceLanguage, options: {defaultValue: '' }})}
                    </Row>
                    <Row>
                        <ColTitle>
                            {transFixed({ str: 'general.referenceNumber', lang: props.invoiceLanguage, options: {defaultValue: '' }})}
                        </ColTitle>
                        <ColValue>
                            <b>00000000</b>
                        </ColValue>
                    </Row>
                    <Row>
                        <ColTitle>
                            {transFixed({ str: 'general.duedate', lang: props.invoiceLanguage, options: {defaultValue: '' }})}
                        </ColTitle>
                        <ColValue>
                            <Flex spread>
                                <div>
                                    <b>
                                        {invoice.dueDate
                                            ? formatDate(invoice.dueDate)
                                            : getApproximateDueDate(invoice)}
                                    </b>
                                </div>
                                <div>
                                    <b>
                                        {formatCents(
                                            invoice.totalWithVat,
                                            true
                                        )}{' '}
                                        EUR
                                    </b>
                                </div>
                            </Flex>
                        </ColValue>
                    </Row>
                </InvoiceCol>
            </Flex>
            <BarCode />
            <Contact>
                {transFixed({ str: 'invoice.footer.contact', lang: props.invoiceLanguage, options: {defaultValue: '' }})}
            </Contact>
        </Wrapper>
    );
};

export default InvoiceFooter;
