import * as R from 'ramda';
import { zeroOrVal } from './common';

export const vatFactor = R.curry(zeroOrVal)(
    R.pipe(R.divide(R.__, 100), R.add(1))
);

export const multiply: (a: number, b: number) => number = R.multiply;

export const divide: (a: number, b: number) => number = R.divide;

export const round = Math.round;

export const abs = Math.abs;

export const commaToPeriod = R.replace(',', '.');

export const NUMBER_REGEX = /^[0-9\s]*[,.]?[0-9\s]*$/;
export const NUMBER_REGEX_NEG = /^-?[0-9\s]*[,.]?[0-9\s]*$/;

export const isValidNumber = (a: string) => NUMBER_REGEX.test(a);
export const isValidNumberNeg = (a: string) => NUMBER_REGEX_NEG.test(a);

export const notEmpty = <TValue>(
    value: TValue | null | undefined
): value is TValue => {
    return value !== null && value !== undefined;
};

export const hToHAndMin = (hours?: number) => {
    const h = hours ? Math.floor(hours) : 0;
    const m = hours ? Math.round((hours - h) * 60) : 0;
    return { hours: h, minutes: m };
};

export const hAndMinToH = (hours: number, minutes: number) => {
    return hours + minutes / 60;
};

export const addVat = (value: number, vat: number) => {
    return round(multiply(value, vatFactor(vat)));
};
