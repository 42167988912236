import React from 'react';
import { Modal, ModalContent } from '../../components/modals/Modal';
import { ISalesPage } from './SalesPageCreate';
import { ShareWrapper, QRCodeContainer } from './SalesPages';
import { formatCents, trans } from '../../utils';
import { EezyButton } from '../../components/Buttons';
import { Icon } from '../../components/Icon';
import { COLOR_WHITE_WALKER } from '../../styles/variables';

export interface ISalesPageShareModalProps {
    shareModalOpen: boolean;
    handleShareModalClose: () => void;
    page: ISalesPage;
    qrCodeUrl?: string;
    handleCopyLinkClick?: () => void;
}

const SalesPageShareModal = (props: ISalesPageShareModalProps) => {
    const { shareModalOpen, handleShareModalClose, page, qrCodeUrl } = props;

    return (
        <Modal
            id="modal-share-sales-page"
            isOpen={shareModalOpen}
            onClose={handleShareModalClose}
            title={page.serviceName ? page.serviceName : ''}
            small
        >
            <ModalContent>
                <div
                    style={{
                        borderBottom: '1px solid #ADA8BE',
                        paddingBottom: '24px',
                        marginBottom: '24px'
                    }}
                >
                    {trans('form.eurs', {
                        eurs: formatCents(page.servicePrice, true)
                    })}
                </div>
                <ShareWrapper>
                    <QRCodeContainer>
                        <img src={qrCodeUrl} alt=""></img>
                    </QRCodeContainer>
                    <div
                        style={{
                            borderTop: '1px solid #ADA8BE',
                            paddingTop: '24px',
                            width: '100%'
                        }}
                    >
                        <EezyButton
                            style={{ width: '100%', textAlign: 'left' }}
                            color="purple"
                            dark
                            iconAlignment="right"
                            onClick={props.handleCopyLinkClick}
                        >
                            <Icon
                                color={COLOR_WHITE_WALKER}
                                icon={['fas', 'link']}
                                style={{
                                    marginRight: 5,
                                    marginTop: 6,
                                    fontWeight: 600
                                }}
                            />
                            {trans('eezyPay:copy-link')}
                        </EezyButton>
                    </div>
                </ShareWrapper>
            </ModalContent>
        </Modal>
    );
};

export default SalesPageShareModal;
