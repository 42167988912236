import { MutationFunctionOptions } from '@apollo/client';
import React from 'react';
import { IUserBasicData } from '../../../../shared/src/types/user';
import { FormCheckmark } from '../../components/form/FormCheckmark';
import { Icon } from '../../components/Icon';
import { InfoTooltip } from '../../components/ToolTip';
import { COLOR_BLUM } from '../../styles/variables';
import { trans } from '../../utils';
import styled from 'styled-components';

interface IProps {
    userData: IUserBasicData;
    updateUserData: (
        options?: MutationFunctionOptions<any, Record<string, any>> | undefined
    ) => Promise<any>;
}

const FieldWrapper = styled.div`
    margin-top: 15px;
`;

const FieldWrapperInner = styled.div`
    position: relative;
    display: inline-block;
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 4px;
    left: calc(100% + 10px);
`;

export const ProfileSettingsStorageConsent = (props: IProps) => {
    const handleConsentUpdate = () => {
        props.updateUserData({
            optimisticResponse: {
                updateUserData: {
                    __typename: 'User',
                    ...props.userData,
                    longTermStorageConsent: !props.userData
                        ?.longTermStorageConsent
                }
            },
            variables: {
                longTermStorageConsent: !props.userData?.longTermStorageConsent
            }
        });
    };

    return (
        <FieldWrapper>
            <FieldWrapperInner>
                <FormCheckmark
                    id="storage-consent"
                    label={trans('profile:settings.long-term-storage-consent')}
                    checked={props.userData?.longTermStorageConsent}
                    name="storage-consent"
                    onChange={handleConsentUpdate}
                    required
                    value={'longTermStorageConsent'}
                />
                <InfoTooltip
                    title={trans(
                        'profile:settings.long-term-storage-consent-info'
                    )}
                    placement="top"
                    enterTouchDelay={0}
                    arrow
                >
                    <IconWrapper>
                        <Icon
                            icon={['fas', 'info-circle']}
                            color={COLOR_BLUM}
                            style={{ width: '15px' }}
                        />
                    </IconWrapper>
                </InfoTooltip>
            </FieldWrapperInner>
        </FieldWrapper>
    );
};
