import { gql } from '@apollo/client';

const GET_AAVA_GROUP_INVOICES = gql`
    query AavaGroupInvoices {
        aavaGroupInvoices {
            total
            items {
                clientType
                contactPersonId
                contactPersonName
                costInvoiceStatus
                creditRequested
                description
                draftNumber
                dueDate
                eezyExpress
                eezyExpressStatus
                hasBeenReclaimed
                id
                inDebtCollection
                invoiceDate
                invoiceNumber
                isGroupInvoice
                name
                paidAmount
                paymentReceived
                priority
                recipientId
                reimbursedAmount
                sendingFailed
                status
                total
                totalWithVat
                updateDate
            }
        }
    }
`;

export default GET_AAVA_GROUP_INVOICES;
