import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'components/form';
import { ButtonContainer, H1, H3, PrimaryButton, SecondaryButton, WrapperRow } from 'styles/yelStyles';
import { centsToEur, eursToCents, formatCents } from 'utils';
import { useYelData } from 'queries/useYelData';
import { useMutation } from '@tanstack/react-query';
import API from 'utils/API';
import { ERROR_GENERAL_YEL, getError } from 'utils/error';
import { AxiosError } from 'axios';
import useYelStore from 'stores/yelStore';

const YelWithdraw = () => {
    const { t } = useTranslation();
    const updateYelStore = useYelStore((state) => state.updateYelStore);

    const [sumToWithdraw, setSumToWithdraw] = useState(0);

    const { data, error, setError } = useYelData();

    const postWithdrawRequest = useMutation({
        mutationFn: async (requestedAmount: number) => {
            try {
                const response = await API.post('/yel/withdrawal', { requestedAmount });
                if (response.status === 200 && response.data.status === 'do_redirect') {
                    updateYelStore({ justWithdrew: true });
                    window.location = response.data.location;
                } else {
                    setError(getError({ errorType: ERROR_GENERAL_YEL }));
                }
            } catch (error) {
                if (error instanceof AxiosError) {
                    setError(getError({ errorType: ERROR_GENERAL_YEL }));
                }

                throw error;
            }
        },
    });

    const sumIsValid = sumToWithdraw > 0 && sumToWithdraw <= data.collectionInfo.accountBalance;

    const handleSumChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSumToWithdraw(eursToCents(Number(e.currentTarget.value)));
    };

    const handleCancel = () => updateYelStore({ showWithdrawMoney: false });

    const handleSubmit = () => {
        postWithdrawRequest.mutate(sumToWithdraw);
    };

    return (
        <div className="flex flex-col p-2">
            {error && <H1>{error.title}</H1>}
            <WrapperRow>
                <H3>{t('yel:collection-account-amount')}</H3>
                <p>{t('form.eurs', { eurs: formatCents(data.collectionInfo.accountBalance) })}</p>
            </WrapperRow>
            <TextInput
                label={t('general.accountNumber')}
                readonly
                value={data.collectionInfo.accountNumber}
            />
            <TextInput
                endAdornment="€"
                label={t('form.sum')}
                onChange={handleSumChange}
                type="number"
                value={centsToEur(sumToWithdraw)}
            />
            <p>{t('yel:withdraw-note')}</p>
            <ButtonContainer>
                <SecondaryButton onClick={handleCancel}>{t('general.cancel')}</SecondaryButton>
                <PrimaryButton disabled={!sumIsValid} onClick={handleSubmit}>
                    {t('auth.continue-to-auth')}
                </PrimaryButton>
            </ButtonContainer>
        </div>
    );
};

export default YelWithdraw;
