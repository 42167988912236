import { useQuery } from '@apollo/client';
import React from 'react';
import { initialClient } from '../../../utils/recipient/recipientLogic';
import GET_YTJ_COMPANY from '../queries/getYtjCompany';
import NewRecipientFillHelper from './NewRecipientFillHelper';

interface IRecipientFillHelper {
    invoiceId?: number;
    showModals?: (types: string[]) => void;
    ytjBusinessId: string;
}

const YtjRecipientFillHelper = (props: IRecipientFillHelper) => {
    const { data: ytjData, loading } = useQuery(GET_YTJ_COMPANY, {
        variables: { businessId: props.ytjBusinessId }
    });

    return (
        <NewRecipientFillHelper
            initialFormData={{
                ...initialClient,
                ...ytjData?.ytjCompany
            }}
            invoiceId={props.invoiceId}
            loading={loading}
            showModals={props.showModals}
        />
    );
};

export default React.memo(YtjRecipientFillHelper);
