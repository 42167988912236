import { ApolloError } from '@apollo/client';

interface IGraphQLFilteredErrors {
    basicInfo?: any;
    costs?: any;
    error?: any;
    express?: any;
    mustAccept?: any;
    provisions?: any;
}

export const getErrors = (e: ApolloError): IGraphQLFilteredErrors | undefined => {
    if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        const extensions = e.graphQLErrors[0].extensions;
        // TODO: Check "extensions.errors" properties are they exist and correct. From documentation I founded that exist only "code" and "stacktrace" properties.
        return {
            // @ts-ignore
            basicInfo: extensions?.errors?.basicInfo,
            // @ts-ignore
            costs: extensions?.errors?.costs,
            error: extensions?.code,
            // @ts-ignore
            express: extensions?.errors?.eezyExpress,
            // @ts-ignore
            mustAccept: extensions?.errors?.mustAccept,
            // @ts-ignore
            provisions: extensions?.errors?.provisions
        };
    }
};

export const getErrorKey = (e: ApolloError): string | null => {
    if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        const extensions = e.graphQLErrors[0].extensions;
        // TODO: Check "extensions.backendResponse?.messages" properties are they exist and correct.
        // @ts-ignore
        return extensions?.backendResponse?.messages[0].key || extensions?.response?.body?.messages[0]?.key || null;
    }
    return null;
};
