import { gql } from '@apollo/client';

const GET_INCOME = gql`
    query Income($year: Int!, $month: Int!) {
        income(year: $year, month: $month) {
            cumulativeInvoicing
            incomeOpen
            incomeForMonth
            incomeForYear
            serviceFee
        }
    }
`;

export default GET_INCOME;
