import React from 'react';
import { IClient } from '../../../../../shared/src/types/invoice';
import { FormInput, SplitRow } from '../../../components/form';
import { trans } from '../../../utils';

interface IPersonNameProps {
    disableRecipientChange?: boolean;
    errors: any;
    formData: IClient;
    handleChange: (val: string, name: string) => void;
    invoiceId?: number;
    readOnly?: boolean;
    recipientId?: string;
    required?: boolean;
    showErrors?: boolean;
}

const PersonNameField = (props: IPersonNameProps) => {
    return (
        <SplitRow>
            <FormInput
                data-mf-replace='**REMOVED**'
                error={props.errors?.firstName}
                label={trans(`invoice.form.recipient.firstName`)}
                name="firstName"
                onChange={props.handleChange}
                readonly={props.readOnly}
                required
                value={props.formData.firstName}
            />
            <FormInput
                data-mf-replace='**REMOVED**'
                error={props.errors?.lastName}
                label={trans(`invoice.form.recipient.lastName`)}
                name="lastName"
                onChange={props.handleChange}
                readonly={props.readOnly}
                required
                value={props.formData.lastName}
            />
        </SplitRow>
    );
};

export default PersonNameField;
