import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

interface ITabHead {
    active?: boolean;
    className?: string;
    children: ReactNode;
    onClick?: () => void;
}

const TabLink = styled.div`
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    position: relative;
    cursor: pointer;
    margin-bottom: 7px;

    &.active:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -7px;
        width: 100%;
        height: 3px;
        background: #351f65;
        border-top-left-radius: 999px;
        border-top-right-radius: 999px;
    }
`;

const TabHead = (props: ITabHead) => {
    const className = [props.active ? 'active' : '', props.className].filter((i) => i).join(' ');

    return (
        <TabLink className={className} onClick={props.onClick}>
            {props.children}
        </TabLink>
    );
};

export default TabHead;
