import styled from 'styled-components';
import { SCREEN_S } from 'styles/variables';

export const RightSideBody = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const RightSideBodySlim = styled(RightSideBody)`
    @media (min-width: ${SCREEN_S}px) {
        padding: 40px 0px;
    }
`;
