import { gql } from "@apollo/client";

const HIDE_NOTIFICATION = gql`
    mutation MarkNotificationAsHidden(
        $notification: NotificationInput!
        $isHidden: Boolean!
    ) {
        markNotificationHidden(
            notification: $notification
            isHidden: $isHidden
        ) {
            content
            date
            id
            isHidden
            isRead
            type
            title
        }
    }
`;

export default HIDE_NOTIFICATION;
