import { useApolloClient } from '@apollo/client';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { IInvoice } from '../../../../shared/src/types/invoice';
import { UiLanguage } from '../../../../shared/src/types/user';
import { EezyButton } from '../../components/Buttons';
import { Icon } from '../../components/Icon';
import { LabelStrong, P } from '../../components/textElements';
import {
    BORDER_RADIUS,
    COLOR_BLACKWATER,
    COLOR_BLUM,
    COLOR_LILA,
    SCREEN_S
} from '../../styles/variables';
import { formatDate, trans } from '../../utils';
import GroupInvoiceParticipants from './GroupInvoiceParticipants';
import { GROUP_INVOICE_TOTAL_CHANGED } from './queries/invoiceStateQueries';

const Box = styled.div`
    background-color: ${COLOR_LILA};
    border-radius: ${BORDER_RADIUS};
    margin: -45px 30px 60px 15px;
    padding: 30px;
    position: relative;
    &.preview {
        margin: 0 0 15px;
    }

    @media (max-width: ${SCREEN_S}px) {
        && {
            margin: 15px;
        }
    }
`;

const TableWrapper = styled.div`
    margin: 20px 0;
`;

interface IProps {
    buttonDisabled?: boolean;
    hideButton?: boolean;
    invoice?: IInvoice;
    isOwner: boolean;
    handleAddExpenses?: () => void;
    handleAddCoworkers?: () => void;
    language: UiLanguage;
    preview?: boolean;
}

const GroupInvoiceInfoBox = (props: IProps) => {
    const client = useApolloClient();

    const handleClick = () => {
        client.writeQuery({
            data: { groupInvoiceTotalChanged: false },
            query: GROUP_INVOICE_TOTAL_CHANGED
        });
        props.isOwner
            ? props.handleAddCoworkers!()
            : props.handleAddExpenses!();
    };

    const hasCoworkers = (props.invoice?.provisions?.length || 0) > 1;

    return (
        <Box className={props.preview ? 'preview' : ''}>
            <LabelStrong data-mf-replace='**REMOVED**' as="h4" color={COLOR_BLACKWATER}>
                {trans('invoice.groupInvoice.infoBox.title', {
                    name: props.invoice?.recipient?.name
                })}
                {!props.isOwner && (
                    <>
                        {' - '}
                        {props.invoice?.status === 'paid' ? (
                            <>{trans('invoice.statuses.paid')}</>
                        ) : props.invoice?.dueDate ? (
                            <>
                                {trans('general.duedate')}
                                {': '}
                                {formatDate(props.invoice?.dueDate)}
                            </>
                        ) : (
                            <>{trans('invoice.statuses.unaccepted')}</>
                        )}
                    </>
                )}
            </LabelStrong>
            {props.isOwner ? (
                <>
                    {props.invoice?.status === 'paid' ? (
                        <>
                            <P>
                                {trans(
                                    'invoice.groupInvoice.infoBox.owner-description-5'
                                )}
                            </P>
                            <P>
                                {trans(
                                    'invoice.groupInvoice.infoBox.owner-description-6'
                                )}
                            </P>
                        </>
                    ) : (
                        <>
                            <P>
                                {trans(
                                    'invoice.groupInvoice.infoBox.owner-description-1'
                                )}
                            </P>
                            <P>
                                {trans(
                                    'invoice.groupInvoice.infoBox.owner-description-2'
                                )}
                            </P>
                            <P>
                                {trans(
                                    'invoice.groupInvoice.infoBox.owner-description-3'
                                )}
                            </P>
                            <P>
                                <a
                                    className="unstyled"
                                    href={
                                        props.language === 'en'
                                            ? 'https://kevytyrittajat.eezy.fi/en/when-am-i-able-to-get-expenses-reimbursed/'
                                            : 'https://kevytyrittajat.eezy.fi/milloin-voin-saada-kulukorvauksia/'
                                    }
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {trans(
                                        'invoice.groupInvoice.infoBox.owner-description-4'
                                    )}{' '}
                                    <Icon
                                        icon={['far', 'arrow-up-right-from-square']}
                                        color={COLOR_BLUM}
                                    />
                                </a>
                            </P>
                        </>
                    )}
                </>
            ) : (
                <>
                    {props.invoice?.status === 'paid' ? (
                        <>
                            <P data-mf-replace='**REMOVED**'>
                                {trans(
                                    'invoice.groupInvoice.infoBox.coworker-description-4',
                                    {
                                        name: props.invoice?.createdBy?.name
                                    }
                                )}
                            </P>
                        </>
                    ) : (
                        <>
                            <P data-mf-replace='**REMOVED**'>
                                {trans(
                                    'invoice.groupInvoice.infoBox.coworker-description-1',
                                    {
                                        name: props.invoice?.createdBy?.name
                                    }
                                )}
                            </P>
                            <P>
                                {trans(
                                    'invoice.groupInvoice.infoBox.coworker-description-2'
                                )}
                            </P>
                            <P>
                                <a
                                    className="unstyled"
                                    href={
                                        props.language === 'en'
                                            ? 'https://kevytyrittajat.eezy.fi/en/when-am-i-able-to-get-expenses-reimbursed/'
                                            : 'https://kevytyrittajat.eezy.fi/milloin-voin-saada-kulukorvauksia/'
                                    }
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {trans(
                                        'invoice.groupInvoice.infoBox.coworker-description-3'
                                    )}{' '}
                                    <Icon
                                        icon={['far', 'arrow-up-right-from-square']}
                                        color={COLOR_BLUM}
                                    />
                                </a>
                            </P>
                        </>
                    )}
                </>
            )}

            <TableWrapper>
                <GroupInvoiceParticipants
                    invoice={props.invoice}
                    isOwner={props.isOwner}
                />
            </TableWrapper>

            {!props.hideButton && (
                <EezyButton
                    color="purple"
                    dark={!hasCoworkers}
                    transparent={hasCoworkers}
                    disabled={props.buttonDisabled}
                    onClick={handleClick}
                >
                    {props.isOwner
                        ? (props.invoice?.provisions?.length || 0) > 1
                            ? trans('general.edit')
                            : trans(
                                  'invoice.groupInvoice.infoBox.button-add-coworkers'
                              )
                        : props.invoice?.costInvoice &&
                          !props.isOwner &&
                          props.preview
                        ? trans('invoice.sending-costinvoice')
                        : trans(
                              'invoice.groupInvoice.infoBox.button-add-expenses'
                          )}
                </EezyButton>
            )}
        </Box>
    );
};

const mapStateToProps = (state: any) => {
    return {
        language: state.user.language
    };
};

export default connect(mapStateToProps)(GroupInvoiceInfoBox);
