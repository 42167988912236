import { IDropdownOption } from '../../components/form/AutocompleteDropdown';
import countries from '../../locales/fi/countries.json';
import { trans } from '../i18n';
import countryCodes from './countryCodes.json';

export const getCountriesOptions = () =>
    Object.keys(countries).map((c: string) => ({
        label: trans(`countries:${c}`),
        value: c
    }));

export const getCountryCodeOptions = (): IDropdownOption[] => {
    const cc: { [key: string]: string } = countryCodes;
    const keys = Object.keys(cc);
    const finlandCode = '358';
    const finlandCodeIndex = keys.findIndex(el => el === finlandCode);
    keys.splice(finlandCodeIndex, 1);
    keys.unshift(finlandCode);

    return keys.map((code: string) => ({
        label: `${trans(`countries:${cc[code]}`)} +${code} `,
        value: code
    }));
};
