import { useMutation } from '@apollo/client';
import React from 'react';
import { IUserBasicData, UiLanguage } from '../../../../shared/src/types/user';
import { FormRadio } from '../../components/form';
import { trans } from '../../utils';
import { UPDATE_USER_LANGUAGE } from '../eezypay/queries';

interface IProps {
    userData: IUserBasicData;
    changeLanguage: (lang: UiLanguage) => void;
    language: UiLanguage;
}

export const ProfileSettingsLanguage = (props: IProps) => {
    const [updateUserLanguageInHasura] = useMutation(UPDATE_USER_LANGUAGE, {
        context: { clientName: 'eezyPayHasura' }
    });

    const handleLanguageUpdate = (lang: string) => {
        props.changeLanguage(lang as UiLanguage);
        if (props.userData?.id) {
            updateUserLanguageInHasura({
                variables: {
                    id: props.userData.id,
                    language: lang
                }
            });
        }
    };

    return (
        <FormRadio
            label={trans('profile:settings.language')}
            options={[
                { label: trans('languages.fi'), value: 'fi' },
                { label: trans('languages.en'), value: 'en' }
            ]}
            name="language"
            onChange={handleLanguageUpdate}
            required
            value={props.language}
        />
    );
};
