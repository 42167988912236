import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import Success from '../../assets/images/success.svg';
import { COLOR_BLUM } from '../../styles/variables';

interface ISuccessCard {
    title?: string;
    titleStyles?: CSSProperties;
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

const SuccessMessage = styled.p`
    font-size: 16px;
    font-weight: 600;
    color: ${COLOR_BLUM};
    margin: 20px 0 30px 0;
    max-width: 260px;
    text-align: center;
`;

const SuccessCard = (props: ISuccessCard) => {
    return (
        <Wrapper>
            <img src={Success} alt="" style={{ maxWidth: 245 }} />
            <SuccessMessage style={props.titleStyles}>{props.title}</SuccessMessage>
        </Wrapper>
    );
};

export default SuccessCard;
