import { IUserData, IWithholdingTax } from '../../../shared/src/types/user';
import {
    FETCH_USER_BEGIN,
    FETCH_USER_SUCCESS,
    FETCH_WITHHOLDING_TAX_SUCCESS,
    USER_ASYNC_FAILURE,
    USER_RESET,
    USER_SET_COMPANY_NAME,
    USER_SET_STATE,
    YEL_SELF_REPORTED,
    USER_SET_TWO_FACTOR_AUTHENTICATION,
    SAVE_CAR,
} from 'actionTypes';
import { getError, IClientError } from 'utils/error';

export interface IUser extends IUserData {
    error: IClientError | null;
    withholdingTax?: IWithholdingTax;
    twoFactorAuthentication?: string;
}

// eslint-disable-next-line
export default (state = {} as IUser, action: any) => {
    switch (action.type) {
        case FETCH_USER_SUCCESS:
            return { ...state, ...action.payload };
        case FETCH_USER_BEGIN:
            return { ...state, error: null };
        case USER_ASYNC_FAILURE:
            return { ...state, error: getError({ errorType: action.payload }) };
        case USER_SET_STATE:
            return { ...state, ...action.payload };
        case USER_RESET:
            return {};
        case USER_SET_COMPANY_NAME:
            return { ...state, companyName: action.payload };
        case YEL_SELF_REPORTED:
            return { ...state, ...action.payload };
        case FETCH_WITHHOLDING_TAX_SUCCESS:
            return {
                ...state,
                withholdingTax: {
                    ...action.payload,
                    error:
                        action.payload.error.reason || action.payload.error.code || action.payload.error.text
                            ? { ...action.payload.error }
                            : undefined,
                },
            };
        case USER_SET_TWO_FACTOR_AUTHENTICATION:
            return { ...state, twoFactorAuthentication: action.payload };
        case SAVE_CAR:
            return { ...state, preferences: { ...state.preferences, carNumber: action.payload } };
        default:
            return state;
    }
};
