import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Fade, Hidden } from '@mui/material';
import styled from 'styled-components';
import { IThread } from '../../../../shared/src/types/message';
import { EezyButton } from 'components/Buttons';
import { Flex } from 'components/Flex';
import { Icon } from 'components/Icon';
import { List } from 'components/layout/List';
import { LabelMedium, LabelStrong, SingleText, UniqueTitle } from 'components/textElements';
import {
    BORDER_RADIUS,
    COLOR_BLUM,
    COLOR_GREYJOY,
    COLOR_LILA,
    COLOR_STATUS_DONE,
    COLOR_WHITE_WALKER,
} from 'styles/variables';
import { formatUserId, submitVisitorVariable } from 'utils/giosg';
import { useSelectedListItem } from 'utils/hooks';
import { isWeekend } from 'utils/support/supportUtils';
import ChatStatus from './ChatStatus';
import MessageListItem from './MessageListItem';
import { GET_MESSAGE_THREADS } from './queries';
import { IRootState } from 'reducers';
import { useCustomerServiceOpen } from 'routes/SupportWrapper';

const ITEM_DELAY = 20;
const FADE_TIMEOUT = 200;

const Wrapper = styled.div`
    background-color: ${COLOR_WHITE_WALKER};
    border-radius: ${BORDER_RADIUS};
    padding: 0 15px;
`;

type MessageListProps = {
    serviceOpen?: boolean;
};

const MessageList: FC<MessageListProps> = ({ serviceOpen }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const customerService = useCustomerServiceOpen();
    const customerServiceOpen = customerService?.customerServiceOpen || serviceOpen; // TODO: kitur nebeveikia!!!

    const language = useSelector((state: IRootState) => state.user.language);
    const recruitmentCode = useSelector((state: IRootState) => state.user.recruitmentCode);
    const userId = useSelector((state: IRootState) => state.user.id);

    const { data: messagesData, refetch: refetchMessages } = useQuery(GET_MESSAGE_THREADS, {
        pollInterval: 5 * 60 * 1000,
    });

    const selectedId = useSelectedListItem();

    useEffect(() => {
        // Refetch new messages when entering the view
        refetchMessages();
    }, [refetchMessages]);

    const submitChatInfo = () => {
        const visitorVariable = {
            recruitmentCode: recruitmentCode,
            userId: formatUserId(userId),
        };
        submitVisitorVariable(visitorVariable);
    };

    const listThreads = (items: IThread[], delay: number) => {
        return (
            <ul>
                {items.map((thread, index) => {
                    const handleClick = () => {
                        navigate(`/support/messages/${thread.id}`);
                    };
                    const selected = selectedId === thread.id;
                    return (
                        <li
                            className={selected ? 'selected' : ''}
                            key={`${index}-threads-${thread.id}`}
                            tabIndex={0}
                            onClick={handleClick}
                            onKeyPress={handleClick}
                        >
                            <Fade
                                in={true}
                                timeout={FADE_TIMEOUT}
                                style={{
                                    transitionDelay: `${ITEM_DELAY * index + delay}ms`,
                                }}
                            >
                                <div>
                                    <MessageListItem thread={thread} key={thread.id} language={language} />
                                </div>
                            </Fade>
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <Wrapper>
            <Fade in={true} timeout={FADE_TIMEOUT}>
                <Flex style={{ padding: '40px 0' }}>
                    <UniqueTitle>{t('support:title')}</UniqueTitle>
                </Flex>
            </Fade>

            <Flex spread style={{ marginBottom: 16 }}>
                <LabelMedium color={COLOR_BLUM}>{t('support:new-message.label')}</LabelMedium>
                <EezyButton
                    color="purple"
                    dark
                    hasIcon
                    width={130}
                    onClick={() => navigate('/support/new-message')}
                >
                    <Icon icon={['far', 'envelope']} color={COLOR_LILA} /> {t('support:new-message.button')}
                </EezyButton>
            </Flex>

            <ChatStatus onChatStart={submitChatInfo} />

            <Flex spread style={{ marginBottom: 34 }}>
                <LabelMedium color={COLOR_BLUM} style={{ display: 'grid', alignItems: 'center' }}>
                    <span>{t('support:title')}</span>
                    {customerServiceOpen ? (
                        <Icon icon={['fas', 'circle']} color={COLOR_STATUS_DONE} style={{ marginLeft: 8 }} />
                    ) : (
                        <Icon icon={['far', 'circle']} color={COLOR_GREYJOY} style={{ marginLeft: 8 }} />
                    )}
                </LabelMedium>

                <Hidden mdDown>
                    <Flex column style={{ minWidth: 150 }}>
                        <Flex spread style={isWeekend() ? { opacity: 0.8 } : {}}>
                            <LabelStrong color={COLOR_BLUM}>
                                {t('support:contact.times.weekdays')}
                            </LabelStrong>
                            <SingleText color={COLOR_BLUM}>
                                {t('support:contact.times.weekdays-time')}
                            </SingleText>
                        </Flex>
                        <Flex spread style={!isWeekend() ? { opacity: 0.8 } : {}}>
                            <LabelStrong color={COLOR_BLUM}>
                                {t('support:contact.times.weekends')}
                            </LabelStrong>
                            <SingleText color={COLOR_BLUM}>
                                {t('support:contact.times.weekends-time')}
                            </SingleText>
                        </Flex>
                    </Flex>
                </Hidden>

                <Hidden mdUp>
                    <EezyButton color="purple" dark onClick={() => navigate('/support/contact')} width={130}>
                        {t('support:contact.title')}
                    </EezyButton>
                </Hidden>
            </Flex>

            {messagesData?.allThreads && (
                <List
                    className="hide-scroll"
                    style={{
                        paddingBottom: 20,
                        top: 280,
                    }}
                >
                    {listThreads(messagesData?.allThreads, 0)}
                </List>
            )}
        </Wrapper>
    );
};

export default MessageList;
