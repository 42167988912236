import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { fetchUser } from '../../actions/user';
import { UniqueTitle } from '../../components/textElements';
import { IAuth } from '../../reducers/authReducer';
import { H3, PageWrapper, Wrapper } from '../../styles/yelStyles';
import { trans } from '../../utils';

interface IInsuranceInfoDocumentProps {
    auth: IAuth;
    fetchUser: () => void;
}

class InsuranceInfoDocument extends PureComponent<IInsuranceInfoDocumentProps> {
    componentDidUpdate(prevProps: IInsuranceInfoDocumentProps) {
        if (prevProps.auth.loggedIn !== this.props.auth.loggedIn) {
            this.props.fetchUser();
        }
    }

    render() {
        const { auth } = this.props;
        if (!auth.loggedIn || auth.error) {
            return <PageWrapper></PageWrapper>;
        }

        return (
            <PageWrapper>
                <UniqueTitle>{trans('yel:document.title')}</UniqueTitle>
                <Wrapper>
                    <H3>{trans('yel:document.header1')}</H3>
                    <Wrapper>
                        <p>
                            <b>{trans('yel:document.name')}:</b> Eezy
                            Kevytyrittäjät Oy
                        </p>
                        <p>
                            <b>{trans('yel:document.businessId')}:</b> 2197002-2
                        </p>
                        <p>
                            <b>{trans('yel:document.address')}:</b> Itämerenkatu
                            3, 00240 Helsinki
                        </p>
                        <p>
                            <b>{trans('yel:document.tel')}:</b> 09 4247 5630
                        </p>
                        <p>
                            <b>{trans('yel:document.email')}:</b>{' '}
                            info.kevytyrittajat@eezy.fi
                        </p>
                    </Wrapper>
                </Wrapper>

                <Wrapper>
                    <H3>{trans('yel:document.header2')}</H3>
                    <Wrapper>
                        <p>{trans('yel:document.section2')}</p>
                    </Wrapper>
                </Wrapper>

                <Wrapper>
                    <H3>{trans('yel:document.header3')}</H3>
                    <Wrapper>
                        <p>{trans('yel:document.section3')}</p>
                        <a
                            href="https://sol.itella.net/b/vakuutusedustajarekisteri/"
                            rel="noopener noreferrer"
                            style={{ wordBreak: 'break-all' }}
                            target="_blank"
                        >
                            https://sol.itella.net/b/vakuutusedustajarekisteri/
                        </a>
                    </Wrapper>
                </Wrapper>

                <Wrapper>
                    <H3>{trans('yel:document.header4')}</H3>
                    <Wrapper>
                        <p>{trans('yel:document.section4')}</p>
                    </Wrapper>
                </Wrapper>

                <Wrapper>
                    <H3>{trans('yel:document.header5')}</H3>
                    <Wrapper>
                        <p>{trans('yel:document.section5')}</p>
                    </Wrapper>
                </Wrapper>
            </PageWrapper>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        language: state.user.language
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        fetchUser: () => {
            dispatch(fetchUser());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InsuranceInfoDocument);
