import { useEffect, useCallback, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { IFile } from '../../../../shared/src/types/common';
import { IReceiptCost } from '../../../../shared/src/types/costs';
import { markCostFileAsUsed, uploadCostReceipt } from 'actions/file';
import { Flex } from 'components/Flex';
import FileInput from 'components/form/FileInput';
import FileRow from 'components/form/FileRow';
import { FormLabel } from 'components/form/FormLabel';
import { Icon } from 'components/Icon';
import { Line2 } from 'components/Lines';
import LoadingSpinner from 'components/Loading';
import { SingleText } from 'components/textElements';
import { ITempFile } from 'reducers/fileReducer';
import { COLOR_GREY_FOG, COLOR_IMPORTANT } from 'styles/variables';
import { formatDateISO } from 'utils';
import {
    COST_RECEIPT_FILETYPES,
    filterNewAttachments,
    filterRemainedFiles,
    getReceipt,
    ICostComponentProps
} from 'utils/costs/costLogic';
import { IClientError } from 'utils/error';
import { stripSpaces } from 'utils/str';
import { DELETE_ATTACHMENT, GET_ATTACHMENTS } from './queries';

export interface IUiFile extends IFile {
    isNew?: boolean;
    selected?: boolean;
}

const ReceiptUpload: FC<ICostComponentProps> = ({currentCost, travelCostTemp, dispatch}) => {
    // Note! The names of the files need to be unique
    const dispatchRedux = useDispatch<ThunkDispatch<{}, {}, any>>();
    const { t } = useTranslation();

    const fileError: IClientError | null = useSelector((state: IRootState) => state.file.error);
    const fileLoading: boolean = useSelector((state: IRootState) => state.file.loading);
    const receiptFilesProp: ITempFile[] = useSelector((state: IRootState) => state.file.costReceiptFiles);

    const { data: attachmentsData, refetch: refetchAttachments } = useQuery(
        GET_ATTACHMENTS,
        {
            pollInterval: 1 * 60 * 1000
        }
    );

    const receipt: IReceiptCost | undefined = getReceipt(
        currentCost,
        travelCostTemp
    );

    const isTemp = useCallback(() => {
        return !!travelCostTemp;
    }, [travelCostTemp]);

    const saveFiles = useCallback(
        (files: IUiFile[]) => {
            const type = isTemp() ? 'SAVE_TRAVEL_COST_TEMP' : 'SAVE_COST';
            dispatch({ payload: { receiptFiles: files }, type });
        },
        [dispatch, isTemp]
    );

    const [deleteAttachment] = useMutation(DELETE_ATTACHMENT, {
        onCompleted: ({ deleteAttachment: { id } }) => {
            saveFiles(
                receipt?.receiptFiles.filter((f: IUiFile) => {
                    return f.id !== id;
                }) || []
            );
        },
        onError: () => null
    });

    useEffect(() => {
        refetchAttachments();
    }, []);

    useEffect(() => {
        // get the difference between unused attachments and cost files
        const attachments = attachmentsData?.allUnusedAttachments || [];
        const newAttachments: IUiFile[] = filterNewAttachments(
            attachments,
            receipt?.receiptFiles || []
        );
        const remainedFiles: IUiFile[] = filterRemainedFiles(
            attachments,
            receipt?.receiptFiles || []
        );
        saveFiles(
            [...newAttachments, ...remainedFiles].sort(
                (a: IUiFile, b: IUiFile) => b.id - a.id
            )
        );
    }, [attachmentsData]);

    useEffect(() => {
        // Fill the id for the freshly uploaded file
        if (receipt?.receiptFiles.find(r => !r.id)) {
            saveFiles(
                receipt?.receiptFiles.map((f: IFile) => {
                    if (f.id === 0) {
                        const match = receiptFilesProp.find(
                            (r: ITempFile) => r.name === f.name
                        );
                        if (match) {
                            setTimeout(() => {
                                    dispatchRedux(markCostFileAsUsed(match.id));
                                }, 300);
                            return { ...f, id: match.id, url: match.url || '' };
                        }
                    }
                    return f;
                }) || []
            );
        }
    }, [receiptFilesProp]);

    useEffect(() => {
        if (fileError) {
            // There was error while uploading file,
            // so remove file without id from the list
            // because that wasn't uploaded successfully
            saveFiles(
                receipt?.receiptFiles.filter((f: IUiFile) => {
                    return !!f.id;
                }) || []
            );
        }
    }, [fileError, receipt?.receiptFiles, saveFiles]);

    if (!receipt) {
        return null;
    }

    const getSelectedFiles = () => {
        return receipt.receiptFiles.filter(r => (r as IUiFile).selected);
    };

    const receiptFiles: IUiFile[] = receipt.receiptFiles.filter(
        (f: IUiFile) => !f.isNew
    );
    const unusedFiles: IUiFile[] = receipt.receiptFiles.filter(
        (f: IUiFile) => f.isNew
    );

    const handleAddFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const newFile = e.target.files[0];

            if (
                receipt.receiptFiles.filter(
                    (f: IUiFile) =>
                        f.name === newFile.name ||
                        f.name === stripSpaces(newFile.name)
                ).length === 0
            ) {
                const uploadData = new FormData();
                uploadData.append('file', newFile);
                dispatchRedux(uploadCostReceipt(uploadData));

                saveFiles([
                    {
                        contentType: newFile.type,
                        createTime: formatDateISO(new Date()),
                        id: 0,
                        name: newFile.name,
                        previewUrl: '',
                        selected: true,
                        url: ''
                    },
                    ...receipt.receiptFiles
                ]);
            } else {
                toast.error(t('file.duplicate-name'));
            }
        }
    };

    const handleFileChosenToggle = (fileId: number) => {
        saveFiles(
            receipt.receiptFiles.map((f: IUiFile) => {
                if (fileId === f.id) {
                    return {
                        ...f,
                        selected: !f.selected
                    };
                } else {
                    return f;
                }
            })
        );
    };

    const handleFileRemoval = (fileId: number) => {
        // Files not attached to costInvoice can be removed immediately
        // but other files are removed only on save. Thus clicking back or refersh, the
        // removed costInvoice receiptFiles still exist.
        if (unusedFiles.find((f: IUiFile) => f.id === fileId)) {
            deleteAttachment({ variables: { attachmentId: fileId } });
        } else {
            saveFiles(
                receipt?.receiptFiles.filter((f: IUiFile) => {
                    return f.id !== fileId;
                }) || []
            );
        }
    };

    return (
        <div>
            <Flex baseline spread style={{ marginBottom: 15 }}>
                <FormLabel aria-hidden value={getSelectedFiles().length > 0}>
                    {t('file.upload-receipt')}
                </FormLabel>
                <FileInput
                    accept={COST_RECEIPT_FILETYPES}
                    color={fileLoading ? COLOR_IMPORTANT : undefined}
                    onChange={handleAddFile}
                >
                    {fileLoading ? (
                        <LoadingSpinner color="white" size="1em" />
                    ) : (
                        <Icon icon={['far', 'arrow-to-top']} />
                    )}{' '}
                    <span aria-hidden>{t('file.upload')}</span>
                    <span style={{ display: 'none' }}>
                        {t('file.upload-receipt')}
                    </span>
                </FileInput>
            </Flex>

            {receiptFiles.map((file: IUiFile) => {
                return (
                    <FileRow
                        date={file.createTime}
                        handleRemoval={handleFileRemoval}
                        handleSelectionToggle={handleFileChosenToggle}
                        id={file.id}
                        key={`file-${file.id}`}
                        name={file.name}
                        selected={file.selected}
                        url={file.url}
                    />
                );
            })}

            {unusedFiles.length > 0 && (
                <SingleText style={{ margin: '10px 0' }}>
                    {t('file.choose-previously-loaded-file')}:
                </SingleText>
            )}

            {unusedFiles.map((attachment: IUiFile) => {
                return (
                    <FileRow
                        date={attachment.createTime}
                        handleRemoval={handleFileRemoval}
                        handleSelectionToggle={handleFileChosenToggle}
                        id={attachment.id}
                        key={`file-${attachment.id}`}
                        name={attachment.name}
                        selected={attachment.selected}
                        url={attachment.url}
                    />
                );
            })}

            <Line2 color={COLOR_GREY_FOG} />
        </div>
    );
};

export default ReceiptUpload;
