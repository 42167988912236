import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { saveSearchQuery } from '../../actions/salary';
import SearchField from '../../components/SearchField';
import { trans } from '../../utils';

const SEARCH_TIMEOUT = 1000;

interface ISalarySearch {
    onChange: (searchQuery: string) => void;
    searchQuery: string;
}
const SalarySearch = (props: ISalarySearch) => {
    const [query, setQuery] = React.useState('');
    const [searchTimer, setSearchTimer] = React.useState(0);

    React.useEffect(() => {
        setQuery(props.searchQuery);
    }, [props.searchQuery]);

    const handleChange = (val: string, fireImmediately?: boolean) => {
        setQuery(val);
        clearTimeout(searchTimer);
        if (fireImmediately) {
            props.onChange(val);
        } else {
            setSearchTimer(
                setTimeout((_: number) => {
                    props.onChange(val);
                }, SEARCH_TIMEOUT)
            );
        }
    };
    return (
        <SearchField
            onChange={handleChange}
            placeholder={trans('salaries:search')}
            query={query}
            tooltip={trans('salaries:searchTooltip')}
        />
    );
};

const mapStateToProps = (state: any) => {
    return {
        language: state.user.language,
        searchQuery: state.salary.searchQuery
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        onChange: (q: string) => {
            dispatch(saveSearchQuery(q));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SalarySearch);
