import { gql } from "@apollo/client";

const GET_NOTIFICATIONS = gql`
    query GetNotifications($lang: LanguageId!, $dateLimitForHiding: String) {
        allNotifications(lang: $lang, dateLimitForHiding: $dateLimitForHiding) {
            actionData {
                downloadUrl
                dueDate
                name
                sum
            }
            actionType
            content
            date
            id
            isHidden
            isRead
            title
            type
        }
    }
`;

export default GET_NOTIFICATIONS;
