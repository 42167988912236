import { addressValidator } from '../profile/addressValidator';
import { MULTIPLE_EMAIL_REGEX, validator as v } from '../validation';

const descriptionValidator = v.string().trim().min(1).required();

const typeValidator = v.string().required();

const priceValidator = v.number().required();

const quantityValidator = v.number().required();

const quantityUnitValidator = v.string().min(1).required();

const vatEuValidator = v.number().valid(0).required();

const vatForeignValidator = v.number().valid(0, 24, 25.5).required();

const vatReverseVatConstructionIndustryValidator = v.number().valid(0).required();

const vatDomesticValidator = v.number().valid(0, 10, 24, 25.5).required();

const vatValidator = v.when('template', {
    is: 'eu',
    then: vatEuValidator,
    // tslint:disable-next-line: object-literal-sort-keys
    otherwise: v.when('template', {
        is: 'reverse_vat_construction_industry',
        then: vatReverseVatConstructionIndustryValidator,
        // tslint:disable-next-line: object-literal-sort-keys
        otherwise: v.when('template', {
            is: 'foreign',
            then: vatForeignValidator,
            // tslint:disable-next-line: object-literal-sort-keys
            otherwise: vatDomesticValidator,
        }),
    }),
});

const startDateValidator = v.date().iso().max(v.ref('endDate')).allow(null).allow('');

const invoiceItemsTypeValidator = v.array().has(v.object({ itemType: v.valid('work') }));

const invoiceItemValidator = v.object({
    description: descriptionValidator,
    // endDate - no need! startdate and enddate depend on each other
    itemType: typeValidator,
    price: priceValidator,
    quantity: quantityValidator,
    quantityUnit: quantityUnitValidator,
    startDate: startDateValidator,
    vat: vatValidator,
});

const invoiceItemDomesticValidator = invoiceItemValidator.append({
    vat: vatDomesticValidator,
});

const invoiceItemEuValidator = invoiceItemValidator.append({
    vat: vatEuValidator,
});

const invoiceItemForeignValidator = invoiceItemValidator.append({
    vat: vatForeignValidator,
});

const invoiceItemreverseConstructionIndustryValidator = invoiceItemValidator.append({
    vat: vatReverseVatConstructionIndustryValidator,
});

const invoiceItemsValidator = v
    .array()
    .min(1)
    .required()
    .has(v.object({ itemType: v.valid('work') }))
    .when('template', {
        is: 'eu',
        then: v.array().items(invoiceItemEuValidator),
        // tslint:disable-next-line: object-literal-sort-keys
        otherwise: v.when('template', {
            is: 'foreign',
            then: v.array().items(invoiceItemForeignValidator),
            // tslint:disable-next-line: object-literal-sort-keys
            otherwise: v.when('template', {
                is: 'reverse_vat_construction_industry',
                then: v.array().items(invoiceItemreverseConstructionIndustryValidator),
                // tslint:disable-next-line: object-literal-sort-keys
                otherwise: v.array().items(invoiceItemDomesticValidator),
            }),
        }),
    });

const occupationValidator = v.number().integer().positive().required();

// Note!.v.date() accepts e.g. 31/09 (that is invalid in real life) but not 32/09
const dueDateValidator = v.when('recipient.type', {
    is: 'person',
    then: v.when('paymentTerm', {
        is: null,
        then: v.date().min(v.ref('nowPlus14')),
    }),
    // tslint:disable-next-line: object-literal-sort-keys
    otherwise: v.when('recipient.invoicingMethod', {
        is: 'mail',
        then: v.when('paymentTerm', {
            is: null,
            then: v.date().min(v.ref('nowPlus7')),
        }),
        // tslint:disable-next-line: object-literal-sort-keys
        otherwise: v.when('paymentTerm', {
            is: null,
            then: v.date().min(v.ref('now')),
        }),
    }),
});

const dispatchDateValidator = v.date().min(v.ref('tomorrow')).optional().allow(null).allow('');

const paymentTermValidator = v.when('dueDate', {
    is: null,
    then: v.when('recipient.type', {
        is: 'person',
        then: v.number().min(14),
        // tslint:disable-next-line: object-literal-sort-keys
        otherwise: v.when('recipient.invoicingMethod', {
            is: 'mail',
            then: v.number().min(7),
            // tslint:disable-next-line: object-literal-sort-keys
            otherwise: v.number().min(0),
        }),
    }),
});

// Recipient validator
export const businessIdValidator = v.when('type', {
    is: 'company',
    then: v.string().required(),
    // tslint:disable-next-line: object-literal-sort-keys
    otherwise: v.optional(),
});
export const companyValidator = v.when('type', {
    is: 'company',
    then: v.string().required(),
    // tslint:disable-next-line: object-literal-sort-keys
    otherwise: [v.allow(null), v.string().optional()],
});
export const einvoiceValidator = v.when('invoicingMethod', {
    is: 'einvoice',
    then: v.string().min(5).required(),
    // tslint:disable-next-line: object-literal-sort-keys
    otherwise: [v.allow(null), v.string().optional()],
});
export const emailValidator = v.when('invoicingMethod', {
    is: 'email',
    then: v.string().regex(MULTIPLE_EMAIL_REGEX).required(),
    // tslint:disable-next-line: object-literal-sort-keys
    otherwise: [v.allow(null), v.string().optional()],
});
export const nameValidator = v.when('type', {
    is: 'person',
    then: v.string().required(),
    // tslint:disable-next-line: object-literal-sort-keys
    otherwise: [v.allow(null), v.string().optional()],
});
const invoiceRecipientValidator = v
    .object({
        address: addressValidator,
        einvoiceOvt: einvoiceValidator,
        email: emailValidator,
        invoicingMethod: v.string().required(),
    })
    .required();

const templateValidator = v.string().required();

const personNameValidator = v.when('type', {
    is: 'person',
    then: v.string().min(1).required(),
    // tslint:disable-next-line: object-literal-sort-keys
    otherwise: [v.allow(null), v.string().optional()],
});

export const contactPeopleValidator = v.array().items(
    v.object({
        firstName: v.when('active', {
            is: true,
            then: v.string().min(1),
            // tslint:disable-next-line: object-literal-sort-keys
            otherwise: v.optional(),
        }),
        lastName: v.when('active', {
            is: true,
            then: v.string().min(1),
            // tslint:disable-next-line: object-literal-sort-keys
            otherwise: v.optional(),
        }),
    }),
);

const recipientValidator = v
    .object({
        address: addressValidator,
        businessId: businessIdValidator,
        company: companyValidator,
        contactPeople: contactPeopleValidator,
        einvoiceOvt: einvoiceValidator,
        email: emailValidator,
        firstName: personNameValidator,
        invoicingMethod: v.string().required(),
        lastName: personNameValidator,
        name: v.forbidden(),
    })
    .required();

export const invoiceValidator = v
    .object({
        dispatchDate: dispatchDateValidator,
        dueDate: dueDateValidator,
        invoiceItems: invoiceItemsValidator,
        occupation: occupationValidator,
        paymentTerm: paymentTermValidator,
        recipient: invoiceRecipientValidator,
        totalWithVat: v.number().min(0),
    })
    .unknown(true)
    .or('dueDate', 'paymentTerm');

const validators = {
    invoiceItem: invoiceItemValidator,
    invoiceItemsType: invoiceItemsTypeValidator,
    invoiceRecipient: invoiceRecipientValidator,
    occupation: occupationValidator,
    recipient: recipientValidator,
    template: templateValidator,
};

export default validators;
