import { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { DatePicker as KeyboardDatePicker, DateValidationError } from '@mui/x-date-pickers-pro';
import styled from 'styled-components';
import { COLOR_BLACKWATER, COLOR_GREY_FOG, COLOR_GREYJOY, FOCUS_COLOR } from 'styles/variables';
import { dateIsValid, formatDate, formatDateISO } from 'utils';
import { ValidationMessage } from '../textElements';
import moment from 'moment';
import { isBefore } from 'date-fns';

const Wrapper = styled.div`
    position: relative;

    & > div {
        width: 100%;
    }

    input {
        color: ${COLOR_BLACKWATER};
        &::placeholder {
            color: ${COLOR_GREYJOY};
        }
        height: 40px;
        box-sizing: border-box;
        padding: 0;

        &:focus {
            border-style: solid !important;
            border-color: ${FOCUS_COLOR} !important;
        }
        &:focus ~ div {
            border-style: solid !important;
            border-color: ${FOCUS_COLOR} !important;
        }
    }

    & button {
        padding: 0;
    }
    & button:hover {
        background-color: transparent;
    }
`;

interface IProps {
    buttonStyle?: CSSProperties;
    className?: string;
    disableBorder?: boolean;
    disabled?: boolean;
    disablePast?: boolean;
    disableToolbar?: boolean;
    errorPlacement?: 'tooltip' | 'underline';
    // TODO: not clear how to change icon. Need more investigation!
    icon?: [IconPrefix, IconName];
    id: string;
    inputStyle?: CSSProperties;
    keyboardOnly?: boolean;
    label: string;
    labelAbove?: string;
    lightBorder?: boolean;
    maxDate?: Date;
    minDate?: Date;
    onBlur?: ((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void) | undefined;
    onChange: (date: Date | null, value?: string | null | undefined) => void;
    onFocus?: ((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void) | undefined;
    onOpen?: () => void;
    placeholder: string;
    popoverProps?: any;
    required?: boolean;
    style?: CSSProperties;
    value?: string | Date;
    variant?: 'dialog' | 'inline' | 'static';
    hiddeOpenPickerIcon?: boolean;
}

export const DatePicker: FC<IProps> = ({
    disableBorder,
    disabled,
    disableToolbar,
    errorPlacement,
    id,
    lightBorder,
    maxDate,
    minDate,
    onChange,
    placeholder,
    required,
    style,
    value,
    hiddeOpenPickerIcon,
}) => {
    const { t } = useTranslation();
    const [dateValue, setDateValue] = useState<Date | undefined>(undefined);
    const [currentError, setCurrentError] = useState<DateValidationError | string | null>(null);

    useEffect(() => {
        if (value instanceof Date) setDateValue(value);
        else if (typeof value === 'string') {
            const parsedDate = Date.parse(value);
            if (!parsedDate) {
                setDateValue(undefined);
            } else {
                const d: Date = new Date(value);
                setDateValue(d);
            }
        } else setDateValue(undefined);
    }, [value]);

    const errorMessage = useMemo(() => {
        if (!currentError) return null;
        if (value instanceof Date) setDateValue(value);
        switch (currentError) {
            case 'maxDate':
                return maxDate
                    ? t('errors.date-not-late-enough', { date: formatDate(formatDateISO(maxDate)) })
                    : t('errors.date-invalid');
            case 'minDate': {
                return minDate
                    ? t('errors.date-not-early-enough', { date: formatDate(formatDateISO(minDate)) })
                    : t('errors.date-invalid');
            }
            case 'invalidDate': {
                return <ValidationMessage $error>{t('errors.date-invalid')}</ValidationMessage>;
            }
            default: {
                return null;
            }
        }
    }, [currentError]);

    const handleChange = (date: Date | null) => {
        date && setDateValue(date);

        if (date && minDate && isBefore(date, minDate)) {
            setDateValue(minDate);
            onChange(minDate);
        } else if (date && maxDate && isBefore(maxDate, date)) {
            setDateValue(maxDate);
            onChange(maxDate);
        } else if (!date || (date && dateIsValid(date))) {
            onChange(date);
        }
    };

    return (
        <Wrapper style={style}>
            <KeyboardDatePicker
                value={dateValue ?? null}
                label={null}
                // onClose={onBlur}
                onAccept={handleChange}
                maxDate={maxDate}
                minDate={minDate}
                onError={(error) => {
                    if (error) {
                        setCurrentError(error);
                    } else {
                        setCurrentError(null);
                    }
                }}
                slotProps={{
                    textField: {
                        variant: 'standard',
                        id: id,
                        placeholder,
                        // helperText: errorMessage,
                        sx: {
                            borderBottom:
                                disableBorder || disabled
                                    ? 'none'
                                    : `${lightBorder ? '1px' : '2px'} ${
                                          required ? 'solid' : 'dotted'
                                      } ${COLOR_GREY_FOG}`,
                            marginLeft: 0,
                            minHeight: 40,
                            '& .MuiIconButton-root': {
                                margin: 0,
                            },
                        },
                        // mui date picker doesn't have onBlur event so we need this,
                        // we don't want the onChange to fire for every keyboard stroke
                        onBlur: (event) => handleChange(moment(event.target.value, 'DD.MM.YYYY').toDate()),
                    },
                    openPickerIcon: {
                        sx: {
                            display: hiddeOpenPickerIcon ? 'none' : '',
                        },
                        // icon: <Icon icon={['far', 'calendar-day']} />
                    },
                    toolbar: {
                        hidden: disableToolbar,
                    },
                }}
            />
            {errorMessage && errorPlacement === 'underline' && (
                <ValidationMessage $error>{errorMessage}</ValidationMessage>
            )}
        </Wrapper>
    );
};
