import { FC, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { showModals } from 'actions/auth';
import { IRootState } from 'reducers';

interface IEezyPayTermsCheckerProps {
    children: ReactNode;
}

const EezyPayTermsChecker: FC<IEezyPayTermsCheckerProps> = ({ children}) => {
    const dispatch = useDispatch();

    const isIdentified = useSelector((state: IRootState) => state.user.isIdentified);
    const termsAccepted = useSelector((state: IRootState) => state.eezyPay.eezyPayTermsAccepted);
    const userLoadingComplete = useSelector((state: IRootState) => state.eezyPay.userLoadingComplete);

    useEffect(() => {
        if (!termsAccepted && isIdentified && userLoadingComplete) {
            dispatch(showModals(['EEZY_PAY']));
        }
    }, [isIdentified, termsAccepted, dispatch, userLoadingComplete]);

    return (
        isIdentified ? <>{children }</> : <Navigate to="/" />
    );
};

export default EezyPayTermsChecker;
