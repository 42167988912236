import { IMessageReferenceInput } from './../../../shared/src/types/message';
/*
    Helper method to submit visitor variables to Giosg chat. 
    https://developers.giosg.com/javascript_api.html
*/

export const submitVisitorVariable = (variable: object) => {
    const roomList: any[] = [];
    const giosg = (window as any).giosg;
    giosg.rooms.forEach((item: string) => {
        roomList.push({ id: item });
    });
    const _giosg = (window as any)._giosg;
    _giosg('visitor', 'submit', variable, 'plain', true, roomList);
};

export const formatUserId = (userId: number) =>
    `https://eezy-1.aavaohjelmistot.fi/aava/#people/${userId}`;

export const formatRelatedObject = (relatedObject: IMessageReferenceInput) => {
    switch (relatedObject.type) {
        case 'cost_invoice':
            return `https://eezy-1.aavaohjelmistot.fi/aava/#cost_invoices/${relatedObject.id}`;
        case 'invoice':
            return `https://eezy-1.aavaohjelmistot.fi/aava/?#sales_orders/${relatedObject.id}`;
        case 'salary_payment':
            return `https://eezy-1.aavaohjelmistot.fi/aava/#salary_payments/${relatedObject.id}`;
    }
};
