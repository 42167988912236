import { Factory } from 'fishery';
import moment from 'moment';
export const ALLOWED_CHART_LINE_NUMBER = 4;
export const StatisticEventLabels = [
    'Tammi',
    'Helmi',
    'Maalis',
    'Huhti',
    'Touko',
    'Kesä',
    'Heinä',
    'Elo',
    'Syys',
    'Loka',
    'Marras',
    'Joulu',
];

export const CHART_COMPARE_COLORS = ['#351F65', '#7D52D5', '#418B7E', '#DB2777', '#85e4eb'];

type StatisticTransientParams = {
    name: string;
    color: string;
};
export const StatisticEvent = Factory.define<any, StatisticTransientParams>(({ transientParams }) => {
    const { name, color } = transientParams;
    return {
        name,
        color: color || '#351F65',
        items: StatisticEventLabels.map((i) => ({
            label: i,
            value: Math.floor(Math.random() * 6000),
        })),
    };
});

export const DefaultMinDatePicker = moment({ year: 2012, month: 1 }).toDate();
