import { Divider } from '@mui/material';
import styled from 'styled-components';
import { COLOR_GREY_FOG, COLOR_WHITE_WALKER } from '../styles/variables';

export const Line = styled(Divider)`
    && {
        margin: 10px;
    }
`;

export const Line2 = styled.hr`
    height: 1px;
    background-color: ${props => (props.color ? props.color : COLOR_GREY_FOG)};
    margin: 15px 0 0 0;
`;

export const LineMiddle = styled.hr`
    background-color: ${props => (props.color ? props.color : COLOR_GREY_FOG)};
    flex-grow: 1;
    height: 1px;
    margin: 0 0 0 1em;
`;

export const StrongLine = styled.hr`
    height: 3px;
    background-color: ${props =>
        props.color ? props.color : COLOR_WHITE_WALKER};
    margin: 20px 0;
`;
