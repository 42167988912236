import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';
import { UiLanguage } from '../../../shared/src/types/user';
import { changeUiLang } from 'actions/user';
import { COLOR_LILA } from 'styles/variables';

const LanguageSection = styled.div`
    button {
        font-weight: bold;
        padding: 0 8px;

        &:hover,
        &.selected {
            text-decoration: underline;
        }

        &:focus {
            border-bottom: 1px solid ${COLOR_LILA};
        }
    }
    button:not(:first-child) {
        border-left: 2px solid ${COLOR_LILA};
    }
    button {
        color: ${COLOR_LILA};
        font-size: 16px;
    }
`;

interface ILanguageChangeProps {
    changeUiLang: (lang: UiLanguage) => void;
    language: UiLanguage;
    style?: CSSProperties;
}

const LanguageOptions = (props: ILanguageChangeProps) => {
    const { t } = useTranslation();
    return (
        <LanguageSection style={props.style}>
            <button
                className={props.language === 'fi' ? 'selected' : ''}
                aria-label={t('menu.lang-change-fi') || ''}
                onClick={() => props.changeUiLang('fi')}
            >
                FI
            </button>
            <button
                className={props.language === 'en' ? 'selected' : ''}
                aria-label={t('menu.lang-change-en') || ''}
                onClick={() => props.changeUiLang('en')}
            >
                EN
            </button>
        </LanguageSection>
    );
}

const mapStateToProps = (state: any) => {
    return {
        language: state.user.language
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        changeUiLang: (lang: UiLanguage) => {
            dispatch(changeUiLang(lang));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageOptions);
