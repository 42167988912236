import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { UiLanguage } from '../../../../shared/src/types/user';
import { confirmAction, IConfirmAction } from 'actions/app';
import { uploadInvoiceLogo } from 'actions/file';
import { changeUiLang } from 'actions/user';
import { Flex } from 'components/Flex';
import { WholePageBody } from 'components/layout/WholePageBody';
import { UniqueTitle } from 'components/textElements';
import { DELETE_ATTACHMENT, GET_ATTACHMENTS } from '../costs/queries';
import { GET_COWORKERS, GET_USER_DATA, UPDATE_USER_DATA } from './queries';
import { trans } from 'utils';
import { SCREEN_M } from 'styles/variables';
import styled from 'styled-components';
import GroupInvoicingInvitation from 'containers/profile/GroupInvoicingInvitation';
import { IRootState } from 'reducers';
import ProfileContact from 'containers/profile/ProfileContact';
import { ProfileSalary } from 'containers/profile/ProfileSalary';
import ProfileInsurances from 'containers/profile/ProfileInsurances';
import ProfileMarketing from 'containers/profile/ProfileMarketing';
import { ProfileSettings } from 'containers/profile/ProfileSettings';
import GroupInvoicing from 'containers/profile/GroupInvoicing';
import ProfileImportant from 'containers/profile/ProfileImportant';

const Panel = styled(Flex)`
    & {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        width: 100%;
    }
    & > div {
        width: 100%;
    }
    & > div ~ div {
        margin: 30px 0 0 0;
    }

    @media (min-width: ${SCREEN_M}px) {
        & {
            flex-wrap: nowrap;
        }
        & > div {
            width: calc((100% - 30px) / 2);
        }
        & > div ~ div {
            margin: 0 0 0 30px;
        }
    }
`;

interface IProfileProps {
    cardOpen?: string;
}

const Profile: FC<IProfileProps> = ({ cardOpen }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();

    const fileLoading = useSelector((state: IRootState) => state.file.loading);
    const invoiceLogo = useSelector((state: IRootState) => state.file.invoiceLogo);
    const language = useSelector((state: IRootState) => state.user.language);

    const { data: userData, refetch: refetchUserData, loading: profileLoading } = useQuery(GET_USER_DATA);

    const { data: contactsData } = useQuery(GET_COWORKERS);

    const [updateUserData] = useMutation(UPDATE_USER_DATA, {
        onCompleted: () => {
            toast(trans('general.saved'));
            refetchUserData();
        },
        onError: () => {
            toast.error(trans('errors.general'));
        },
    });

    const [deleteAttachment, { loading: deleteLoading }] = useMutation(DELETE_ATTACHMENT, {
        onCompleted: () => {
            refetchUserData();
        },
        onError: () => null,
        refetchQueries: () => [{ query: GET_ATTACHMENTS }],
    });

    useEffect(() => {
        if (invoiceLogo) {
            refetchUserData();
        }
    }, [invoiceLogo, refetchUserData]);

    const handleDelete = (fileId: number) => {
        deleteAttachment({ variables: { attachmentId: fileId } });
    };

    return (
        <WholePageBody>
            <UniqueTitle>{t('menu.profile')}</UniqueTitle>

            <GroupInvoicingInvitation language={language} contacts={contactsData?.coworkers} />
            <Panel>
                <ProfileContact
                    userData={userData?.userData}
                    defaultOpen={cardOpen === 'contact'}
                    refetchUserData={refetchUserData}
                />
                <ProfileSalary
                    userData={userData?.userData}
                    defaultOpen={cardOpen === 'salary'}
                    refetchUserData={refetchUserData}
                />
            </Panel>
            <Panel>
                <ProfileInsurances birthday={userData?.userData?.birthday} />
                <ProfileMarketing
                    deleteAttachment={handleDelete}
                    deleteLoading={deleteLoading}
                    fileLoading={fileLoading}
                    profileLoading={profileLoading}
                    uploadLogo={(data: FormData) => dispatch(uploadInvoiceLogo(data))}
                    userData={userData?.userData}
                    refetchUserData={refetchUserData}
                />
            </Panel>
            <Panel>
                <ProfileSettings
                    language={language}
                    changeLanguage={(lang: UiLanguage) => dispatch(changeUiLang(lang))}
                    confirmAction={(payload: IConfirmAction) => dispatch(confirmAction(payload))}
                    userData={userData?.userData}
                    updateUserData={updateUserData}
                />
                <GroupInvoicing contacts={contactsData?.coworkers} />
            </Panel>
            <Panel>
                <ProfileImportant />
            </Panel>
        </WholePageBody>
    );
};

export default Profile;
