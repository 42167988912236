import { InvoiceTemplate } from '../../../../shared/src/types/invoice';
import { round } from '../calc';
import { onSameDay } from './../time';
import { ICostState, isTravelByCar } from './costLogic';

export const formatForeignSalaryTypeForAutocompleteValue = (salaryType?: string) => {
    if (!salaryType) {
        return '';
    }
    if (!salaryType.includes('-taxable')) {
        return salaryType;
    }
    return salaryType.split('-taxable')[0];
};

export const getDistance = (state: ICostState): number => {
    return isTravelByCar(state.step)
        ? state.currentTravel?.reimbursedKMs?.quantity || 0
        : state.currentTravel?.totalKMs || 0;
};

const getTravelDays = (travelHours: number) => {
    return Math.floor(travelHours / 24);
};

export const getExtendedHours = (travelHours: number) => {
    return round(travelHours - getTravelDays(travelHours) * 24);
};

export const tooSmallDistance = (kilometers: number) => {
    return kilometers < 30;
};

export const isConstruction = (template: InvoiceTemplate, startTime?: string, endTime?: string) => {
    return template === 'reverse_vat_construction_industry' && onSameDay(startTime, endTime);
};

export const getMaxFullAllowances = (travelHours: number, distance: number) => {
    if (tooSmallDistance(distance)) {
        return 0;
    }
    const travelDays = getTravelDays(travelHours);
    const extendedHours = getExtendedHours(travelHours);
    return extendedHours >= 6 && travelHours >= 10 ? travelDays + 1 : travelDays;
};

export const getMaxFullAllowancesGivenHalfAllowances = (
    travelHours: number,
    halfAllowances: number,
    distance: number
) => {
    if (tooSmallDistance(distance)) {
        return 0;
    }
    const maxFull = getMaxFullAllowances(travelHours, distance);
    const ext = getExtendedHours(travelHours);
    if (maxFull === 0) {
        return 0;
    } else if (ext >= 2 && ext < 6) {
        return maxFull - (Math.max(halfAllowances, 1) - 1);
    } else {
        return maxFull - halfAllowances;
    }
};

export const getMaxHalfAllowances = (
    travelHours: number,
    fullAllowanceQuantity: number,
    distance: number
) => {
    if (tooSmallDistance(distance)) {
        return 0;
    }
    const ext = getExtendedHours(travelHours);
    if (travelHours < 6) {
        return 0;
    } else if (travelHours < 10) {
        return 1;
    } else {
        return ext < 2 || ext >= 6
            ? getMaxFullAllowances(travelHours, distance) - fullAllowanceQuantity
            : getMaxFullAllowances(travelHours, distance) - fullAllowanceQuantity + 1;
    }
};

export const getMaxMeals = (travelHours: number) => {
    const ext = getExtendedHours(travelHours);
    const d = getTravelDays(travelHours);
    return travelHours < 4 ? 0 : travelHours < 24 ? 1 : ext >= 2 ? 2 * d + 1 : 2 * d;
};

export const getMaxForeignAllowances = (travelHours: number) => {
    const travelDays = getTravelDays(travelHours);
    const extendedHours = getExtendedHours(travelHours);
    return extendedHours >= 10 ? travelDays + 1 : travelDays;
};
