import React from 'react';
import styled from 'styled-components';

import { IFile } from '../../../../shared/src/types/common';
import FileRow from '../../components/form/FileRow';
import { LineMiddle } from '../../components/Lines';
import { Tools, ToolsHeader } from '../../components/TogglingTools';
import { COLOR_BLACKWATER } from '../../styles/variables';

interface IProps {
    files?: IFile[];
    handleAttachmentClicked: (index: number) => void;
    handleDeleteFile: (index: number) => void;
}

const FileListWrapper = styled.div`
    padding: 0 10px;
`;

const InvoiceAttachmentAddList = (props: IProps) => {
    const { files } = props;
    return !files?.length ? null : (
        <Tools
            header={
                <ToolsHeader
                    titleTransKey="invoice.attachments"
                    disabled={false}
                    titleExtra={`(${files?.length})`}
                >
                    <LineMiddle color={COLOR_BLACKWATER} />
                </ToolsHeader>
            }
        >
            <FileListWrapper>
                {files.map((file, index: number) => (
                    <FileRow
                        name={file.name}
                        id={file.id}
                        handleFileNameClick={() =>
                            props.handleAttachmentClicked(index)
                        }
                        handleRemoval={() => props.handleDeleteFile(index)}
                        key={`attachment-row-${file.id}`}
                    />
                ))}
            </FileListWrapper>
        </Tools>
    );
};

export default InvoiceAttachmentAddList;
