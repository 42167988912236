import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IContractTemplate, UiLanguage } from '../../../../shared/src/types/user';
import { EezyButton, EezyLink } from 'components/Buttons';
import { Flex } from 'components/Flex';
import { Modal, ModalActions, ModalContent } from 'components/modals/Modal';
import { BodyP, LabelMedium } from 'components/textElements';
import { COLOR_BLUM } from 'styles/variables';
import { GET_CONTRACT_TEMPLATES } from './queries';

interface IProps {
    handleModalClose: () => void;
    isOpen: boolean;
    lang: UiLanguage;
}

const ContractsModal = (props: IProps) => {
    const { t } = useTranslation();
    const { data: contractsData } = useQuery(GET_CONTRACT_TEMPLATES, {
        onError: () => null,
        variables: { lang: props.lang }
    });

    return (
        <Modal
            id="modal-contracts"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={t('profile:important.contracts.title') || ''}
        >
            <ModalContent>
                <BodyP>{t('profile:important.contracts.modal-1')}</BodyP>

                <BodyP style={{ margin: '16px 0' }}>
                    {t('profile:important.contracts.modal-2')}
                </BodyP>

                {contractsData?.contractTemplates.map(
                    (contract: IContractTemplate, i: number) => {
                        return (
                            <Flex
                                spread
                                fullWidth
                                style={{ marginTop: 22 }}
                                key={`contract-${i}`}
                            >
                                <LabelMedium color={COLOR_BLUM}>
                                    {contract.name}
                                </LabelMedium>
                                <EezyLink
                                    color="purple"
                                    href={contract.url}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {t('profile:important.download')}
                                </EezyLink>
                            </Flex>
                        );
                    }
                )}
            </ModalContent>

            <ModalActions style={{ justifyContent: 'center' }}>
                <EezyButton color="purple" onClick={props.handleModalClose}>
                    {t('menu.close')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

const mapStateToProps = (state: any) => {
    return {
        lang: state.user.language
    };
};

export default connect(mapStateToProps)(ContractsModal);
