import React, { FormEvent } from 'react';
import { DatePickerYel, RadioInput } from '.';
import { IUnitInputProps, UnitElement } from './UnitElement';

interface IRadioInputWithUnitInputProps<T> {
    checked: boolean;
    unitOptions: IUnitInputProps;
    label: string;
    name: string;
    onChange: (e: FormEvent<HTMLInputElement>) => void;
    value: T;
}

const RadioInputWithUnitInput = <T,>(props: IRadioInputWithUnitInputProps<T>) => {
    const { checked, unitOptions, label, name, onChange, value } = props;

    if (checked) {
        return (
            <div style={{ display: 'flex' }}>
                <UnitElement name={name} onChange={unitOptions.onChange} value={unitOptions.value} />
            </div>
        );
    } else {
        return <RadioInput checked={checked} label={label} name={name} onChange={onChange} value={value} />;
    }
};

interface IDateOptions {
    label: string;
    onChange: (value: string) => void;
    selectedDate: string;
}

interface IRadioOption<T> {
    label: string;
    labelShort?: string;
    dateOptions?: IDateOptions;
    unitOptions?: IUnitInputProps;
    value: T;
}

interface IRadioElementProps<T> {
    name: string;
    onChange: (e: FormEvent<HTMLInputElement>) => void;
    options: IRadioOption<T>[];
    selectedOption: T;
}

export const RadioElement = <T,>(props: IRadioElementProps<T>) => {
    const { name, onChange, options, selectedOption } = props;

    return (
        <>
            {options.map((option: IRadioOption<T>) => {
                if (option.dateOptions) {
                    return (
                        <DatePickerYel
                            key={String(option.value)}
                            label={option.label}
                            labelShort={option.labelShort}
                            onChange={option.dateOptions.onChange}
                            selectedDate={option.dateOptions.selectedDate}
                        />
                    );
                } else if (option.unitOptions) {
                    return (
                        <RadioInputWithUnitInput
                            checked={selectedOption === option.value}
                            unitOptions={option.unitOptions}
                            key={String(option.value)}
                            label={option.label}
                            name={name}
                            onChange={onChange}
                            value={option.value}
                        />
                    );
                } else {
                    return (
                        <RadioInput
                            checked={selectedOption === option.value}
                            key={String(option.value)}
                            label={option.label}
                            name={name}
                            onChange={onChange}
                            value={option.value}
                        />
                    );
                }
            })}
        </>
    );
};
