import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HelpButton } from 'styles/yelStyles';
import { cn } from 'utils';

interface YelQuestionWrapperProps {
    children: React.ReactNode;
    helpText?: string | JSX.Element[];
    label?: string;
    className?: string;
    containerClassName?: string;
}

export const YelQuestion = (props: YelQuestionWrapperProps) => {
    const { t } = useTranslation();
    return (
        <div className={props.containerClassName}>
            {props.label && (
                <div className="flex items-center justify-between">
                    <h2 className="mr-2 font-bold mb-2">{props.label}</h2>
                    {props.helpText && (
                        <Popover>
                            <PopoverTrigger asChild>
                                <HelpButton>{t('general.help')}</HelpButton>
                            </PopoverTrigger>
                            <PopoverContent className="sm:w-[450px]">{props.helpText}</PopoverContent>
                        </Popover>
                    )}
                </div>
            )}
            <div className={cn('flex flex-col sm:flex-row *:flex-1 gap-4', props.className)}>
                {props.children}
            </div>
        </div>
    );
};
