import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IFile } from '../../../../shared/src/types/common';
import { IMessage, IThread } from '../../../../shared/src/types/message';
import { EezyButton } from '../../components/Buttons';
import { Flex } from '../../components/Flex';
import { Icon } from '../../components/Icon';
import { Line2 } from '../../components/Lines';
import { LinkItUrl } from 'react-linkify-it';
import {
    BodyP,
    BodyPSmall,
    LabelMedium,
    LabelStrong,
    PSmall,
    UniqueTitle,
} from '../../components/textElements';
import { COLOR_GREY_FOG, COLOR_GREYJOY, COLOR_GRIPE } from '../../styles/variables';
import { formatDateAndTime, trans } from '../../utils';
import { getRelatedObjectIcon, getRelatedObjectUrl } from '../../utils/support/messageUtils';
import { Message } from './Message';

interface IProps {
    onReply?: () => void;
    thread: IThread;
}

const MessageThread = (props: IProps) => {
    const navigate = useNavigate();
    const handleCancel = () => {
        navigate('/support');
    };

    return (
        <Message>
            <div className="header">
                <UniqueTitle color="black">{trans('support:message.title')}</UniqueTitle>

                <div className="button-container">
                    <EezyButton color="purple" onClick={handleCancel} className="close-button">
                        {trans('menu.close')}
                    </EezyButton>
                    {props.onReply && (
                        <EezyButton color={'purple'} dark onClick={props.onReply} className="reply-button">
                            {trans('support:message.reply')}
                        </EezyButton>
                    )}
                </div>
            </div>

            <div className="body">
                <LabelStrong data-mf-replace="**REMOVED**" color="black">
                    {props.thread.title}
                </LabelStrong>
                <Line2 color={COLOR_GREY_FOG} style={{ opacity: 1 }} />

                {props.thread.messages.map((message: IMessage) => {
                    return (
                        <div
                            key={`message-${message.id}`}
                            className={'message ' + (message.isFromEezy ? 'from-eezy' : '')}
                        >
                            <BodyPSmall color={COLOR_GREYJOY} className="time">
                                {formatDateAndTime(message.createdAt)}
                            </BodyPSmall>
                            {message.isFromEezy && (
                                <LabelMedium color={COLOR_GRIPE}>
                                    {trans('support:message.from-eezy')}
                                </LabelMedium>
                            )}
                            <BodyP data-mf-replace="**REMOVED**" className="content">
                                <LinkItUrl>{message.content}</LinkItUrl>
                            </BodyP>
                            {(message.files.length > 0 || message.relatedObject) && (
                                <Flex column className="attachments">
                                    <BodyPSmall color={COLOR_GREYJOY}>
                                        {trans('support:message.attachments')}
                                    </BodyPSmall>

                                    {message.relatedObject && (
                                        <Flex center>
                                            <Icon
                                                icon={getRelatedObjectIcon(message.relatedObject)}
                                                color={COLOR_GRIPE}
                                            />
                                            <PSmall color={COLOR_GRIPE}>
                                                {getRelatedObjectUrl(message.relatedObject) ? (
                                                    <Link
                                                        className="unstyled"
                                                        to={getRelatedObjectUrl(message.relatedObject) || ''}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    >
                                                        {trans(
                                                            `support:message.related-object-text.${message.relatedObject.type}`,
                                                            {
                                                                id: message.relatedObject.id,
                                                            },
                                                        )}
                                                    </Link>
                                                ) : (
                                                    trans(
                                                        `support:message.related-object-text.${message.relatedObject.type}`,
                                                        {
                                                            id: message.relatedObject.id,
                                                        },
                                                    )
                                                )}
                                            </PSmall>
                                        </Flex>
                                    )}
                                    {message.files.map((file: IFile) => {
                                        return (
                                            <Flex center key={`file-${file.id}`}>
                                                <Icon icon={['far', 'paperclip']} color={COLOR_GRIPE} />
                                                <PSmall color={COLOR_GRIPE}>
                                                    <a
                                                        className="unstyled"
                                                        href={file.previewUrl}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    >
                                                        {file.name}
                                                    </a>
                                                </PSmall>
                                            </Flex>
                                        );
                                    })}
                                </Flex>
                            )}
                        </div>
                    );
                })}
            </div>

            <style>{`
                .header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .button-container {
                    display: flex;
                    align-items: center;
                }

                .close-button {
                    margin-right: 8px;
                }

                @media (max-width: 600px) {
                    .header {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .button-container {
                        margin-top: 16px;
                    }
                }
            `}</style>
        </Message>
    );
};

export default MessageThread;
