import { IBriefInvoice } from '../../../../shared/src/types/invoice';
import { StatisticInvoice } from './types/SalesPageStatisticTypes';
import { CHART_COMPARE_COLORS } from './validation/constants';

export const randomSimleId = () => {
    return Math.floor(Math.random() * 1e7);
}

export const getNewDataColor = (currentData: Array<any>, index?: number) => {
    if (index !== undefined) {
        return CHART_COMPARE_COLORS[index];
    }
    const color = CHART_COMPARE_COLORS.find((c) => currentData.every((item) => item.color !== c));
    return color || 'black';
};

export const isFullCreditedInvoice = (invoice: StatisticInvoice) => invoice.status === 'credited';

export const isPartlyCreditedInvoice = (invoice: StatisticInvoice) => invoice.partlyCredited;

export const checkInvoiceAcceptForStatistics = (invoice: StatisticInvoice) => [
    "completed", "paid", "overdued"
].includes(invoice.status);

export const calculateCreditedPrices = (invoice: StatisticInvoice | IBriefInvoice) => {
    const originalPriceWithVAT = invoice.totalWithVat;
    const originalPriceWithoutVAT = invoice.total;
    const paidAmount = invoice.paidAmount ?? 0;

    // Calculate the VAT percentage
    const vatPercentage = ((originalPriceWithVAT - originalPriceWithoutVAT) / originalPriceWithoutVAT) * 100;

    // Calculate the credited price without VAT
    const creditedPriceWithoutVAT = paidAmount / (1 + vatPercentage / 100);

    // Calculate the credited price with VAT
    const creditedPriceWithVAT = paidAmount;

    return {
        creditedPriceWithoutVAT: +creditedPriceWithoutVAT.toFixed(2),
        creditedPriceWithVAT: +creditedPriceWithVAT.toFixed(2),
        vatPercentage: +vatPercentage.toFixed(2),
    };
};

export const groupInvoices = (invoices: StatisticInvoice[], getKey: (item: StatisticInvoice) => string): { [key: string]: StatisticInvoice } => {
    const items = invoices
        .filter(checkInvoiceAcceptForStatistics)
        .map((i) => {
            if (isPartlyCreditedInvoice(i)) {
                const credit = calculateCreditedPrices(i);
                return {
                    ...i,
                    total: credit.creditedPriceWithoutVAT,
                    totalWithVat: credit.creditedPriceWithVAT,
                };
            }

            return i;
        }); // Clone array because it is read-only
    
    // Group by period
    const byGroups: { [key: string]: StatisticInvoice } = {};

    items.forEach((i) => {
        const key = getKey(i);

        if (!byGroups[key]) {
            byGroups[key] = { ...i };
        } else {
            const item = byGroups[key];

            item.paidAmount += i.paidAmount;
            item.total += i.total;
            item.totalWithVat += i.totalWithVat;
        }
    });

    for (const k in byGroups) {
        const item = byGroups[k];
        item.paidAmount *= 0.01;
        item.total *= 0.01;
        item.totalWithVat *= 0.01;
    }

    return byGroups;
}