import { gql } from "@apollo/client";

const CREATE_MESSAGE_THREAD = gql`
    mutation CreateMessageThread($message: MessageInput!) {
        createThread(message: $message) {
            id
        }
    }
`;

export default CREATE_MESSAGE_THREAD;
