import { ApolloClient } from '@apollo/client';
import React from 'react';
import { SCREEN_S } from '../../../styles/variables';
import { SELECTED_FILL_HELPER } from '../queries/invoiceStateQueries';
import EditRecipientFillHelper from './EditRecipientFillHelper';
import InvoiceFillHelper from './InvoiceFillHelper';
import InvoiceRowsFillHelper from './InvoiceRowsFillHelper';
import NewRecipientFillHelper from './NewRecipientFillHelper';
import RecipientFillHelperChangeOrEdit from './RecipientFillHelperChangeOrEdit';
import YtjRecipientFillHelper from './YtjRecipientFillHelper';

interface IFillHelper {
    id: string;
    type: string;
}

const fillhelpersAffectedBySwitch = ['invoice', 'invoiceRow'];

// fillhelper switch disables invoice and invoice rows fillhelpers
// on larger screens

export const isDesktopSize = () => {
    try {
        return window.innerWidth >= SCREEN_S;
    } catch {
        return true;
    }
};

const shouldHideFh = (fillHelper?: IFillHelper, fillhelperOn?: boolean) => {
    return (
        !fillHelper ||
        (!fillhelperOn &&
            isDesktopSize() &&
            fillhelpersAffectedBySwitch.includes(fillHelper?.type))
    );
};

export const getFillhelper = (
    fillHelper?: IFillHelper,
    editable?: boolean,
    invoiceId?: number,
    showModals?: (types: string[]) => void,
    fillhelperOn?: boolean
): React.ReactNode => {
    if (shouldHideFh(fillHelper, fillhelperOn)) {
        return null;
    }

    switch (fillHelper?.type) {
        case 'recipient':
            if (editable) {
                return (
                    <EditRecipientFillHelper
                        invoiceId={invoiceId}
                        showModals={showModals}
                        recipientId={fillHelper?.id}
                    />
                );
            } else {
                return (
                    <EditRecipientFillHelper
                        invoiceId={invoiceId}
                        showModals={showModals}
                        recipientId={fillHelper?.id}
                        readOnly
                    />
                );
            }
        case 'new-recipient':
            return (
                <NewRecipientFillHelper
                    invoiceId={invoiceId}
                    showModals={showModals}
                />
            );
        case 'new-ytj-recipient':
            return (
                <YtjRecipientFillHelper
                    invoiceId={invoiceId}
                    showModals={showModals}
                    ytjBusinessId={fillHelper?.id}
                />
            );
        case 'recipient-switch-or-edit':
            //  on mobile ask first whether to edit or change the client
            return (
                <RecipientFillHelperChangeOrEdit
                    invoiceId={invoiceId}
                    recipientId={fillHelper?.id}
                />
            );
        case 'invoice':
            return (
                <InvoiceFillHelper
                    invoiceId={invoiceId}
                    showModals={showModals}
                />
            );
        case 'invoiceRow':
            return (
                <InvoiceRowsFillHelper
                    invoiceId={invoiceId}
                    rowId={fillHelper?.id}
                    showModals={showModals}
                />
            );
        default:
            return null;
    }
};

export const closeAllFillHelpers = (client: ApolloClient<any>) => {
    client.writeQuery({
        data: {
            fillHelper: {
                __typename: 'FillHelper',
                id: null,
                type: null
            }
        },
        query: SELECTED_FILL_HELPER
    });
};

export const openFillHelper = (
    client: ApolloClient<any>,
    type: string,
    id?: string
) => {
    client.writeQuery({
        data: {
            fillHelper: {
                __typename: 'FillHelper',
                id: id || null,
                type
            }
        },
        query: SELECTED_FILL_HELPER
    });
};
