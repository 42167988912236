import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
    ADD_COST_FILE,
    ADD_INVOICE_ATTACHMENT,
    ADD_INVOICE_LOGO,
    ADD_MESSAGE_ATTACHMENT,
    ADD_TAX_CARD,
    DELETE_COST_FILE_ID,
    DELETE_INVOICE_ATTACHMENT,
    DELETE_MESSAGE_ATTACHMENT,
    FILE_UPLOAD_BEGIN,
    FILE_UPLOAD_FAILURE,
    FILE_UPLOAD_FAILURE_HIDE
} from '../actionTypes';
import { fileAPI } from '../utils/API';
import { ERROR_FILE_TOO_LARGE, ERROR_SIMPLE } from '../utils/error';

const costReceiptPath = '/receipt';
const taxCardPath = '/taxcard';
const invoiceLogoPath = '/invoice-logo';
const invoiceAttachmentPath = '/invoice-attachment';
const messageAttachmentPath = '/message-attachment';

export const uploadCostReceipt = (data: FormData) => async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
) => {
    dispatch(uploadBegin());

    await fileAPI
        .post(costReceiptPath, data)
        .then((response: any) => {
            dispatch({
                payload: {
                    id: response.data.id,
                    name: (data.get('file') as File).name,
                    url: response.data.url
                },
                type: ADD_COST_FILE
            });
        })
        .catch((err: any) => {
            if (
                err?.response?.data?.message &&
                err?.response?.data?.message.includes('File is too large')
            ) {
                dispatch(uploadFailure(ERROR_FILE_TOO_LARGE));
            } else {
                dispatch(uploadFailure(ERROR_SIMPLE));
            }
        });
};

export const uploadTaxCard = (data: FormData) => async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
) => {
    dispatch(uploadBegin());

    await fileAPI
        .post(taxCardPath, data)
        .then((response: any) => {
            dispatch({
                payload: {
                    id: response.data.id,
                    name: (data.get('file') as File).name,
                    url: response.data.url
                },
                type: ADD_TAX_CARD
            });
        })
        .catch((err: any) => {
            dispatch(uploadFailure(ERROR_SIMPLE));
        });
};

export const uploadInvoiceLogo = (data: FormData) => async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
) => {
    dispatch(uploadBegin());

    await fileAPI
        .post(invoiceLogoPath, data)
        .then((response: any) => {
            dispatch({
                payload: {
                    id: response.data.id,
                    name: (data.get('file') as File).name,
                    url: response.data.url
                },
                type: ADD_INVOICE_LOGO
            });
        })
        .catch(() => {
            dispatch(uploadFailure(ERROR_SIMPLE));
        });
};

export const uploadInvoiceAttachment = (data: FormData) => async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
) => {
    dispatch(uploadBegin());

    await fileAPI
        .post(invoiceAttachmentPath, data)
        .then((response: any) => {
            dispatch({
                payload: {
                    id: response.data.id,
                    name: response.data.name,
                    size: response.data.size,
                    url: response.data.url
                },
                type: ADD_INVOICE_ATTACHMENT
            });
        })
        .catch((err: any) => {
            if (
                err?.response?.data?.message &&
                err?.response?.data?.message.includes('File is too large')
            ) {
                dispatch(uploadFailure(ERROR_FILE_TOO_LARGE));
            } else {
                dispatch(uploadFailure(ERROR_SIMPLE));
            }
        });
};

export const uploadMessageAttachment = (data: FormData) => async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
) => {
    dispatch(uploadBegin());

    await fileAPI
        .post(messageAttachmentPath, data)
        .then((response: any) => {
            dispatch({
                payload: {
                    id: response.data.id,
                    name: (data.get('file') as File).name,
                    url: response.data.url
                },
                type: ADD_MESSAGE_ATTACHMENT
            });
        })
        .catch((err: any) => {
            dispatch(uploadFailure(ERROR_SIMPLE));
        });
};

export const markAttachmentAsUsed = (id: number) => ({
    payload: { id },
    type: DELETE_INVOICE_ATTACHMENT
});

export const markCostFileAsUsed = (id: number) => ({
    payload: { id },
    type: DELETE_COST_FILE_ID
});

export const deleteMessageAttachment = (id: number) => ({
    payload: { id },
    type: DELETE_MESSAGE_ATTACHMENT
});

export const uploadBegin = () => ({
    type: FILE_UPLOAD_BEGIN
});

export const uploadFailure = (errorType: string) => ({
    payload: { errorType },
    type: FILE_UPLOAD_FAILURE
});

export const hideFileError = () => ({
    type: FILE_UPLOAD_FAILURE_HIDE
});
