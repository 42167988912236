import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import styled from 'styled-components';
import { EezyButton } from 'components/Buttons';
import { Icon } from 'components/Icon';
import { COLOR_BLUM, COLOR_LILA, COLOR_LILA_LIGHT, COLOR_WHITE_WALKER } from 'styles/variables';
import { buildVersion } from 'utils/version';
import { GET_BACKEND_VERSION } from './queries';

const Wrapper = styled.div`
    background-color: ${COLOR_WHITE_WALKER};
    bottom: 0;
    left: 0;
    padding: 0;
    position: fixed;
    right: 0;
    z-index: 4;

    & > div {
        background-color: ${COLOR_LILA_LIGHT};
        color: ${COLOR_BLUM};
        font-size: 15px;
        padding: 15px;
        text-align: center;

        & button {
            margin-left: 20px;
        }
    }
`;

const VersionMismatch = () => {
    const { t } = useTranslation();
    const [frontEndVersion, setFrontEndVersion] = useState<string>();

    const [getBackendVersion, { loading, data, refetch }] = useLazyQuery(
        GET_BACKEND_VERSION
    );
    useEffect(() => {
        const version = buildVersion?.split(' ')?.[0];
        setFrontEndVersion(version);
    }, []);

    useEffect(() => {
        getBackendVersion();
        const interval = setInterval(() => {
            if (refetch) {
                refetch();
            }
        }, 3 * 60 * 1000); // 3 minutes
        return () => {
            clearInterval(interval);
        };
    }, [getBackendVersion, refetch]);

    const handleUpdate = () => {
        window.location.reload();
    };

    return (
        <>
            {!loading &&
                frontEndVersion !== data?.backendVersion?.split(' ')?.[0] && (
                    <Wrapper>
                        <div>
                            {t('errors.update-available')}
                            <EezyButton
                                color="purple"
                                dark
                                hasIcon
                                iconAlignment="right"
                                onClick={handleUpdate}
                                width={130}
                            >
                                {t('errors.update-available-button')}
                                <Icon
                                    color={COLOR_LILA}
                                    icon={['fas', 'redo']}
                                />
                            </EezyButton>
                        </div>
                    </Wrapper>
                )}
        </>
    );
};

export default VersionMismatch;
