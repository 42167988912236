import React from 'react';
import { Icon } from 'components/Icon';
import { SalesPageStatisticData } from 'containers/eezypay/types/SalesPageStatisticTypes';
import { formatCents } from 'utils/money';

interface ISalesStatsBillingDetails {
    data: SalesPageStatisticData[];
    subtext: string;
}

const BillingDetailsMain = (props: ISalesStatsBillingDetails) => {
    const data = props.data?.[0] ?? { items: [] };

    const sum = data.items.reduce((r, x) => r + (isNaN(x.value) ? 0 : x.value), 0);

    return (
        <div className="flex items-center">
            <div style={{ width: '65px' }} className="pl-1">
                <Icon className="medium" icon={['far', 'coins']} color={'#AB9AEB'} width={30} height={30} />
            </div>
            <div>
                <h3
                    className="font-semibold whitespace-nowrap"
                    style={{ fontSize: '1.75rem', lineHeight: '2.625rem', color: '#351F65' }}
                >
                    {formatCents(sum * 100, true)} €
                </h3>
                <p className="text-sm" style={{ color: '#71717A' }}>
                    {props.subtext}
                </p>
            </div>
        </div>
    );
};

const BillingDetailsSubs = (props: ISalesStatsBillingDetails) => {
    const list = props.data.slice(1);

    return (
        <>
            {list.map((i) => {
                const sum = i.items.reduce((r, x) => r + (isNaN(x.value) ? 0 : x.value), 0);

                return (
                    <div key={i.name}>
                        <h3
                            className="text-xl font-semibold whitespace-nowrap"
                            style={{ lineHeight: '2.625rem', color: i.color }}
                        >
                            {formatCents(sum * 100, true)} €
                        </h3>
                        <p className="text-sm capitalize" style={{ color: '#71717A' }}>
                            {i.name}
                        </p>
                    </div>
                );
            })}
        </>
    );
};

const SalesStatsBillingDetails = (props: ISalesStatsBillingDetails) => {
    return (
        <div className="flex items-center gap-8 items-stretch max-w-full overflow-auto">
            <BillingDetailsMain data={props.data} subtext={props.subtext} />
            <div className="w-px" style={{ background: '#D4D4D8' }}></div>
            <BillingDetailsSubs data={props.data} subtext={props.subtext} />
        </div>
    );
};

export default SalesStatsBillingDetails;
