import { CSSProperties, HTMLAttributes, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import styled from 'styled-components';
import {
    COLOR_BLUM,
    COLOR_DARK_GRAY,
    COLOR_IMPORTANT,
    COLOR_WHITE_WALKER,
    FOCUS_COLOR,
} from 'styles/variables';
import ErrorPointer from '../ErrorPointer';
import { Icon } from '../Icon';

const useStyles = makeStyles()({
    root: {
        border: 'none',
        '& fieldset': { border: 'none' },
        '& .MuiInputBase-root': { padding: '0' },
    },
    listbox: {
        padding: 0,
    },
    option: {
        border: 0,
        margin: 0,
        padding: '7px 7px',

        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: COLOR_WHITE_WALKER,
            color: COLOR_IMPORTANT,
        },
    },
});

export const StyledField = styled(TextField).attrs(() => ({
    autoComplete: 'off',
}))`
    &&&& {
        vertical-align: baseline;
        width: 100%;

        input {
            box-sizing: border-box;
            color: ${COLOR_DARK_GRAY};
            font-size: 15px;
            height: auto;
            padding: 0;
            text-align: left;
            width: 100%;

            &:focus {
                border-color: ${FOCUS_COLOR} !important;
            }
        }

        &.right input {
            text-align: right;
        }
        &.endAdornment input {
            padding-right: 15px;
            text-align: right;
        }
    }
`;

export interface IDropdownOption {
    disabled?: boolean;
    label: string;
    value: string;
}

interface IProps {
    align?: 'left' | 'right';
    disabled?: boolean;
    endAdornment?: string;
    error?: any;
    errorPointer?: boolean;
    getOptionLabel?: (option: IDropdownOption) => string;
    iconColor?: string;
    id?: string;
    renderInput?: (params: AutocompleteRenderInputParams) => ReactNode;
    name?: string;
    options: IDropdownOption[];
    onChange: (val: string | number) => void;
    onBlur?: (val: string | number) => void;
    onFocus?: () => void;
    onInputChange?: () => void;
    renderOption?: (option: IDropdownOption | HTMLAttributes<HTMLLIElement>) => string;
    showIcon?: boolean;
    style?: CSSProperties;
    styleInput?: CSSProperties;
    type: string;
    value: string;
    clearable?: boolean;
}

export const AutocompleteDropdown = (props: IProps) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const hasErrorClass = props.error ? 'hasError' : '';
    const endAdornmentClass = props.endAdornment ? 'endAdornment' : '';
    const alignClass = props.align ? props.align : '';

    const handleInputChange = (event: object, insert: string, reason: string) => {
        const matches = props.options.filter((opt) => opt.value === insert || opt.label.trim() === insert);
        if (reason === 'input' && matches.length === 1) {
            if (props.type === 'number') {
                props.onChange(parseInt(matches[0].value, 10));
            } else {
                props.onChange(matches[0].value);
            }
        }
    };

    return (
        <Autocomplete
            autoSelect
            blurOnSelect={false}
            classes={{ root: classes.root, listbox: classes.listbox, option: classes.option }}
            disabled={props.disabled}
            disableClearable={props.clearable ? false : true}
            getOptionDisabled={(option: IDropdownOption) => option.disabled || false}
            getOptionLabel={
                props.getOptionLabel
                    ? props.getOptionLabel
                    : (option: IDropdownOption) => option?.label.trim()
            }
            filterOptions={(options: IDropdownOption[], { inputValue }: { inputValue: string }) => {
                return options.filter((opt: IDropdownOption) => {
                    return (
                        opt.label.trim().toLowerCase().includes(inputValue.toLowerCase()) ||
                        opt.value.includes(inputValue.toLowerCase())
                    );
                });
            }}
            forcePopupIcon={props.showIcon || false}
            id={props.id}
            loadingText={t('general.loading')}
            noOptionsText={t('general.no-options')}
            onChange={(event: React.ChangeEvent<{}>, val: IDropdownOption | null, reason) => {
                if (reason === 'clear') {
                    props.onChange('');
                }

                if (val && val.value) {
                    if (props.type === 'number') {
                        props.onChange(parseInt(val.value, 10));
                    } else {
                        props.onChange(val.value);
                    }
                }
            }}
            onFocus={props.onFocus}
            onInputChange={props.onInputChange || handleInputChange}
            options={props.options}
            popupIcon={<Icon icon={['fal', 'chevron-down']} color={COLOR_BLUM} style={{ opacity: 0.4 }} />}
            renderInput={(params) => (
                <>
                    {props.renderInput ? (
                        props.renderInput(params)
                    ) : (
                        <>
                            {props.error && props.errorPointer && <ErrorPointer />}
                            <StyledField
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    'aria-labelledby': props.name,
                                    style: props.styleInput,
                                }}
                                error={!!props.error}
                                className={`${hasErrorClass} ${endAdornmentClass} ${alignClass}`}
                            />
                            {props.endAdornment && <span className="endAdornment">{props.endAdornment}</span>}
                        </>
                    )}
                </>
            )}
            renderOption={props.renderOption}
            style={props.style}
            value={props.options.find((o) => o.value === props.value) || undefined}
        />
    );
};
