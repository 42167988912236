import { YelStatusType } from '../../../../shared/src/types/yel';

export const isActive = (status?: YelStatusType) => status === 'active';

export const isActivationRequested = (status?: YelStatusType) => status === 'activation_requested';

export const isHandledIndependently = (status?: YelStatusType) => status === 'handled_independently';

export const isIncompleteData = (status?: YelStatusType) => status === 'incomplete_data';

export const hasNoInsurance = (status?: YelStatusType) =>
    status === 'no_insurance';

export const isUnknown = (status?: YelStatusType) => status === 'unknown';

export const isTerminated = (status?: YelStatusType) => status === 'terminated';

export const isTerminationRequested = (status?: YelStatusType) =>
    status === 'termination_requested';

// Combine statuses into meanings:

export const hasTerminatedYEL = (status?: YelStatusType) => {
    return isTerminationRequested(status) || isTerminated(status);
};

export const yelIsOn = (status?: YelStatusType) => {
    return isActive(status) || isTerminationRequested(status);
};
