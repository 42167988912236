import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { EezyButton } from 'components/Buttons';
import { Icon } from 'components/Icon';
import { Flex } from 'components/Flex';
import CardTitle from 'components/cards/CardTitle';
import GET_PAYMENTS_BY_DATE from 'containers/eezypay/queries/getPaymentsByDate';
import { currentMonth, currentYear, formatCents, formatPercentage, priceWithoutVat, trans } from 'utils';
import { BodyPSmall, Bold, LabelThin, RowTitle, Span } from 'components/textElements';
import { GET_INCOME } from './queries';
import { LabelValueType } from 'containers/eezypay/types/SalesPageStatisticTypes';
import { useStatisticInvoice } from 'containers/invoice/hooks/useStatisticInvoice';
import { groupInvoices, randomSimleId } from 'containers/eezypay/utils';
import { DashboardCard } from 'components/cards/DashboardCard';
import { isDesktopSize } from 'containers/invoice/fillHelpers/utils';
import { IIncomeSummary } from '../../../../shared/src/types/invoice';
import {
    BORDER_RADIUS,
    COLOR_BLACKWATER,
    COLOR_BLUM,
    COLOR_DARK_GRAY,
    COLOR_GREYHOUND,
    COLOR_GREYJOY,
    COLOR_STATUS_DONE,
    COLOR_EEZY_BORDER_GRAY_200,
    COLOR_WHITE_WALKER,
    SCREEN_M,
    SCREEN_S,
} from 'styles/variables';
import TabHeaders from 'components/tabs/TabHeaders';
import SalesStatsCard from 'components/cards/SalesStatsCard';
import LoadingSpinner from 'components/Loading';
import { ITransaction } from 'containers/eezypay/Transactions';

interface IDashboardSalesCard {
    language?: string;
}

interface IServiceFeeProps {
    current: number;
    minLimit: number;
    maxLimit?: number;
    serviceFee: number;
}

enum SalesTabEnum {
    Laskutus,
    EezyPay,
}

const serviceFeeSteps = [
    {
        maxLimit: 5000000,
        minLimit: 0,
        serviceFee: 7,
    },
    {
        maxLimit: 15000000,
        minLimit: 5000000,
        serviceFee: 6,
    },
    {
        maxLimit: 30000000,
        minLimit: 15000000,
        serviceFee: 5.5,
    },
    {
        minLimit: 30000000,
        serviceFee: 5,
    },
];

const Ball = styled.div`
    width: 35px;
    height: 35px;
    background-color: ${COLOR_GREYJOY};
    font-size: 13px;
    font-weight: 600;
    color: ${COLOR_WHITE_WALKER};
    border-radius: 50%;
    text-align: center;
    line-height: 35px;
    &.active {
        width: 45px;
        height: 45px;
        line-height: 45px;
        background-color: ${COLOR_STATUS_DONE};
        color: ${COLOR_BLUM};
        margin-right: -8px;
    }
`;

const Connector = styled.div`
    width: 4px;
    background-color: ${COLOR_GREYJOY};
    position: absolute;
    z-index: 0;
    right: 30px;
    top: 30px;
    bottom: 30px;
`;

const ServiceFeeBox = styled(Flex)`
    padding: 10px 15px;
    z-index: 1;

    &.active {
        background-color: rgba(255, 255, 255, 0.8);
        border: 2px solid ${COLOR_STATUS_DONE};
        border-radius: ${BORDER_RADIUS};
    }
`;

const ServiceFeeBlock = (props: IServiceFeeProps) => {
    const previous = props.maxLimit && props.current >= props.maxLimit;
    const active = props.current >= props.minLimit && (!props.maxLimit || props.current < props.maxLimit);
    const upcoming = props.current < props.minLimit;
    const classes = active ? 'active' : '';
    return (
        <ServiceFeeBox className={classes} spread>
            <Flex column>
                <LabelThin
                    color={upcoming ? COLOR_GREYHOUND : COLOR_GREYJOY}
                    style={{
                        marginTop: !active ? 8 : 0,
                        textDecoration: previous ? 'line-through' : 'none',
                    }}
                >
                    {!props.maxLimit
                        ? trans('salaries.moreThan300000')
                        : props.maxLimit === 5000000
                        ? trans('salaries.lessThan50000')
                        : props.maxLimit === 15000000
                        ? trans('salaries.moreThan50000')
                        : trans('salaries.moreThan150000')}
                </LabelThin>
                {active && (
                    <>
                        <div>
                            <Money id="income-year">{formatCents(props?.current, true)}</Money>
                            <Unit>{trans('form.eurs', { eurs: '' })}</Unit>
                        </div>
                        <LabelThin color={COLOR_DARK_GRAY}>{trans('salaries.cumulativeInvoicing')}</LabelThin>
                    </>
                )}
            </Flex>
            <Flex column justifyCenter>
                <Ball className={classes}>
                    <span>{formatPercentage(props.serviceFee)}%</span>
                </Ball>
            </Flex>
        </ServiceFeeBox>
    );
};

const Money = styled(Bold)`
    color: ${COLOR_BLUM};
    font-size: 16px;
    line-height: 16px;
`;
const Unit = styled(Span)`
    color: ${COLOR_BLACKWATER};
    font-size: 16px;
    line-height: 16px;
`;
const SectionSeparator = styled.div`
    @media (min-width: ${SCREEN_S}px) {
        width: 1px;
        background: #e3e3e3;
        margin: 0 32px;
    }
`;
const DashboardSalesBillingHistoryWrapper = styled.div`
    @media (max-width: ${SCREEN_M}px) {
        margin-top: 8px;
        border-radius: ${BORDER_RADIUS};
        border: 1px solid ${COLOR_EEZY_BORDER_GRAY_200};
        padding: 24px;
    }
`;

const DashboardSalesBillingHistory = (props: IDashboardSalesCard) => {
    const { t } = useTranslation();

    const { data } = useQuery(GET_INCOME, {
        variables: { year: currentYear(), month: currentMonth() },
    });
    const incomeData: IIncomeSummary = data?.income;

    const [useCustomPercentage, setUseCustomPercentage] = useState(false);

    useEffect(() => {
        if (!incomeData?.serviceFee) {
            return;
        }
        const ownPercentage = incomeData.serviceFee;
        const invoicing = incomeData.cumulativeInvoicing;
        const currentStep = serviceFeeSteps.find(
            (s) => invoicing >= s.minLimit && (!s.maxLimit || invoicing < s.maxLimit),
        );
        setUseCustomPercentage(ownPercentage < (currentStep?.serviceFee ?? 0));
    }, [incomeData]);

    if (!incomeData) {
        return (
            <div className="text-center">
                <LoadingSpinner color="inherit" />
            </div>
        );
    }

    return (
        <DashboardSalesBillingHistoryWrapper>
            <RowTitle className="hidden md:block">{t('salaries.cumulativeInvoicingTitle')}</RowTitle>
            <BodyPSmall>
                {useCustomPercentage && incomeData?.serviceFee
                    ? t('salaries.customServiceFee', {
                          percentage: formatPercentage(incomeData.serviceFee),
                      })
                    : t('salaries.serviceFee')}
            </BodyPSmall>

            <Flex column style={{ marginTop: 10, position: 'relative' }}>
                {incomeData && (
                    <>
                        {serviceFeeSteps.map((conf) => (
                            <ServiceFeeBlock
                                key={conf.minLimit}
                                minLimit={conf.minLimit}
                                maxLimit={conf.maxLimit}
                                current={incomeData.cumulativeInvoicing}
                                serviceFee={conf.serviceFee}
                            />
                        ))}
                        <Connector />
                    </>
                )}
            </Flex>
        </DashboardSalesBillingHistoryWrapper>
    );
};

type DashboardStatSum = {
    id?: any;
    amount: number;
    description?: string;
};

const DashboardSalesCardContent = (props: IDashboardSalesCard) => {
    const SalesTabs: LabelValueType<SalesTabEnum>[] = [
        { label: 'statistic.tabs.invoices', value: SalesTabEnum.Laskutus },
        { label: 'statistic.tabs.eezy-page', value: SalesTabEnum.EezyPay },
    ];

    const [activeSalesTab, setActiveSalesTab] = useState<SalesTabEnum>(SalesTabEnum.Laskutus);
    const [visibleBillingHistory, setVisibleBillingHistory] = useState(isDesktopSize());

    const [statSums, setStatSums] = useState<DashboardStatSum[]>([
        { id: randomSimleId(), amount: 0, description: 'salaries.incomeMonth' },
        { id: randomSimleId(), amount: 0, description: 'salaries.incomeYear' },
    ]);

    const [getYearPayments] = useLazyQuery(GET_PAYMENTS_BY_DATE, {
        context: { clientName: 'eezyPayHasura' },
        fetchPolicy: 'cache-and-network',
    });
    const [getMonthPayments] = useLazyQuery(GET_PAYMENTS_BY_DATE, {
        context: { clientName: 'eezyPayHasura' },
        fetchPolicy: 'cache-and-network',
    });

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { getStatisticInvoices } = useStatisticInvoice();

    const showStatisticInvoices = async () => {
        const from = moment().startOf('year').toDate();
        const to = moment().endOf('year').toDate();

        const invoices = await getStatisticInvoices(from, to);

        if (!invoices) {
            return;
        }

        const yearlyInvoices = groupInvoices(invoices!, (item) => moment(item.date).format('YYYY'));
        const monthlyInvoices = groupInvoices(invoices!, (item) => moment(item.date).format('YYYY-MM'));

        const currentYearInvoice = yearlyInvoices[moment().format('YYYY')];
        const currentMonthInvoice = monthlyInvoices[moment().format('YYYY-MM')];

        const stats: DashboardStatSum[] = [
            {
                id: randomSimleId(),
                amount: currentMonthInvoice?.total ?? 0,
                description: 'salaries.incomeMonth',
            },
            {
                id: randomSimleId(),
                amount: currentYearInvoice?.total ?? 0,
                description: 'salaries.incomeYear',
            },
        ];

        setStatSums(stats);
    };

    const getToken = async (retry = 30): Promise<string | null> => {
        const sessionToken = sessionStorage.getItem('jwt');

        if (sessionToken) {
            return sessionToken;
        }

        if (retry <= 0) {
            return null;
        }

        return new Promise((resolve) => {
            setTimeout(async () => {
                resolve(await getToken(retry - 1));
            }, 1000);
        });
    };

    const showEezyPayInvoices = async () => {
        const token = await getToken();

        if (!token) {
            setStatSums([
                { id: randomSimleId(), amount: 0, description: 'dashboard.sales.eezy-pay.paymentsThisMonth' },
                { id: randomSimleId(), amount: 0, description: 'dashboard.sales.eezy-pay.paymentsThisYear' },
            ]);
        }

        const yearData = await getYearPayments({
            variables: {
                yearStartTimestamp: new Date(new Date().getFullYear(), 0, 1).toISOString(),
                yearEndTimestamp: new Date(new Date().getFullYear(), 12, 1).toISOString(),
            },
        });
        const eezyPayTotalYear =
            yearData.data?.transactions?.reduce(
                (prev: number, curr: ITransaction) =>
                    prev + priceWithoutVat(curr.servicePrice, curr.serviceVat),
                0,
            ) ?? 0;

        const monthData = await getMonthPayments({
            variables: {
                yearStartTimestamp: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    1,
                ).toISOString(),
                yearEndTimestamp: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0,
                ).toISOString(),
            },
        });
        const eezyPayTotalMonth =
            monthData.data?.transactions?.reduce(
                (prev: number, curr: ITransaction) =>
                    prev + priceWithoutVat(curr.servicePrice, curr.serviceVat),
                0,
            ) ?? 0;

        const stats: DashboardStatSum[] = [
            {
                id: randomSimleId(),
                amount: eezyPayTotalMonth * 0.01,
                description: 'dashboard.sales.eezy-pay.paymentsThisMonth',
            },
            {
                id: randomSimleId(),
                amount: eezyPayTotalYear * 0.01,
                description: 'dashboard.sales.eezy-pay.paymentsThisYear',
            },
        ];

        setStatSums(stats);
    };

    useEffect(() => {
        switch (activeSalesTab) {
            case SalesTabEnum.Laskutus:
                showStatisticInvoices();
                return;
            case SalesTabEnum.EezyPay:
                showEezyPayInvoices();
                return;
        }
    }, [activeSalesTab]);

    return (
        <div className="flex flex-col md:flex-row">
            <div className="md:flex-5">
                <div className="flex flex-col gap-9">
                    <TabHeaders
                        items={SalesTabs}
                        activeValue={activeSalesTab}
                        onChange={(v) => setActiveSalesTab(v)}
                    />
                    <div className="flex gap-2 md:gap-3 flex-wrap md:flex-nowrap">
                        {statSums.map((i) => (
                            <SalesStatsCard
                                key={i.id}
                                amount={i.amount}
                                description={i.description && t(i.description)}
                            />
                        ))}
                    </div>
                    <div>
                        <EezyButton
                            className="v2-btn min-w-full md:min-w-0"
                            dark
                            color="eezy-green"
                            hasIcon={true}
                            onClick={() => navigate('/statistics')}
                        >
                            <Icon icon={['fas', 'chart-mixed']} />
                            {t('dashboard.sales.navigate-statistics')}
                        </EezyButton>
                    </div>
                </div>
            </div>
            <SectionSeparator />
            <div className="md:flex-3">
                <EezyButton
                    className="v2-btn w-full border-0 mt-2 md:hidden"
                    transparent
                    color="purple"
                    hasIcon={true}
                    iconAlignment="right"
                    onClick={() => setVisibleBillingHistory(!visibleBillingHistory)}
                >
                    {t('dashboard.sales.billing-history')}
                    {visibleBillingHistory ? (
                        <Icon icon={['far', 'chevron-up']} />
                    ) : (
                        <Icon icon={['far', 'chevron-down']} />
                    )}
                </EezyButton>
                {visibleBillingHistory && <DashboardSalesBillingHistory {...props} />}
            </div>
        </div>
    );
};

const DashboardSalesCard = (props: IDashboardSalesCard) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <DashboardCard className="w-full">
            <div className="flex flex-col gap-7">
                <div className="flex justify-between">
                    <CardTitle
                        title={t('dashboard.sales.title')}
                        subtitle={t('dashboard.sales.subtitle')}
                        subIcon={<Icon className="small" icon={['far', 'info-circle']} />}
                    />
                    <EezyButton
                        className="v2-btn"
                        color="purple"
                        hasIcon
                        iconAlignment="right"
                        onClick={() => navigate('/salaries')}
                    >
                        {t('salaries.salaries')}
                        <Icon icon={['far', 'arrow-right']} />
                    </EezyButton>
                </div>
                <DashboardSalesCardContent {...props} />
            </div>
        </DashboardCard>
    );
};

const mapStateToProps = (state: any) => {
    return {
        language: state.user.language,
    };
};

export default connect(mapStateToProps)(DashboardSalesCard);
