import { gql } from '@apollo/client';

const REJECT_COWORKER_INVITATION = gql`
    mutation Coworkers($personId: Int!) {
        rejectContact(personId: $personId)
    }
`;

export default REJECT_COWORKER_INVITATION;

