import styled from 'styled-components';
import { WhiteDocumentArea } from '../components/layout/WhiteDocumentArea';
import {
    COLOR_BLACKWATER,
    COLOR_DARK_GRAY,
    COLOR_GREY_FOG,
    COLOR_GREYHOUND,
    COLOR_GREYJOY,
    COLOR_IMPORTANT,
    COLOR_WHITE_GRAY,
    COLOR_WHITE_WALKER,
    INVOICE_WIDTH,
    SCREEN_M,
    SCREEN_S,
    TOPBAR_HEIGHT,
    FOOTER_HEIGHT,
    FOOTER_HEIGHT_MOBILE
} from './variables';

export const InvoiceBackground = styled.div`
    width: 100%;
    border-radius: 0 0 10px 10px;
    min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT_MOBILE}px);
    @media (min-width: ${SCREEN_M}px) {
        padding: 0 15px;
        min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT}px);
    }
    .content {
        display: flex;
        flex-direction: row;

        .content-left {
            width: 100%;
        }

        @media (min-width: ${SCREEN_M}px) {
            height: 100%;

            & > div.spinner {
                min-width: calc((${INVOICE_WIDTH}px) + 30px);
                max-width: calc((${INVOICE_WIDTH}px) + 30px);
            }
        }
    }

    .invoice-attachments-wrapper {
        margin: 15px;
        & > div {
            background-color: ${COLOR_WHITE_WALKER};
            padding: 40px 0;
        }
    }

    @media (min-width: ${SCREEN_M}px) {
        ${WhiteDocumentArea} {
            /* lift the invoice over pink bar */
            margin: -45px 30px 0 15px;
        }
        .invoice-attachments-wrapper {
            min-width: 720px;
            max-width: 720px;
        }
    }
    @media (max-width: ${SCREEN_M}px) {
        &.withFillHelper {
            display: none;
        }
    }
`;

//  The right side
export const InvoiceUtilsArea = styled.div`
    width: 100%;
    padding: 15px;
    margin-top: 30px;
    background: ${COLOR_WHITE_GRAY};
    border-radius: 4px;
    align-self: start;
    @media (min-width: ${SCREEN_M}px) {
        max-width: 550px;
        min-width: 300px;
    }
`;

export const ResponsiveUpperPart = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: ${SCREEN_S}px) {
        flex-direction: row;
    }
`;

export const InvoiceCol = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 40%;
`;

export const Table = styled.table`
    border-spacing: 0;
    width: 100%;

    thead th {
        border-bottom: 1px solid ${COLOR_BLACKWATER};
        color: ${COLOR_BLACKWATER};
        font-size: 13px;
        letter-spacing: 0.2px;
        padding-bottom: 1px;
        padding-right: 2px;
        text-align: right;
        text-transform: uppercase;
        &:first-child {
            /*  1st column */
            text-align: left;
        }
        &:nth-last-child(1) {
            /*  with vat */
            padding-right: 0;
        }
    }

    tbody tr {
        color: ${COLOR_DARK_GRAY};
        font-size: 15px;
        text-align: right;
        td {
            border-bottom: 1px solid ${COLOR_GREY_FOG};
            padding: 10px 3px 4px 3px;
            vertical-align: top;

            @media (min-width: ${SCREEN_S}px) {
                &.td-del {
                    /* Cell with del button */
                    padding: 10px 0 3px 0;

                    button:focus svg {
                        visibility: visible;
                    }
                    button > svg {
                        cursor: pointer;
                        margin-left: 5px;
                        margin-right: -5px;
                        visibility: hidden;
                    }
                }
            }

            &:first-child,
            &:first-child input {
                /*  1st column, description */
                padding-left: 0;
                max-width: 200px;
                min-width: 150px;
                text-align: left;
            }
            &:nth-child(2) {
                /* quantity */
                padding-left: 0;
            }
            &:last-child {
                div {
                    margin-left: 5px;
                    margin-right: -10px;
                }
            }
        }
        &:hover > td > button > svg,
        & > td:hover > button > svg,
        & > td > button:hover > svg {
            visibility: visible;
        }
        td span.light {
            color: ${COLOR_GREYJOY};
            font-size: 13px;
        }
        td span.hasError {
            color: ${COLOR_IMPORTANT};
            font-size: 13px;
        }
        input {
            color: ${COLOR_GREYHOUND};
            font-size: 15px;
            text-align: right;
            &.endAdornment {
                padding-right: 20px;
            }
            /* Chrome, Safari, Edge, Opera */
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            /* Firefox */
            &[type='number'] {
                -moz-appearance: textfield;
            }
        }
        span.endAdornment {
            margin-left: -15px;
        }
        &:last-child {
            button {
                margin: 0 5px 5px 0;
            }
        }
    }
    tfoot {
        tr {
            color: ${COLOR_BLACKWATER};
            th {
                border-bottom: 1px solid ${COLOR_BLACKWATER};
                font-size: 13px;
                text-align: left;
                text-transform: uppercase;
            }
            td {
                border-bottom: 1px solid ${COLOR_BLACKWATER};
                font-size: 15px;
                font-weight: bold;
                letter-spacing: 0.16px;
                padding: 6px 3px 13px 3px;
                text-align: right;
            }
            td.error {
                color: ${COLOR_IMPORTANT};
            }
        }
        tr:first-of-type {
            th,
            td {
                border-top: 3px solid ${COLOR_BLACKWATER};
            }
        }
        tr:last-of-type {
            th,
            td {
                border-bottom: 3px solid ${COLOR_BLACKWATER};
            }
        }
        tr.highlight {
            color: ${COLOR_IMPORTANT};
        }
    }
`;

export const SimpleTable = styled.table`
    border-spacing: 0;
    width: 100%;
    margin-bottom: 20px;
    tbody tr {
        color: ${COLOR_BLACKWATER};
        font-size: 15px;
        text-align: right;
        td {
            padding: 5px 3px 5px 3px;
            vertical-align: top;
            span {
                color: ${COLOR_GREYJOY};
                line-height: 32px;
                font-size: 16px;
                padding-left: 3px;
            }
            &:first-child {
                padding-left: 12px;
                max-width: 200px;
                text-align: left;
            }
            &:nth-child(2) {
                padding-right: 10px;
            }
            &:nth-child(3) {
                /* quantity unit */
                padding-right: 0;
                text-align: left;
                width: 1px;
            }
            &:last-child {
                button {
                    margin: 0 5px 5px 0;
                }
            }
        }
    }
    tfoot {
        tr {
            color: ${COLOR_BLACKWATER};
            td {
                span {
                    color: ${COLOR_GREYJOY};
                    line-height: 32px;
                    font-size: 16px;
                    padding-left: 3px;
                }
                &:first-child {
                    font-weight: 800;
                    padding-left: 12px;
                    max-width: 200px;
                    text-align: left;
                }
                &:nth-child(2) {
                    font-weight: 800;
                    padding-right: 10px;
                    text-align: right;
                }
            }
        }
    }
`;

export const InvoiceError = styled.h1`
    color: ${COLOR_IMPORTANT};
    font-size: 15px;
    margin-bottom: 25px;
    text-align: center;
    text-transform: uppercase;
`;
