import * as R from 'ramda';
import { SCREEN_M } from '../styles/variables';

//  copied from server/src/util

const isNaN = (arg: string | number): boolean => {
    return typeof arg === 'number' ? Number.isNaN(arg) : false;
};

export const isEmpty = (arg: string | number) =>
    arg === undefined || arg === null || isNaN(arg);

export const defaultOrVal = (
    defaultValue: any | null | undefined,
    func: (a: any) => any,
    arg: any | null | undefined
) => (isEmpty(arg) || isEmpty(func(arg)) ? defaultValue : func(arg));

export const nullOrVal: (func: (a: any) => any, b: any) => any = R.curry(
    defaultOrVal
)(null);

export const zeroOrVal: (func: (a: any) => any, b: any) => any = R.curry(
    defaultOrVal
)(0);

export const convertStrToCents = R.pipe(
    R.replace(/\s/g, ''),
    parseFloat,
    R.multiply(100),
    Math.round
);
export const convertStrToCentsWithoutRounding = R.pipe(
    parseFloat,
    R.multiply(100)
);

export const strToCents: (a: string) => number = R.curry(nullOrVal)(
    convertStrToCents
);

export const strToCentsOrZero: (a: string) => number = R.curry(zeroOrVal)(
    convertStrToCents
);

export const strToCentsOrZeroWithoutRounding: (a: string) => number = R.curry(
    zeroOrVal
)(convertStrToCentsWithoutRounding);

export const toInt = (str: string): number => nullOrVal(parseInt, str);

export const log = <T>(title: string) => (message: T): T => {
    // tslint:disable-next-line:no-console
    console.log(title, message);
    return message;
};

export const isMobile = () => window.innerWidth < SCREEN_M;
