import { Cookies } from 'react-cookie';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { ApolloClient, ApolloLink, concat, createHttpLink, split } from '@apollo/client';
import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { logout, serverWholePageError, showLogin } from 'actions/auth';
import { resetUser } from 'actions/user';
import config from 'config';

const httpLink = createHttpLink({
    credentials: 'same-origin',
    uri: config.backendUrl2,
});

const eezyPayHasuraLink = createHttpLink({
    credentials: 'same-origin',
    uri: config.hasuraUrl,
});

const wsLink = new GraphQLWsLink(
    createClient({
        url: config.subscriptionUrl,
        connectionParams: () => ({
            jwt: sessionStorage.getItem('jwt'),
        }),
    }),
);

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
);

interface IError {
    statusCode?: number;
}

const logoutLink = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return onError(({ graphQLErrors, networkError }) => {
        const err = networkError as IError;
        if (graphQLErrors) {
            const errorCode = graphQLErrors[0].extensions?.code;
            if (errorCode === 'invalid-jwt' || errorCode === 'invalid-headers') {
                dispatch(logout());
            }
        }
        if (err?.statusCode === 401) {
            dispatch(resetUser());
            dispatch(showLogin());
        }
    });
};

const serverErrorLink = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return onError(({ networkError }) => {
        const err = networkError as IError;
        if (err?.statusCode && err?.statusCode >= 500) {
            dispatch(serverWholePageError());
        }
    });
};

const getTokenLink = new ApolloLink((operation, forward) => {
    const definition = getMainDefinition(operation.query);
    if (definition.kind === 'OperationDefinition' && definition.operation === 'subscription') {
        return forward(operation).map((data) => {
            return data;
        });
    }
    return forward(operation).map((data) => {
        const context = operation.getContext();
        const {
            response: { headers },
        } = context;

        if (headers) {
            const token = headers.get('token');
            if (token) {
                sessionStorage.setItem('jwt', token);
            }
        }
        return data;
    });
});

const cleanTypeName = new ApolloLink((operation, forward) => {
    // https://stackoverflow.com/questions/47211778/cleaning-unwanted-fields-from-graphql-responses/51380645#51380645
    if (operation.variables) {
        const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation).map((data) => {
        return data;
    });
});

const cookie = new Cookies();

const setHeaders = new ApolloLink((operation, forward) => {
    const jwt = sessionStorage.getItem('jwt');
    const uuid = cookie.get('uuid');

    const context: any = {
        headers: {
            'x-uuid': uuid,
        },
    }

    if (jwt) {
        context.headers.Authorization = `Bearer ${jwt}`
    }

    context.headers['x-front-page'] = window.location.pathname

    operation.setContext(context);

    return forward(operation);
});

export const createApolloClient = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const logoutHandler = logoutLink(dispatch);
    const errorHandler = serverErrorLink(dispatch);

    const link = ApolloLink.from([cleanTypeName, getTokenLink, logoutHandler, errorHandler, splitLink]);

    const eezyPayLink = ApolloLink.from([cleanTypeName, logoutHandler, errorHandler, eezyPayHasuraLink]);

    return new ApolloClient({
        cache: new InMemoryCache({
            dataIdFromObject: (object: any) => {
                switch (object.__typename) {
                    case 'InvoiceRecipient':
                        return `InvoiceRecipient:${object.invoiceId}:${object.id}`;
                    case 'FillHelper':
                        return `FillHelper:${object.type}:${object.id}`;
                    default:
                        return defaultDataIdFromObject(object);
                }
            },
        }),
        link: concat(
            setHeaders,
            ApolloLink.split(
                (operation) => operation.getContext().clientName === 'eezyPayHasura',
                eezyPayLink, // if above
                link,
            ),
        ),
        resolvers: {},
    });
};
