import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { LabelValueType } from 'containers/eezypay/types/SalesPageStatisticTypes';
import styled from '@emotion/styled';

interface INestedPageNavigationTabs {
    links: LabelValueType<string>[];
    extraNode?: ReactNode;
    activeValue: any;
    onChange?: (value: any) => void;
}

const TabLink = styled.div`
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    position: relative;
    cursor: pointer;

    &.disabled {
        pointer-events: none;
        color: #bfbeca;
    }

    &:not(.disabled).active:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -7px;
        width: 100%;
        height: 3px;
        background: #351f65;
        border-top-left-radius: 999px;
        border-top-right-radius: 999px;
    }
`;

const NestedPageNavigationTabs = (props: INestedPageNavigationTabs) => {
    return (
        <div
            className="pb-8 border-solid border-b flex flex-col md:flex-row justify-between gap-8"
            style={{ borderColor: '#E4E4E7' }}
        >
            <div className="flex gap-7 items-start">
                {props.links.map((i) => (
                    <TabLink
                        key={i.value}
                        className={[
                            !i.value ? 'disabled' : undefined,
                            props.activeValue == i.value ? 'active' : undefined,
                        ].join(' ')}
                        onClick={props.onChange && (() => props.onChange!(i.value))}
                    >
                        <span>{i.label}</span>
                    </TabLink>
                ))}
            </div>
            {props.extraNode}
        </div>
    );
};

export default NestedPageNavigationTabs;
