import { ApolloError, useMutation } from '@apollo/client';
import React, { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { IContactList, ICoworker, UiLanguage } from '../../../../shared/src/types/user';
import { EezyButton } from '../../components/Buttons';
import ColorBox from '../../components/ColorBox';
import { Bold, P } from '../../components/textElements';
import { COLOR_BLACKWATER, COLOR_LILA_MEDIUM } from '../../styles/variables';
import { trans } from '../../utils';
import { getErrorKey } from '../../utils/apolloErrors';
import { ACCEPT_COWORKER_INVITATION, GET_COWORKERS, REJECT_COWORKER_INVITATION } from './queries';

interface IGroupInvoicingInviteProps {
    language: UiLanguage;
    contacts: IContactList;
}

const Wrapper = styled.div`
    width: 100%;
    margin-top: 10px;
    &:nth-child(2) {
        margin-top: 0px;
    }
`;

const GroupInvoicingInvitation = (props: IGroupInvoicingInviteProps) => {
    const { contacts } = props;

    const [invitationAccepted, setInvitationAccepted] = useState(false);

    const [acceptInvitation] = useMutation(ACCEPT_COWORKER_INVITATION, {
        onCompleted: () => {
            toast(trans('profile:group-invoicing.user-added-toast'));
        },
        onError: (e: ApolloError) => {
            if ((getErrorKey(e) || '').includes('contact_already_exists')) {
                toast.error(
                    trans(
                        'profile:group-invoicing.delete-coworker-modal.errors.contact-already-exists'
                    )
                );
            } else {
                toast.error(trans('errors.general'));
            }
        },
        refetchQueries: [{ query: GET_COWORKERS }]
    });

    const [rejectInvitation] = useMutation(REJECT_COWORKER_INVITATION, {
        onCompleted: () => {
            toast(trans('profile:group-invoicing.user-reject-toast'));
        },
        onError: (e: ApolloError) => {
            toast.error(trans('errors.general'));
        },
        refetchQueries: [{ query: GET_COWORKERS }]
    });

    const handleAcceptInvitation = (id: number) => {
        acceptInvitation({
            variables: {
                personId: id
            }
        });
        setInvitationAccepted(true);
    };

    const handleRejectInvitation = (id: number) => {
        rejectInvitation({
            variables: {
                personId: id
            }
        });
    };

    const contactList = (contacts?.requestedContacts || []).map(contact => (
        <ContactLabel
            title={`${contact.name} ${trans(
                'profile:group-invoicing-invite.title'
            )}`}
            description={trans('profile:group-invoicing-invite.description')}
            key={contact.id}
            contact={contact}
            language={props.language}
            handleAcceptInvitation={handleAcceptInvitation}
            handleRejectInvitation={handleRejectInvitation}
        />
    ));

    return (
        <Fragment>
            {contactList}
            {invitationAccepted && (
                <ContactLabel
                    title={trans('profile:group-invoicing.invite-accepted')}
                    description={trans(
                        'profile:group-invoicing.invite-accepted-description'
                    )}
                    language={props.language}
                    closeLabel={setInvitationAccepted}
                />
            )}
        </Fragment>
    );
};

interface IContatctLabelProps {
    title: string;
    description: string;
    contact?: ICoworker;
    language: UiLanguage;
    handleAcceptInvitation?: (id: number) => void;
    handleRejectInvitation?: (id: number) => void;
    closeLabel?: (state: boolean) => void;
}

const ContactLabel = (props: IContatctLabelProps) => {
    return (
        <Wrapper>
            <ColorBox
                disableClose
                color={COLOR_LILA_MEDIUM}
                style={{ padding: 30, marginTop: 0 }}
            >
                <Bold>{props.title}</Bold>
                <P style={{ color: COLOR_BLACKWATER }}>{props.description}</P>
                {!props.closeLabel ? (
                    <P>
                        <EezyButton
                            color="purple"
                            transparent
                            onClick={() =>
                                props.handleRejectInvitation!(
                                    props.contact?.id!
                                )
                            }
                        >
                            {trans(
                                'profile:group-invoicing-invite.reject-invite'
                            )}
                        </EezyButton>
                        <EezyButton
                            color="purple"
                            dark
                            onClick={() =>
                                props.handleAcceptInvitation!(
                                    props.contact?.id!
                                )
                            }
                        >
                            {trans(
                                'profile:group-invoicing-invite.accept-invite'
                            )}
                        </EezyButton>
                    </P>
                ) : (
                    <P>
                        <EezyButton
                            color="purple"
                            dark
                            onClick={() => props.closeLabel!(false)}
                        >
                            {trans('menu.close')}
                        </EezyButton>
                    </P>
                )}
            </ColorBox>
        </Wrapper>
    );
};

export default GroupInvoicingInvitation;
