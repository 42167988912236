import moment from 'moment';

export const [
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY
] = [1, 2, 3, 4, 5, 6, 0];

export const isWeekday = (date: string, weekday: number) => {
    return moment(date).day() === weekday;
};

export const isWeekend = (date?: string) => {
    if (!date) {
        date = moment().toISOString();
    }
    return isWeekday(date, SATURDAY) || isWeekday(date, SUNDAY);
};
