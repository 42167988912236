import React from 'react';
import { Outlet } from 'react-router-dom';
import Drawer from '../containers/nav/Drawer';

export const YelWrapper = () => {
    return (
        <Drawer>
            <div style={{ marginTop: 20 }}>
                <Outlet />
            </div>
        </Drawer>
    );
};
