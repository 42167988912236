import { ModalType } from '../../containers/ModalOpener';

/*
    Use NewUserGuider as an example of how to create a new guider with unique steps.
    Guider logic can be further customized in Guider.tsx.
*/

export interface IGuider {
    openLabel: string;
    closeLabel: string;
    finalAction: IStep;
    name: string;
    steps: IStep[];
    subTitle?: string;
    title: string;
}

export interface IStep {
    isDone?: boolean;
    modal?: ModalType;
    title: string;
    url?: string;
}

export const getStepsDone = (steps: IStep[]): number =>
    steps.filter((step: IStep) => step.isDone).length;

export const getStepsUndone = (steps: IStep[]): number =>
    steps.filter((step: IStep) => !step.isDone).length;

export const isGuiderFinished = (guider: IGuider): boolean =>
    !!guider.finalAction.isDone && getStepsUndone(guider.steps) === 0;

export const sortSteps = (a: IStep, b: IStep) =>
    a.isDone === b.isDone ? 0 : a.isDone && !b.isDone ? -1 : 1;
