import { validator as v } from '../validation';

const startDateValidator = v
    .date()
    .max(v.ref('startMax'))
    .min(v.ref('startMin'))
    .allow('');

const endDateValidator = v
    .date()
    .iso()
    .max(v.ref('endMax'))
    .min(v.ref('endMin'))
    .allow('');

export const salaryRangeValidators = v.object({
    endDate: endDateValidator,
    startDate: startDateValidator
});
