import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';

import { IFile } from '../../../../shared/src/types/common';
import { confirmAction, IConfirmAction } from '../../actions/app';
import { EezyButton, EezyLink } from '../../components/Buttons';
import { Flex } from '../../components/Flex';
import { Icon } from '../../components/Icon';
import { EmbedA4Wrapper } from '../../components/layout/EmbedWrapper';
import LoadingSpinner from '../../components/Loading';
import { Label, P } from '../../components/textElements';
import { COLOR_BLACKWATER, COLOR_BLUM } from '../../styles/variables';
import { trans } from '../../utils';

const Wrapper = styled.div`
    ${EmbedA4Wrapper} {
        margin: 10px 0;
    }
`;

interface IProps {
    confirmAction: (payload: IConfirmAction) => void;
    deleteLoading: boolean;
    editable: boolean;
    files: IFile[] | null | undefined;
    handleFileRemoval: (index: number) => void;
    invoiceId: number;
    refs?: any[];
}

const InvoiceAttachments = (props: IProps) => {
    const { files } = props;

    if (files) {
        return (
            <Wrapper className="invoice-attachments-wrapper">
                {files.map((file: IFile, index: number) => {
                    return (
                        <div key={`file-${file.id}`}>
                            {props.refs ? (
                                <span ref={props.refs[index]} />
                            ) : null}
                            <Flex
                                style={{
                                    flexWrap: 'wrap',
                                    margin: 10,
                                    padding: '0 30px 30px'
                                }}
                            >
                                <Label
                                    color={COLOR_BLACKWATER}
                                    style={{ flexGrow: 1 }}
                                >
                                    <Icon
                                        color={COLOR_BLACKWATER}
                                        className="small"
                                        icon={['far', 'paperclip']}
                                        style={{ marginRight: '10px' }}
                                    />
                                    {file.name}
                                </Label>

                                <div>
                                    {props.editable && (
                                        <EezyButton
                                            color="purple"
                                            onClick={() => {
                                                props.handleFileRemoval(index);
                                            }}
                                            disabled={props.deleteLoading}
                                            style={{ marginRight: '25px' }}
                                        >
                                            {props.deleteLoading ? (
                                                <LoadingSpinner color="white" />
                                            ) : (
                                                trans('form.delete')
                                            )}
                                        </EezyButton>
                                    )}
                                    <EezyLink
                                        color="purple"
                                        href={file?.url}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <Icon
                                            icon={['far', 'arrow-to-bottom']}
                                        />{' '}
                                        {trans('file.download')}
                                    </EezyLink>
                                </div>
                            </Flex>
                            <EmbedA4Wrapper>
                                <object
                                    data={`${file.url}#toolbar=0&navpanes=0&scrollbar=0&view=fit`}
                                    title={trans('invoice.attachment', {
                                        name: file.name
                                    })}
                                >
                                    <P
                                        color={COLOR_BLUM}
                                        style={{ marginTop: 60 }}
                                    >
                                        {trans('file.preview-error')}
                                    </P>
                                </object>
                            </EmbedA4Wrapper>
                        </div>
                    );
                })}
            </Wrapper>
        );
    } else {
        return null;
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        confirmAction: (payload: IConfirmAction) => {
            dispatch(confirmAction(payload));
        }
    };
};

export default connect(null, mapDispatchToProps)(InvoiceAttachments);
