import { FC } from 'react';
import Drawer from 'containers/nav/Drawer';
import { Outlet } from 'react-router-dom';
import FrontPage from 'containers/dashboard/FrontPage';
import { SCREEN_M } from 'styles/variables';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';
import DashboardQuickActions from 'containers/dashboard/DashboardQuickActions';
import EezyDataBanner from 'components/banners/EezyDataBanner';

const InvoiceWrapper: FC = () => {
    const { width } = useWindowDimensions();

    return (
        <Drawer banner={<EezyDataBanner />}>
            {width < SCREEN_M ? (
                <div>
                    <DashboardQuickActions style={{ marginBottom: '20px' }} />
                    <Outlet />
                </div>
            ) : (
                <FrontPage>
                    <Outlet />
                </FrontPage>
            )}
        </Drawer>
    );
};

export default InvoiceWrapper;
