import { useEffect, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { IUserBasicData } from '../../../../shared/src/types/user';
import { ManualCard } from 'components/cards';
import { Flex } from 'components/Flex';
import { FormInput, FormSelect } from 'components/form';
import { Icon } from 'components/Icon';
import { BodyP, SmallP } from 'components/textElements';
import { COLOR_BLACKWATER, COLOR_BLUM, COLOR_IMPORTANT, COLOR_STATUS_DONE } from 'styles/variables';
import { trans } from 'utils';
import { getErrorKey } from 'utils/apolloErrors';
import { getSalaryPaymentOptions } from 'utils/profile/salaryPaymentRules';
import { bankAccountValidator } from 'utils/profile/validators';
import { stripSpaces } from 'utils/str';
import { formatValidationResult } from 'utils/validation';
import { UPDATE_USER_DATA } from './queries';
import { useTranslation } from 'react-i18next';

interface IProps {
    defaultOpen?: boolean;
    userData?: IUserBasicData;
    refetchUserData: () => void;
}

export const ProfileSalary = (props: IProps) => {
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [salaryPaymentRule, setSalaryRule] = useState('');
    const [bankAccountError, setBankAccountError] = useState<any>(null);
    const [closeF, setCloseF] = useState<any>(null);

    const { t } = useTranslation();

    const hadBankAccount = !!props.userData?.bankAccountNumber;

    const hasForeignIban =
        (props.userData?.bankAccountNumber && !props.userData?.bankAccountNumber.startsWith('FI')) || false;

    // Local version of update is needed as we want to close the card only if the mutation
    // is completed, and there are currently no other ways to call onCompleted
    const [updateUserData, { loading }] = useMutation(UPDATE_USER_DATA, {
        onCompleted: () => {
            if (closeF) {
                closeF();
            }
            toast(trans('general.saved'));
            handleReset();
            props.refetchUserData();
        },
        onError: (e: ApolloError) => {
            if ((getErrorKey(e) || '').includes('iban')) {
                toast.error(trans('profile:salary.bankAccount.error2'));
            } else {
                toast.error(trans('errors.general'));
            }
        }
    });

    useEffect(() => {
        if (props.userData?.bankAccountNumber) {
            setBankAccountNumber(props.userData?.bankAccountNumber);
        }
        if (props.userData?.salaryPaymentRuleId) {
            setSalaryRule(props.userData?.salaryPaymentRuleId.toString());
        }
    }, [props.userData]);

    const handleSave = (callback: () => void) => {
        // callback-fn (that closes the card) is available here but needs to be called in
        // onCompleted of the mutation, thus the hack
        setCloseF(() => () => callback());

        const error = hasForeignIban
            ? undefined
            : formatValidationResult(bankAccountValidator.validate(stripSpaces(bankAccountNumber)));
        if (error) {
            setBankAccountError(error);
        } else {
            // no optimistic response, as if the iban is invalid, the update will fail
            // and optimistic response would loose the invalid value in the form
            if (hadBankAccount) {
                updateUserData({
                    variables: {
                        bankAccountNumber: hasForeignIban ? undefined : stripSpaces(bankAccountNumber),
                        salaryPaymentRuleId: parseInt(salaryPaymentRule, 10)
                    }
                });
            } else {
                updateUserData({
                    variables: {
                        bankAccountNumber: hasForeignIban ? undefined : stripSpaces(bankAccountNumber),
                        salaryPaymentRuleId: parseInt(salaryPaymentRule, 10),
                        notifyWhenInvoicePaid: true
                    }
                });
            }
        }
    };

    const handleReset = () => {
        setBankAccountError(null);
        setBankAccountNumber(props.userData?.bankAccountNumber || '');
        setSalaryRule(props.userData?.salaryPaymentRuleId.toString() || '');
    };

    return (
        <ManualCard
            defaultOpen={props.defaultOpen}
            editableContent={
                <Flex column>
                    <FormInput
                        data-mf-replace="**REMOVED**"
                        disabled={hasForeignIban}
                        endAdornment={
                            hasForeignIban ? null : formatValidationResult(
                                  bankAccountValidator.validate(stripSpaces(bankAccountNumber))
                              ) ? (
                                <Icon icon={['far', 'exclamation-circle']} color={COLOR_IMPORTANT} />
                            ) : (
                                <Icon icon={['far', 'check']} color={COLOR_STATUS_DONE} />
                            )
                        }
                        error={bankAccountError}
                        label={t('profile:salary.bankAccount.label-edit') || ''}
                        onChange={val => setBankAccountNumber(val)}
                        name="bankAccountNumber"
                        placeholder={'FI87 1234 5678 1234 56'}
                        required
                        value={bankAccountNumber}
                    />
                    {bankAccountError && (
                        <SmallP color={COLOR_IMPORTANT} style={{ marginTop: 10 }}>
                            {t('profile:salary.bankAccount.error')}
                        </SmallP>
                    )}
                    {hasForeignIban && (
                        <SmallP color={COLOR_BLACKWATER}>
                            {t('profile:salary.bankAccount.foreign-note')}
                        </SmallP>
                    )}
                    <FormSelect
                        data-mf-replace="**REMOVED**"
                        color={COLOR_BLUM}
                        label={t('profile:salary.salaryPayment.label') || ''}
                        name="salaryPaymentRule"
                        onChange={val => setSalaryRule(val)}
                        options={getSalaryPaymentOptions()}
                        required
                        showIcon
                        value={salaryPaymentRule}
                    />
                </Flex>
            }
            onClose={handleReset}
            onSave={handleSave}
            route="salary"
            saveLoading={loading}
            title={t('profile:salary.title')}
            viewContent={
                <Flex column>
                    <BodyP>
                        <b>{t('profile:salary.bankAccount.label')}</b>
                    </BodyP>
                    <BodyP data-mf-replace="**REMOVED**">{props.userData?.bankAccountNumber}</BodyP>
                    {props.userData?.salaryPaymentRuleId && (
                        <BodyP style={{ marginTop: 10 }}>
                            {t(`profile:salary.payments.${props.userData?.salaryPaymentRuleId}`)}
                        </BodyP>
                    )}
                </Flex>
            }
        />
    );
};
