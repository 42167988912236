import { Icon } from 'components/Icon';
import React from 'react';
import styled from 'styled-components';

interface ISalesStatsBillingChartLegend {
    color: string;
    label: string;
    canRemove: boolean;
    className?: string;
    onClick?: () => void;
}

const SalesStatsBillingChartLegend = (props: ISalesStatsBillingChartLegend) => {
    return (
        <div
            className={['flex items-center', props.className, !props.canRemove ? 'disabled' : ''].join(' ')}
            onClick={!props.canRemove ? undefined : props.onClick}
        >
            <div className="rounded-full w-4 h-4" style={{ backgroundColor: props.color }}></div>
            <div className="text-sm pl-3 capitalize">{props.label}</div>
            <Icon icon={['far', 'xmark']} className="small ico-remove ml-2" />
        </div>
    );
};

const SalesStatsBillingChartLegendStyled = styled(SalesStatsBillingChartLegend)`
    user-select: none;

    .ico-remove {
        opacity: 0;
    }

    :not(.disabled) {
        cursor: pointer;

        .ico-remove {
            opacity: 1;
        }

        &:not(:hover) {
            &:not(:active) {
                .ico-remove {
                    color: #a1a1aa;
                }
            }
        }
    
`;

export default SalesStatsBillingChartLegendStyled;
