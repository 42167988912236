import { ISpreadState } from '../../../shared/src/types/invoice';
import {
    INVOICE_SEARCH_QUERY, INVOICE_SET_EXPENSE_REIMBURSEMENT_WARNING_HIDDEN, INVOICE_SET_TRAVEL_WARNING_HIDDEN,
    INVOICE_SPREAD_STATE,
    INVOICE_SPREAD_STATE_CLIENT_SET
} from '../actionTypes';

export interface IInvoiceState {
    expenseReimbursementWarningHidden: boolean;
    searchQuery: string;
    searchRecipientId: null | number;
    spreadState?: ISpreadState | null;
    travelWarningHidden: boolean;
}

const initialState: IInvoiceState = {
    expenseReimbursementWarningHidden: false,
    searchQuery: '',
    searchRecipientId: null,
    spreadState: null,
    travelWarningHidden: false
};

// eslint-disable-next-line
export default (state = initialState, action: any) => {
    switch (action.type) {
        case INVOICE_SET_TRAVEL_WARNING_HIDDEN:
        case INVOICE_SET_EXPENSE_REIMBURSEMENT_WARNING_HIDDEN:
        case INVOICE_SEARCH_QUERY:
            return {
                ...state,
                ...action.payload
            };
        case INVOICE_SPREAD_STATE:
            return {
                ...state,
                spreadState: {
                    ...action.payload,
                    justSetClient: state.spreadState?.justSetClient
                }
            };
        case INVOICE_SPREAD_STATE_CLIENT_SET:
            return {
                ...state,
                spreadState: {
                    ...state.spreadState,
                    justSetClient: action.payload
                }
            };
        default:
            return state;
    }
};
