import React from 'react';
import { ICostInvoice } from '../../../../shared/src/types/costs';
import { formatCents, trans } from '../../utils';
import { getCostsTotalWithVat, getTravelsTotalWithoutStatus } from '../../utils/costs/costLogic';
import { CostInvoiceTable } from './CostInvoiceTable';

interface IProps {
    costInvoice: ICostInvoice;
}

const CostDocumentSummary = ({ costInvoice }: IProps) => {
    return (
        <>
            <CostInvoiceTable>
                <thead>
                    <tr>
                        <th colSpan={3}>{trans('costs.summary')}</th>
                    </tr>
                </thead>
                <tbody>
                    {/*  If costInvoice is not incomplete and travels are both taxfree and taxable, 
                    separate these 2 to their own rows. Otherwise show only one travel row */}
                    {costInvoice.status !== 'incomplete' &&
                    getTravelsTotalWithoutStatus(costInvoice.travels, 'rejected', false) !==
                        getTravelsTotalWithoutStatus(costInvoice.travels, 'rejected') ? (
                        <>
                            <tr>
                                <td>{trans('costs.travel-expenses-taxfree')}</td>
                                <td style={{ minWidth: 100 }}>
                                    {trans('form.eurs', {
                                        eurs: formatCents(
                                            getTravelsTotalWithoutStatus(
                                                costInvoice.travels,
                                                'rejected',
                                                false,
                                            ),
                                            true,
                                        ),
                                    })}
                                </td>
                            </tr>
                            <tr>
                                <td>{trans('costs.travel-expenses-taxable')}</td>
                                <td style={{ minWidth: 100 }}>
                                    {trans('form.eurs', {
                                        eurs: formatCents(
                                            getTravelsTotalWithoutStatus(
                                                costInvoice.travels,
                                                'rejected',
                                                true,
                                            ),
                                            true,
                                        ),
                                    })}
                                </td>
                            </tr>
                        </>
                    ) : (
                        <tr>
                            <td>{trans('costs.travel-expenses')}</td>
                            <td style={{ minWidth: 100 }}>
                                {trans('form.eurs', {
                                    eurs: formatCents(
                                        getTravelsTotalWithoutStatus(costInvoice.travels, 'rejected'),
                                        true,
                                    ),
                                })}
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>{trans('costs.costs')}</td>
                        <td>
                            {trans('form.eurs', {
                                eurs: formatCents(
                                    getCostsTotalWithVat(
                                        costInvoice.receiptCosts.filter((r) => r.status !== 'rejected'),
                                    ),
                                    true,
                                ),
                            })}
                        </td>
                    </tr>

                    <tr>
                        <td>{trans('invoice.sum')}</td>
                        <td>
                            {trans('form.eurs', {
                                eurs: formatCents(
                                    getCostsTotalWithVat(
                                        costInvoice.receiptCosts.filter((r) => r.status !== 'rejected'),
                                    ) + getTravelsTotalWithoutStatus(costInvoice.travels, 'rejected'),
                                    true,
                                ),
                            })}
                        </td>
                    </tr>
                </tbody>
            </CostInvoiceTable>
        </>
    );
};

export default CostDocumentSummary;
