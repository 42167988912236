import { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EezyButton } from 'components/Buttons';
import DownloadSalariesModal from './DownloadSalariesModal';

interface IProps {
    disabled?: boolean;
    style?: CSSProperties;
}

export const DownloadSalaries = (props: IProps) => {
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div style={props.style}>
            <EezyButton
                dark
                color="purple"
                disabled={props.disabled}
                onClick={() => setModalOpen(true)}
                width={165}
            >
                {t('salaries:modal.title')}
            </EezyButton>

            {modalOpen && (
                <DownloadSalariesModal
                    handleModalClose={() => setModalOpen(false)}
                    isOpen={modalOpen}
                />
            )}
        </div>
    );
};
