import React from 'react';
import { connect } from 'react-redux';
import { UiLanguage } from '../../../../shared/src/types/user';
import config from '../../config';
import { calcOrderProcessingMinutesDependingOnWeekday, trans } from '../../utils';
import { EezyButton } from '../Buttons';
import EezyPayPricingTable from '../EezyPayPricingTable';
import { Flex } from '../Flex';
import { Modal, ModalContent } from './Modal';
import { getLocalStorage, setLocalStorage } from '../../utils/user/userUtils';
import { Icon } from '../Icon';
import styled from 'styled-components';
import { SCREEN_S } from '../../styles/variables';
import InfoMessage from 'components/InfoMessage';

export const SUBSCRIPTION_BUY_CLICK = 'subscription-buy-click';

interface IModalProps {
    handleModalClose: () => void;
    isOpen: boolean;
    language: UiLanguage;
    subscriptionEndDate: Date;
}

const ButtonContainer = styled(Flex)`
    margin-top: 28px;
    padding: 20px;
    @media (max-width: ${SCREEN_S}px) {
        padding: 0;
        position: absolute;
        bottom: 20px;
        right: 20px;
        left: 20px;
        align-items: center;
    }
`;

const StyledModalContent = styled(ModalContent)`
    overflow: auto;
    width: 100%;
    max-width: 100vw;
    @media (max-width: ${SCREEN_S}px) {
        margin-bottom: 80px !important;
        max-height: 100vh;
    }
`;

export const renderOrderProcessingMessage = () => (
    <InfoMessage message={trans('eezyPay:order-processing-message')} />
);

const SubscriptionModal = (props: IModalProps) => {
    const [selectedSubscription, setSelectedSubscription] = React.useState<number>(1);
    const handleBuyClick = () => {
        const { language, handleModalClose } = props;
        let subscriptionLink;
        switch (selectedSubscription) {
            case 1:
                subscriptionLink =
                    language === 'en'
                        ? config.eezyPaySubscriptionPayUrl4En
                        : config.eezyPaySubscriptionPayUrl4Fi; //new 1 month package, case 1 is url 4
                break;
            case 2:
                subscriptionLink =
                    language === 'en'
                        ? config.eezyPaySubscriptionPayUrl1En
                        : config.eezyPaySubscriptionPayUrl1Fi;
                break;
            case 3:
                subscriptionLink =
                    language === 'en'
                        ? config.eezyPaySubscriptionPayUrl2En
                        : config.eezyPaySubscriptionPayUrl2Fi;
                break;
            case 4:
                subscriptionLink =
                    language === 'en'
                        ? config.eezyPaySubscriptionPayUrl3En
                        : config.eezyPaySubscriptionPayUrl3Fi;
                break;
        }
        setLocalStorage(SUBSCRIPTION_BUY_CLICK, {});
        const win = window.open(subscriptionLink, '_blank');
        win?.focus();
        handleModalClose();
    };

    const { minutesSince: minutesSinceLastBuySubscriptionClick } =
        getLocalStorage(SUBSCRIPTION_BUY_CLICK) || {};

    return (
        <Modal id="modal-subscription" isOpen={props.isOpen} onClose={props.handleModalClose} title=" " small>
            <StyledModalContent>
                <Flex column>
                    {minutesSinceLastBuySubscriptionClick <
                        calcOrderProcessingMinutesDependingOnWeekday(minutesSinceLastBuySubscriptionClick) &&
                        renderOrderProcessingMessage()}
                    <Flex style={{ fontWeight: 700, margin: '24px 0 12px 0' }}>
                        {trans('eezyPay:choose-package-start-subscription')}
                    </Flex>
                    <EezyPayPricingTable
                        selectedSubscription={selectedSubscription}
                        setSelectedSubscription={setSelectedSubscription}
                    />
                    <Flex style={{ marginTop: 24 }}>{trans('eezyPay:fee-explanation-1')}</Flex>
                    <Flex style={{ marginTop: 24 }}>{trans('eezyPay:fee-explanation-2')}</Flex>
                </Flex>
            </StyledModalContent>
            <ButtonContainer justifyCenter>
                <EezyButton color="purple" dark fullWidth onClick={handleBuyClick} style={{ zIndex: 3 }}>
                    {trans('eezyPay:buy')}
                </EezyButton>
            </ButtonContainer>
        </Modal>
    );
};

const mapStateToProps = (state: any) => {
    return {
        subscriptionEndDate: state.eezyPay.subscriptionEndDate,
    };
};

export default connect(mapStateToProps)(SubscriptionModal);
