import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NestedPageWrapper from 'components/layout/NestedPageWrapper';
import SalesStatsBillingChart from 'components/charts/SalesStatsBillingChart';
import SalesStatsBillingChartLegend from 'components/charts/SalesStatsBillingChartLegend';
import SalesStatsBillingDetails from 'components/charts/SalesStatsBillingDetails';
import SalesStatsBillingSuggestion from 'components/charts/SalesStatsBillingSuggestion';
import NestedPageNavigationTabs from 'components/layout/NestedPageNavigationTabs';
import { ALLOWED_CHART_LINE_NUMBER, DefaultMinDatePicker } from './validation/constants';
import { calculateCreditedPrices, getNewDataColor, groupInvoices, randomSimleId } from './utils';
import { Checkbox } from 'components/form';
import { EezyButton } from 'components/Buttons';
import { Icon } from 'components/Icon';
import { useStatisticInvoice } from 'containers/invoice/hooks/useStatisticInvoice';
import ButtonPeriodPicker from 'components/form/ButtonPeriodPicker';
import {
    LabelValueType,
    SalesPageStatisticData,
    SalesPageStatisticSuggestion,
    StatisticInvoice,
} from './types/SalesPageStatisticTypes';
import { useApolloClient } from '@apollo/client';
import { GET_AAVA_INVOICES, GET_INVOICES } from 'containers/dashboard/queries';
import { IBriefInvoice, IInvoice } from '../../../../shared/src/types/invoice';
import { IRootState } from 'reducers';
import GET_PAYMENTS_BY_DATE from './queries/getPaymentsByDate';
import { ITransaction } from './Transactions';
import { priceWithoutVat } from 'utils';
import { capitalize } from 'utils/str';

enum SalesTabEnum {
    Laskutus,
    EezyPay,
}

function mapInvoiceToChartItem(
    item: StatisticInvoice,
    withVat = true,
    period = 'year',
): LabelValueType<number> {
    return {
        label: moment(item.date).format(period == 'year' ? 'MMM' : 'DD'),
        value: withVat ? item.totalWithVat : item.total,
    };
}

function makeLegendLabel(from: Date, to: Date, period: string): string {
    if (period == 'month') {
        return moment(from).format('MMM YYYY');
    }
    return from.getFullYear().toString();
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SalesPageStatistic = () => {
    const [activeSalesTab, setActiveSalesTab] = useState<SalesTabEnum>(SalesTabEnum.Laskutus);
    const [minDatePicker, setMinDatePicker] = useState<Date | null>(null);

    const [defaultFilter, setDefaultFilter] = useState({
        fromDate: moment().startOf('year').toDate(),
        toDate: moment().endOf('date').toDate(),
        period: 'year',
        maxDate: new Date(),
    });

    const userId = useSelector((state: IRootState) => state.user.id);

    const [shouldRefetch, setShouldRefetch] = useState(true);
    const [statisticInvoicesFetched, setStatisticInvoicesFetched] = useState(false);
    const [data, setData] = useState<SalesPageStatisticData[]>([]);
    const [includeDataVat, setIncludeDataVat] = useState(false);

    const [suggestions, setSuggestions] = useState<SalesPageStatisticSuggestion[]>([]);

    const { getStatisticInvoices, updateOneStatisticInvoice } = useStatisticInvoice();

    const { t } = useTranslation();

    const subNavigationLinks: LabelValueType<string>[] = [
        { label: t('statistic.tabs.invoices'), value: SalesTabEnum.Laskutus.toString() },
        { label: t('statistic.tabs.eezy-page'), value: SalesTabEnum.EezyPay.toString() },
    ];

    const fetchStatisticInvoices = async (
        from: Date,
        to: Date,
        period: string,
    ): Promise<StatisticInvoice[]> => {
        // An issue occur when requesting data of current year that is past current date.
        // Therefore, we only request a period until today if it is current year.
        const now = new Date();
        const currentYear = now.getFullYear();

        if (from.getFullYear() == currentYear && +from - +now > 0) {
            return [];
        }
        if (to.getFullYear() == currentYear && +to - +now > 0) {
            to = now;
        }

        const results = await getStatisticInvoices(from, to);

        if (!results) {
            return [];
        }

        const groupByPeriod = period;
        const groupByKey = groupByPeriod == 'year' ? 'YYYY-MM' : 'YYYY-MM-DD';
        const byGroups = groupInvoices(results, (item) => moment(item.date).format(groupByKey));

        if (groupByPeriod == 'month') {
            const daysInMonth = moment(from).daysInMonth();

            for (let i = 1; i <= daysInMonth; i++) {
                const date = moment(from).set('date', i);
                const key = date.format('YYYY-MM-DD');
                const isAfterToday = +date.toDate() - +now > 0;
                const value = isAfterToday ? NaN : 0;

                if (!byGroups[key]) {
                    byGroups[key] = {
                        id: Math.floor(Math.random() * 10000),
                        date: key,
                        updatedDate: key,
                        status: 'Generated',
                        total: value,
                        totalWithVat: value,
                        paidAmount: value,
                        partlyCredited: false,
                    };
                }
            }
        } else if (groupByPeriod == 'year') {
            for (let month = 0; month < 12; month++) {
                const date = moment(from).set({ month });
                const key = date.format('YYYY-MM');
                const isAfterToday = +date.toDate() - +now > 0;
                const value = isAfterToday ? NaN : 0;

                if (!byGroups[key]) {
                    byGroups[key] = {
                        id: Math.floor(Math.random() * 10000),
                        date: key,
                        updatedDate: key,
                        status: 'Generated',
                        total: value,
                        totalWithVat: value,
                        paidAmount: value,
                        partlyCredited: false,
                    };
                }
            }
        }

        const result = Object.values(byGroups);

        result.sort((a, b) => +moment(a.date).toDate() - +moment(b.date).toDate());

        return result;
    };

    const fetchEezyPayInvoices = async (from: Date, to: Date, period: string) => {
        try {
            const { data } = await fetchAavaClient.query({
                query: GET_PAYMENTS_BY_DATE,
                context: { clientName: 'eezyPayHasura' },
                fetchPolicy: 'cache-first',
                variables: {
                    yearStartTimestamp: from.toISOString(),
                    yearEndTimestamp: to.toISOString(),
                },
            });

            const invoices: StatisticInvoice[] = data?.transactions?.map((i: ITransaction) => ({
                id: i.orderNumber.toString(),
                date: i.completed,
                status: 'completed',
                total: priceWithoutVat(i.servicePrice, i.serviceVat),
                totalWithVat: i.servicePrice,
            }));

            if (!invoices) {
                return [];
            }

            const now = new Date();
            const groupByPeriod = period;
            const groupByKey = groupByPeriod == 'year' ? 'YYYY-MM' : 'YYYY-MM-DD';
            const byGroups = groupInvoices(invoices, (item) => moment(item.date).format(groupByKey));

            if (groupByPeriod == 'month') {
                const daysInMonth = moment(from).daysInMonth();

                for (let i = 1; i <= daysInMonth; i++) {
                    const date = moment(from).set('date', i);
                    const key = date.format('YYYY-MM-DD');
                    const isAfterToday = +date.toDate() - +now > 0;
                    const value = isAfterToday ? NaN : 0;

                    if (!byGroups[key]) {
                        byGroups[key] = {
                            id: Math.floor(Math.random() * 10000),
                            date: key,
                            updatedDate: key,
                            status: 'Generated',
                            total: value,
                            totalWithVat: value,
                            paidAmount: value,
                            partlyCredited: false,
                        };
                    }
                }
            } else if (groupByPeriod == 'year') {
                for (let month = 0; month < 12; month++) {
                    const date = moment(from).set({ month });
                    const key = date.format('YYYY-MM');
                    const isAfterToday = +date.toDate() - +now > 0;
                    const value = isAfterToday ? NaN : 0;

                    if (!byGroups[key]) {
                        byGroups[key] = {
                            id: Math.floor(Math.random() * 10000),
                            date: key,
                            updatedDate: key,
                            status: 'Generated',
                            total: value,
                            totalWithVat: value,
                            paidAmount: value,
                            partlyCredited: false,
                        };
                    }
                }
            }

            const result = Object.values(byGroups);

            result.sort((a, b) => +moment(a.date).toDate() - +moment(b.date).toDate());

            return result;
        } catch (e) {
            return [];
        }
    };

    const fetchInvoices = (tab: SalesTabEnum, from: Date, to: Date, period: string) => {
        switch (tab) {
            case SalesTabEnum.Laskutus:
                return fetchStatisticInvoices(from, to, period);
            case SalesTabEnum.EezyPay:
                return fetchEezyPayInvoices(from, to, period);
            default:
                return [];
        }
    };

    const generateSuggestions = () => {
        if (!minDatePicker) {
            return;
        }

        // Generate suggestions
        const NUMBER_OF_YEARS = ALLOWED_CHART_LINE_NUMBER + 1;
        const list: SalesPageStatisticSuggestion[] = [];

        let currentYear = new Date().getFullYear() + 1;
        const mainFromDate = defaultFilter.fromDate;
        const mainToDate = defaultFilter.toDate;
        const period = defaultFilter.period as any;

        do {
            currentYear--;

            if (currentYear < minDatePicker.getFullYear()) {
                break;
            }

            const from = moment(mainFromDate).set({ year: currentYear }).startOf(period).toDate();
            const to = moment(mainToDate).set({ year: currentYear }).endOf(period).toDate();
            const name = makeLegendLabel(from, to, period);

            const exist = data.some((i) => i.name == name);

            if (exist) {
                continue;
            }

            list.push({
                id: randomSimleId(),
                label: name,
                value: {
                    from: moment().set({ year: currentYear }).startOf('year').toDate(),
                    to: moment().set({ year: currentYear }).endOf('year').toDate(),
                },
            });
        } while (list.length < NUMBER_OF_YEARS);

        setSuggestions(list);
    };

    const onSelectSuggestionRange = async (item: SalesPageStatisticSuggestion) => {
        // Check duplicate
        const selectedName = makeLegendLabel(item.value.from, item.value.to, defaultFilter.period);
        const alreadyExist = data.some((i) => i.name == selectedName);
        if (alreadyExist) {
            return;
        }

        let from = item.value.from;
        let to = item.value.to;
        const period = defaultFilter.period;

        if (defaultFilter.period == 'month') {
            const targetMonth = moment(defaultFilter.fromDate).month();
            from = moment(from).set({ month: targetMonth }).startOf('month').toDate();
            to = moment(to).set({ month: targetMonth }).endOf('month').toDate();
        }

        const invoices = await fetchInvoices(activeSalesTab, from, to, period);

        // Add to new data
        setData([
            ...data,
            {
                name: makeLegendLabel(from, to, period),
                color: getNewDataColor(data),
                fromDate: item.value.from,
                toDate: item.value.to,
                invoices,
                items: invoices.map((i) => mapInvoiceToChartItem(i, includeDataVat, period)),
            },
        ]);
    };

    const onRemoveSuggestionRange = (item: SalesPageStatisticData) => {
        // Remove from current data
        setData(data.filter((i, index) => index == 0 || i.name != item.name));

        // Reload suggestions
        const updatedSuggestions: SalesPageStatisticSuggestion[] = [
            ...suggestions,
            {
                id: randomSimleId(),
                label: item.name,
                value: { from: item.fromDate, to: item.toDate },
            },
        ];

        updatedSuggestions.sort((a, b) => b.value.from.getFullYear() - a.value.from.getFullYear());

        setSuggestions(updatedSuggestions);
    };

    const onChangeFilterDateRange = async ([from, to, period]: [Date, Date, string]) => {
        setDefaultFilter({
            ...defaultFilter,
            fromDate: from,
            toDate: to,
            period,
        });
    };

    const goNextFilterDateRange = () => {
        const period = defaultFilter.period;

        const from = moment(defaultFilter.fromDate)
            .add(1, period as any)
            .toDate();
        const to = moment(defaultFilter.toDate)
            .add(1, period as any)
            .toDate();

        if (moment().isBefore(from)) {
            return;
        }

        onChangeFilterDateRange([from, to, period]);
    };

    const goPreviousFilterDateRange = () => {
        const period = defaultFilter.period;

        let from = moment(defaultFilter.fromDate).add(-1, period as any);
        let to = moment(defaultFilter.toDate).add(-1, period as any);

        if (defaultFilter.period == 'year') {
            from = from.startOf('year');
            to = to.endOf('year');
        }

        if (moment(minDatePicker).isAfter(to)) {
            return;
        }

        onChangeFilterDateRange([from.toDate(), to.toDate(), period]);
    };

    const onChangeFilterDatePeriod = (period: string) => {
        setDefaultFilter({
            ...defaultFilter,
            period,
        });
    };

    const toggleIncludeVAT = () => {
        const shouldIncludeVat = !includeDataVat;

        setIncludeDataVat(shouldIncludeVat);

        setData(
            data.map((item) => ({
                ...item,
                items: item.invoices.map((i) =>
                    mapInvoiceToChartItem(i, shouldIncludeVat, defaultFilter.period),
                ),
            })),
        );
    };

    const onChangeTab = (value: any) => {
        setActiveSalesTab(parseInt(value));
    };

    const visibleLabelText = (defaultLabel: string): string => {
        const now = new Date();
        const thisYear = now.getFullYear();

        if (defaultFilter.period == 'year') {
            if (
                defaultFilter.fromDate.getFullYear() == thisYear &&
                defaultFilter.toDate.getFullYear() == thisYear
            ) {
                return t(defaultLabel);
            }
        }

        if (defaultFilter.period == 'year') {
            return defaultFilter.fromDate.getFullYear().toString();
        } else if (defaultFilter.period == 'month') {
            return moment(defaultFilter.fromDate).format('MMM YYYY');
        }

        return `${moment(defaultFilter.fromDate).format('MMM')} ${defaultFilter.fromDate.getFullYear()}`;
    };

    const visibleSuggestions = (): boolean => {
        return data.length <= ALLOWED_CHART_LINE_NUMBER - 1 && !!suggestions.length;
    };

    const visibleDetailSubtext = (): string => {
        const text = visibleLabelText(
            activeSalesTab == SalesTabEnum.Laskutus
                ? 'statistic.stats-details-current-year'
                : 'statistic.eezy-details-current-year',
        );
        return capitalize(text);
    };

    useEffect(() => {
        if (!shouldRefetch) {
            return;
        }
        // Fetch data of current year as default
        const from = defaultFilter.fromDate;
        const to = defaultFilter.toDate;
        const period = defaultFilter.period;

        (async () => {
            const invoices = await fetchInvoices(activeSalesTab, from, to, period);

            setData([
                {
                    name: makeLegendLabel(from, to, period),
                    color: getNewDataColor(data, 0),
                    fromDate: from,
                    toDate: to,
                    invoices,
                    items: invoices.map((i) => mapInvoiceToChartItem(i, includeDataVat, period)),
                },
            ]);
            setStatisticInvoicesFetched(true);
        })();
    }, [shouldRefetch, activeSalesTab, defaultFilter]);

    useEffect(() => {
        generateSuggestions();
    }, [data, defaultFilter, minDatePicker]);

    const fetchAavaClient = useApolloClient();

    useEffect(() => {
        if (!statisticInvoicesFetched) return;

        let page = 0;
        const pageSize = 10;

        (async () => {
            // maximum 10 iteration to limit update action
            for (let k = 0; k < 10; k++) {
                const { data } = await fetchAavaClient.query({
                    query: GET_AAVA_INVOICES,
                    variables: { offset: page * pageSize, pageSize },
                });

                const items = data?.aavaInvoices?.items?.map((invoice: IBriefInvoice) => {
                    const creditedPrices = calculateCreditedPrices(invoice);

                    return {
                        id: invoice.id,
                        userId,
                        date: moment(invoice.invoiceDate || invoice.updateDate).format('YYYY-MM-DD'),
                        updatedDate: moment(invoice.updateDate || invoice.invoiceDate).format(
                            'YYYY-MM-DD HH:mm:ss',
                        ),
                        status: invoice.status,
                        total: invoice.total,
                        totalWithVat: invoice.totalWithVat,
                        paidAmount: invoice.paidAmount,
                        partlyCredited:
                            invoice.status === 'paid' &&
                            creditedPrices.creditedPriceWithVAT < invoice.totalWithVat,
                    };
                });

                if (!items) {
                    return;
                }

                for (const invoice of items) {
                    const res = await updateOneStatisticInvoice(invoice);

                    if (!res) {
                        setShouldRefetch(true);
                        return;
                    }
                }

                page += 1;
            }
        })();
    }, [statisticInvoicesFetched]);

    useEffect(() => {
        (async () => {
            const { data } = await fetchAavaClient.query({
                query: GET_INVOICES,
                fetchPolicy: 'cache-first',
                variables: {
                    order_by: 'invoiceDate Asc',
                },
            });

            // const list = data.allInvoices.items.toSorted((a: any, b: any) => {
            //     if (!a.invoiceDate) {
            //         return 1;
            //     }
            //     if (!b.invoiceDate) {
            //         return -1;
            //     }
            //     return moment(a.invoiceDate).isAfter(b.invoiceDate) ? 1 : -1;
            // });
            const list = data.allInvoices.items.filter((invoice: IInvoice) => invoice.invoiceDate);
            const firstInvoice = list[list.length - 1];
            if (!firstInvoice) {
                setMinDatePicker(new Date());
                return;
            }

            const firstDate = moment(firstInvoice.invoiceDate).toDate();
            setMinDatePicker(firstDate);
        })();
    }, []);

    const filterRegion = (
        <div className="flex flex-col-reverse md:flex-row gap-8 md:items-center">
            <Checkbox
                checked={includeDataVat}
                label={t('statistic.stats-include-vat')}
                onChange={toggleIncludeVAT}
            />
            <div className="flex gap-8">
                <ButtonPeriodPicker
                    label={visibleLabelText(t('statistic.stats-default-date'))}
                    value={[defaultFilter.fromDate, defaultFilter.toDate]}
                    disabled={!minDatePicker}
                    onChange={onChangeFilterDateRange}
                    onChangePeriod={onChangeFilterDatePeriod}
                    maxDate={defaultFilter.maxDate}
                    minDate={minDatePicker!}
                />

                <div>
                    <EezyButton
                        className="v2-btn"
                        color="purple"
                        style={{ border: 0 }}
                        transparent
                        hasIcon={true}
                        onClick={goPreviousFilterDateRange}
                    >
                        <Icon icon={['fas', 'chevron-left']} />
                    </EezyButton>
                    <EezyButton
                        className="v2-btn"
                        color="purple"
                        style={{ border: 0 }}
                        transparent
                        hasIcon={true}
                        onClick={goNextFilterDateRange}
                    >
                        <Icon icon={['fas', 'chevron-right']} />
                    </EezyButton>
                </div>
            </div>
        </div>
    );

    return (
        <NestedPageWrapper name={t('statistic.page-title')}>
            <NestedPageNavigationTabs
                links={subNavigationLinks}
                extraNode={filterRegion}
                activeValue={activeSalesTab}
                onChange={onChangeTab}
            />

            <SalesStatsBillingDetails data={data} subtext={visibleDetailSubtext()} />

            <SalesStatsBillingChart data={data} />

            <div className="md:px-16 pb-12 flex flex-col md:flex-row items-center gap-8 items-stretch">
                <div className="flex gap-4 flex-wrap">
                    {data.map((i, index) => (
                        <SalesStatsBillingChartLegend
                            key={i.name}
                            label={i.name}
                            color={i.color}
                            canRemove={index > 0}
                            onClick={() => onRemoveSuggestionRange(i)}
                        />
                    ))}
                </div>

                {visibleSuggestions() ? (
                    <div className="w-px hidden md:block" style={{ background: '#D4D4D8' }} />
                ) : null}

                <div>
                    {visibleSuggestions() ? (
                        <>
                            <SalesStatsBillingSuggestion
                                disabled={!minDatePicker}
                                minDate={minDatePicker ?? DefaultMinDatePicker}
                                options={suggestions.slice(0, 2)}
                                onSelect={onSelectSuggestionRange}
                            />
                        </>
                    ) : null}
                </div>
            </div>
        </NestedPageWrapper>
    );
};

export default SalesPageStatistic;
