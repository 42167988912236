import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { showModals } from 'actions/auth';
import ColorBox from 'components/ColorBox';
import { Bold, P } from 'components/textElements';
import { EezyButton } from 'components/Buttons';
import UploadTaxCard from './UploadTaxCard';
import { COLOR_BLACKWATER, COLOR_LILA_MEDIUM } from 'styles/variables';

interface IProps {
    nextTaxCardStartDate?: string;
    taxCardMissing?: boolean;
    taxCardPending?: boolean;
    taxCardNotValidYet?: boolean;
    isIdentified?: boolean;
}

const TaxCardNote: FC<IProps> = (
    { nextTaxCardStartDate, taxCardMissing, taxCardPending, taxCardNotValidYet, isIdentified}
) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();
    if (
        !taxCardPending &&
        !taxCardMissing &&
        isIdentified &&
        !(taxCardNotValidYet && nextTaxCardStartDate)
    ) {
        return null;
    }

    return (
        <ColorBox
            color={COLOR_LILA_MEDIUM}
            disableClose
            style={{ marginBottom: 40, padding: 30 }}
        >
            {taxCardPending ? (
                <>
                    <Bold>{t('salaries:taxCard.pending1')}</Bold>
                    <P style={{ marginTop: 10, color: COLOR_BLACKWATER }}>
                        {t('salaries:taxCard.pending2')}
                    </P>
                </>
            ) : taxCardNotValidYet && nextTaxCardStartDate ? (
                <>
                    <Bold>
                        {t('salaries:taxCard.notValidYet1', {
                            date: moment(
                                nextTaxCardStartDate,
                                'DD.MM.YYYY'
                            ).format('l')
                        })}
                    </Bold>
                    <P style={{ marginTop: 10, color: COLOR_BLACKWATER }}>
                        {t('salaries:taxCard.notValidYet2')}
                    </P>

                    <UploadTaxCard hideLabel style={{ marginTop: 40 }} />
                </>
            ) : taxCardMissing && isIdentified ? (
                <>
                    <Bold>{t('salaries:taxCard.missing1')}</Bold>
                    <P style={{ marginTop: 10, color: COLOR_BLACKWATER }}>
                        {t('salaries:taxCard.missing2')}
                    </P>
                    <UploadTaxCard hideLabel style={{ marginTop: 40 }} />
                </>
            ) : taxCardMissing && !isIdentified ? (
                <>
                    <Bold>
                        {t('salaries:taxCard.notIdentifiedMissing1')}
                    </Bold>
                    <P style={{ marginTop: 10, color: COLOR_BLACKWATER }}>
                        {t('salaries:taxCard.notIdentifiedMissing2')}
                    </P>
                    <EezyButton
                        color="purple"
                        dark
                        onClick={() => dispatch(showModals(['AUTH']))}
                        width={90}
                        style={{ marginTop: 40 }}
                    >
                        {t('salaries:taxCard.notIdentifiedButton')}
                    </EezyButton>
                </>
            ) : null}
        </ColorBox>
    );
};

export default TaxCardNote;
