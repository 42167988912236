import { useQuery } from '@apollo/client';
import React from 'react';
import { toast } from 'react-toastify';
import { formatTime, trans } from '../../utils';
import { GET_BACKEND_MESSAGE } from './queries';

// How often the backend message is queried from the server.
// E.g. for cases when the maintenace break is dismissed.
const REFETCH_MINUTES = 10;

// How often a warning is displayed if the previous one is hidden
const WARNING_MINUTES = 5;

const MaintenanceBreak = () => {
    const [interval, setIntervalTimer] = React.useState(0);

    const { data } = useQuery(GET_BACKEND_MESSAGE, {
        fetchPolicy: 'network-only',
        onCompleted: (message: any) => {
            warn(message);
            setIntervalTimer(
                setInterval((_: number) => {
                    warn(message);
                }, WARNING_MINUTES * 60 * 1000)
            );
        },
        pollInterval: REFETCH_MINUTES * 60 * 1000
    });

    const warn = (message: any) => {
        if (message?.backendMessage?.type === 'restart') {
            toast.warn(
                trans('errors.maintenance-break-coming', {
                    min: message?.backendMessage.duration,
                    time: formatTime(message?.backendMessage.time)
                }),
                { autoClose: false, toastId: 'maintenanceWarning' }
            );
        } else {
            clearInterval(interval);
        }
    };

    return null;
};

export default MaintenanceBreak;
