import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { UiLanguage } from '../../../../shared/src/types/user';
import { Bold, P, UniqueTitleCenter } from '../../components/textElements';
import { COLOR_BLUM } from '../../styles/variables';
import { trans } from '../../utils';

interface IProps {
    email: string;
    language: UiLanguage;
}

const PasswordLinkSent = (props: IProps) => {
    window.dataLayer = window.dataLayer || [];

    return (
        <>
            <UniqueTitleCenter>{trans('login.confirmation')}</UniqueTitleCenter>
            <Bold color={COLOR_BLUM}>{trans('login.email-sent')}</Bold>
            <P>
                {trans('login.email-sent-to')}
                {props.email}
            </P>
            <P>{trans('login.click-n-login')}</P>
            <P>
                {trans('login.ps1')}
                <a href="mailto:tuki.kevytyrittajat@eezy.fi">
                    tuki.kevytyrittajat@eezy.fi
                </a>
                {trans('login.ps2')}
            </P>
            <P>
                {trans('login.ps3')}
                <a href="/u/login" className="link-eezy-fi-u-register">
                    {trans('login.ps3-link')}
                </a>
                .
            </P>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        language: state.user.language
    };
};

export default connect(mapStateToProps)(PasswordLinkSent);
