import { useLocation } from 'react-router-dom';
import { Location } from 'history';

export type ProtectedRouteState = {
  originalLocation: Location;
  redirectToOriginalUrl?: boolean;
  fullyQualifiedLogin?: boolean;
};

export const useAppLocation = <T extends ProtectedRouteState>() => {
  return useLocation() as Location & { state?: T };
};
