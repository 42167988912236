import { useApolloClient } from '@apollo/client';
import React from 'react';
import { IInvoice } from '../../../../shared/src/types/invoice';
import { EezyButton } from '../../components/Buttons';
import ErrorPointer from '../../components/ErrorPointer';
import { P } from '../../components/textElements';
import { Tools, ToolsHeader } from '../../components/TogglingTools';
import { trans } from '../../utils';
import { IInvoiceTemplate } from '../../utils/invoice/invoiceTemplates';
import { invoiceValidator } from '../../utils/invoice/validators';
import { formatValidationResult } from '../../utils/validation';
import { GET_OCCUPATIONS } from './queries';

interface IInvoiceTypeEditProps {
    editable: boolean;
    invoice?: IInvoice;
    selectedTemplate: IInvoiceTemplate;
    setTypeModalOpen: (val: boolean) => void;
}

const InvoiceTypeEdit = (props: IInvoiceTypeEditProps) => {
    const client = useApolloClient();
    const { editable, invoice, selectedTemplate, setTypeModalOpen } = props;
    const showArrows = true;

    const invoiceErrors = formatValidationResult(
        invoiceValidator.validate(invoice)
    );

    return (
        <div
            onMouseOver={() =>
                client.query({
                    query: GET_OCCUPATIONS
                })
            }
        >
            <Tools
                header={
                    <ToolsHeader
                        disabled={!invoice}
                        titleTransKey="invoice.type-title"
                    >
                        {editable && (
                            <EezyButton
                                color="purple"
                                disabled={!invoice}
                                onClick={() => setTypeModalOpen(true)}
                            >
                                {trans('general.edit')}
                            </EezyButton>
                        )}
                    </ToolsHeader>
                }
            >
                {(invoiceErrors?.occupation || invoiceErrors?.template) &&
                    showArrows &&
                    editable && (
                        <ErrorPointer
                            style={{ marginLeft: -27, marginTop: -25 }}
                        />
                    )}

                <P
                    disabled={!invoice}
                    style={{
                        marginTop: '4px',
                        fontSize: '13px'
                    }}
                >
                    {trans(`invoice.templates.${selectedTemplate.id}`)}
                    {invoice?.occupation && (
                        <>, {trans(`occupations:${invoice?.occupation}`)}</>
                    )}
                    {invoice?.isGroupInvoice && (
                        <>, {trans('invoice.groupInvoice.toggle')}</>
                    )}
                </P>
            </Tools>
        </div>
    );
};

export default InvoiceTypeEdit;
