import React from 'react';
import { IReceiptCost } from '../../../../shared/src/types/costs';
import { FormRadio, FormRow, FormSection } from '../../components/form';
import { trans } from '../../utils';
import {
    getTravelReceiptType,
    ICostComponentProps
} from '../../utils/costs/costLogic';

export const TravelReceiptType = (props: ICostComponentProps) => {
    const { dispatch } = props;
    const receipt: IReceiptCost | undefined = props.travelCostTemp?.cost;
    const [costType, setCostType] = React.useState('');

    React.useEffect(() => {
        const receiptType = receipt ? getTravelReceiptType(receipt) : undefined;
        setCostType(receiptType || '');
    }, [receipt]);

    if (!receipt) {
        return null;
    }

    const saveReceiptCost = (payload: any) => {
        dispatch({
            payload: { ...payload },
            type: 'SAVE_TRAVEL_COST_TEMP'
        });
    };

    const handleTypeChange = (val: string) => {
        setCostType(val);
        if (val === 'accommodation') {
            saveReceiptCost({
                description: trans('costs.travelReceiptType.accommodation')
            });
        } else {
            saveReceiptCost({
                description: trans('costs.travelReceiptType.ticket')
            });
        }
    };

    return (
        <div>
            <FormSection>
                <FormRow>
                    <FormRadio
                        label={trans('costs.travelReceiptType.question')}
                        name="travel-type"
                        options={[
                            {
                                label: trans(
                                    'costs.travelReceiptType.accommodation'
                                ),
                                value: 'accommodation'
                            },
                            {
                                label: trans('costs.travelReceiptType.ticket'),
                                value: 'ticket'
                            }
                        ]}
                        onChange={handleTypeChange}
                        required
                        value={costType}
                    />
                </FormRow>
            </FormSection>
        </div>
    );
};
