import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ThunkDispatch } from 'redux-thunk';
import { UiLanguage } from '../../../../shared/src/types/user';
import { reportYelToStore } from 'actions/user';
import { EezyButton } from 'components/Buttons';
import { AutoSaveCard } from 'components/cards';
import { Flex } from 'components/Flex';
import { Icon } from 'components/Icon';
import { P, RowTitle } from 'components/textElements';
import { COLOR_BLUM, COLOR_GREYJOY } from 'styles/variables';
import { REPORT_SHOULD_HAVE_YEL } from '../dashboard/queries';
import { useEffect, useState } from 'react';

interface IProps {
    language: UiLanguage;
    reportYelToStore: (yelSelfReported: boolean, yelShouldAsk: boolean) => void;
    yelSelfReported: boolean;
    birthday: string;
}

const shouldDisableYel = (birthday: string) => {
    const birthYear = Number(birthday.substring(0, 4));

    const birthDate = new Date(birthday);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    if (
        age < 18 ||
        (birthYear <= 1957 && age > 68) ||
        (birthYear >= 1958 && birthYear <= 1961 && age > 69) ||
        (birthYear >= 1962 && age > 78)
    ) {
        return true;
    }
    return false;
};

const ProfileInsurances = (props: IProps) => {
    const { t } = useTranslation();

    const [disabledYel, setDisabledYel] = useState(false);

    useEffect(() => {
        if (props.birthday) {
            if (shouldDisableYel(props.birthday)) {
                setDisabledYel(true);

                if (props.yelSelfReported) {
                    reportYel({ variables: { shouldHave: false } });
                }
            }
        }
    }, [props.birthday]);

    const [reportYel] = useMutation(REPORT_SHOULD_HAVE_YEL, {
        onCompleted: (data) => {
            toast(t('general.saved'));
            // the values are retrieved from rest api and updated through graphql api
            // so they don't automatically stay in sync
            props.reportYelToStore(data?.userYelSelfReport?.yelSelfReported, false);
        },
        onError: () => {
            toast.error(t('errors.general'));
        },
    });

    const handleConfirm = (shouldHave: boolean) => {
        reportYel({ variables: { shouldHave } });
    };

    return (
        <AutoSaveCard title={t('profile:insurances.title')}>
            <P>{t('profile:insurances.description')}</P>

            <P style={{ marginTop: 15 }}>
                <a
                    href={
                        props.language === 'en'
                            ? 'https://kevytyrittajat.eezy.fi/en/eezy-light-entrepreneurs-insurances/'
                            : 'https://kevytyrittajat.eezy.fi/kevytyrittajan-vakuutukset-ja-korvauksen-hakeminen/'
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {t('profile:insurances.link3')}
                    <Icon
                        className="small"
                        color={COLOR_BLUM}
                        icon={['far', 'arrow-up-right-from-square']}
                        style={{ marginLeft: 10 }}
                    />
                </a>
            </P>

            <P style={{ marginTop: 15 }}>{t('profile:insurances.description2')}</P>

            <Flex spread useWrap style={{ marginTop: 15 }}>
                <RowTitle color={COLOR_BLUM}>{t('dashboard.messages.yel.modal-confirm')}</RowTitle>
                <div>
                    <EezyButton
                        dark={props.yelSelfReported}
                        color="purple"
                        square
                        onClick={() => handleConfirm(true)}
                        disabled={disabledYel}
                    >
                        {t('general.yes')}
                    </EezyButton>
                    <EezyButton
                        dark={!props.yelSelfReported}
                        color="purple"
                        square
                        onClick={() => handleConfirm(false)}
                        disabled={disabledYel}
                    >
                        {t('general.no-I')}
                    </EezyButton>
                </div>
            </Flex>

            {disabledYel && <P color={COLOR_GREYJOY}>{t('profile:insurances.disabled-reason')}</P>}

            <P>
                <Link to="/yel">
                    {t('profile:insurances.link2')}
                    <Icon
                        className="small"
                        color={COLOR_BLUM}
                        icon={['far', 'arrow-up-right-from-square']}
                        style={{ marginLeft: 10 }}
                    />
                </Link>
            </P>
        </AutoSaveCard>
    );
};

const mapStateToProps = (state: any) => {
    return {
        language: state.user.language,
        // Should user be yel insured. Reported by user xerself.
        yelSelfReported: state.user.yelSelfReported,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        reportYelToStore: (yelSelfReported: boolean, yelShouldAsk: boolean) => {
            dispatch(reportYelToStore(yelSelfReported, yelShouldAsk));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInsurances);
