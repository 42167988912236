import { gql } from '@apollo/client';

// Queries for local state management in invoice

export const GROUP_INVOICE_TOTAL_CHANGED = gql`
    {
        groupInvoiceTotalChanged @client
    }
`;

// Wether to show  the "invoice-data-missing"-error-note on ivoice or not
export const INVOICE_ERRORS_VISIBLE = gql`
    {
        invoiceErrorsVisible @client
    }
`;

export const RECIPIENT_FH_ERRORS_VISIBLE = gql`
    {
        recipientFHErrorsVisible @client
    }
`;

export const SELECTED_FILL_HELPER = gql`
    {
        fillHelper @client {
            id
            type
        }
    }
`;

export const NEW_INVOICE_NEW_RECIPIENT = gql`
    {
        newInvoiceRecipient @client {
            contactPersonId
            id
            type
        }
    }
`;

export const INVOICE_COPY_META = gql`
    {
        copyMeta @client {
            id
            messages
        }
    }
`;

export const INVOICE_SPREAD_STATE = gql`
    {
        invoiceSpread @client {
            id
            panel
            openSecondMenu
        }
    }
`;
