import React from 'react';

const GdprFi = () => {
    return (
        <div>
            <div>
                <p>
                    Sopimus henkilötietojen käsittelystä on sinun ja Eezyn
                    välinen. Tarkoituksena on selventää asiakkaidesi
                    henkilötietojen käsittelyn tarkoituksia ja ehtoja.
                    Kerätessäsi asiakkailtasi tietoja laskutusta varten
                    muodostuu sinulle asiakasrekisteri, joka tarkoittaa, että
                    olet rekisterinpitäjä. Täyttäessäsi asiakkaasi tietoja Eezyn
                    nettipalveluun annat asiakkaasi henkilötietoja meille ja me
                    taas käsittelemme tietoja esim. laskutuksen toteuttamiseksi.
                    Tämä tekee meistä henkilötietojen käsittelijän.
                    Tietosuoja-asetuksen mukaan henkilötietojen käsittelijän
                    suorittamaa käsittelyä on määritettävä sopimuksella ja tällä
                    sopimuksella määritetään ehdot henkilötietojen käsittelylle.
                </p>
            </div>
            <div>
                <p>
                    Sinun tulee hyväksyä sopimus, jotta voit palveluamme
                    käyttää, mutta muistathan rekisterinpitäjänä luoda myös
                    tietosuojaselosteen, mikäli säilytät asiakkaasi
                    henkilötietoja esim. tietokoneellasi. Tietosuojaselosteen
                    voit luoda täältä:{' '}
                    <a
                        href="https://kevytyrittajat.eezy.fi/tietosuojaseloste-2/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        https://kevytyrittajat.eezy.fi/tietosuojaseloste-2/
                    </a>
                </p>
            </div>
            <div>
                <p>Sinä palvelun käyttäjänä (Rekisterinpitäjä)</p>

                <p>JA</p>

                <p>Eezy Kevytyrittäjät Oy</p>
            </div>
            <div>
                <p>
                    <b>LIITTEET</b>
                </p>
                <p>Liite 1 Henkilötietojen käsittelyä koskeva liite</p>
                <p>Liite 2 Alihankkijat</p>
            </div>

            <div>
                <p>
                    <b>1. OSAPUOLET</b>
                </p>
                <div className="p1">
                    <p>
                        1.1 Palvelun käyttäjä (eli "sinä") jäljempänä "Asiakas"
                    </p>

                    <p>1.2 Eezy Kevytyrittäjät Oy</p>
                    <p>Y-tunnus: 2197002-2</p>
                    <p>Maistraatinportti 1, 00240 Helsinki</p>
                    <p>jäljempänä "Toimittaja"</p>

                    <p>
                        Asiakas ja Toimittaja jäljempänä yhdessä "Osapuolet" ja
                        erikseen "Osapuoli".
                    </p>
                </div>
            </div>
            <div>
                <p>
                    <b>2. TAUSTA</b>
                </p>
                <div>
                    <p>
                        2.1 Toimittaja ja Asiakas ovat tehneet sopimuksen siitä,
                        että Toimittaja tarjoaa Asiakkaalle ns.
                        laskutuspalvelun, jonka avulla Asiakas voi yritystä
                        perustamatta työskennellä oman asiakkaansa kanssa
                        tekemänsä toimeksiantosopimuksen perusteella Toimittajan
                        hoitaessa työn laskutuksen ja Asiakkaan kanssa
                        sovittujen ansiotuloon liittyvien lakisääteisten
                        velvoitteiden hoitamisen.
                    </p>

                    <p>
                        2.2 Tämä sopimus henkilötietojen käsittelystä
                        ("Sopimus") määrittää Toimittajan Asiakkaan puolesta
                        suorittaman Henkilötietojen käsittelyn ehdot.
                    </p>

                    <p>
                        2.3 Osapuolet täydentävät tätä Sopimusta laatimalla
                        Henkilötietojen käsittelyä koskevan täydennysliitteen
                        ("Täydennysliite"), joka on tämän Sopimuksen liitteenä
                        1. Osapuolet ovat tämän Sopimuksen voimaantullessa
                        laatineet seuraavat Täydennysliitteet Liite 1 ja Liite
                        2. Täydennysliitteet, jotka laaditaan tämän Sopimuksen
                        voimaantulon jälkeen, lisätään tämän Sopimuksen
                        liitteeksi. Jos tämän Sopimuksen ja Täydennysliitteen
                        välillä on ristiriita, Täydennysliite saa etusijan.
                    </p>

                    <p>
                        2.4 Osapuolet sopivat, että Toimittaja toimii
                        Tietosuojasääntelyn mukaisena Henkilötietojen
                        käsittelijänä ja Asiakas rekisterinpitäjänä.
                    </p>

                    <p>
                        2.5 Jos tämän Sopimuksen ja sen liitteiden,
                        Tietosuojasääntelyn tai Mallisopimuslausekkeiden välillä
                        on ristiriitoja, sovelletaan seuraavaa
                        etusijajärjestystä:
                    </p>

                    <div className="p1">
                        <p>
                            i) Tietosuojasääntely, mukaan lukien
                            Mallisopimuslausekkeet
                        </p>
                        <p>ii) Sopimus liitteineen</p>
                    </div>
                </div>
            </div>
            <div>
                <p>
                    <b>3. MÄÄRITELMÄT</b>
                </p>
                <p>
                    <i>Henkilötieto</i>
                </p>
                <p className="p1">
                    tarkoittaa kaikkia tunnistettavissa olevaan luonnolliseen
                    henkilöön liittyviä tietoja, joita Toimittaja käsittelee
                    tarkoituksenaan asiakasrekisterin ylläpito sekä
                    toimeksiantosuhteeseen liittyvien lakisääteisten
                    velvoitteiden hoitaminen. Tässä yhteydessä käsittelyllä
                    tarkoitetaan kaikkia Toimittajan Henkilötiedoille millä
                    tahansa tavalla suorittamia toimenpiteitä, kuten keräämistä,
                    järjestämistä, säilyttämistä, muokkaamista, hakemista,
                    käyttöä, luovuttamista, siirtämistä, välittämistä,
                    yhdistämistä, estämistä, poistamista tai tuhoamista.
                </p>

                <p>
                    <i>Mallisopimuslausekkeet</i>
                </p>
                <p className="p1">
                    tarkoittaa Euroopan komission päätöksen 2010/87/EU (ja
                    siihen tehtyjä muutoksia) mukaisia mallisopimuslausekkeita
                    henkilötietojen siirrosta kolmansiin maihin.
                </p>

                <p>
                    <i>Rekisteröity</i>
                </p>
                <p className="p1">
                    tarkoittaa luonnollista henkilöä, jonka Henkilötietoja
                    Toimittaja käsittelee tämän Sopimuksen mukaisesti
                    Rekisterinpitäjän lukuun.
                </p>

                <p>
                    <i>Tietosuoja-asetus</i>
                </p>
                <p className="p1">
                    tarkoittaa Euroopan unionin yleistä tietosuoja-asetusta
                    2016/679/EU.
                </p>

                <p>
                    <i>Tietoturvaloukkaus</i>
                </p>
                <p className="p1">
                    tarkoittaa tietoturvaloukkausta, jonka seurauksena on
                    Henkilötietojen tämän Sopimuksen vastainen,
                    Tietosuojasääntelyn vastainen tai muutoin lainvastainen
                    tuhoaminen, häviäminen, muuttaminen, luvaton luovuttaminen
                    taikka pääsy Henkilötietoihin.
                </p>

                <p>
                    <i>Tietosuojasääntely</i>
                </p>
                <p className="p1">
                    tarkoittaa kaikkia Suomessa sovellettavia henkilötietojen
                    suojaa koskevia säännöksiä, mukaan lukien lait joilla on
                    saatettu voimaan direktiivi 95/46/EU ja direktiivi
                    2002/58/EU (ja niihin tehdyt muutokset) sekä
                    Tietosuoja-asetus 2016/679/EU.
                </p>
            </div>
            <div>
                <p>
                    <b>4. ASIAKKAAN OIKEUDET JA VELVOLLISUUDET</b>
                </p>
                <div className="p1">
                    <p>
                        4.1 Asiakas sitoutuu käsittelemään Henkilötietoja
                        Tietosuojasääntelyn ja hyvän tietojenkäsittelytavan
                        mukaisesti.
                    </p>

                    <p>
                        4.2 Asiakkaan Toimittajalle antamat kirjalliset ohjeet
                        Henkilötietojen käsittelystä annetaan ensisijaisesti
                        tällä Sopimuksella.
                    </p>
                </div>
            </div>

            <div>
                <p>
                    <b>5. TOIMITTAJAN VELVOLLISUUDET</b>
                </p>
                <div className="p1">
                    <p>
                        5.1 Henkilötietojen käsittelyyn sovellettavat yleiset
                        periaatteet
                    </p>
                    <div className="p1">
                        <p>Toimittaja sitoutuu:</p>

                        <p>
                            5.1.1 käsittelemään Henkilötietoja
                            Tietosuojasääntelyn ja hyvän tietojenkäsittelytavan
                            mukaisesti;
                        </p>

                        <p>
                            5.1.2 käsittelemään Henkilötietoja Asiakkaan
                            Henkilötietojen käsittelystä antamien kirjallisten
                            ohjeiden mukaisesti, ellei Toimittajaan sovellettava
                            Tietosuojasääntely määrää toisin. Toimittaja
                            ilmoittaa tällaisesta Tietosuojasääntelyn
                            vaatimuksesta Asiakkaalle kohtuullisessa ajassa
                            ennen kyseisten ohjeiden mukaisesti tapahtuvan
                            Henkilötietojen käsittelyn aloittamista, paitsi jos
                            tällainen tiedottaminen on kielletty
                            Tietosuojasääntelyssä. Toimittajan tulee ilmoittaa
                            Asiakkaalle ilman aiheetonta viivytystä, jos
                            Toimittaja katsoo Asiakkaan ohjeiden rikkovan
                            Tietosuojasääntelyä;
                        </p>

                        <p>
                            5.1.3 varmistamaan, että Toimittajan henkilöstö,
                            jolla on pääsy Henkilötietoihin, on sitoutunut
                            noudattamaan asianmukaista
                            salassapitovelvollisuutta;
                        </p>

                        <p>
                            5.1.4 toteuttamaan tämän Sopimuksen kohdan 5.2
                            mukaiset toimenpiteet;
                        </p>

                        <p>
                            5.1.5 noudattamaan tämän Sopimuksen kohdassa 8
                            mainittuja alihankkijoiden käytön edellytyksiä;
                        </p>

                        <p>
                            5.1.6 mahdollisuuksiensa mukaan ja Toimittajan
                            saatavilla olevat tiedot huomioon ottaen avustamaan
                            Asiakasta vastaamaan Rekisteröityjen
                            Tietosuojasääntelyn mukaisiin pyyntöihin, kun
                            pyyntöön vastaamiseksi tarvittavat tiedot eivät ole
                            Asiakkaan saatavilla. Toimittajalla on oikeus
                            veloittaa Asiakkaalta kustannukset, jotka ovat
                            syntyneet tämän kohdan 5.1.6 noudattamisesta;
                        </p>

                        <p>
                            5.1.7 Asiakkaan valinnan mukaan poistamaan tai
                            palauttamaan Henkilötiedot Asiakkaalle tämän
                            Sopimuksen kohdan 11.2 mukaisesti;
                        </p>

                        <p>
                            5.1.8 antamaan Asiakkaalle kaikki sellaiset tiedot,
                            jotka ovat tarpeen tämän Sopimuksen ja
                            Tietosuojasääntelyn noudattamisen osoittamista
                            varten. Asiakkaalla on velvollisuus pitää edellä
                            mainitut tiedot luottamuksellisina. Toimittajalla on
                            oikeus veloittaa Asiakkaalta kustannukset, jotka
                            ovat syntyneet tämän kohdan 5.1.9 noudattamisesta;
                            sekä
                        </p>

                        <p>
                            5.1.9 antamaan Asiakkaalle oikeuden suorittaa
                            auditointeja tämän Sopimuksen kohdan 9 mukaisesti.
                        </p>
                    </div>

                    <p>5.2 TIETOTURVA</p>

                    <p>
                        Toimittajan tulee toteuttaa asianmukaiset tekniset ja
                        organisatoriset toimenpiteet Henkilötietojen
                        suojaamiseksi asiattomalta pääsyltä ja tietojen
                        häviämiseltä, muuttamiselta, luovuttamiselta,
                        siirtämiseltä taikka muulta laittomalta käsittelyltä.
                    </p>
                </div>
            </div>

            <div>
                <p>
                    <b>6. ILMOITUS TIETOTURVALOUKKAUKSESTA</b>
                </p>
                <div className="p1">
                    <p>
                        6.1 Toimittajan on ilmoitettava Asiakkaalle kaikista
                        Tietoturvaloukkauksista ilman aiheetonta viivytystä,
                        mutta viimeistään neljän (4) arkipäivän kuluessa siitä,
                        kun Toimittaja on tullut tietoiseksi
                        Tietoturvaloukkauksesta. Ilmoitukseen on sisällytettävä
                        seuraavat tiedot:
                    </p>

                    <div className="p1">
                        <p>
                            (a) kuvaus Tietoturvaloukkauksesta, sisältäen tiedot
                            siitä, mitä Rekisteröityjen ryhmiä ja
                            henkilörekistereitä Tietoturvaloukkaus on koskenut
                            sekä näiden ryhmien ja henkilörekisterien arvioitu
                            lukumäärä;
                        </p>
                        <p>
                            (b) tietoturvaloukkauksen selvittämistä hoitavan
                            Toimittajan yhteyshenkilön nimi ja yhteystiedot;
                        </p>
                        <p>
                            (c) kuvaus Tietoturvaloukkauksen toteutuneista
                            seurauksista ja/tai todennäköisistä seurauksista; ja
                        </p>
                        <p>
                            (d) kuvaus toimenpiteistä, joihin Toimittaja on
                            ryhtynyt Tietoturvaloukkauksen johdosta ja sen
                            haittavaikutusten lieventämiseksi
                        </p>
                    </div>
                    <p>
                        6.2 Jos edellä mainittujen tietojen antaminen ei ole
                        mahdollista samanaikaisesti, tiedot voidaan antaa
                        osissa.
                    </p>

                    <p>
                        6.3 Toimittajan on dokumentoitava Tietoturvaloukkaukset
                        ja luovutettava dokumentaatio Asiakkaalle sen pyynnöstä.
                    </p>

                    <p>
                        6.4 Tietoturvaloukkauksen tultua Toimittajan tietoon
                        Toimittajan on varmistuttava Henkilötietojen suojasta ja
                        toteutettava yhteistyössä Asiakkaan kanssa asianmukaiset
                        toimenpiteet Henkilötietojen suojan turvaamiseksi.
                    </p>
                </div>
            </div>

            <div>
                <p>
                    <b>7. HENKILÖTIETOJEN SIIRROT</b>
                </p>
                <p className="p1">
                    Toimittaja ei pääsääntöisesti siirrä Henkilötietoja Euroopan
                    unionin jäsenvaltioiden alueen tai Euroopan talousalueen
                    ulkopuolelle, ellei se ole henkilötietojen käsittelyn
                    tarkoitusten tai tietojen käsittelyn teknisen toteuttamisen
                    kannalta tarpeellista, jolloin tietojen siirrossa
                    noudatetaan Tietosuojasääntelyn vaatimuksia.
                </p>
            </div>

            <div>
                <p>
                    <b>8. ALIHANKKIJAT</b>
                </p>
                <div className="p1">
                    <p>
                        8.1 Toimittajalla on oikeus käyttää Henkilötietojen
                        käsittelyssä alihankkijoita, jotka Asiakas on
                        hyväksynyt. Asiakas on tämän Sopimuksen
                        voimaantulohetkellä antanut hyväksyntänsä Toimittajan
                        verkkosivuilla esitettyjen alihankkijoiden käytölle.
                    </p>

                    <p>
                        8.2 Toimittajalla on oikeus vähentää alihankkijoiden
                        määrää ilman erillistä ilmoitusta.
                    </p>

                    <p>
                        8.3 Toimittajan tulee ilmoittaa Asiakkaalle uuden, tämän
                        Sopimuksen mukaisia Henkilötietoja käsittelevän
                        alihankkijan lisäämisestä vähintään (1) viikko ennen
                        kyseisen alihankkijan suorittaman Henkilötietojen
                        käsittelyn aloittamista. Toimittaja voi vaihtaa tai
                        lisätä alihankkijan vain, jos uusi alihankkija noudattaa
                        Tietoturvasääntelyä eikä Toimittajan arvion mukaan
                        tietoturvan taso vaihdon/lisäyksen vuoksi heikkene.
                        Asiakas voi kieltää uuden alihankkijan käytön
                        ainoastaan, jos Asiakkaalla on perusteltu epäilys uuden
                        alihankkijan kyvystä noudattaa Tietosuojasääntelyä.
                        Mikäli Asiakas ei vastusta uuden alihankkijan käyttöä
                        kirjallisesti yhden (1) viikon kuluessa Toimittajan
                        ilmoituksesta, Toimittaja voi käyttää uutta alihankkijaa
                        Henkilötietojen käsittelyssä. Mikäli Asiakas vastustaa
                        alihankkijan vaihtoa/lisäämistä, on mahdollista, että
                        Toimittaja ei voi enää tarjota sovittua palvelua.
                    </p>

                    <p>
                        8.4 Toimittajan tulee pyrkiä asianmukaisin keinoin
                        varmistumaan siitä, että alihankkijat noudattavat tätä
                        Sopimusta vastaavia velvollisuuksia, mukaan lukien
                        tietoturvavaatimuksia ja salassapitovelvollisuutta.
                        Toimittaja vastaa alihankkijoidensa osuudesta kuin
                        omastaan.
                    </p>
                </div>
            </div>

            <div>
                <p>
                    <b>9. AUDITOINTI</b>
                </p>
                <div className="p1">
                    <p>
                        9.1 Osapuolet sopivat, että Asiakkaan pyytäessä
                        auditointia Toimittajan nimeämä tai kirjallisesti
                        hyväksymä kolmas osapuoli auditoi Toimittajan tämän
                        Sopimuksen velvoitteiden noudattamisen, jotta Asiakas
                        voi varmistua siitä, että Toimittaja on täyttänyt tämän
                        Sopimuksen mukaiset velvollisuutensa. Asiakkaalla on
                        oikeus pyytää tässä kohdassa 9.1 tarkoitettua
                        auditointia kahdentoista (12) kuukauden välein.
                    </p>

                    <p>
                        9.2 Asiakas vastaa auditoinnista Toimittajalle ja
                        Asiakkaalle aiheutuneista kustannuksista sekä kolmannen
                        osapuolen palkkiosta ja kuluista. Toimittajan on
                        avustettava Asiakasta ja kolmatta osapuolta auditoinnin
                        suorittamisessa kohtuullisin keinoin.
                    </p>

                    <p>
                        9.3 Jos auditoinnissa havaitaan puutteita, Toimittajan
                        tulee korjata puutteet viivytyksettä, kuitenkin
                        viimeistään kolmenkymmenen (30) päivän kuluessa
                        Asiakkaan kirjallisesta ilmoituksesta lukien, elleivät
                        Osapuolet erikseen toisin sovi. Olennaiset puutteet,
                        jotka muodostavat ilmeisen uhkan tietoturvallisuudelle,
                        on korjattava ilman viivytystä.
                    </p>
                </div>
            </div>

            <div>
                <p>
                    <b>10. VASTUUNRAJOITUS</b>
                </p>
                <div className="p1">
                    <p>
                        10.1 Toimittajan vastuu tämän Sopimuksen tai
                        Tietosuojasääntelyn rikkomisesta määräytyy seuraavasti:
                    </p>

                    <div className="p1">
                        <p>
                            10.1.1 Toimittaja on vastuussa Asiakasta kohtaa vain
                            jos
                        </p>
                        <div className="p1">
                            <p>
                                10.1.1.1 Toimittaja on rikkonut Asiakkaan
                                Tietosuojasääntelyn mukaisia kirjallisia ohjeita
                                Toimittajan Asiakkaan lukuun suorittaman
                                Henkilötietojen käsittelyn yhteydessä; tai
                            </p>

                            <p>
                                10.1.1.2 Toimittaja on rikkonut käsittelijöihin
                                kohdistuvia Tietosuojasääntelyn vaatimuksia,
                                jotka ovat suoraan sovellettavia Toimittajaan,
                                kun Toimittaja käsittelee Henkilötietoja
                                Asiakkaan lukuun (kohdat 10.1.1.1 ja 10.1.1.2
                                yhdessä "Rikkomus").
                            </p>
                        </div>
                        <p>
                            10.1.2 Toimittaja ei vastaa menetetystä
                            liikevoitosta tai mistään välillisestä vahingosta.
                            Toimittajan vastuu Rikkomuksista Asiakkaalle
                            aiheutuneisiin välittömiin vahinkoihin rajoittuu
                            seuraavasti:
                        </p>
                        <div className="p1">
                            <p>
                                10.1.2.1 Toimittaja on vastuussa Asiakkaan
                                Rekisteröidyille maksamien vahingonkorvausten
                                osalta vain siltä osin kuin vahingot ovat
                                aiheutuneet välittömästi Toimittajan
                                Rikkomuksesta;
                            </p>

                            <p>
                                10.1.2.2 Toimittaja on vastuussa valvovien
                                viranomaisten Asiakkaalle määräämistä
                                hallinnollisista sakoista vain siltä osin kuin
                                hallinnolliset sakot ovat aiheutuneet
                                välittömästi Toimittajan Rikkomuksesta; ja
                            </p>

                            <p>
                                10.1.2.3 Toimittajan kohtien 10.1.2.1 ja
                                10.1.2.2 mukaisen vastuun enimmäismäärä
                                Asiakasta kohtaan rajoittuu aina Asiakkaalta
                                vähennetyn palvelumaksun määrään. Toimittajan
                                vastuu Asiakasta kohtaan on kuitenkin yhteensä
                                enintään 5.000 euroa.
                            </p>
                        </div>

                        <p>
                            10.1.3 Toimittaja ei ole vastuussa
                            vahingonkorvauksista tai hallinnollisista sakoista
                            siltä osin kuin vahingonkorvaukset tai
                            hallinnolliset sakot johtuvat välittömästi tai
                            välillisesti mistä tahansa Asiakkaan teosta tai
                            laiminlyönnistä.
                        </p>
                    </div>
                </div>
            </div>

            <div>
                <p>
                    <b>11. VOIMASSAOLO JA SOPIMUKSEN PÄÄTTYMINEN</b>
                </p>
                <div className="p1">
                    <p>
                        11.1 Tämä Sopimus tulee voimaan, kun kumpikin Osapuoli
                        on allekirjoittanut sen. Sopimus on voimassa
                        toistaiseksi.
                    </p>

                    <p>
                        11.2 Toimittajalla on oikeus käsitellä Henkilötietoja
                        vain tämän Sopimuksen voimassaoloaikana. Sopimuksen
                        Asiakkaan kirjallisesta pyynnöstä Toimittajan on
                        palautettava Asiakkaalle tai Asiakkaan nimeämälle
                        kolmannelle osapuolelle käsitellyt Henkilötiedot, tai
                        Asiakkaan pyynnöstä tuhottava Toimittajan hallussa
                        olevat Henkilötiedot, ellei Tietosuojasääntely tai muu
                        sovellettava lainsäädäntö edellytä säilyttämään
                        Henkilötietoja. Jos Asiakas vaatii, että Henkilötiedot
                        palautetaan Asiakkaalle tai siirretään kolmannelle
                        osapuolelle, Asiakas on velvollinen korvaamaan
                        Toimittajalle Henkilötietojen palauttamisesta tai
                        siirrosta aiheutuneet kustannukset.
                    </p>
                </div>
            </div>

            <p>
                <b>LIITE 1</b>
            </p>
            <div className="p1">
                <p>
                    <b>HENKILÖTIETOJEN KÄSITTELYÄ KOSKEVA LIITE</b>
                </p>

                <p>
                    <b>1. TAUSTA JA TÄMÄN LIITTEEN TARKOITUS</b>
                </p>
                <div className="p1">
                    <p>
                        1.1 Tämä liite 1 on tehty Asiakkaan ja Toimittajan
                        Henkilötietojen käsittelyä koskevan Sopimuksen
                        liitteeksi. Tämä liite on erottamaton osa Sopimusta.
                    </p>

                    <p>
                        1.2 Tässä liitteessä Osapuolet sopivat seuraavista
                        seikoista:
                    </p>
                    <div className="p1">
                        <p>
                            (a) Henkilötietojen käsittelyn luonteesta ja
                            tarkoituksesta;
                        </p>
                        <p>(b) Käsiteltävistä Henkilötiedoista;</p>
                        <p>
                            (c) Rekisteröityjen ryhmistä ja käsittelytavoista;
                        </p>
                        <p>
                            (d) Sopimuksen kohdan 5.2 tarkoittamista teknisistä
                            ja organisatorisista toimenpiteistä; ja
                        </p>
                        <p>(e) Henkilötietojen säilytysajasta.</p>
                    </div>

                    <p>
                        1.3 Toimittaja sitoutuu käsittelemään Henkilötietoja
                        Asiakkaan puolesta tämän liitteen mukaisin ehdoin.
                    </p>
                </div>
                <p>
                    <b>2. HENKILÖTIETOJEN KÄSITTELYN LUONNE JA TARKOITUKSET</b>
                </p>
                <div className="p1">
                    <p>
                        Toimittaja käsittelee Asiakkaan henkilötietoja sen
                        tarjoaman palvelun toimittamisen vaatimalla tavalla,
                        esimerkiksi kerää tietoja, tallentaa, järjestelee,
                        koostaa, soveltaa, muokkaa, vertailee, säilyttää,
                        anonymisoi, poistaa tai varmuuskopioi henkilötietoja.
                    </p>

                    <p>
                        Toimittaja käsittelee Henkilötietoja Asiakkaan ja tämän
                        asiakkaan välisen toimeksiantosopimuksen mukaisen
                        laskutuksen toteuttamiseksi ja siihen liittyvien
                        prosessien hallinnoimiseksi.
                    </p>
                </div>
                <p>
                    <b>
                        3. KÄSITELTÄVÄT HENKILÖTIEDOT JA REKISTERÖITYJEN RYHMÄT
                    </b>
                </p>
                <div className="p1">
                    <p>
                        Toimittaja käsittelee seuraavia Henkilötietojen ryhmiä:
                    </p>

                    <div className="p1">
                        <p>etu- ja sukunimet</p>

                        <p>kieli</p>

                        <p>
                            yhteystiedot (postiosoite, koti- ja
                            matkapuhelinnumero, sähköpostiosoite)
                        </p>

                        <p>Muut laskutuksen kannalta olennaiset tiedot</p>

                        <p>edellä yksilöityjen tietojen muutostiedot</p>

                        <p>
                            Rekisteröityjen henkilöiden ryhmä koostuu Asiakkaan
                            kuluttaja-asiakkaista ja yritysasiakkaiden
                            yhteyshenkilöistä.
                        </p>
                    </div>
                </div>
                <p>
                    <b>4. TIETOTURVA</b>
                </p>
                <div className="p1">
                    <p>
                        Henkilötietoja tulee käsitellä aina rekisterinpitäjän
                        antamien ohjeiden ja tietosuoja-asetuksen mukaisesti.
                    </p>

                    <p>
                        Henkilötietojen käsittelijän tulee noudattaa seuraavia
                        ohjeita.
                    </p>

                    <p>
                        Henkilötietojen käsittelyyn osallistuvat henkilöt ovat
                        lukeneet tämän dokumentin.
                    </p>

                    <p>
                        Henkilötietojen käsittelyyn osallistuvat henkilöt ovat
                        sitoutuneet salassapitovelvollisuuteen.
                    </p>

                    <p>
                        Henkilötietoja ei saa siirtää tai kopioida mihinkään
                        muihin kuin alihankkijoiden järjestelmiin ilman
                        Asiakkaan lupaa.
                    </p>

                    <p>
                        Jos Toimittaja joutuu lataamaan henkilötietoja omalle
                        tietokoneelleen tai muulla tavalla erilleen
                        yllämainituista tietovarastoista, tietoja saa säilyttää
                        vain sen ajan, jota käsillä oleva tehtävä edellyttää ja
                        sen jälkeen ne on tuhottava.
                    </p>

                    <p>
                        Kaikessa henkilötietojen käsittelyssä tulee noudattaa
                        riittävää huolellisuutta ja varovaisuutta.
                    </p>

                    <p>
                        Toimittajan on raportoitava kaikki tietoturvavaatimusten
                        vastaiset toimet ja tilanteet Asiakkaalle.
                    </p>

                    <p>
                        Lisäksi Toimittajan on omalla vastuualueellaan
                        ryhdyttävä niihin välttämättömiin toimiin, joilla se
                        rajoittaa ja minimoi häiriön, ongelman, muutoksen tai
                        muun tapahtuman henkilötiedoille aiheuttamaa vahinkoa.
                    </p>

                    <p>
                        Toimittajalla täytyy olla määritellyt, ylläpidetyt ja
                        riittävät tietoturvaprosessit, jotta sen asiakkaalle
                        tuottamien palveluiden tietoturva voidaan varmistaa.
                        Toimittaja vastaa tietoturvan jatkuvasta parantamisesta
                        ja sen Toimittajalle tuottaman palvelun
                        tietoturvariskien säännöllisestä arvioinnista, jonka
                        tulee tapahtua vähintään kerran vuodessa.
                    </p>
                </div>
                <p>
                    <b>5. HENKILÖTIETOJEN KÄSITTELYN KESTO</b>
                </p>

                <p className="p1">
                    Henkilötietoja käsitellään niin kauan kuin se on tarpeen
                    Toimittajan palvelun tarjoamiseksi, mikäli sovellettavasta
                    lainsäädännöstä ei muuta johdu.
                </p>
            </div>
            <p>
                <b>LIITE 2</b>
            </p>
            <div className="p1">
                <p>
                    <b>ALIHANKKIJAT</b>
                </p>

                <p>
                    Ajantasainen lista alihankkijoista löytyy Eezyn
                    verkkosivuilta:{' '}
                    <a
                        href="https://kevytyrittajat.eezy.fi/alihankkijat"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        https://kevytyrittajat.eezy.fi/alihankkijat/
                    </a>
                </p>
            </div>
        </div>
    );
};

export default GdprFi;
