import { Hidden } from '@mui/material';
import React from 'react';
import { EezyButton } from '../Buttons';
import { Flex } from '../Flex';
import { SectionTitleNew } from '../textElements';
import { useTranslation } from 'react-i18next';

interface IProps {
    editModeOn?: boolean;
    onClose?: () => void;
    onEdit?: () => void;
    title: string;
}

export const CardHeader = (props: IProps) => {
    const { t } = useTranslation();
    return (
        <Flex spread style={{ paddingBottom: 15 }}>
            <SectionTitleNew>{props.title}</SectionTitleNew>

            <Hidden smDown>
                {props.onClose && props.editModeOn && (
                    <EezyButton
                        color="purple"
                        onClick={() => {
                            if (props.onClose) {
                                props.onClose();
                            }
                        }}
                        width={120}
                    >
                        {t('menu.close')}
                    </EezyButton>
                )}

                {props.onEdit && !props.editModeOn && (
                    <EezyButton
                        color="purple"
                        dark
                        onClick={() => {
                            if (props.onEdit) {
                                props.onEdit();
                            }
                        }}
                        width={120}
                    >
                        {t('general.edit')}
                    </EezyButton>
                )}
            </Hidden>
        </Flex>
    );
};
