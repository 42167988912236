import React from 'react';
import {
    IAmountWithVat,
    IReceiptCost
} from '../../../../shared/src/types/costs';
import { Flex } from '../../components/Flex';
import { formatCents, percentOf, percentOff, trans } from '../../utils';
import {
    getCostTotal,
    getCostTotalWithoutVat
} from '../../utils/costs/costLogic';

interface IProps {
    cost: IReceiptCost;
}

export const CostTaxBreakDown = (props: IProps) => {
    const { cost } = props;
    return (
        <>
            <Flex spread>
                <span>{trans('costs.taxfree')}</span>
                <span>
                    {trans('form.eurs', {
                        eurs: formatCents(
                            getCostTotalWithoutVat(cost.amountsWithVats),
                            true
                        )
                    })}
                </span>
            </Flex>

            {cost.amountsWithVats?.map((a: IAmountWithVat) => {
                return (
                    <Flex spread key={`amount-${a.vatPercent}-${a.id}`}>
                        <span>
                            {trans('costs.tax-total', {
                                taxPercent: a.vatPercent
                            })}
                        </span>
                        <span>
                            {trans('form.eurs', {
                                eurs: formatCents(
                                    percentOf(
                                        a.vatPercent,
                                        percentOff(a.vatPercent, a.amount)
                                    ),
                                    true
                                )
                            })}
                        </span>
                    </Flex>
                );
            })}

            <Flex spread>
                <span>{trans('costs.taxable')}</span>
                <span>
                    {trans('form.eurs', {
                        eurs: formatCents(
                            getCostTotal(cost.amountsWithVats),
                            true
                        )
                    })}
                </span>
            </Flex>
        </>
    );
};
