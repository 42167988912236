import React from 'react';
import { useLocation } from 'react-router-dom';

/*
    Parses the number at the end of the url.

    E.g. /some/url/123 or /some/much/longer/url/123
*/

export const useSelectedListItem = (id?: number) => {
    const [selectedId, setSelectedId] = React.useState<number | undefined>(id);

    const location = useLocation();

    const split = (path: string) => path.split('/').slice(-1)[0];
    const parse = React.useCallback(
        (path: string) => parseInt(split(path), 10) || undefined,
        []
    );

    React.useEffect(() => {
        // Set selected item on page load and set selected item on item on location change (= click)
        setSelectedId(parse(location.pathname));
    }, [parse, location]);

    return selectedId;
};
