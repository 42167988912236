import {
    INVOICE_SEARCH_QUERY,
    INVOICE_SET_EXPENSE_REIMBURSEMENT_WARNING_HIDDEN,
    INVOICE_SET_TRAVEL_WARNING_HIDDEN,
    INVOICE_SPREAD_STATE,
    INVOICE_SPREAD_STATE_CLIENT_SET
} from '../actionTypes';

export const saveSearchQuery = (searchQuery: string) => ({
    payload: { searchQuery, searchRecipientId: null },
    type: INVOICE_SEARCH_QUERY
});

export const saveRecipientSearchQuery = (
    searchQuery: string,
    searchRecipientId: string
) => ({
    payload: { searchQuery, searchRecipientId },
    type: INVOICE_SEARCH_QUERY
});

export const handleInvoiceSpread = (
    openPanelId: number,
    openSecondMenu?: boolean,
    openSubObjectId?: number
) => ({
    payload: { openPanelId, openSubObjectId, openSecondMenu },
    type: INVOICE_SPREAD_STATE
});

export const handleInvoiceSpreadClientSet = (clientJustSet: boolean) => ({
    payload: clientJustSet,
    type: INVOICE_SPREAD_STATE_CLIENT_SET
});

export const setTravelWarningHidden = (travelWarningHidden: boolean) => ({
    payload: { travelWarningHidden },
    type: INVOICE_SET_TRAVEL_WARNING_HIDDEN
});

export const setExpenseReimbursementWarningHidden = (expenseReimbursementWarningHidden: boolean) => ({
    payload: { expenseReimbursementWarningHidden },
    type: INVOICE_SET_EXPENSE_REIMBURSEMENT_WARNING_HIDDEN
});

