import { gql } from "@apollo/client";

const GET_DOCUMENTS_USER_MUST_ACCEPT = gql`
    query DocumentsUserMustAccept {
        userMustAccept {
            document
            documentId
        }
    }
`;
export default GET_DOCUMENTS_USER_MUST_ACCEPT;
