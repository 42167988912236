import { gql } from '@apollo/client';

const UPDATE_STATISTIC_INVOICE = gql`
    mutation UpdateStatisticInvoice($invoice: StatisticInvoiceInput!) {
        updateStatisticInvoice(invoice: $invoice) {
            id
            date
            updatedDate
            status
            total
            totalWithVat
            paidAmount
        }
    }
`;

export default UPDATE_STATISTIC_INVOICE;
