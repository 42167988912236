import { ApolloError, useMutation, useQuery } from '@apollo/client';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { IInvoiceKeys } from '../../../../../shared/src/types/invoice';
import { EezyButton } from '../../../components/Buttons';
import EscIcon from '../../../components/EscIcon';
import {
    DatePicker,
    FormInput,
    FormRadio,
    FormRow,
    FormSection,
    FormSelect,
    FormTextArea,
    SplitRow,
} from '../../../components/form';
import { FormBackground } from '../../../components/form/FormBackground';
import { Icon } from '../../../components/Icon';
import { P, SectionTitle } from '../../../components/textElements';
import { COLOR_IMPORTANT } from '../../../styles/variables';
import { formatDate, formatDateISO, formatPercentage, trans } from '../../../utils';
import { getErrors } from '../../../utils/apolloErrors';
import { getMinDuedate, getPaymentTermOptions, getTemplate } from '../../../utils/invoice/invoiceLogic';
import { IInvoiceField } from '../../../utils/invoice/invoiceTemplates';
import { IDocument } from '../../../utils/user/userUtils';
import { GET_INVOICE, UPDATE_INVOICE } from '../queries';
import FillHelper from './FillHelper';

interface IFillHelperProps {
    invoiceId?: number;
    showModals?: (modals: string[]) => void;
}

const InvoiceFillHelper = (props: IFillHelperProps) => {
    const { data: invoiceData } = useQuery(GET_INVOICE, {
        variables: { id: props.invoiceId },
    });
    const invoice = invoiceData?.invoice;

    const [inputType, setInputType] = React.useState(invoice?.paymentTerm ? 'paymentTerm' : 'dueDate');

    const paymenttermOptions = getPaymentTermOptions(invoice);
    const template = getTemplate(invoice?.template);

    React.useEffect(() => {
        if (invoice?.paymentTerm && inputType === 'dueDate') {
            setInputType('paymentTerm');
        } else if (invoice?.dueDate && inputType === 'paymentTerm') {
            setInputType('dueDate');
        }
    }, [invoice]);

    const [updateInvoice] = useMutation(UPDATE_INVOICE, {
        onError: (e: ApolloError) => {
            const errors = getErrors(e);
            if (errors?.mustAccept && props.showModals) {
                props.showModals(errors?.mustAccept.map((t: IDocument) => t.document));
            } else {
                toast.error(trans('errors.general'));
            }
        },
    });

    const handleChange = (o: IInvoiceKeys) => {
        updateInvoice({
            optimisticResponse: {
                updateInvoice: {
                    __typename: 'Invoice',
                    ...invoice,
                    ...o,
                },
            },
            variables: {
                id: props.invoiceId,
                ...o,
            },
        });
    };

    const onCopy = () => {
        toast(trans('form.copied'));
    };

    const CopyButton = (
        <EezyButton
            color="purple"
            style={{
                borderRadius: '4px',
                minWidth: '75px',
            }}
            type="button"
        >
            {trans('form.copy')}
        </EezyButton>
    );

    return (
        <FillHelper
            renderMenuLeft={() => (
                <SectionTitle color="white">{trans('invoice.fillhelper.title')}</SectionTitle>
            )}
            renderMenuRight={(handleClose) => (
                <EezyButton color="important" onClick={handleClose} style={{ minWidth: '110px' }}>
                    {trans('general.ready')}
                    <EscIcon style={{ marginLeft: 10 }} />
                </EezyButton>
            )}
        >
            <FormBackground as={'form'}>
                <FormSection>
                    <SplitRow>
                        <FormInput
                            disabled
                            label={trans('invoice.draftNumber')}
                            name="fh-draftNumber"
                            placeholder={trans('invoice.fillhelper.eezy-fills')}
                            value={invoice?.draftNumber}
                        />
                        {invoice?.draftNumber && (
                            <CopyToClipboard onCopy={onCopy} text={invoice?.draftNumber}>
                                {CopyButton}
                            </CopyToClipboard>
                        )}
                    </SplitRow>

                    <FormInput
                        disabled
                        label={trans('invoice.number')}
                        name="fh-invoiceNumber"
                        placeholder={trans('invoice.fillhelper.eezy-fills')}
                        value={invoice?.invoiceNumber || ''}
                    />

                    <FormInput
                        disabled
                        label={trans('invoice.date')}
                        name="fh-invoiceDate"
                        placeholder={trans('invoice.fillhelper.eezy-fills')}
                        value={formatDate(invoice?.invoiceDate)}
                    />

                    {invoice?.notificationDays && (
                        <FormInput
                            disabled
                            label={trans('invoice.notificationTime')}
                            name="fh-notificationDays"
                            placeholder={''}
                            value={trans('dates.x-days', {
                                days: invoice?.notificationDays,
                            })}
                        />
                    )}

                    {invoice?.interestPercentage && (
                        <FormInput
                            disabled
                            label={trans('invoice.interestRate')}
                            name="fh-interestRate"
                            placeholder={''}
                            value={trans('form.percent', {
                                percentage: formatPercentage(invoice?.interestPercentage),
                            })}
                        />
                    )}
                </FormSection>

                <FormSection>
                    <FormRadio
                        label={trans('invoice.paymentTerm-duedate')}
                        name="calcType"
                        onChange={(val) => setInputType(val)}
                        onTwoLines
                        options={[
                            {
                                label: trans('invoice.paymentTerm'),
                                value: 'paymentTerm',
                            },
                            {
                                label: trans('general.duedate'),
                                value: 'dueDate',
                            },
                        ]}
                        value={inputType}
                    />
                    {inputType === 'paymentTerm' && (
                        <FormSelect
                            name="invoice-fh-paymentterm"
                            options={paymenttermOptions}
                            value={invoice?.paymentTerm ? invoice?.paymentTerm.toString() : ''}
                            onChange={(val) => {
                                handleChange({
                                    paymentTerm: parseInt(val, 10),
                                });
                            }}
                            showIcon
                        />
                    )}
                    {inputType === 'dueDate' && (
                        <FormRow>
                            <DatePicker
                                disablePast={true}
                                disableToolbar
                                label={trans('general.select-duedate')}
                                minDate={getMinDuedate(invoice)}
                                placeholder=""
                                icon={['far', 'calendar-day']}
                                id="invoice-fh-duedate"
                                onChange={(dueDate: Date | null) => {
                                    if (dueDate) {
                                        handleChange({
                                            dueDate: formatDateISO(dueDate),
                                        });
                                    }
                                }}
                                value={invoice?.dueDate}
                            />
                        </FormRow>
                    )}
                </FormSection>

                <FormSection style={{ position: 'relative' }}>
                    <FormRadio
                        label={trans('invoice.debtCollection.question')}
                        helpTexts={[
                            'invoice.debtCollection.help1',
                            'invoice.debtCollection.help2',
                            'invoice.debtCollection.help3',
                            'invoice.debtCollection.help4',
                            'invoice.debtCollection.help5',
                            'invoice.debtCollection.help6',
                            'invoice.debtCollection.help7',
                            'invoice.debtCollection.help8',
                            'invoice.debtCollection.help9',
                            'invoice.debtCollection.help10',
                        ]}
                        name="fh-debtCollection"
                        onChange={(val) => handleChange({ debtCollection: val === 'yes' })}
                        onTwoLines
                        options={[
                            {
                                label: trans('general.no-I'),
                                value: 'no',
                            },
                            {
                                label: trans('general.yes'),
                                value: 'yes',
                            },
                        ]}
                        value={invoice?.debtCollection ? 'yes' : 'no'}
                    />

                    <div
                        style={{
                            alignSelf: 'flex-end',
                            bottom: 5,
                            position: 'absolute',
                        }}
                    >
                        <Icon
                            icon={['far', 'user-cowboy']}
                            color={COLOR_IMPORTANT}
                            style={invoice?.debtCollection ? {} : { opacity: 0.3 }}
                            title={trans('invoice.debtCollection.on')}
                        />
                    </div>
                </FormSection>
                {invoice?.debtCollection && <P>{trans('invoice.debtCollection.help-yes1')}</P>}
                {invoice && (
                    <FormSection>
                        {template.additionalFields.map(({ id, name }: IInvoiceField) => {
                            return (
                                <FormRow key={id}>
                                    <FormInput
                                        label={trans(id)}
                                        name={`fh-${name}`}
                                        onBlur={(val) => {
                                            handleChange({ [name]: val });
                                        }}
                                        placeholder={''}
                                        value={invoice[name] || ''}
                                    />
                                </FormRow>
                            );
                        })}
                    </FormSection>
                )}

                <FormSection>
                    <FormTextArea
                        label={trans('invoice.description.label')}
                        name="fh-description"
                        onChange={(val) => handleChange({ description: val })}
                        placeholder={trans('invoice.description.placeholder')}
                        value={invoice?.description || ''}
                    />
                </FormSection>
            </FormBackground>
        </FillHelper>
    );
};

export default InvoiceFillHelper;
