import styled from 'styled-components';
import { Icon } from './Icon';
import { COLOR_BLUM, COLOR_WHITE_WALKER, SCREEN_S } from 'styles/variables';
import { EezyButton } from './Buttons';
import { useTranslation } from 'react-i18next';

interface PackageOptions {
    value: string;
    label: string;
    price: string;
}

interface ICampwirePackages {
    options: PackageOptions[];
    onSelect: (value: string) => void;
    selected: number;
}

const PackageOptionsContainer = styled.div`
    display: flex;
    margin: 20px 0;
    justify-content: center;
    align-content: center;
    gap: 20px;

    @media (max-width: ${SCREEN_S}px) {
        flex-direction: column;
        align-items: center;
    }
`;

const PackageOption = styled.div`
    display: flex;
    padding: 20px;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    max-width: 200px;
    flex: 1;

    @media (max-width: ${SCREEN_S}px) {
        max-width: 100%;
        width: 100%;
    }
`;

const PackageLabel = styled.div`
    font-size: 14px;
    font-weight: 600;
`;

const PackageLabelWrapper = styled.div`
    text-align: center;
    flex-grow: 1;
`;

const PackagePriceWrapper = styled.div`
    align-items: center;
`;

const PackagePrice = styled.div`
    font-size: 24px;
    font-weight: 600;
    color: ${COLOR_BLUM};
    margin: 10px 0;
    line-height: 32px;
    text-align: center;
`;

const PackagePerMonthPrice = styled.div`
    font-size: 14px;
    color: #707070;
    text-align: center;
`;

const CampwirePackages = (props: ICampwirePackages) => {
    const { options, onSelect, selected } = props;
    const { t } = useTranslation();

    return (
        <PackageOptionsContainer>
            {options.map((option) => {
                const isSelected = selected === parseInt(option.value);

                return (
                    <PackageOption key={option.value}>
                        <PackageLabelWrapper>
                            <PackageLabel>{option.label}</PackageLabel>
                            {option.value !== '1' && (
                                <PackagePerMonthPrice>
                                    {t(`campwire-modal.package-${option.value}-per-month`)}
                                </PackagePerMonthPrice>
                            )}
                        </PackageLabelWrapper>
                        <PackagePriceWrapper>
                            <PackagePrice>{option.price}</PackagePrice>
                            <EezyButton
                                color="purple"
                                dark={isSelected}
                                fullWidth
                                onClick={() => {
                                    onSelect(option.value);
                                }}
                            >
                                {t(`campwire-modal.select`)}
                                {isSelected && (
                                    <Icon
                                        color={COLOR_WHITE_WALKER}
                                        style={{
                                            cursor: 'pointer',
                                            marginLeft: 3,
                                            width: 14,
                                            height: 14,
                                        }}
                                        icon={['fas', 'check']}
                                    />
                                )}
                            </EezyButton>
                        </PackagePriceWrapper>
                    </PackageOption>
                );
            })}
        </PackageOptionsContainer>
    );
};

export default CampwirePackages;
