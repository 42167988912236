import { FC, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducers';
import { fetchUser } from 'actions/user';
import { logoFull } from 'assets/images/eezylogo';
import { RoundBox } from 'components/Balloon';
import LoadingSpinner from '../../components/Loading';
import { IAuth } from 'reducers/authReducer';
import { H1, PageWrapper } from 'styles/yelStyles';
import { yelLogic } from 'utils/yel';
import YelOk from './YelOk';
import YelSummary from './YelSummary';
import YelTerminate from './YelTerminate';
import YelWithdraw from './YelWithdraw';
import YelWizard from './YelWizard';
import { useYelData } from 'queries/useYelData';
import { GeneralError } from 'containers/error/GeneralError';
import useYelStore from 'stores/yelStore';

const YelPageWrapper = ({ children }: { children: ReactNode }) => (
    <PageWrapper>
        <div style={{ margin: '0 auto', maxWidth: '150px' }}>
            <img src={logoFull} alt="Eezy Logo" />
        </div>
        <RoundBox>{children}</RoundBox>
    </PageWrapper>
);

const Yel: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();
    const { data, loading, error } = useYelData();
    const yelStoreData = useYelStore((state) => state.yelData);

    const auth: IAuth = useSelector((state: IRootState) => state.auth);
    const isLoggedIn: boolean | null = useSelector((state: IRootState) => state.auth.loggedIn);

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchUser());
        }
    }, [isLoggedIn]);

    if (error && error.place === 'yelPage') {
        return (
            <PageWrapper>
                <GeneralError error={error} />
            </PageWrapper>
        );
    }

    if (!auth.loggedIn || auth.error) {
        return null;
    } else if (loading) {
        return (
            <YelPageWrapper>
                <LoadingSpinner />
            </YelPageWrapper>
        );
    }

    if (yelStoreData.showTerminate) {
        return (
            <YelPageWrapper>
                <YelTerminate />
            </YelPageWrapper>
        );
    } else if (yelStoreData.showWithdrawMoney) {
        return (
            <YelPageWrapper>
                <H1>{t('yel:withdraw-money')}</H1>
                <YelWithdraw />
            </YelPageWrapper>
        );
    } else if (yelStoreData.showSummary) {
        return (
            <YelPageWrapper>
                <YelSummary />
            </YelPageWrapper>
        );
    } else if (yelLogic.shouldShowYelOK(data.status)) {
        return (
            <YelPageWrapper>
                <YelOk />
            </YelPageWrapper>
        );
    } else if (yelLogic.shouldShowWizard(data.status)) {
        return (
            <YelPageWrapper>
                <YelWizard />
            </YelPageWrapper>
        );
    } else {
        return null;
    }
};

export default Yel;
