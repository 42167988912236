import React from 'react';
import {
    IInvoiceItem,
    IInvoiceItemKeys
} from '../../../../../shared/src/types/invoice';
import { formatCents, trans } from '../../../utils';
import InvoiceTableData from './InvoiceTableData';

const TotalPrice = (props: {
    editable: boolean;
    handleFocus?: () => void;
    handleUpdate: (changedProperty: IInvoiceItemKeys) => void;
    item: IInvoiceItem;
}) => {
    return (
        <InvoiceTableData
            allowNegative
            editable={props.editable}
            endAdornment="€"
            readOnlyContent={trans('form.eurs', {
                eurs: formatCents(props.item.totalPrice, true)
            })}
            handleFocus={props.handleFocus}
            handleUpdate={props.handleUpdate}
            isEur={true}
            item={props.item}
            name="totalPrice"
            style={{ width: '80px' }}
            type="number"
        />
    );
};

export default TotalPrice;
