import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { showModals, hideModal } from 'actions/auth';
import { COLOR_GREYHOUND, COLOR_BLUM, SCREEN_S } from '../../styles/variables';
import { Modal, ModalActions, ModalContent } from '../../components/modals/Modal';
import { GET_USER_DATA } from '../../containers/profile/queries';
import { useLazyQuery, useQuery } from '@apollo/client';
import { EezyButton } from '../Buttons';
import { UiLanguage } from '../../../../shared/src/types/user';
import { Icon } from '../../components/Icon';
import config from '../../config';
import Expandable from 'components/Expandable';
import Step from 'components/Step';
import GET_SUBSCRIPTION from 'containers/eezypay/queries/getSubscription';
import {
    calcOrderProcessingMinutesDependingOnWeekday,
    daysLeftForSubscription,
    formatDate,
} from 'utils/time';
import SuccessCard from 'containers/eezypay/SuccessCard';
import LoadingSpinner from 'components/Loading';
import { getLocalStorage, setLocalStorage } from '../../utils/user/userUtils';
import { renderOrderProcessingMessage } from './SubscriptionModal';
import CampwirePackages from 'components/CampwirePackages';

interface IModalProps {
    handleModalClose: () => void;
    acceptCampwireTerms: () => void;
    isOpen: boolean;
    isIdentified: boolean;
    hasAddress: boolean;
    language: UiLanguage;
}

const CAMPWIRE_SUBSCRIPTION_BUY_CLICK = 'campwire-subscription-buy-click';
const CAMPWIRE_LOGIN_URL =
    process.env.NODE_ENV === 'development' ? ' https://test.cwxdev.com/' : 'https://app.campwire.com/';

const BulletsContainer = styled.ul`
    list-style-type: disc;
    margin: 10px 10px;

    li {
        margin-bottom: 10px;
        margin-left: 10px;
    }
`;

const IntroText = styled.p`
    color: ${COLOR_GREYHOUND};
`;

const DisabledMessageList = styled.ul`
    list-style-type: none; // Removes the default bullets
    padding: 0;
    margin: 0 0 15px 0;

    li {
        position: relative;
        margin: 0;
        padding-left: 20px;
        color: ${COLOR_GREYHOUND};

        &:before {
            content: '•'; // You can use other symbols or even images
            position: absolute;
            left: 0;
            color:${COLOR_GREYHOUND} // Match the color with the text
            font-size: 1.2em; // Adjust the size
        }
    }
`;

const DisabledMessageItem = styled.li`
    :hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const ValidSubsciptionContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const RenewContainer = styled.div`
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    @media (max-width: ${SCREEN_S}px) {
        flex-direction: column;
    }
`;

const RenewContainerLabel = styled.div`
    @media (max-width: ${SCREEN_S}px) {
        margin-bottom: 10px;
    }
`;

const RenewContainerButton = styled.div`
    min-width: 40%;
    @media (max-width: ${SCREEN_S}px) {
        min-width: 100%;
    }
`;

const LinkButton = styled(EezyButton)`
    align-self: flex-start;
    padding: 0 !important;
    text-decoration: underline;
    border: 0 !important;
    margin: 15px 0;
`;

const LoginButton = styled.a`
    text-decoration: none;
    border: 1px solid ${COLOR_BLUM};
    border-radius: 100px;
    font-size: 14px;
    font-weight: 600;
    height: 38px;
    letter-spacing: -0.5px;
    line-height: 35px;
    min-width: 110px;
    padding: 0px 32px;
    text-align: center;
    text-transform: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    margin: 10px 0;

    @media (max-width: ${SCREEN_S}px) {
        height: 44px;
        line-height: 41px;
    }
`;

const CampwireModal = (props: IModalProps) => {
    const { isIdentified, hasAddress, isOpen, language, handleModalClose, acceptCampwireTerms } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const eezyPayTermsAccepted = useSelector<IRootState>((state) => state.eezyPay.eezyPayTermsAccepted);
    const campwireTermsAccepted = useSelector<IRootState>((state) => state.eezyPay.campwireTermsAccepted);

    const { data: userData } = useQuery(GET_USER_DATA);
    const [fetchSubscription, { data: subscriptionData, loading: subscriptionLoading }] = useLazyQuery(
        GET_SUBSCRIPTION,
        {
            context: { clientName: 'eezyPayHasura' },
            fetchPolicy: 'cache-and-network',
        },
    );

    const [token, setToken] = useState(sessionStorage.getItem('jwt'));
    const [selectedPackage, setSelectedPackage] = useState<number>(1);

    const campwireSubscriptionEndDate = subscriptionData?.subscriptions[0]?.campwireEndDate;
    const campwireSubscriptionDaysLeft = daysLeftForSubscription(campwireSubscriptionEndDate);
    const campwireSubscriptionExpired = campwireSubscriptionDaysLeft < 0;

    const bankAccountNumber = userData?.userData?.bankAccountNumber;
    const isDisabled = !isIdentified || !bankAccountNumber || !hasAddress;
    const readMoreUrl =
        language === 'en'
            ? 'https://kevytyrittajat.eezy.fi/en/campwire-online-course-platform/'
            : 'https://kevytyrittajat.eezy.fi/campwire-verkkokurssialusta/';

    const packageOptions = [
        {
            value: '1',
            label: t('campwire-modal.package-1-label'),
            price: t('campwire-modal.package-1-price'),
        },
        {
            value: '3',
            label: t('campwire-modal.package-3-label'),
            price: t('campwire-modal.package-3-price'),
        },
        {
            value: '12',
            label: t('campwire-modal.package-12-label'),
            price: t('campwire-modal.package-12-price'),
        },
    ];

    const steps = eezyPayTermsAccepted
        ? [
              { number: '1', content: t('campwire-modal.list-2'), name: 'SELECT_PACKAGE' },
              { number: '2', content: t('campwire-modal.list-3'), name: 'CREATE_ACCOUNT' },
              { number: '3', content: t('campwire-modal.list-4'), name: 'READY' },
          ]
        : [
              { number: '1', content: t('campwire-modal.list-1'), name: 'ACTIVATE_EEZYPAY' },
              { number: '2', content: t('campwire-modal.list-2'), name: 'SELECT_PACKAGE' },
              { number: '3', content: t('campwire-modal.list-3'), name: 'CREATE_ACCOUNT' },
              { number: '4', content: t('campwire-modal.list-4'), name: 'READY' },
          ];

    useEffect(() => {
        if (token) {
            fetchSubscription();
        }
    }, [fetchSubscription, token]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!token) {
                setToken(sessionStorage.getItem('jwt'));
            } else {
                clearInterval(interval);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [token]);

    const handleClick = (type: string) => {
        dispatch(hideModal('CAMPWIRE'));

        switch (type) {
            case 'isIdentified':
                dispatch(showModals(['AUTH']));
                break;
            case 'bankAccountNumber':
                navigate('/profile/salary');
                break;
            case 'hasAddress':
                navigate('/profile/contact');
                break;
            default:
                break;
        }
    };

    const openEezyPayTermsModal = () => {
        dispatch(showModals(['CAMPWIRE', 'EEZY_PAY_TERMS']));
    };

    const getDisabledMessage = () => {
        const steps = [];
        if (!isIdentified)
            steps.push({ text: t('campwire-modal.complete-identification'), type: 'isIdentified' });
        if (!bankAccountNumber)
            steps.push({ text: t('campwire-modal.add-bank-account'), type: 'bankAccountNumber' });
        if (!hasAddress) steps.push({ text: t('campwire-modal.add-address'), type: 'hasAddress' });

        return (
            <div>
                <IntroText>{t('campwire-modal.you-still-need-to')}</IntroText>
                <DisabledMessageList>
                    {steps.map((step, index) => (
                        <DisabledMessageItem
                            key={index}
                            onClick={() => {
                                handleClick(step.type);
                            }}
                        >
                            {step.text}
                        </DisabledMessageItem>
                    ))}
                </DisabledMessageList>
            </div>
        );
    };

    const getPurchaseButtonText = () => {
        switch (selectedPackage) {
            case 1:
                return t('campwire-modal.purchase-button-1');
            case 3:
                return t('campwire-modal.purchase-button-3');
            case 12:
                return t('campwire-modal.purchase-button-12');
            default:
                return t('campwire-modal.purchase-button-1');
        }
    };

    const handleConfirm = () => {
        if (!campwireTermsAccepted) {
            acceptCampwireTerms();
        }

        let subscriptionLink;
        switch (selectedPackage) {
            case 1:
                subscriptionLink =
                    language === 'en' ? config.campwireSubscriptionUrl1En : config.campwireSubscriptionUrl1Fi;
                break;
            case 3:
                subscriptionLink =
                    language === 'en' ? config.campwireSubscriptionUrl3En : config.campwireSubscriptionUrl3Fi;
                break;
            case 12:
                subscriptionLink =
                    language === 'en'
                        ? config.campwireSubscriptionUrl12En
                        : config.campwireSubscriptionUrl12Fi;
                break;
        }
        setLocalStorage(CAMPWIRE_SUBSCRIPTION_BUY_CLICK, {});
        const win = window.open(subscriptionLink, '_blank');
        win?.focus();
        handleModalClose();
    };

    const handleTrackPaymentsClick = () => {
        dispatch(hideModal('CAMPWIRE'));
        navigate('/sales-pages/payments');
    };

    const { minutesSince: minutesSinceLastBuySubscriptionClick } =
        getLocalStorage(CAMPWIRE_SUBSCRIPTION_BUY_CLICK) || {};

    const GetStartedContainer = () => (
        <>
            <Expandable title={t('campwire-modal.what-is-campwire')}>
                <BulletsContainer>
                    <li>{t('campwire-modal.bulletpoint-1')}</li>
                    <li>{t('campwire-modal.bulletpoint-2')}</li>
                    <li>{t('campwire-modal.bulletpoint-3')}</li>
                    <li>{t('campwire-modal.bulletpoint-4')}</li>
                </BulletsContainer>

                <p>
                    <a
                        href={readMoreUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: COLOR_BLUM }}
                    >
                        <strong>{t('campwire-modal.read-more')}</strong>
                        <Icon
                            className="small"
                            color={COLOR_BLUM}
                            icon={['far', 'arrow-up-right-from-square']}
                            style={{ marginLeft: 10 }}
                        />
                    </a>
                </p>
            </Expandable>

            <strong>{t('campwire-modal.how-to-start')}</strong>

            <div>
                {steps.map((step) => {
                    if (step.name === 'ACTIVATE_EEZYPAY') {
                        return (
                            <div key={step.number}>
                                <Step stepNumber={step.number} stepContent={step.content}></Step>
                                <div
                                    style={{
                                        marginBottom: '0px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <div>
                                        <EezyButton
                                            style={{ marginBottom: 20 }}
                                            dark
                                            color="purple"
                                            onClick={openEezyPayTermsModal}
                                            disabled={isDisabled}
                                        >
                                            {t('campwire-modal.view-and-accept-eezypay-terms')}
                                        </EezyButton>
                                    </div>
                                    {isDisabled && (
                                        <DisabledMessageList>{getDisabledMessage()}</DisabledMessageList>
                                    )}
                                </div>
                            </div>
                        );
                    } else if (step.name === 'SELECT_PACKAGE') {
                        return (
                            <div key={step.number}>
                                <Step stepNumber={step.number} stepContent={step.content}></Step>
                                <CampwirePackages
                                    options={packageOptions}
                                    selected={selectedPackage}
                                    onSelect={(value) => {
                                        setSelectedPackage(parseInt(value));
                                    }}
                                />
                            </div>
                        );
                    }
                    return (
                        <Step stepNumber={step.number} stepContent={step.content} key={step.number}></Step>
                    );
                })}
            </div>

            <p style={{ fontWeight: 600 }}>{t('campwire-modal.deductions-info')}</p>
            <BulletsContainer>
                <li>{t('campwire-modal.deductions-bulletpoint-1')}</li>
                <li>{t('campwire-modal.deductions-bulletpoint-2')}</li>
            </BulletsContainer>
        </>
    );

    const SubscriptionDetailsContainer = () => (
        <>
            <ValidSubsciptionContainer>
                <ImageContainer>
                    <SuccessCard
                        title={
                            t('campwire-modal.subscription-valid-until', {
                                date: formatDate(campwireSubscriptionEndDate),
                            }) || ''
                        }
                        titleStyles={{ maxWidth: 'none' }}
                    />
                </ImageContainer>
                <RenewContainer>
                    <RenewContainerLabel style={{ marginRight: 20, fontSize: 14 }}>
                        {t('campwire-modal.renew-label')}
                    </RenewContainerLabel>
                    <RenewContainerButton>
                        <EezyButton
                            style={{ width: '100%' }}
                            autoFocus
                            dark
                            color="purple"
                            onClick={() => {
                                dispatch(showModals(['CAMPWIRE_RENEW']));
                            }}
                            type="submit"
                        >
                            {t('campwire-modal.renew-button')}
                        </EezyButton>
                    </RenewContainerButton>
                </RenewContainer>
                <LoginButton href={CAMPWIRE_LOGIN_URL} rel="noopener noreferrer" target="_blank">
                    {t('campwire-modal.campwire-login')}
                </LoginButton>
                <LinkButton
                    color="purple"
                    onClick={() => {
                        handleTrackPaymentsClick();
                    }}
                    type="submit"
                >
                    {t('campwire-modal.track-payments')}
                    <Icon
                        className="small"
                        color={COLOR_BLUM}
                        icon={['far', 'circle-right']}
                        style={{ marginLeft: 8 }}
                    />
                </LinkButton>
                <strong style={{ fontSize: 14 }}>{t('campwire-modal.campwire-support')}</strong>
                <div>
                    <a href="mailto:tuki@campwire.com">tuki@campwire.com</a>
                </div>
            </ValidSubsciptionContainer>
        </>
    );

    return (
        <Modal
            huge
            id="campwire-about"
            isOpen={isOpen}
            onClose={handleModalClose}
            title={campwireSubscriptionExpired && t('campwire-modal.title')}
        >
            <ModalContent $huge>
                {subscriptionLoading ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LoadingSpinner size={24} color={COLOR_BLUM} />
                    </div>
                ) : campwireSubscriptionExpired ? (
                    GetStartedContainer()
                ) : (
                    SubscriptionDetailsContainer()
                )}
            </ModalContent>

            <ModalActions style={{ flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                {subscriptionLoading ? null : campwireSubscriptionExpired ? (
                    <EezyButton
                        disabled={!eezyPayTermsAccepted || isDisabled}
                        style={{ minWidth: '50%', margin: '5px auto' }}
                        autoFocus
                        dark
                        color="purple"
                        onClick={() => {
                            handleConfirm();
                            handleModalClose();
                        }}
                        type="submit"
                    >
                        {getPurchaseButtonText()}
                    </EezyButton>
                ) : (
                    <EezyButton
                        disabled={!eezyPayTermsAccepted || isDisabled}
                        style={{ minWidth: '50%', margin: '5px auto' }}
                        autoFocus
                        color="purple"
                        onClick={() => {
                            handleModalClose();
                        }}
                        type="submit"
                    >
                        {t('campwire-modal.close')}
                    </EezyButton>
                )}
                {minutesSinceLastBuySubscriptionClick <
                    calcOrderProcessingMinutesDependingOnWeekday(minutesSinceLastBuySubscriptionClick) &&
                    campwireSubscriptionExpired &&
                    renderOrderProcessingMessage()}
            </ModalActions>
        </Modal>
    );
};

export default CampwireModal;
