import styled from 'styled-components';
import { COLOR_BLUM } from '../styles/variables';

interface IStepProps {
    stepNumber: string;
    stepContent: string;
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0;
`;

const Number = styled.div`
    width: 32px;
    height: 32px;
    border: 1px solid ${COLOR_BLUM};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${COLOR_BLUM};
    margin-right: 20px;
    flex-shrink: 0;
`;

const Content = styled.div`
    letter-spacing: 0.125px;
`;

const Step = (props: IStepProps) => {
    const { stepNumber, stepContent } = props;
    return (
        <Wrapper>
            <Number>{stepNumber}</Number>
            <Content>{stepContent}</Content>
        </Wrapper>
    );
};

export default Step;
