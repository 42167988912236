import React from 'react';
import { Link } from 'react-router-dom';
import { INotification } from '../../../../shared/src/types/notifications';
import { EezyLink } from '../../components/Buttons';
import { Flex } from '../../components/Flex';
import { Icon } from '../../components/Icon';
import { BodyP, LabelMedium, SingleText } from '../../components/textElements';
import { COLOR_BLUM, COLOR_GREYHOUND } from '../../styles/variables';
import { formatDate, trans } from '../../utils';

interface IProps {
    notification: INotification;
}

export const ExpenseNotificationModal = (props: IProps) => {
    return (
        <Flex column fullWidth>
            <Flex spread>
                <LabelMedium color={COLOR_BLUM}>
                    {trans('dashboard.messages.expense.topic')}
                </LabelMedium>
                <SingleText color={COLOR_GREYHOUND}>
                    {props.notification.actionData?.name}
                </SingleText>
            </Flex>

            <Flex spread>
                <LabelMedium color={COLOR_BLUM}>
                    {trans('dashboard.messages.expense.date')}
                </LabelMedium>
                <SingleText color={COLOR_GREYHOUND}>
                    {formatDate(props.notification.date)}
                </SingleText>
            </Flex>

            {props.notification.actionData?.dueDate && (
                <Flex spread>
                    <LabelMedium color={COLOR_BLUM}>
                        {trans('dashboard.messages.expense.duedate')}
                    </LabelMedium>
                    <SingleText color={COLOR_GREYHOUND}>
                        {formatDate(props.notification.actionData?.dueDate)}
                    </SingleText>
                </Flex>
            )}

            <Flex spread style={{ marginTop: 24 }}>
                <LabelMedium color={COLOR_BLUM}>
                    {trans('dashboard.messages.expense.download')}
                </LabelMedium>
                <EezyLink
                    color="purple"
                    href={props.notification.actionData?.downloadUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {trans('file.download')}
                </EezyLink>
            </Flex>

            <BodyP style={{ marginTop: 24 }}>
                {trans('dashboard.messages.expense.help')}
            </BodyP>

            <BodyP color={COLOR_BLUM} style={{ marginTop: 30 }}>
                <Link
                    className="unstyled"
                    to={'/support/new-message'}
                    state={{
                            message: {
                                content: `${trans(
                                    'dashboard.messages.expense.message-1'
                                )}\n\n${trans(
                                    'dashboard.messages.expense.message-2',
                                    {
                                        name:
                                            props.notification.actionData?.name
                                    }
                                )}`,
                                subject: trans(
                                    'dashboard.messages.expense.message-title',
                                    {
                                        name:
                                            props.notification.actionData?.name
                                    }
                                )
                            }
                        }
                    }
                >
                    {trans('dashboard.messages.expense.request')}
                    <Icon
                        className="small"
                        color={COLOR_BLUM}
                        icon={['far', 'arrow-right']}
                        style={{ marginLeft: 8 }}
                    />
                </Link>
            </BodyP>
        </Flex>
    );
};
