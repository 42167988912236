import SalaryCalculator, { CalculatorViewEnum } from '@eezydev/eezy-salary-calculator';
import * as React from 'react';
import { SalaryCalcConfig } from '.';

const SalaryCalculatorPlainFi = () => {
    return (
        <SalaryCalculator language={'fi'} config={SalaryCalcConfig} initialView={CalculatorViewEnum.Simple} />
    );
};

export default SalaryCalculatorPlainFi;
