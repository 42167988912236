import React from 'react';

import {
    Chart,
    LineController,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    ChartOptions,
    Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { SalesPageStatisticData } from 'containers/eezypay/types/SalesPageStatisticTypes';
import { SCREEN_L } from 'styles/variables';
import { capitalize } from 'utils/str';
import { formatCents } from 'utils';

Chart.register(LineController, LineElement, PointElement, CategoryScale, LinearScale, Tooltip);

interface ISalesStatsBillingChart {
    data: SalesPageStatisticData[];
}

const SalesStatsBillingChart = (props: ISalesStatsBillingChart) => {
    const labels = props.data?.[0]?.items?.map((i) => capitalize(i.label));
    const values = props.data?.map((i, index) => ({
        label: i.name,
        tension: 0,
        borderColor: i.color,
        data: i.items.map((x) => x.value),
        borderWidth: 2,
        borderDash: index > 0 ? [7, 3] : undefined,
        // pointStyle: 'rectRot',
        pointRadius: 5,
        pointBackgroundColor: i.color,
        pointBorderColor: '#fff',
    }));

    const data = {
        labels,
        datasets: values,
    };

    const chartOptions: ChartOptions<'line'> = {
        responsive: true,
        aspectRatio: window.innerWidth > SCREEN_L ? 3.8964 : 1.5,
        scales: {
            y: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                        return value + '€';
                    },
                },
                min: 0,
            },
        },
        plugins: {
            tooltip: {
                borderWidth: 1,
                borderColor: '#E4E4E7',
                backgroundColor: '#fff',
                titleColor: '#1F2937',
                titleFont: { size: 12, weight: 500 },
                bodyFont: { size: 14, weight: 600 },
                cornerRadius: 10,
                padding: 10,
                displayColors: false,
                callbacks: {
                    labelTextColor: (context) => {
                        return context.dataset.borderColor as string;
                    },
                    label: (context) => {
                        const value = context.parsed.y;

                        return formatCents(value * 100, true) + ' €';
                    },
                },
            },
        },
    };

    return (
        <>
            <Line options={chartOptions} data={data} />
        </>
    );
};

export default SalesStatsBillingChart;
