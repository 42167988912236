import React, { FormEvent } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams, NavigateFunction } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';
import { hideError, IResetPasswordData, resetPassword } from '../../actions/auth';
import { EezyButton, InlineButtonLink } from '../../components/Buttons';
import ErrorBox from '../../components/ErrorBox';
import { FormInput, FormSection } from '../../components/form';
import LanguageOptions from '../../components/LanguageOptions';
import LoadingSpinner from '../../components/Loading';
import { P, UniqueTitle } from '../../components/textElements';
import { IAuth } from '../../reducers/authReducer';
import { IUser } from '../../reducers/userReducer';
import {
    BORDER_RADIUS,
    COLOR_BLUM,
    COLOR_WHITE_WALKER,
    TOPBAR_HEIGHT,
    FOOTER_HEIGHT_MOBILE,
    FOOTER_HEIGHT,
    SCREEN_M,
} from '../../styles/variables';
import { trans } from '../../utils';
import { getLocalStorage } from '../../utils/user/userUtils';
import { resetPasswordValidator } from '../../utils/user/validators';
import { EMAIL_REGEX, formatValidationResult } from '../../utils/validation';
import Bar, { BarContent } from '../nav/Bar';
import PurpleBackground from '../nav/PurpleBackground';
import Footer from '../../components/Footer';

// When user requests link to reset password, email is stored in local storage for x
// minutes and after that email is asked in the form
const EMAIL_VALID_IN_LOCAL_STORAGE_MINUTES = 15;

const ContentWrapper = styled.div`
    display: block;
    margin: 0 auto;
    padding-top: 40px;
    text-align: center;
    min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT_MOBILE}px);
    @media (min-width: ${SCREEN_M}px) {
        min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT}px);
    }
`;

const LoginWrapper = styled.div`
    align-items: start;
    background-color: ${COLOR_WHITE_WALKER};
    border-radius: ${BORDER_RADIUS};
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 30px;
    text-align: left;
    margin: 40px auto;
    max-width: 450px;
    width: 100%;

    h1 {
        margin-bottom: 0;
    }
    form {
        width: 100%;
    }
`;

interface IForm {
    email: string;
    password: string;
    password2: string;
}

const initialFormData: IForm = {
    email: '',
    password: '',
    password2: '',
};

interface IProps {
    auth: IAuth;
    hideError: () => void;
    resetPassword: (data: IResetPasswordData, navigate: NavigateFunction) => void;
    user: IUser;
}

const PasswordReset = (props: IProps) => {
    const navigate = useNavigate();
    const { token } = useParams();

    const { error, loading } = props.auth;
    const [formData, setFormData] = React.useState<IForm>(initialFormData);
    const [errors, setErrors] = React.useState<any>(null);
    const [emailAutoFilled, setEmailAutoFilled] = React.useState(false);

    React.useEffect(() => {
        const localData = getLocalStorage('reset-email');
        if (localData) {
            const { email, minutesSince } = localData;
            const emailIsValid = email.match(EMAIL_REGEX); // Failsafe check
            if (minutesSince <= EMAIL_VALID_IN_LOCAL_STORAGE_MINUTES && emailIsValid) {
                setFormData({ ...formData, email });
                setEmailAutoFilled(true);
            } else {
                localStorage.removeItem('reset-email');
            }
        }
    }, []);

    React.useEffect(() => {
        if (props.user.email) {
            navigate('/');
        }
    }, [props.user, props.auth]);

    React.useEffect(() => {
        props.hideError();
    }, []);

    const validate = (data: any) => {
        return formatValidationResult(resetPasswordValidator.validate(data));
    };

    const handleChange = (val: string, name: string) => {
        const newFormData = { ...formData, [name]: val };
        setFormData(newFormData);
        if (errors) {
            setErrors(validate(newFormData) || null);
        }
    };

    const handleSubmit = (e: FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const err = validate(formData);
        if (err) {
            setErrors(err);
        } else {
            setErrors(null);
            props.resetPassword({ ...formData, token: token ? token : '' }, navigate);
        }
    };

    return (
        <>
            <Bar>
                <BarContent>
                    <LanguageOptions />
                </BarContent>
            </Bar>
            <PurpleBackground>
                <ContentWrapper>
                    <LoginWrapper>
                        <UniqueTitle color={COLOR_BLUM}>{trans('login.pwd-reset-title')}</UniqueTitle>
                        <form>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minHeight: '40px',
                                }}
                            >
                                {loading && <LoadingSpinner />}
                                {error && !error.place && (
                                    <ErrorBox>
                                        {error.title}
                                        {error.internalUrl && (
                                            <>
                                                {' '}
                                                <InlineButtonLink
                                                    color="white"
                                                    onClick={() => navigate(error.internalUrl || '')}
                                                >
                                                    {error.internalLinkAnchor}
                                                </InlineButtonLink>
                                            </>
                                        )}
                                    </ErrorBox>
                                )}
                                {errors && (
                                    <ErrorBox>
                                        {errors?.email && trans('login.invalid-email')}
                                        {errors?.password2 && trans('login.invalid-password-2')}
                                        {errors?.password && trans('login.invalid-password')}
                                    </ErrorBox>
                                )}
                            </div>
                            <FormSection>
                                <P>{trans('login.pwd-reset-help')}</P>
                            </FormSection>
                            <FormSection>
                                {!emailAutoFilled && (
                                    <FormInput
                                        error={errors?.email}
                                        label={trans('login.email')}
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                        type="email"
                                    />
                                )}
                                <FormInput
                                    autoFocus
                                    error={errors?.password}
                                    label={trans('login.pwd-new')}
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                    type="password"
                                />
                                <FormInput
                                    error={errors?.password2}
                                    label={trans('login.pwd-repeat')}
                                    name="password2"
                                    value={formData.password2}
                                    onChange={handleChange}
                                    required
                                    type="password"
                                />
                            </FormSection>
                            <FormSection>
                                <EezyButton
                                    color="purple"
                                    onClick={handleSubmit}
                                    style={{ alignSelf: 'flex-end' }}
                                    type="submit"
                                >
                                    {trans('login.login')}
                                </EezyButton>
                            </FormSection>
                        </form>
                    </LoginWrapper>
                </ContentWrapper>
            </PurpleBackground>
            <Footer />
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        hideError: () => {
            dispatch(hideError());
        },
        resetPassword: (data: IResetPasswordData, navigate: NavigateFunction) => {
            dispatch(resetPassword(data, navigate));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
