import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Modal, ModalActions, ModalContent } from './Modal';
import { EezyButton } from '../Buttons';
import { UiLanguage } from '../../../../shared/src/types/user';
import config from '../../config';
import { calcOrderProcessingMinutesDependingOnWeekday } from 'utils/time';
import { getLocalStorage, setLocalStorage } from '../../utils/user/userUtils';
import { renderOrderProcessingMessage } from './SubscriptionModal';
import CampwirePackages from 'components/CampwirePackages';

interface IModalProps {
    handleModalClose: () => void;
    isOpen: boolean;
    language: UiLanguage;
}

const BulletsContainer = styled.ul`
    list-style-type: disc;
    margin: 10px 10px;

    li {
        margin-bottom: 10px;
        margin-left: 10px;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
`;

const CAMPWIRE_SUBSCRIPTION_RENEW_CLICK = 'campwire-subscription-renew-click';

const CampwireRenewModal = (props: IModalProps) => {
    const { isOpen, language, handleModalClose } = props;
    const { t } = useTranslation();

    const [selectedPackage, setSelectedPackage] = useState<number>(1);

    const handleConfirm = () => {
        let subscriptionLink;
        switch (selectedPackage) {
            case 1:
                subscriptionLink =
                    language === 'en' ? config.campwireSubscriptionUrl1En : config.campwireSubscriptionUrl1Fi;
                break;
            case 3:
                subscriptionLink =
                    language === 'en' ? config.campwireSubscriptionUrl3En : config.campwireSubscriptionUrl3Fi;
                break;
            case 12:
                subscriptionLink =
                    language === 'en'
                        ? config.campwireSubscriptionUrl12En
                        : config.campwireSubscriptionUrl12Fi;
                break;
        }
        setLocalStorage(CAMPWIRE_SUBSCRIPTION_RENEW_CLICK, {});
        const win = window.open(subscriptionLink, '_blank');
        win?.focus();
        handleModalClose();
    };

    const { minutesSince: minutesSinceLastBuySubscriptionClick } =
        getLocalStorage(CAMPWIRE_SUBSCRIPTION_RENEW_CLICK) || {};

    const packageOptions = [
        {
            value: '1',
            label: t('campwire-modal.package-1-label'),
            price: t('campwire-modal.package-1-price'),
        },
        {
            value: '3',
            label: t('campwire-modal.package-3-label'),
            price: t('campwire-modal.package-3-price'),
        },
        {
            value: '12',
            label: t('campwire-modal.package-12-label'),
            price: t('campwire-modal.package-12-price'),
        },
    ];

    return (
        <Modal
            huge
            id="campwire-renew"
            isOpen={isOpen}
            onClose={handleModalClose}
            title={t('campwire-modal.renew-modal-title')}
        >
            <ModalContent $huge>
                <div style={{ textAlign: 'center' }}>{t('campwire-modal.renew-modal-choose')}</div>
                <CampwirePackages
                    options={packageOptions}
                    selected={selectedPackage}
                    onSelect={(value) => {
                        setSelectedPackage(parseInt(value));
                    }}
                />
                <p style={{ fontWeight: 600 }}>{t('campwire-modal.deductions-info')}</p>
                <BulletsContainer>
                    <li>{t('campwire-modal.deductions-bulletpoint-1')}</li>
                    <li>{t('campwire-modal.deductions-bulletpoint-2')}</li>
                </BulletsContainer>
            </ModalContent>

            <ModalActions $huge style={{ flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                <ButtonWrapper>
                    <EezyButton
                        style={{ flex: 1 }}
                        autoFocus
                        color="purple"
                        onClick={() => {
                            handleModalClose();
                        }}
                        type="submit"
                    >
                        {t('campwire-modal.cancel')}
                    </EezyButton>
                    <EezyButton
                        style={{ flex: 1 }}
                        dark
                        autoFocus
                        color="purple"
                        onClick={() => {
                            handleConfirm();
                            handleModalClose();
                        }}
                        type="submit"
                    >
                        {t('campwire-modal.renew')}
                    </EezyButton>
                </ButtonWrapper>

                {minutesSinceLastBuySubscriptionClick <
                    calcOrderProcessingMinutesDependingOnWeekday(minutesSinceLastBuySubscriptionClick) &&
                    renderOrderProcessingMessage()}
            </ModalActions>
        </Modal>
    );
};

export default CampwireRenewModal;
