import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { useLocation, useNavigate } from 'react-router-dom';
import { FetchPolicy, useApolloClient, useMutation } from '@apollo/client';
import { INotification } from '../../../../shared/src/types/notifications';
import { showModals } from 'actions/auth';
import { EezyButton } from 'components/Buttons';
import { HeaderRow } from 'components/HeaderRow';
import LoadingSpinner from 'components/Loading';
import { UniqueTitle } from 'components/textElements';
import styled from 'styled-components';
import NotificationsItem from './NotificationsItem';
import { BORDER_RADIUS, COLOR_WHITE_WALKER } from 'styles/variables';
import { subtractTwoWeeks } from 'utils';
import { getLinkHref, getStaticNotificationIconColor } from 'utils/notifications/notifications';
import { Notification } from './Notification';
import NotificationModal from './NotificationModal';
import { IRootState } from 'reducers';
import Show from 'components/ui/Show';
import LIST_NOTIFICATIONS from './queries/listNotifications';
import SET_HIDE_NOTIFICATION from './queries/setHideNotification';
import SET_READ_NOTIFICATION from './queries/setReadNotification';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
    width: 100%;
    background-color: ${COLOR_WHITE_WALKER};
    border-radius: ${BORDER_RADIUS};
    padding: 27px 20px 20px;
`;

type NotificationsData = {
    getNotifications: INotification[];
};

type Props = {};

const Notifications: React.FC<Props> = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [notificationsData, setNotifications] = useState<NotificationsData>({ getNotifications: [] });
    const [visibleNotification, setVisibleNotification] = useState<INotification | undefined>(undefined);
    const [showArchive, setShowArchive] = useState(false);
    const [notificationFetchMode, setNotificationFetchMode] = useState('cache-first');

    const el = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const apolloClient = useApolloClient();
    const language = i18n.language;
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();
    const isIdentified: boolean = useSelector((state: IRootState) => state.user.isIdentified);
    const registrationDate: string = useSelector((state: IRootState) => state.user.registrationDate);
    const yelShouldAsk: boolean = useSelector((state: IRootState) => state.user.yelShouldAsk);

    const shouldHideNotification = (notification: INotification, regDate: string): boolean => {
        const dateLimitForHiding = subtractTwoWeeks(regDate);
        return new Date(notification.date) < new Date(dateLimitForHiding);
    };

    const notifications = () => {
        const dateLimitForHiding = subtractTwoWeeks(registrationDate);
        if (showArchive) {
            return notificationsData.getNotifications.filter(
                (i) => i.isHidden || shouldHideNotification(i, registrationDate),
            );
        }
        return notificationsData.getNotifications.filter(
            (i) => !i.isHidden && !shouldHideNotification(i, registrationDate),
        );
    };

    const getNotifications = async () => {
        setLoading(true);
        try {
            const res = await apolloClient.query({
                query: LIST_NOTIFICATIONS,
                errorPolicy: 'all',
                fetchPolicy: notificationFetchMode as FetchPolicy,
                variables: {
                    dateLimitForHiding: subtractTwoWeeks(registrationDate),
                    lang: language,
                    showHidden: showArchive,
                    registrationDate,
                },
            });
            if (res?.data) {
                setNotifications(res.data);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const [hideNotification] = useMutation(SET_HIDE_NOTIFICATION, {
        onCompleted: () => {
            setNotificationFetchMode('network-only');
            getNotifications();
        },
    });

    const [readNotification] = useMutation(SET_READ_NOTIFICATION, {
        onCompleted: () => {
            setNotificationFetchMode('network-only');
            getNotifications();
        },
    });

    const handleNotificationOpen = (notification: INotification) => {
        const link = getLinkHref(notification);
        if (link) {
            navigate(link);
        } else if (notification.actionType === 'expense') {
            setVisibleNotification(notification);
        } else {
            setVisibleNotification(notification);
            readNotification({
                variables: {
                    id: notification.id,
                    announcementId: notification.announcementId,
                },
            });
        }
    };

    const handleNotificationArchive = (notification: INotification) => {
        hideNotification({
            variables: {
                id: notification.id,
                announcementId: notification.announcementId,
            },
        });
    };

    useEffect(() => {
        getNotifications();
    }, [language, showArchive]);

    useEffect(() => {
        const { pathname } = location;
        if (['/notifications'].includes(pathname)) {
            window.scrollTo(0, document.body.scrollHeight);
        }
    }, []);

    return (
        <Wrapper ref={el}>
            <HeaderRow>
                <UniqueTitle>{t('dashboard.messages.title')}</UniqueTitle>

                <EezyButton color="purple" dark onClick={() => setShowArchive(!showArchive)} width={150}>
                    {showArchive ? t('dashboard.only-new') : t('dashboard.only-archive')}
                </EezyButton>
            </HeaderRow>

            <Show when={!showArchive}>
                <Show when={!isIdentified}>
                    <Notification
                        icon={['far', 'exclamation-circle']}
                        iconColor={getStaticNotificationIconColor(isIdentified)}
                        isRead={false}
                        title={t('dashboard.messages.auth.title')}
                        text={t('dashboard.messages.auth.text') || ''}
                        buttonTransKey="dashboard.message-button"
                        onOpen={() => dispatch(showModals(['AUTH']))}
                    />
                </Show>

                <Show when={yelShouldAsk}>
                    <Notification
                        icon={['far', 'exclamation-circle']}
                        iconColor={getStaticNotificationIconColor(!yelShouldAsk)}
                        isRead={false}
                        title={t('dashboard.messages.yel.title')}
                        text={t('dashboard.messages.yel.text') || ''}
                        buttonTransKey="dashboard.message-button"
                        onOpen={() => dispatch(showModals(['YEL']))}
                    />
                </Show>
            </Show>

            <Show when={loading}>
                <LoadingSpinner color="#351f67" style={{ marginTop: 50 }} />
            </Show>

            {notifications().map((item: INotification) => (
                <NotificationsItem
                    key={item.id || item.announcementId}
                    isIdentified={isIdentified}
                    data={item}
                    onOpen={handleNotificationOpen}
                    onArchive={handleNotificationArchive}
                />
            ))}

            <NotificationModal
                isOpen={!!visibleNotification}
                handleModalClose={() => setVisibleNotification(undefined)}
                notification={visibleNotification}
            />
        </Wrapper>
    );
};

export default Notifications;
