import { Check, ChevronsUpDown } from 'lucide-react';

import { cn } from 'utils';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from 'components/ui/command';
import { useState } from 'react';
import { EezyButton } from 'components/Buttons';

type ComboBoxProps = {
    options: { label: string; value: string }[];
    defaultValue: string;
    onChange: (value: string) => void;
    selectLabel: string;
    notFoundLabel: string;
    searchLabel: string;
};

export function Combobox({
    options,
    defaultValue,
    onChange,
    selectLabel,
    notFoundLabel,
    searchLabel,
}: ComboBoxProps) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(defaultValue);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <EezyButton className="flex justify-between items-center w-[300px]" color="black">
                    {value ? options.find((option) => option.value === value)?.label : selectLabel}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </EezyButton>
            </PopoverTrigger>
            <PopoverContent className="p-0">
                <Command>
                    <CommandInput placeholder={searchLabel} />
                    <CommandEmpty>{notFoundLabel}</CommandEmpty>
                    <CommandGroup>
                        <CommandList>
                            {options.map((option) => (
                                <CommandItem
                                    key={option.value}
                                    value={option.value}
                                    onSelect={(currentValue) => {
                                        onChange(currentValue);
                                        setValue(currentValue === value ? '' : currentValue);
                                        setOpen(false);
                                    }}
                                >
                                    <Check
                                        className={cn(
                                            'mr-2 h-4 w-4',
                                            value === option.value ? 'opacity-100' : 'opacity-0',
                                        )}
                                    />
                                    {option.label}
                                </CommandItem>
                            ))}
                        </CommandList>
                    </CommandGroup>
                </Command>
            </PopoverContent>
        </Popover>
    );
}
