import React from 'react';
import { EezyButton } from '../../components/Buttons';
import { Flex } from '../../components/Flex';
import { Icon } from '../../components/Icon';
import { LabelMedium } from '../../components/textElements';
import { COLOR_BLUM, COLOR_GREYJOY, COLOR_STATUS_DONE } from '../../styles/variables';
import { trans } from '../../utils';

interface IProps {
    onChatStart: () => void;
}

const ChatStatus = (props: IProps) => {
    return (
        <>
            {/* Giosg rule shows and hides the .chat-open element when the agents are online/offline */}
            <Flex spread style={{ display: 'none', marginBottom: 16 }} className="chat-open">
                <LabelMedium color={COLOR_BLUM} style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{trans('support:chat.open')}</span>
                    <Icon icon={['fas', 'circle']} color={COLOR_STATUS_DONE} style={{ marginLeft: 8 }} />
                </LabelMedium>
                <EezyButton
                    color="purple"
                    hasIcon
                    className="open-chat"
                    width={130}
                    onClick={props.onChatStart}
                >
                    <Icon icon={['far', 'comment-dots']} color={COLOR_BLUM} />{' '}
                    {trans('support:chat.open-button')}
                </EezyButton>
            </Flex>

            {/* Giosg rule shows and hides the .chat-closed element when the agents are offline/online */}
            <Flex spread style={{ display: 'none', marginBottom: 16 }} className="chat-closed">
                <LabelMedium color={COLOR_BLUM} style={{ display: 'grid', alignItems: 'center' }}>
                    {trans('support:chat.closed')}
                    <Icon icon={['far', 'circle']} color={COLOR_GREYJOY} style={{ marginLeft: 8 }} />
                </LabelMedium>
                <EezyButton color="purple" disabled hasIcon width={130} onClick={() => null}>
                    <Icon icon={['far', 'comment-dots']} color={COLOR_BLUM} />{' '}
                    {trans('support:chat.open-button')}
                </EezyButton>
            </Flex>
        </>
    );
};

export default ChatStatus;
