import React, { ChangeEvent, KeyboardEvent, ReactNode } from 'react';
import styled from 'styled-components';

import { COLOR_BLACKWATER, COLOR_GREY_FOG, COLOR_GREYJOY, FOCUS_COLOR } from '../../styles/variables';
import { trans } from '../../utils';
import { Flex } from '../Flex';
import { InfoTooltipStyled } from '../InfoTooltipStyled';
import { FormRow, IGeneralFormInputProps } from './';
import { FormLabel } from './FormLabel';

export const Input = styled.input.attrs(() => ({
    autoCapitalize: 'on',
    // 'data-lpignore': true, // disable Last pass
    spellCheck: false,
}))`
    background-color: transparent;
    border-bottom: 1px solid;
    border-color: ${COLOR_GREY_FOG};
    color: ${COLOR_BLACKWATER};
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    height: 40px;
    line-height: 40px;
    letter-spacing: 0.15px;
    width: 100%;

    &:disabled {
        border-bottom: none;
    }

    &::placeholder {
        color: ${COLOR_GREYJOY};
    }

    & + span.endAdornment {
        border-bottom: 1px solid;
        border-color: ${COLOR_GREY_FOG};
        width: auto;
        svg {
            font-size: 15px;
            height: 15px;
            width: 15px;
        }
    }

    &:focus {
        border-color: ${FOCUS_COLOR};
    }
    &:focus + span.endAdornment {
        border-color: ${FOCUS_COLOR};
    }
`;

interface IFormInputProps extends IGeneralFormInputProps {
    autoComplete?: string;
    autoFocus?: boolean;
    endAdornment?: string | ReactNode;
    labelColor?: string;
    onBlur?: (val: string, name: string) => void;
    onChange?: (val: string, name: string) => void;
    onFocus?: () => void;
    onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
    placeholder?: string;
    type?: string;
    value?: string;
    className?: any;
    optional?: boolean;
    maxLength?: number;
    tooltip?: string;
    tooltipColor?: string;
    tooltipBackground?: string;
}

export const FormInput = (props: IFormInputProps) => {
    const [value, setValue] = React.useState<string>('');
    const [valueChanged, setValueChanged] = React.useState(false);

    const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
        if (valueChanged && props.onBlur) {
            props.onBlur(e.currentTarget.value, props.name);
            setValueChanged(false);
        }
    };
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.value);
        setValueChanged(true);
        if (props.onChange) {
            props.onChange(e.currentTarget.value, props.name);
        }
    };

    React.useEffect(() => {
        if (props.value) {
            setValue(props.value);
        }
    }, [props.value]);

    const input = (
        <Input
            className={props.className}
            autoComplete={props.autoComplete || 'off'}
            autoFocus={props.autoFocus}
            aria-invalid={!!props.error}
            disabled={props.disabled || props.readonly}
            id={props.name}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={props.onFocus}
            onKeyPress={props.onKeyPress}
            placeholder={props.placeholder || ''}
            // @ts-ignore
            $required={props.required}
            maxLength={props.maxLength}
            type={props.type || 'text'}
            value={value}
        />
    );

    return (
        <FormRow style={{ position: 'relative', ...props.style }}>
            <FormLabel
                color={props.labelColor || COLOR_BLACKWATER}
                error={props.error}
                disabled={props.disabled}
                helpLink={props.helpLink}
                helpTexts={props.helpTexts}
                htmlFor={props.name}
                value={props.value}
                warning={props.warning}
            >
                {props.label}
                {props.optional && (
                    <span style={{ fontWeight: 400, fontStyle: 'italic' }}>{` (${trans(
                        'general.optional'
                    )})`}</span>
                )}
                {props.tooltip && (
                    <InfoTooltipStyled
                        tooltip={props.tooltip}
                        tooltipBackground={props.tooltipBackground}
                        tooltipColor={props.tooltipColor}
                    />
                )}
            </FormLabel>
            {props.endAdornment ? (
                <Flex fullWidth relative>
                    {input}
                    <span className="endAdornment">{props.endAdornment}</span>
                </Flex>
            ) : (
                <>{input}</>
            )}
        </FormRow>
    );
};
