import { useMutation } from '@apollo/client';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ThunkDispatch } from 'redux-thunk';
import { UiLanguage } from '../../../../shared/src/types/user';
import { reportYelToStore } from '../../actions/user';
import { EezyButton } from '../../components/Buttons';
import { Flex } from '../../components/Flex';
import {
    Modal,
    ModalActions,
    ModalContent
} from '../../components/modals/Modal';
import { P, RowTitle } from '../../components/textElements';
import { trans } from '../../utils';
import { REPORT_SHOULD_HAVE_YEL } from './queries';

interface IModalProps {
    accepted?: boolean;
    answered?: boolean;
    handleModalClose: () => void;
    isOpen: boolean;
    language: UiLanguage;
    reportYelToStore: (yelSelfReported: boolean, yelShouldAsk: boolean) => void;
}

const YelModal = (props: IModalProps) => {
    const [reportYel] = useMutation(REPORT_SHOULD_HAVE_YEL, {
        onCompleted: data => {
            toast(trans('general.saved'));
            // the values are retrieved from rest api and updated through graphql api
            // so they don't automatically stay in sync
            props.reportYelToStore(
                data?.userYelSelfReport?.yelSelfReported,
                false
            );
        },
        onError: () => {
            toast.error(trans('errors.general'));
        }
    });

    const handleConfirm = (shouldHave: boolean) => {
        reportYel({ variables: { shouldHave } });
        props.handleModalClose();
    };
    return (
        <Modal
            id="modal-yel"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={trans('dashboard.messages.yel.modal-title')}
        >
            <ModalContent>
                <P color="black">
                    {trans('dashboard.messages.yel.1')}
                    <a
                        href={
                            props.language === 'fi'
                                ? 'https://kevytyrittajat.eezy.fi/kevytyrittajyys/kevytyrittajan-vakuutukset/yel-vakuutus/'
                                : 'https://kevytyrittajat.eezy.fi/en/light-entrepreneurship/light-entrepreneurs-insurances/yel-insurance/'
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {trans('dashboard.messages.yel.link1-anchor')}
                    </a>
                    {trans('dashboard.messages.yel.1-end')}
                </P>

                <P color="black">{trans('dashboard.messages.yel.2')}</P>

                <Flex spread style={{ marginTop: 30 }}>
                    <RowTitle color="black">
                        {trans('dashboard.messages.yel.modal-confirm')}
                    </RowTitle>
                    <div>
                        <EezyButton
                            color="black"
                            disabled={props.answered}
                            onClick={() => handleConfirm(true)}
                            style={
                                props.accepted
                                    ? {
                                          backgroundColor: 'black',
                                          color: 'white'
                                      }
                                    : {}
                            }
                        >
                            {trans('general.yes')}
                        </EezyButton>
                        <EezyButton
                            color="black"
                            disabled={props.answered}
                            onClick={() => handleConfirm(false)}
                            style={
                                !props.accepted && props.answered
                                    ? {
                                          backgroundColor: 'black',
                                          color: 'white'
                                      }
                                    : {}
                            }
                        >
                            {trans('general.no-I')}
                        </EezyButton>
                    </div>
                </Flex>
            </ModalContent>
            <ModalActions style={{ justifyContent: 'center' }}>
                <EezyButton
                    color="purple"
                    dark
                    onClick={props.handleModalClose}
                >
                    {trans('menu.close')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

const mapStateToProps = (state: any) => {
    return {
        language: state.user.language
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        reportYelToStore: (yelSelfReported: boolean, yelShouldAsk: boolean) => {
            dispatch(reportYelToStore(yelSelfReported, yelShouldAsk));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(YelModal);
