import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import freeFillingIcon from '../../assets/images/free-filling.svg';
import wizardFilling from '../../assets/images/wizard-filling.svg';
import { EezyButton } from '../../components/Buttons';
import {
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader
} from '../../components/modals/Modal';
import { P, UniqueTitle } from '../../components/textElements';
import { COLOR_BLUM, COLOR_GREYHOUND } from '../../styles/variables';
import { trans } from '../../utils';

const LinkIcon = styled.div`
    background: url(${(props: { icon: string }) => props.icon});
    background-repeat: no-repeat;
    background-position: 55% 50%;
    width: 238px;
    height: 80px;
    margin-top: 15px;
`;

const LinkBody = styled.button`
    border: 3px solid ${COLOR_BLUM};
    border-radius: 8px;
    text-align: center;
    margin: 12px;
`;

const LinkTitle = styled.h3`
    color: black;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    padding: 12px 0 10px 0;
`;

type IModalProps = {
    handleModalClose: () => void;
    isOpen: boolean;
}

const InvoiceCreationMethodModal: React.FC<IModalProps> = (props: IModalProps) => {
    return (
        <Modal
            id="modal-strong-auth"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
        >
            <ModalHeader>
                <UniqueTitle
                    style={{
                        lineHeight: '35px',
                        padding: '15px 0 0 0'
                    }}
                >
                    {trans('invoice.new-invoice')}
                </UniqueTitle>
                <P
                    style={{
                        color: COLOR_GREYHOUND,
                        fontSize: '15px',
                    }}
                >
                    {trans('invoice.new-invoice-label')}
                </P>
            </ModalHeader>
            <ModalContent style={{ display: 'flex', justifyContent: 'center' }}>
                <Link
                    to={'/invoices/new'}
                    state={{ fillType: 'free' }}
                    onClick={props.handleModalClose}
                >
                    <LinkBody>
                        <LinkIcon icon={freeFillingIcon} />
                        <LinkTitle color="black">
                            {trans('invoice.free-filling-invoice')}
                        </LinkTitle>
                    </LinkBody>
                </Link>
                <Link
                    to={'/invoices/new'}
                    state={{ fillType: 'guided' }}
                    onClick={props.handleModalClose}
                >
                    <LinkBody>
                        <LinkIcon icon={wizardFilling} />
                        <LinkTitle color="black">
                            {trans('invoice.guided-filling-invoice')}
                        </LinkTitle>
                    </LinkBody>
                </Link>
            </ModalContent>
            <ModalActions
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '15px 0 40px 0'
                }}
            >
                <EezyButton
                    style={{ border: `1px solid ${COLOR_BLUM}` }}
                    color="purple"
                    onClick={props.handleModalClose}
                >
                    {trans('general.cancel')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

export default InvoiceCreationMethodModal;
