import {
    TRIGGER_REFETCH,
    RESET_REFETCH
} from '../actionTypes';

export interface IRefetchState {
    queries: { [key: string]: boolean };
}
const initialState: IRefetchState = {
    queries: {},
};

export default (state = initialState, action: any): IRefetchState => {
    switch (action.type) {
        case TRIGGER_REFETCH:
            return {
                ...state,
                queries: { ...state.queries, [action.payload]: true },
            };
        case RESET_REFETCH:
            const newQueries = { ...state.queries };
            delete newQueries[action.payload];
            return {
                ...state,
                queries: newQueries,
            };
        default:
            return state;
    }
}
