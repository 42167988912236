import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Fade, Hidden } from '@mui/material';
import { ThunkDispatch } from 'redux-thunk';
import { showModals } from 'actions/auth';
import { EezyButton } from 'components/Buttons';
import { IBriefInvoice, IIncomeSummary } from '../../../../shared/src/types/invoice';
import { List } from '../../components/layout/List';
import { EmptyListPlaceholder } from 'components/EmptyListPlaceholder';
import { Flex } from 'components/Flex';
import { Icon } from 'components/Icon';
import InvoiceListItem from 'components/InvoiceListItem';
import { MoveButtonUpToBar, MoveFeedbackButtonUpToBar } from 'components/layout/MoveButtonUpToBar';
import { UniqueTitle, PSmall } from 'components/textElements';
import { AAVA_INVOICE_LIMIT } from 'constants/invoices';
import { COLOR_BLUM, COLOR_LILA, SCREEN_L, SCREEN_S } from 'styles/variables';
import { currentMonth, currentYear, formatCents, isMobile } from 'utils';
import { useSelectedListItem } from 'utils/hooks';
import {
    divideInvoices,
    filterInvoiceList,
    filterInvoiceListByRecipient,
    sortInvoices,
} from 'utils/invoice/invoiceLogic';
import {
    GET_AAVA_GROUP_INVOICES,
    GET_AAVA_INVOICES,
    GET_ANKKA_INVOICES,
    GET_INCOME,
} from '../dashboard/queries';
import { GET_INVOICE } from '../invoice/queries';
import { useTranslation } from 'react-i18next';
import { TextDivider } from 'components/TextDivider';
import { IRootState } from 'reducers';
import { FeedbackButton } from '../nav/drawer.styles';
import { resetRefetch } from '../../actions/refetch';
import { DashboardCard } from 'components/cards/DashboardCard';
import InvoiceSearch from 'containers/invoice/InvoiceSearch';
import LoadingSpinner from 'components/Loading';
import FeedbackImg from '../../assets/images/feedback-v2.svg';
import ContainerMobileScrollTop from 'components/ui/ContainerMobileScrollTop';
import ContainerScrollTop from 'components/ui/ContainerScrollTop';
import Show from 'components/ui/Show';

const ITEM_DELAY = 20;
const FADE_TIMEOUT = 200;
const SEARCH_MIN = 0;
const MIN_ITEMS_TO_SHOW_SCROLL = 12;
const AAVA_INVOICE_UPDATE_INTERVAL = 5 * 60 * 1000; // 5 minutes
const DEFAULT_VISIBLE_ITEMS_LENGTH = window.innerWidth >= SCREEN_L ? AAVA_INVOICE_LIMIT : 5;

const Wrapper = styled.div<{ showScroll?: boolean }>`
    padding-right: ${(props) => (props.showScroll ? '15px' : '0')};
`;
Wrapper.displayName = 'Wrapper';

const PendingPaymentInvoice = () => {
    const { t } = useTranslation();

    const { data } = useQuery(GET_INCOME, {
        variables: { year: currentYear(), month: currentMonth() },
    });
    const incomeData: IIncomeSummary = data?.income;

    return (
        <div className="py-6 flex gap-2.5 items-center" style={{ color: '#351F65' }}>
            <Icon icon={['far', 'clock']} />
            <div>{t('dashboard.invoices.pending-payment')}:</div>
            <b className="text-xl">{formatCents(incomeData?.incomeOpen ?? 0, true)} €</b>
        </div>
    );
};

type IInvoiceListItems = {
    items: IBriefInvoice[];
    selectedId: number | undefined;
    client: any;
};

const InvoiceListItems = (props: IInvoiceListItems) => {
    const language = useSelector((state: IRootState) => state.user.language);
    const navigate = useNavigate();

    const sendBriefInvoice = (invoiceBrief: IBriefInvoice) => {
        const url = invoiceBrief.isGroupInvoice ? `/group/view/${invoiceBrief.id}` : `/invoices/view/${invoiceBrief.id}`;
        if (invoiceBrief.status !== 'sending_pending') {
            navigate(url);
        }
    }

    const items = props.items.map((invoiceBrief) => ({
        data: invoiceBrief,
        onClick: () => sendBriefInvoice(invoiceBrief),
        selected: props.selectedId === invoiceBrief.id,
    }))

    return (
        <div>
            <ul>
                {items.map((item, index) => (
                    <li
                        className={item.selected ? 'selected' : ''}
                        key={`${index}-invoice-${item.data.id}`}
                        tabIndex={0}
                        onClick={item.onClick}
                        onKeyPress={item.onClick}
                    >
                        <Fade in={true} timeout={FADE_TIMEOUT}>
                            <div
                                onMouseOver={() => {
                                    if (index < 5) {
                                        props.client
                                            .query({
                                                query: GET_INVOICE,
                                                variables: {
                                                    id: item.data.id,
                                                    isGroupInvoice: item.data.isGroupInvoice,
                                                },
                                            })
                                            .catch(() => {
                                                return;
                                            });
                                    }
                                }}
                            >
                                <InvoiceListItem
                                    invoiceBrief={item.data}
                                    key={item.data.id}
                                    language={language}
                                    selected={item.selected}
                                />
                            </div>
                        </Fade>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const InvoiceList = () => {
    const el = useRef<any>(null);

    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();

    const searchQuery = useSelector((state: IRootState) => state.invoice.searchQuery);
    const searchRecipientId = useSelector((state: IRootState) => state.invoice.searchRecipientId);

    const [allInvoices, setAllInvoices] = useState<IBriefInvoice[]>([]);
    const [filteredTop, setFilteredTop] = useState<IBriefInvoice[]>([]);
    const [filteredBottom, setFilteredBottom] = useState<IBriefInvoice[]>([]);
    const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
    const [visibleLength, setVisibleLength] = useState(DEFAULT_VISIBLE_ITEMS_LENGTH);
    const [initialLoadDone, setInitialLoadDone] = useState<boolean>(false);
    const [aavaLoadDone, setAavaLoadDone] = useState<boolean>(false);
    const [aavaGroupLoadDone, setAavaGroupLoadDone] = useState<boolean>(false);
    const [ankkaLoadDone, setAnkkaLoadDone] = useState<boolean>(false);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const limitReached = () => {
        return allInvoices?.length >= AAVA_INVOICE_LIMIT;
    };

    const {
        data: ankkaInvoiceData,
        refetch: ankkaInvoicesRefetch,
        loading: ankkaInvoiceLoading,
    } = useQuery(GET_ANKKA_INVOICES, {
        errorPolicy: 'all',
        onCompleted: () => {
            setAnkkaLoadDone(true);
        },
    });
    const { data: groupInvoiceData, loading: groupInvoiceLoading } = useQuery(GET_AAVA_GROUP_INVOICES, {
        errorPolicy: 'all',
        onCompleted: () => {
            setAavaGroupLoadDone(true);
        },
    });

    const shouldAnkkaInvoicesRefetch = useSelector(
        (state: IRootState) => state.refetch.queries['GET_ANKKA_INVOICES'],
    );

    const {
        client,
        data: aavaInvoiceData,
        refetch: aavaInvoicesRefetch,
        fetchMore,
        loading: aavaInvoiceLoading,
    } = useQuery(GET_AAVA_INVOICES, {
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true,
        variables: {
            page: { offset: 0, pageSize: AAVA_INVOICE_LIMIT },
        },
        onCompleted: (data) => {
            setAavaLoadDone(true);
        },
    });

    const shouldAavaInvoicesRefetch = useSelector(
        (state: IRootState) => state.refetch.queries['GET_AAVA_INVOICES'],
    );
    const selectedId = useSelectedListItem();

    const scrollRef = useRef<HTMLSpanElement>(null);

    const showScroll = !isMobile() && filteredTop.length + filteredBottom.length >= MIN_ITEMS_TO_SHOW_SCROLL;

    const handleNewInvoiceClick = () => {
        window.innerWidth >= SCREEN_S ? dispatch(showModals(['CREATION_METHOD'])) : navigate('/invoices/new');
    };

    const handleHelpClick = () => {
        navigate('/notifications');
    };

    const displayTopInvoices = () => {
        return filteredTop.slice(0, visibleLength);
    };
    const displayBottomInvoices = () => {
        return filteredBottom.slice(0, visibleLength);
    };

    const loadMoreInvoices = useCallback(() => {
        setVisibleLength(visibleLength + DEFAULT_VISIBLE_ITEMS_LENGTH);
        if (visibleLength < allInvoices?.length) {
            return;
        }
        fetchMore({
            updateQuery: (prev: any, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return prev;
                }
                return {
                    ...prev,
                    aavaInvoices: {
                        ...prev.aavaInvoices,
                        items: [...prev.aavaInvoices.items, ...fetchMoreResult.aavaInvoices.items],
                    },
                };
            },
            variables: {
                page: {
                    offset: aavaInvoiceData?.aavaInvoices.items.length,
                    pageSize: AAVA_INVOICE_LIMIT,
                },
            },
        });
    }, [fetchMore, aavaInvoiceData?.aavaInvoices.items.length, allInvoices, visibleLength]);

    const updateInvoices = useCallback(() => {
        fetchMore({
            updateQuery: (prev: any, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return prev;
                }
                return {
                    ...prev,
                    aavaInvoices: {
                        ...prev.aavaInvoices,
                        items: fetchMoreResult.aavaInvoices.items,
                    },
                };
            },
            variables: {
                page: {
                    offset: 0,
                    pageSize: aavaInvoiceData?.aavaInvoices.items.length,
                },
            },
        });
    }, [fetchMore, aavaInvoiceData?.aavaInvoices.items.length]);

    useMemo(() => {
        const invoices = aavaInvoiceData?.aavaInvoices?.items.concat(
            ankkaInvoiceData?.ankkaInvoices.items,
            groupInvoiceData?.aavaGroupInvoices.items,
        );

        if (aavaInvoiceData && ankkaInvoiceData && groupInvoiceData && aavaLoadDone) {
            const invoicesSorted = sortInvoices(invoices);
            setAllInvoices(invoicesSorted);

            const { topInvoices, bottomInvoices } = divideInvoices(invoicesSorted);
            setFilteredTop(topInvoices);
            setFilteredBottom(bottomInvoices);
            setInitialLoadDone(true);
        }
    }, [ankkaInvoiceData, aavaInvoiceData, groupInvoiceData, aavaLoadDone]);

    useMemo(() => {
        const finishLoads = aavaLoadDone && ankkaLoadDone && aavaGroupLoadDone;
        const canLoadMore = finishLoads && !limitReached();
        setShowLoadMore(canLoadMore);
    }, [aavaLoadDone, ankkaLoadDone, aavaGroupLoadDone]);

    useEffect(() => {
        if (shouldAnkkaInvoicesRefetch) {
            ankkaInvoicesRefetch().finally(() => {
                dispatch(resetRefetch('GET_ANKKA_INVOICES'));
            });
        }
    }, [shouldAnkkaInvoicesRefetch, ankkaInvoicesRefetch, dispatch]);

    useEffect(() => {
        if (shouldAavaInvoicesRefetch) {
            aavaInvoicesRefetch().finally(() => {
                dispatch(resetRefetch('GET_AAVA_INVOICES'));
            });
        }
    }, [shouldAavaInvoicesRefetch, aavaInvoicesRefetch, dispatch]);

    useEffect(() => {
        if (allInvoices) {
            let searchResults;
            if (searchRecipientId) {
                searchResults = filterInvoiceListByRecipient(searchRecipientId.toString(), allInvoices);
            } else {
                const filter = (searchQ: string) => {
                    if (searchQ.length >= SEARCH_MIN) {
                        return filterInvoiceList(searchQ, allInvoices);
                    }
                };

                searchResults = filter(searchQuery || '');
            }

            if (searchResults) {
                const { topInvoices, bottomInvoices } = searchResults;
                setFilteredTop(topInvoices);
                setFilteredBottom(bottomInvoices);
            }
        }
    }, [allInvoices, searchQuery, searchRecipientId]);

    useEffect(() => {
        const interval = setInterval(() => {
            updateInvoices();
        }, AAVA_INVOICE_UPDATE_INTERVAL);
        return () => {
            clearInterval(interval);
        };
    }, [updateInvoices]);

    return (
        <DashboardCard>
            <Wrapper showScroll={showScroll} className="xl:relative w-full">
                <Hidden mdUp>
                    <MoveFeedbackButtonUpToBar>
                        <FeedbackButton
                            className="feedback-button-xs"
                            onClick={() => {
                                dispatch(showModals(['FEEDBACK']));
                            }}
                            left={false}
                        >
                            <img src={FeedbackImg} alt="Feedback" />
                        </FeedbackButton>
                    </MoveFeedbackButtonUpToBar>
                    <MoveButtonUpToBar>
                        <EezyButton
                            className="help-button-xs"
                            color="purple"
                            dark
                            onClick={handleHelpClick}
                            style={{ border: `1px solid ${COLOR_LILA}` }}
                        >
                            {t('dashboard.frontpage-button')}
                        </EezyButton>
                    </MoveButtonUpToBar>
                </Hidden>
                <Fade in={true} timeout={FADE_TIMEOUT}>
                    <Flex spread center style={{ paddingBottom: '27px' }}>
                        <UniqueTitle>{t('invoice.invoices')}</UniqueTitle>
                        <EezyButton dark color="purple" className="v2-btn" onClick={handleNewInvoiceClick}>
                            <Icon color="white" icon={['far', 'plus']} /> {t('invoice.new-invoice')}
                        </EezyButton>
                    </Flex>
                </Fade>

                {(aavaInvoiceLoading && !initialLoadDone) || ankkaInvoiceLoading || groupInvoiceLoading ? (
                    <Flex justifyCenter style={{ padding: '25px 0' }}>
                        <LoadingSpinner color={COLOR_BLUM} />
                    </Flex>
                ) : (
                    <>
                        <Fade in={true} timeout={FADE_TIMEOUT} style={{ transitionDelay: '100ms' }}>
                            {allInvoices.length > 0 ? (
                                <div className="mb-1">
                                    <InvoiceSearch />
                                </div>
                            ) : (
                                <div>
                                    <EmptyListPlaceholder text={t('invoice.empty-list-text')} />
                                </div>
                            )}
                        </Fade>
                        <PendingPaymentInvoice />
                    </>
                )}

                <List ref={el} className={showScroll ? 'show-scroll' : 'hide-scroll'}>
                    <Hidden mdDown>
                        <span ref={scrollRef} />
                    </Hidden>
                    <InvoiceListItems items={displayTopInvoices()} selectedId={selectedId} client={client} />
                    <Show when={displayBottomInvoices().length > 0}>
                        <Fade
                            in={true}
                            timeout={FADE_TIMEOUT}
                            style={{ transitionDelay: `${ITEM_DELAY * filteredTop.length}ms` }}
                        >
                            <div>
                                <TextDivider>
                                    <PSmall color={COLOR_BLUM}>{t('invoice.invoices-ready')}</PSmall>
                                </TextDivider>
                            </div>
                        </Fade>
                    </Show>
                    <InvoiceListItems
                        items={displayBottomInvoices()}
                        selectedId={selectedId}
                        client={client}
                    />
                    <Show when={showLoadMore}>
                        <div className={['mt-10'].join(' ')}>
                            <EezyButton
                                color="purple"
                                transparent
                                iconAlignment="right"
                                onClick={loadMoreInvoices}
                                className="v2-btn w-full border-0"
                            >
                                {aavaInvoiceLoading ? (
                                    <LoadingSpinner size="1em" />
                                ) : (
                                    t('dashboard.invoices.load-more-button')
                                )}
                                <Icon icon={['far', 'arrow-down']} />
                            </EezyButton>
                        </div>
                    </Show>
                </List>
                <Show when={el.current}>
                    <Show when={window.innerWidth >= SCREEN_L}>
                        <ContainerScrollTop container={el.current} key={visibleLength} />
                    </Show>
                    <Show when={visibleLength > DEFAULT_VISIBLE_ITEMS_LENGTH}>
                        <ContainerMobileScrollTop
                            container={el.current}
                            key={visibleLength}
                            topOffset={148}
                        />
                    </Show>
                </Show>
            </Wrapper>
        </DashboardCard>
    );
};

export default InvoiceList;
