import { gql } from '@apollo/client';

const GET_STATISTIC_INVOICES = gql`
    query StatisticInvoices($from: String!, $to: String!) {
        statisticInvoices(from: $from, to: $to) {
            id
            date
            updatedDate
            status
            total
            totalWithVat
            paidAmount
            partlyCredited
        }
    }
`;

export default GET_STATISTIC_INVOICES;
