import { Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { SCREEN_S, SCREEN_XS } from '../../styles/variables';
import { Icon } from '../Icon';
import { UniqueTitle } from '../textElements';

type StyledModalProp = {
    $noscroll?: boolean;
    $small?: boolean;
    $huge?: boolean;
};
const StyledModal = styled(Dialog)<StyledModalProp>`
    & > div > div {
        overflow-y: ${({ $noscroll }) => ($noscroll ? 'hidden' : 'visible')};
        border-radius: 30px;
        height: auto;
        margin: 30px;
        max-height: calc(100vh - 120px);
        position: absolute;
        ${({ $huge }) => $huge && 'max-width: 680px;'};

        @media (min-width: ${SCREEN_S}px) {
            border-radius: 30px;
            height: auto;
            margin: 30px;
            max-height: calc(100vh - 120px);
            position: absolute;
            top: 30px;
        }
    }
    ${UniqueTitle} {
        font-size: ${({ $small }) => ($small ? '18px;' : '26px')};
        ${({ $small }) => $small && 'line-height: 1.4'};
        padding: ${({ $small }) => ($small ? '20px 60px 20px 20px' : '20px 60px 14px 20px')};
        text-align: left;

        @media (min-width: ${SCREEN_S}px) {
            font-size: ${({ $small }) => ($small ? '18px;' : '28px')};
            padding: ${({ $small, $huge }) =>
                $small ? '20px 60px 20px 20px' : $huge ? '20px 60px 20px 20px' : '30px 60px 14px 50px'};
        }
    }
`;

export const ModalHeader = styled(DialogContent)`
    && {
        margin: 0;
        text-align: center;
        @media (min-width: ${SCREEN_XS}px) {
            padding: 8px 30px;
        }
    }

    &&:last-child {
        /* Not followed by ModalActions */
        margin-bottom: 30px;
    }
`;

export const ModalContent = styled(DialogContent)<{ $huge?: boolean }>`
    && {
        padding: ${({ $huge }) => ($huge ? '20px' : '8px 20px')};

        @media (min-width: ${SCREEN_S}px) {
            padding: ${({ $huge }) => ($huge ? '20px' : '8px 50px')};
        }
    }

    &&:last-child {
        /* Not followed by ModalActions */
        margin-bottom: 30px;
    }
`;

export const ModalActions = styled(DialogActions)<{ $small?: boolean; $huge?: boolean }>`
    && {
        ${(props) => !props.$small && 'box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1)'};
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-height: 60px;
        padding: 20px;

        @media (min-width: ${SCREEN_XS}px) and (min-height: 500px) {
            padding: ${(props) =>
                props.$small ? '17px 20px' : props.$huge ? '14px 20px 30px 20px' : '14px 50px 30px 50px'};
        }

        @media (min-width: ${SCREEN_S}px) and (min-height: 500px) {
            box-shadow: none;
        }
    }
`;

interface IModalProps {
    children: ReactNode;
    id: string;
    isOpen: boolean;
    onClose: () => void;
    title?: string | ReactNode;
    noscroll?: boolean;
    small?: boolean;
    huge?: boolean;
    disableClose?: boolean;
}

export const Modal = (props: IModalProps) => {
    const { children, id, isOpen, title, noscroll, small, huge, disableClose, ...restProps } = props;
    const handleClose = () => {
        props.onClose();
    };
    return (
        <StyledModal
            aria-labelledby={id}
            // disableBackdropClick // TODO: Deprecated. Fix https://robertmarshall.dev/blog/disablebackdropclick-of-forwardrefdialog-is-deprecated-solution-and-example/
            // onEscapeKeyDown={handleClose} // TODO: Deprecated.
            open={isOpen}
            $noscroll={noscroll}
            $small={small}
            $huge={huge}
            {...restProps}
        >
            <UniqueTitle color="black" id={id} data-mf-replace="**REMOVED**">
                {title ? title : ''}
                {!disableClose && (
                    <Icon
                        icon={['fal', 'times']}
                        className="bold"
                        onClick={handleClose}
                        style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: 30,
                            top: 30,
                        }}
                    />
                )}
            </UniqueTitle>
            {children}
        </StyledModal>
    );
};
