import {
    TRIGGER_REFETCH,
    RESET_REFETCH
} from '../actionTypes';

export const triggerRefetch = (queryName: string) => ({
    type: TRIGGER_REFETCH,
    payload: queryName,
});

export const resetRefetch = (queryName: string) => ({
    type: RESET_REFETCH,
    payload: queryName,
});
