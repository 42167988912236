import React from 'react';
import styled from 'styled-components';
import { EezyButton } from '../../components/Buttons';
import { Label, P, PSmall } from '../../components/textElements';
import { COLOR_BLACKWATER, COLOR_GREYHOUND } from '../../styles/variables';
import { trans } from '../../utils';
import {
    ICostComponentProps,
    MATERIAL,
    TRAVEL_CAR_ROUND,
    TRAVEL_OTHER_ROUND,
} from '../../utils/costs/costLogic';

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
`;

export const CostTypeSelection = (props: ICostComponentProps) => {
    const { dispatch } = props;

    const handleNextStep = (step: number) => {
        dispatch({ type: 'GO_STEP', payload: { step } });
    };

    return (
        <div>
            <P color={COLOR_GREYHOUND} style={{ marginBottom: 30 }}>
                <b>{trans('costs.modal-info1-1')}</b>
                {trans('costs.modal-info1-2')}
            </P>
            <P color={COLOR_GREYHOUND} style={{ marginBottom: 30 }}>
                {trans('costs.modal-info2')}
            </P>
            <Row>
                <Label id="input-car" color={COLOR_BLACKWATER}>
                    {trans('costs.travel-by-car')}
                </Label>
                <EezyButton
                    aria-labelledby="input-car"
                    color="purple"
                    dark
                    onClick={() => handleNextStep(TRAVEL_CAR_ROUND)}
                >
                    {trans('form.add')}
                </EezyButton>
            </Row>

            <Row>
                <Label id="input-other" color={COLOR_BLACKWATER}>
                    {trans('costs.travel-other')}
                </Label>
                <EezyButton
                    aria-labelledby="input-other"
                    color="purple"
                    dark
                    onClick={() => handleNextStep(TRAVEL_OTHER_ROUND)}
                >
                    {trans('form.add')}
                </EezyButton>
            </Row>

            <Row>
                <Label id="input-material" color={COLOR_BLACKWATER}>
                    {trans('costs.material')}
                </Label>
                <EezyButton
                    aria-labelledby="input-material"
                    color="purple"
                    dark
                    onClick={() => handleNextStep(MATERIAL)}
                >
                    {trans('form.add')}
                </EezyButton>
            </Row>

            <P>
                <a
                    href={
                        props.language === 'en'
                            ? 'https://kevytyrittajat.eezy.fi/en/when-am-i-able-to-get-expenses-reimbursed/'
                            : 'https://kevytyrittajat.eezy.fi/milloin-voin-saada-kulukorvauksia/'
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {trans('costs.modal-info3')}
                </a>
            </P>

            <PSmall>
                <a
                    href={
                        props.language === 'en'
                            ? 'https://kevytyrittajat.eezy.fi/en/this-is-how-you-apply-for-expense-reimbursements/'
                            : 'https://kevytyrittajat.eezy.fi/nain-haet-kulukorvauksia/'
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {trans('costs.modal-info4')}
                </a>
            </PSmall>

            <PSmall>
                <a
                    href={
                        props.language === 'en'
                            ? 'https://kevytyrittajat.eezy.fi/en/examples-of-reimbursable-expenses/'
                            : 'https://kevytyrittajat.eezy.fi/esimerkkeja-korvattavista-kuluista/'
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {trans('costs.modal-info5')}
                </a>
            </PSmall>
        </div>
    );
};
