import { FC, ReactNode } from 'react';
import { TwoColumnView } from 'components/layout/TwoColumnView';
import SalaryList from './SalaryList';

interface IProps {
    children: ReactNode;
}

const Salary: FC<IProps> = ({ children }) => {
    return (
        <TwoColumnView>
            <div>
                <SalaryList />
            </div>
            <div className="hide-scroll">{children}</div>
        </TwoColumnView>
    );
};

export default Salary;
