import { useState } from 'react';
import { toast } from 'react-toastify';
import { ApolloError, useMutation } from '@apollo/client';
import { CREATE_COWORKER, GET_COWORKERS } from '../../containers/profile/queries';
import { COLOR_BLACKWATER, COLOR_BLUM, COLOR_GREYHOUND } from 'styles/variables';
import { getErrorKey, getErrors } from 'utils/apolloErrors';
import { EezyButton } from '../Buttons';
import { FormInput } from '../form';
import { P, UniqueTitle } from '../textElements';
import { Modal, ModalActions, ModalContent, ModalHeader } from './Modal';
import { useTranslation } from 'react-i18next';

interface IInviteCoworkerModalProps {
    handleModalClose: () => void;
    isOpen: boolean;
}

const InviteCoworkerModal = (props: IInviteCoworkerModalProps) => {
    const { t } = useTranslation();

    const [inviteEmail, setInviteEmail] = useState<string>('');

    const [createCoworker] = useMutation(CREATE_COWORKER, {
        onCompleted: () => {
            props.handleModalClose();
            toast(t('profile:group-invoicing.user-added-toast'));
        },
        onError: (e: ApolloError) => {
            if ((getErrorKey(e) || '').includes('contact_already_exists')) {
                toast.error(
                    t(
                        'profile:group-invoicing.invite-coworker-modal.errors.contact-already-exists'
                    )
                );
            } else { // @ts-ignore
                if (getErrors(e)?.error?.includes('NOT_FOUND')) {
                                toast.error(
                                    t(
                                        'profile:group-invoicing.invite-coworker-modal.errors.contact-is-not-exist'
                                    )
                                );
                            } else {
                                toast.error(t('errors.general'));
                            }
            }
        },
        refetchQueries: [{ query: GET_COWORKERS }]
    });

    const handleCreateCoworker = () => {
        if (!!inviteEmail) {
            createCoworker({
                variables: {
                    email: inviteEmail
                }
            });
        }
    };

    return (
        <Modal
            id="modal-strong-auth"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            noscroll
        >
            <ModalHeader>
                <UniqueTitle
                    color={COLOR_BLACKWATER}
                    style={{
                        lineHeight: '35px',
                        padding: '15px 0 0 0'
                    }}
                >
                    {t(
                        'profile:group-invoicing.invite-coworker-modal.title'
                    )}
                </UniqueTitle>
                <P
                    style={{
                        color: COLOR_GREYHOUND,
                        fontSize: '15px'
                    }}
                >
                    {t(
                        'profile:group-invoicing.invite-coworker-modal.description-1'
                    )}
                    <br />
                    <br />
                    {t(
                        'profile:group-invoicing.invite-coworker-modal.description-2'
                    )}
                </P>
            </ModalHeader>

            <ModalContent style={{ margin: 5 }}>
                <FormInput
                    label={t('profile:group-invoicing.invite-coworker-modal.email') || ''}
                    onChange={val => setInviteEmail(val)}
                    name="invite-email"
                    placeholder={'essi.esimerkki@eezy.fi'}
                    required
                    value={inviteEmail}
                />
            </ModalContent>

            <ModalActions>
                <EezyButton
                    style={{ border: `1px solid ${COLOR_BLUM}` }}
                    color="purple"
                    onClick={props.handleModalClose}
                >
                    {t('general.cancel')}
                </EezyButton>

                <EezyButton
                    style={{ border: `1px solid ${COLOR_BLUM}` }}
                    color="purple"
                    dark
                    onClick={handleCreateCoworker}
                >
                    {t('invoice.send')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

export default InviteCoworkerModal;
