import { gql } from "@apollo/client";

const GET_CONTRACT_TEMPLATES = gql`
    query ContractTemplates($lang: LanguageId!) {
        contractTemplates(lang: $lang) {
            name
            url
        }
    }
`;

export default GET_CONTRACT_TEMPLATES;
