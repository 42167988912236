import { gql } from "@apollo/client";

const REPORT_SHOULD_HAVE_YEL = gql`
    mutation ReportShouldHaveYel($shouldHave: Boolean!) {
        userYelSelfReport(yelSelfReported: $shouldHave) {
            yelSelfReported
        }
    }
`;

export default REPORT_SHOULD_HAVE_YEL;
