import { formatValidationResult } from './validation';
import { trans } from 'utils/i18n';

export const ERROR_GENERAL = 'ERROR/GENERAL';
export const ERROR_NO_INVOICE = 'ERROR/NO_INVOICE';
export const ERROR_NO_COST = 'ERROR/NO_COST';
export const ERROR_SERVER = 'ERROR/SERVER';
export const ERROR_SIMPLE = 'ERROR/SIMPLE';

// yel errors
export const ERROR_GENERAL_YEL = 'ERROR/GENERAL_YEL';
export const ERROR_YEL_ADDRESS_MISSING = 'ERROR/ADDRESS_MISSING';
export const ERROR_YEL_CONTACT_SUPPORT = 'ERROR/CONTACT_SUPPORT';
export const ERROR_YEL_PIC_MISSING = 'ERROR/PIC_MISSING';

// login errors
export const ERROR_LOGIN_CREDENTIALS = 'ERROR/LOGIN_CREDENTIALS';
export const ERROR_LOGIN_VERIFICATION_CODE = 'ERROR/LOGIN_VERIFICATION_CODE';
export const ERROR_LOGIN_OTHER = 'ERROR/LOGIN_OTHER';
export const ERROR_LOGIN_TOO_MANY_TIMES = 'ERROR/LOGIN_TOO_MANY_TIMES';

//  register api errors
export const ERROR_REGISTER_ACCOUNT_EXISTS = 'USER_WITH_SAME_EMAIL_EXISTS';
export const ERROR_REGISTER_ALREADY_AUTHENTICATED = 'ALREADY_AUTHENTICATED';
export const ERROR_REGISTER_INVALID_BONUSCODE = 'INVALID_BONUS_CODE';
export const ERROR_REGISTER_NOT_IDENTIFIED = 'NOT_IDENTIFIED';
export const ERROR_REGISTER_OTHER = 'ERROR/REGISTER_OTHER';
export const ERROR_REGISTER_UNKNOWN_ERROR = 'UNKNOWN_ERROR';

// reset and request password api errors
export const ERROR_INVALID_PWD = 'INVALID_PASSWORD';
export const ERROR_PWD_CHANGE_FAILED = 'PASSWORD_CHANGE_FAILED';
export const ERROR_PWD_CHANGE_OTHER = 'ERROR/PWD_OTHER';
export const ERROR_PWD_MISMATCH = 'PASSWORD_MISMATCH';
export const ERROR_PWD_TOKEN_USED = 'TOKEN_USED';
export const ERROR_REQUEST_PWD = 'ERROR/REQUEST_PWD';
export const ERROR_TOKEN_EXPIRED = 'TOKEN_EXPIRED';

// files
export const ERROR_FILE_TOO_LARGE = 'ERROR/FILE_TOO_LARGE';

// binaries
export const ERROR_BINARY_GENERAL = 'ERROR/BINARY_GENERAL';
export const ERROR_BINARY_INVALID_REGISTRATION_DATE = 'ERROR/BINARY_INVALID_REGISTRATION_DATE';
export const ERROR_BINARY_MISSING_PERSON = 'ERROR/BINARY_MISSING_PERSON';
export const ERROR_BINARY_MISSING_PIC = 'ERROR/BINARY_MISSING_PIC';

type ErrorPlace = 'wholePage' | 'yelPage';

export interface IClientError {
    backButtonText?: string;
    backButtonUrl?: string;
    backButtonUrlExternal?: string;
    description?: string;
    helpText?: string;
    internalUrl?: string;
    internalLinkAnchor?: string;
    title: string;
    place?: ErrorPlace;
}

export const getError = ({ errorType, data }: { errorType: string; data?: any }): IClientError | null => {
    const t = trans;
    switch (errorType) {
        case ERROR_LOGIN_CREDENTIALS:
            return {
                title: t('login.wrong-credentials'),
            };
        case ERROR_LOGIN_VERIFICATION_CODE:
            return {
                title: t('login.wrong-verification-code'),
            };
        case ERROR_LOGIN_TOO_MANY_TIMES:
            return {
                title: t('login.too-many-times'),
            };
        case ERROR_LOGIN_OTHER:
            return {
                title: t('login.failure'),
            };
        case ERROR_REGISTER_ACCOUNT_EXISTS:
            return {
                title: t('errors.register.accountExists'),
            };
        case ERROR_REGISTER_INVALID_BONUSCODE:
            return {
                title: t('errors.register.invalidBonusCode'),
            };
        case ERROR_REGISTER_ALREADY_AUTHENTICATED: {
            return {
                title: t('errors.register.alreadyIn'),
            };
        }
        case ERROR_REGISTER_NOT_IDENTIFIED: {
            return {
                title: t('errors.register.notIdentified'),
            };
        }
        case ERROR_REGISTER_UNKNOWN_ERROR:
        case ERROR_REGISTER_OTHER:
        case ERROR_PWD_CHANGE_OTHER:
            return {
                title: t('errors.general'),
            };
        case ERROR_PWD_TOKEN_USED:
            return {
                internalLinkAnchor: t('login.change-pwd-request') || '',
                internalUrl: '/login',
                title: t('errors.reset-pwd.pwdTokenUsed'),
            };
        case ERROR_PWD_CHANGE_FAILED:
            return {
                internalLinkAnchor: t('login.change-pwd-request') || '',
                internalUrl: '/login',
                title: t('errors.reset-pwd.pwdChangeFailed'),
            };
        case ERROR_PWD_MISMATCH:
            // Client code should prevent this
            return {
                title: t('errors.reset-pwd.pwdMismatch'),
            };
        case ERROR_INVALID_PWD:
            // Client code should prevent this
            return {
                title: t('errors.reset-pwd.invalidPwd') || '',
            };
        case ERROR_TOKEN_EXPIRED:
            return {
                internalLinkAnchor: t('login.change-pwd-request') || '',
                internalUrl: '/login',
                title: t('errors.reset-pwd.tokenExpired') || '',
            };
        case ERROR_REQUEST_PWD:
            return {
                title: t('errors.reset-pwd.requestError'),
            };
        case ERROR_SERVER:
            return {
                description: data?.message || t('errors.maintenance-break') || '',
                helpText: t('errors.sorry') || '',
                place: 'wholePage',
                title: t('errors.server') || '',
            };
        case ERROR_SIMPLE:
            return {
                title: t('errors.general') || '',
            };
        case ERROR_GENERAL:
            return {
                backButtonText: t('errors.go-back') || '',
                description: t('errors.page-not-found') || '',
                helpText: t('errors.page-not-found2') || '',
                place: 'wholePage',
                title: t('errors.general') || '',
            };
        case ERROR_YEL_PIC_MISSING:
            return {
                backButtonText: t('errors.back-to-auth') || '',
                backButtonUrl: '/authenticate',
                description: t('errors.missing-profile-info-description') || '',
                helpText: t('errors.missing-profile-pic-help') || '',
                place: 'yelPage',
                title: t('errors.missing-profile-info-title') || '',
            };
        case ERROR_YEL_ADDRESS_MISSING:
            return {
                backButtonText: t('errors.back-to-change') || '',
                backButtonUrl: '/profile',
                description: t('errors.missing-profile-info-description') || '',
                helpText: t('errors.missing-profile-address-help') || '',
                place: 'yelPage',
                title: t('errors.missing-profile-info-title') || '',
            };
        case ERROR_YEL_CONTACT_SUPPORT:
            return {
                helpText: t('errors.contact-support-help') || '',
                place: 'yelPage',
                title: t('errors.general') || '',
            };
        case ERROR_GENERAL_YEL:
            return {
                backButtonText: t('errors.go-back') || '',
                backButtonUrl: '/yel',
                description: t('errors.page-not-found') || '',
                helpText: t('errors.page-not-found2') || '',
                place: 'yelPage',
                title: t('errors.general') || '',
            };
        case ERROR_NO_INVOICE:
            return {
                backButtonText: t('errors.no-invoice.back') || '',
                backButtonUrl: '/invoices',
                place: 'wholePage',
                title: t('errors.no-invoice.title') || '',
            };
        case ERROR_NO_COST:
            return {
                backButtonText: t('errors.no-cost.back') || '',
                backButtonUrl: `/invoices/${data.invoiceId}`,
                place: 'wholePage',
                title: t('errors.no-cost.title') || '',
            };
        case ERROR_FILE_TOO_LARGE:
            return {
                title: t('errors.file-too-large') || '',
            };
        case ERROR_BINARY_GENERAL:
            return {
                description: t('errors.page-not-found') || '',
                helpText: t('errors.page-not-found2') || '',
                place: 'wholePage',
                title: t('errors.general') || '',
            };
        case ERROR_BINARY_INVALID_REGISTRATION_DATE:
            return {
                description: t('errors.binary.invalid-registration-date') || '',
                helpText: t('errors.contact-support-help') || '',
                place: 'wholePage',
                title: t('errors.general') || '',
            };
        case ERROR_BINARY_MISSING_PERSON:
            return {
                description: t('errors.binary.missing-person') || '',
                helpText: t('errors.contact-support-help') || '',
                place: 'wholePage',
                title: t('errors.general') || '',
            };
        case ERROR_BINARY_MISSING_PIC:
            return {
                backButtonText: t('errors.back-to-auth') || '',
                backButtonUrl: '/authenticate',
                description: t('errors.binary.missing-pic') || '',
                helpText: t('errors.contact-support-help') || '',
                place: 'wholePage',
                title: t('errors.general') || '',
            };
        default:
            return null;
    }
};

export const errHasKey = (key: string) => (errs: ReturnType<typeof formatValidationResult>) => {
    if (!errs) {
        return false;
    }
    return !!Object.keys(errs).find((k) => k?.startsWith(key));
};
