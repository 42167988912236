import React, { HTMLProps } from 'react';

import {
    IInvoiceItem,
    IInvoiceItemKeys
} from '../../../../../shared/src/types/invoice';
import { UiLanguage } from '../../../../../shared/src/types/user';
import { Flex } from '../../../components/Flex';
import {
    FormNumber,
    FormNumberInput,
    FormRadio,
    FormRow,
    FormSection,
    FormSelect,
    HoursMinutes,
    SplitRow
} from '../../../components/form';
import { IDropdownOption } from '../../../components/form/AutocompleteDropdown';
import { FormLabel } from '../../../components/form/FormLabel';
import { trans } from '../../../utils';
import { addVat } from '../../../utils/calc';
import {
    calcTypeOptions,
    quantityUnitOptions
} from '../../../utils/invoice/invoiceLogic';

interface IQuantityAndPricePopOver extends HTMLProps<HTMLDivElement> {
    item: IInvoiceItem;
    language: UiLanguage;
    calcType: string;
    vatType: string;
    rowErrors?: any;
    showArrows: boolean;
    setCalcType: (val: string) => void;
    handleUpdate: (changedProperty: IInvoiceItemKeys) => void;
    handleChange: (val: string | number, name: string) => void;
}

const QuantityAndPricePopOver = (props: IQuantityAndPricePopOver) => {
    const handleCalcTypeChange = (val: string) => {
        props.setCalcType(val);
        if (val === 'h') {
            props.handleChange('h', 'quantityUnit');
        } else if (val === 'units' && props.item.itemType === 'work') {
            props.handleUpdate({ quantityUnit: 'pcs' });
        }
    };

    const rowQuantityUnitOptions: IDropdownOption[] = quantityUnitOptions(
        props.item.itemType
    );

    return (
        <div style={{ ...props.style }}>
            <FormSection style={{ marginTop: 10 }}>
                <FormRadio
                    helpTexts={
                        props.item.itemType === 'work'
                            ? [
                                  'invoice.rows-form.help.calcTypeWork.1',
                                  'invoice.rows-form.help.calcTypeWork.2'
                              ]
                            : [
                                  'invoice.rows-form.help.calcType.1',
                                  'invoice.rows-form.help.calcType.2'
                              ]
                    }
                    label={
                        props.item.itemType === 'work'
                            ? trans('invoice.rows-form.calc-type-q-work')
                            : trans('invoice.rows-form.calc-type-q')
                    }
                    name="calcType"
                    onChange={handleCalcTypeChange}
                    onTwoLines
                    options={calcTypeOptions(props.item.itemType)}
                    value={props.calcType}
                />
            </FormSection>

            <FormSection style={{ marginTop: 20 }}>
                <FormRow>
                    {props.calcType === 'h' && (
                        <SplitRow>
                            <FormRow>
                                <FormLabel
                                    error={
                                        props.showArrows &&
                                        props.rowErrors?.quantity
                                    }
                                    value={props.item.quantity}
                                >
                                    {trans('invoice.rows-form.workTime')}
                                </FormLabel>
                                <HoursMinutes
                                    error={
                                        props.showArrows &&
                                        props.rowErrors?.quantity
                                    }
                                    name="quantity"
                                    onChange={props.handleChange}
                                    required
                                    value={props.item.quantity}
                                />
                            </FormRow>
                            <FormRow style={{ minWidth: '50%' }}>
                                <FormLabel
                                    error={
                                        props.showArrows &&
                                        props.rowErrors?.price
                                    }
                                    value={props.item.price}
                                >
                                    {trans('invoice.rows-form.hourly-price')}
                                </FormLabel>
                                <Flex center>
                                    <FormNumberInput
                                        allowNegative
                                        disableRounding
                                        error={
                                            props.showArrows &&
                                            props.rowErrors?.price
                                        }
                                        onChange={props.handleChange}
                                        isEur={true}
                                        endAdornment={trans(
                                            `form.x-e-${props.item.quantityUnit}`
                                        ).trim()}
                                        name="price"
                                        required
                                        value={
                                            props.vatType === 'without-vat'
                                                ? props.item.price
                                                : addVat(
                                                      props.item.price || 0,
                                                      props.item.vat || 0
                                                  )
                                        }
                                    />
                                </Flex>
                            </FormRow>
                        </SplitRow>
                    )}

                    {props.calcType === 'units' &&
                        (props.item.itemType === 'work' ? (
                            <SplitRow>
                                <FormNumber
                                    allowNegative
                                    label={trans(
                                        'invoice.tableColumns.quantity'
                                    )}
                                    endAdornment={trans(
                                        `form.x-${props.item.quantityUnit}`
                                    ).trim()}
                                    error={
                                        props.showArrows &&
                                        props.rowErrors?.quantity
                                    }
                                    onChange={props.handleChange}
                                    name="quantity"
                                    required
                                    value={props.item.quantity}
                                />
                                <FormNumber
                                    allowNegative
                                    style={{ flexGrow: 1 }}
                                    label={trans(
                                        'invoice.tableColumns.pcsprice'
                                    )}
                                    disableRounding
                                    error={
                                        props.showArrows &&
                                        props.rowErrors?.price
                                    }
                                    onChange={props.handleChange}
                                    isEur={true}
                                    endAdornment={trans('form.eurs', {eurs: ''}).trim()}
                                    name="price"
                                    required
                                    value={
                                        props.vatType === 'without-vat'
                                            ? props.item.price
                                            : addVat(
                                                  props.item.price || 0,
                                                  props.item.vat || 0
                                              )
                                    }
                                />
                            </SplitRow>
                        ) : (
                            <SplitRow>
                                <FormRow>
                                    <FormLabel
                                        error={
                                            props.showArrows &&
                                            (props.rowErrors?.quantity ||
                                                props.rowErrors?.quantityUnit)
                                        }
                                        value={
                                            props.item.quantity &&
                                            props.item.quantityUnit
                                        }
                                    >
                                        {trans(
                                            'invoice.rows-form.quantity-units-q'
                                        )}
                                    </FormLabel>

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}
                                    >
                                        <FormNumberInput
                                            allowNegative
                                            error={
                                                props.showArrows &&
                                                props.rowErrors?.quantity
                                            }
                                            onChange={props.handleChange}
                                            name="quantity"
                                            required
                                            style={{ marginRight: 10 }}
                                            value={props.item.quantity}
                                        />
                                        <FormSelect
                                            error={
                                                props.showArrows &&
                                                props.rowErrors?.quantityUnit
                                            }
                                            name="quantityUnit"
                                            onChange={props.handleChange}
                                            options={rowQuantityUnitOptions}
                                            required
                                            showIcon
                                            style={{ maxWidth: 60 }}
                                            value={props.item.quantityUnit}
                                        />
                                    </div>
                                </FormRow>
                                <FormNumber
                                    allowNegative
                                    disableRounding
                                    label={trans(
                                        'invoice.tableColumns.pcsprice'
                                    )}
                                    error={
                                        props.showArrows &&
                                        props.rowErrors?.price
                                    }
                                    onChange={props.handleChange}
                                    isEur={true}
                                    endAdornment={trans('form.eurs', {eurs: ''}).trim()}
                                    name="price"
                                    required
                                    value={
                                        props.vatType === 'without-vat'
                                            ? props.item.price
                                            : addVat(
                                                  props.item.price || 0,
                                                  props.item.vat || 0
                                              )
                                    }
                                    style={{ width: '50%' }}
                                />
                            </SplitRow>
                        ))}
                    {props.calcType === 'sum' &&
                        props.vatType === 'without-vat' && (
                            <FormNumber
                                allowNegative
                                endAdornment={'€'}
                                error={
                                    props.showArrows &&
                                    props.rowErrors?.totalPrice
                                }
                                isEur={true}
                                label={trans('invoice.rows-form.total-q')}
                                name="totalPrice"
                                onChange={props.handleChange}
                                required
                                style={{ width: '50%' }}
                                value={props.item.totalPrice}
                            />
                        )}
                    {props.calcType === 'sum' &&
                        props.vatType === 'with-vat' && (
                            <FormNumber
                                allowNegative
                                endAdornment={'€'}
                                error={
                                    props.showArrows &&
                                    props.rowErrors?.totalPriceWithVat
                                }
                                isEur={true}
                                label={trans('invoice.rows-form.total-q')}
                                name="totalPriceWithVat"
                                onChange={props.handleChange}
                                required
                                style={{ width: '50%' }}
                                value={props.item.totalPriceWithVat}
                            />
                        )}
                </FormRow>
            </FormSection>
        </div>
    );
};

export default QuantityAndPricePopOver;
