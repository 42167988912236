import { gql } from "@apollo/client";

const READ_NOTIFICATION = gql`
    mutation MarkNotificationAsRead(
        $notification: NotificationInput!
        $isRead: Boolean!
    ) {
        markNotificationRead(notification: $notification, isRead: $isRead) {
            content
            date
            id
            isHidden
            isRead
            type
            title
        }
    }
`;

export default READ_NOTIFICATION;
