import React from 'react';
import { useTranslation } from 'react-i18next';
import { EezyButton } from 'components/Buttons';
import { Icon } from 'components/Icon';
import { LabelValueType } from 'containers/eezypay/types/SalesPageStatisticTypes';
import styled from 'styled-components';
import { SCREEN_M } from 'styles/variables';
import ButtonPeriodPicker from 'components/form/ButtonPeriodPicker';

interface ISalesStatsBillingSuggestion {
    options: LabelValueType<any>[];
    disabled?: boolean;
    minDate?: Date;
    onSelect: (value: LabelValueType<{ from: Date; to: Date }>) => void;
}

const BtnDatePickerWrapper = styled.div`
    @media (max-width: ${SCREEN_M}px) {
        .btn-date-picker,
        .v2-btn {
            width: 100%;
        }
    }
`;

const SalesStatsBillingSuggestion = (props: ISalesStatsBillingSuggestion) => {
    const { t } = useTranslation();

    const onSelect = ([from, to, period]: [Date, Date, string]) => {
        props.onSelect({ label: from.getFullYear().toString(), value: { from, to } });
    };

    return (
        <div className="flex flex-col md:flex-row md:items-center gap-7 md:pl-4">
            {!props.disabled && (
                <div className="flex gap-7">
                    {props.options.map((i) => (
                        <div key={i.label}>
                            <EezyButton
                                className="v2-btn"
                                color="purple"
                                style={{ border: 0 }}
                                transparent
                                hasIcon={true}
                                onClick={() => props.onSelect(i)}
                            >
                                <Icon icon={['far', 'plus']} />
                                <span className="capitalize">{i.label}</span>
                            </EezyButton>
                        </div>
                    ))}
                </div>
            )}

            {props.options.length > 1 && (
                <BtnDatePickerWrapper>
                    <ButtonPeriodPicker
                        label={t('statistic.custom-suggestion')}
                        icon={['far', 'plus']}
                        views={['year']}
                        disabled={props.disabled}
                        onChange={onSelect}
                        maxDate={new Date()}
                        minDate={props.minDate}
                    />
                </BtnDatePickerWrapper>
            )}
        </div>
    );
};

export default SalesStatsBillingSuggestion;
