import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveSearchQuery } from 'actions/invoice';
import SearchField from 'components/SearchField';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducers';

const SEARCH_TIMEOUT = 1000;

const InvoiceSearch: FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [query, setQuery] = useState('');
    const [searchTimer, setSearchTimer] = useState(0);

    const searchQuery = useSelector<IRootState>((state) => state.invoice.searchQuery);

    useEffect(() => {
        setQuery(searchQuery as string);
    }, [searchQuery]);

    const handleChange = (val: string, fireImmediately?: boolean) => {
        setQuery(val);
        clearTimeout(searchTimer);
        if (fireImmediately) {
            dispatch(saveSearchQuery(val));
        } else {
            setSearchTimer(
                setTimeout((_: number) => {
                    dispatch(saveSearchQuery(val));
                }, SEARCH_TIMEOUT),
            );
        }
    };

    return <SearchField onChange={handleChange} placeholder={t('invoice.search')} query={query} />;
};

export default InvoiceSearch;
