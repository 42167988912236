import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { INotification } from '../../../../shared/src/types/notifications';
import { ButtonColor } from 'components/Buttons';
import { INotificationProps } from 'containers/dashboard/Notification';
import {
    COLOR_BLUM,
    COLOR_GRAYS_ANATOMY,
    COLOR_GREYJOY,
    COLOR_IMPORTANT,
    COLOR_IMPORTANT_LIGHTEST,
} from 'styles/variables';
import i18next from 'i18next';
import { formatDate, subtractTwoWeeks } from 'utils/time';

const getDynamicNotification = (notification: INotification) => {
    // @ts-ignore
    const { t } = i18next;
    switch (notification.actionType) {
        case 'coworker':
            return {
                buttonTransKey: 'general.show',
                link: '/profile',
                text: t('dashboard.messages.coworker.text', { name: notification.actionData?.name }),
                title: t('dashboard.messages.coworker.title'),
            };
        case 'guide':
            return {
                buttonTransKey: 'dashboard.message-button',
                modalTitle: t('dashboard.messages.guide.1'),
                text: t('dashboard.messages.guide.text'),
                title: t('dashboard.messages.guide.title'),
            };
        case 'expense':
            return {
                buttonTransKey: 'dashboard.message-button',
                modalTitle: t('dashboard.messages.expense.modal-title'),
                text: notification.actionData?.name || '',
                title: t('dashboard.messages.expense.title', {
                    duedate: formatDate(notification.actionData?.dueDate || ''),
                }),
            };
        case 'taxCard':
            return {
                buttonTransKey: 'dashboard.messages.taxcard.button',
                link: '/salaries',
                text: t('dashboard.messages.taxcard.text'),
                title: t('dashboard.messages.taxcard.title'),
            };
        default:
            return {
                buttonTransKey: '',
                link: '',
                text: '',
                title: '',
            };
    }
};

export const getNotificationIcon = (notification: INotification): IconProp => {
    switch (notification.type) {
        case 'alert':
            return ['far', 'exclamation-circle'];
        case 'expense':
            return ['far', 'file-lines'];
        case 'task':
            return ['far', 'clipboard-list'];
        case 'coworker':
            return ['far', 'user-friends'];
        case 'bulletin':
        case 'unknown':
        default:
            return ['far', notification.isRead ? 'envelope-open' : 'envelope'];
    }
};

export const getStaticNotificationIconColor = (isRead?: boolean) => {
    if (isRead) {
        return COLOR_GREYJOY;
    } else {
        return COLOR_BLUM;
    }
};

export const getNotificationIconColor = (notification: INotification): string => {
    if (
        notification.type === 'alert' ||
        notification.type === 'expense' ||
        notification.type === 'coworker'
    ) {
        return COLOR_IMPORTANT;
    } else if (notification.type === 'task') {
        return COLOR_BLUM;
    } else if (notification.isRead) {
        return COLOR_GREYJOY;
    }
    return COLOR_BLUM;
};

export const getNotificationText = (notification: INotification): string => {
    if (notification.actionType) {
        return getDynamicNotification(notification).text;
    }
    return '';
};

export const getNotificationTitle = (notification: INotification): string => {
    if (notification.actionType) {
        return getDynamicNotification(notification).title;
    }
    return notification.title || '';
};

export const getButtonTransKey = (notification: INotification): string => {
    if (notification.actionType) {
        return getDynamicNotification(notification).buttonTransKey;
    }
    return 'dashboard.message-button';
};

export const getBackgroundColor = (notification: INotification): string => {
    switch (notification.type) {
        case 'alert':
        case 'expense':
        case 'coworker':
            return COLOR_IMPORTANT_LIGHTEST;
        default:
            return COLOR_GRAYS_ANATOMY;
    }
};

export const getButtonColor = (notification: INotification): ButtonColor => {
    switch (notification.type) {
        case 'alert':
        case 'expense':
        case 'coworker':
            return 'important';
        default:
            return 'purple';
    }
};

export const getButtonIcon = (notification: INotification): IconProp | undefined => {
    if (notification.type === 'alert' || notification.type === 'coworker') {
        return ['far', 'arrow-right'];
    }
};

export const getLinkHref = (notification: INotification): string | undefined => {
    return getDynamicNotification(notification).link;
};

export const getModalTitle = (notification: INotification): string | undefined => {
    return getDynamicNotification(notification).modalTitle;
};

export const isArchiveDisabled = (notification: INotification): boolean => {
    return notification.isHidden || notification.type !== 'bulletin';
};

export const shouldHideNotification = (notification: INotification, registrationDate: string): boolean => {
    const dateLimitForHiding = subtractTwoWeeks(registrationDate);
    return new Date(notification.date) < new Date(dateLimitForHiding);
};

export const filterNotifications = (
    notifications: INotification[],
    showArchive: boolean,
    registrationDate: string,
): INotification[] => {
    if (showArchive) {
        return notifications.filter((n) => n.isHidden || shouldHideNotification(n, registrationDate));
    }
    return notifications.filter((n) => !n.isHidden && !shouldHideNotification(n, registrationDate));
};

export const getNotification = (notification: INotification): INotificationProps => {
    return {
        backgroundColor: getBackgroundColor(notification),
        buttonColor: getButtonColor(notification),
        buttonIcon: getButtonIcon(notification),
        buttonTransKey: getButtonTransKey(notification),
        date: notification.date,
        icon: getNotificationIcon(notification),
        iconColor: getNotificationIconColor(notification),
        isRead: notification.isRead,
        text: getNotificationText(notification),
        textHtml: notification.content,
        title: getNotificationTitle(notification),
    };
};
