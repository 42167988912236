import React from 'react';
import { IFile } from '../../../../shared/src/types/common';
import { IReceiptCost } from '../../../../shared/src/types/costs';
import { Flex } from '../../components/Flex';
import { Icon } from '../../components/Icon';
import { LabelCapsSmall } from '../../components/textElements';
import { COLOR_DARK_GRAY } from '../../styles/variables';
import { formatCents, formatDate, trans } from '../../utils';
import { getCostTotal } from '../../utils/costs/costLogic';
import { CostTaxBreakDown } from './CostTaxBreakDown';
import DeleteCost from './DeleteCost';
import EditCost from './EditCost';

interface IProps {
    cost: IReceiptCost;
    costInvoiceId: number;
    editable?: boolean;
    invoiceId: number;
    isGroupInvoice: boolean;
    showOnlyRejected?: boolean;
}

export const CostDocCostRow = (props: IProps) => {
    const { cost } = props;
    const [isOpen, setOpen] = React.useState(false);

    const className =
        cost.status === 'rejected' && !props.showOnlyRejected ? 'rejected' : '';
    return (
        <>
            <tr
                className={isOpen ? 'open' : 'closed'}
                onClick={() => setOpen(!isOpen)}
            >
                <td className={className}>{cost.description}</td>
                <td className={className}>
                    {cost.purchaseDate && formatDate(cost.purchaseDate)}
                </td>
                <td className={className}>
                    {trans('form.eurs', {
                        eurs: formatCents(
                            getCostTotal(cost.amountsWithVats),
                            true
                        )
                    })}
                </td>
                <td>
                    {props.editable && (
                        <>
                            <DeleteCost
                                costId={cost.id}
                                costInvoiceId={props.costInvoiceId}
                                description={cost.description || ''}
                                invoiceId={props.invoiceId}
                                isGroupInvoice={props.isGroupInvoice}
                                type="cost"
                            />
                            <EditCost
                                costId={cost.id}
                                invoiceId={props.invoiceId}
                                isGroupInvoice={props.isGroupInvoice}
                                type="cost"
                            />
                        </>
                    )}
                    <button onClick={() => setOpen(!isOpen)}>
                        <Icon
                            icon={
                                isOpen
                                    ? ['fal', 'chevron-up']
                                    : ['fal', 'chevron-down']
                            }
                            color="black"
                        />
                    </button>
                </td>
            </tr>
            <tr
                style={isOpen ? { display: 'table-row' } : { display: 'none' }}
                className="details"
            >
                <td colSpan={4}>
                    <CostTaxBreakDown cost={cost} />

                    {cost.receiptFiles.length > 0 && (
                        <div style={{ marginTop: 15 }}>
                            <LabelCapsSmall color={COLOR_DARK_GRAY}>
                                {trans('costs.receipts')}:
                            </LabelCapsSmall>
                            <Flex useWrap className="files">
                                {cost.receiptFiles.map((f: IFile, i) => (
                                    <a
                                        key={`${i}-${f.name}`}
                                        href={f.previewUrl}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        style={{ color: 'black' }}
                                    >
                                        {f.name}
                                    </a>
                                ))}
                            </Flex>
                        </div>
                    )}
                </td>
            </tr>
        </>
    );
};
