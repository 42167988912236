import React from 'react';
import styled from 'styled-components';
import { EezyButton } from '../../components/Buttons';
import { FormInput, FormRadio, FormRow } from '../../components/form';
import {
    Modal,
    ModalActions,
    ModalContent
} from '../../components/modals/Modal';
import { P } from '../../components/textElements';
import {
    COLOR_BLACKWATER,
    COLOR_BLUM,
    COLOR_WHITE_WALKER
} from '../../styles/variables';
import { trans } from '../../utils';
import { EMAIL_REGEX } from '../../utils/validation';

const Wrapper = styled.div`
    label {
        color: ${COLOR_BLACKWATER};
    }

    && input:checked + label {
        background-color: ${COLOR_BLUM};
        color: ${COLOR_WHITE_WALKER};
    }

    && input + label {
        min-width: 125px;
        color: ${COLOR_BLUM};
    }

    && input:disabled + label {
        opacity: 0.3;
    }

    && input[type='text'] {
        color: ${COLOR_BLACKWATER};
        border-color: ${COLOR_BLACKWATER};
    }

    & ${FormRow} {
        margin-top: 15px;
    }

    .helpbox {
        background-color: ${COLOR_WHITE_WALKER};
        padding: 0;
        p {
            color: ${COLOR_BLACKWATER};
            font-size: 15px;
            line-height: 20px;
        }

        p ~ p {
            margin-top: 15px;
        }
    }
`;

interface IModalProps {
    handleModalClose: () => void;
    email?: string;
    lang?: string;
    invoiceId?: number;
    isOpen: boolean;
    updateExpress: (val: object) => void;
}

const ExpressModal = (props: IModalProps) => {
    const [type, setType] = React.useState('normal');
    const [sender, setSender] = React.useState('eezy');
    const [email, setEmail] = React.useState(
        props.email?.split(',', 1)[0] || ''
    );

    const handleSave = () => {
        props.updateExpress({
            variables: {
                confirmationRequired: sender === 'eezy',
                email: sender === 'eezy' ? email : '',
                invoiceId: props.invoiceId,
                quick: type === 'quick',
                state: true
            }
        });
    };

    const emailIsInvalid = sender === 'eezy' && !EMAIL_REGEX.test(email);

    return (
        <Modal
            id="express-modal"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={trans('invoice.eezy-express')}
        >
            <ModalContent>
                <Wrapper>
                    <P style={{ margin: '15px 0 15px 0' }}>
                        {trans('invoice.express-long')}
                        <a
                            href={
                                props.lang === 'en'
                                    ? 'https://kevytyrittajat.eezy.fi/en/light-entrepreneurship/this-is-how-our-service-works/additional-services/eezyexpress-salary/'
                                    : 'https://kevytyrittajat.eezy.fi/kevytyrittajyys/nain-kevytyrittaminen-toimii/lisapalvelut/eezyexpress-palkka/'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {trans('invoice.express-long-anchor')}
                        </a>
                        .
                    </P>
                    <P style={{ margin: '15px 0 15px 0' }}>
                        {trans('invoice.express-long2')}
                    </P>

                    <FormRow>
                        <FormRadio
                            label={trans('invoice.express-q1')}
                            name="express-type"
                            onTwoLines
                            options={[
                                {
                                    label: trans('invoice.express-q1-a2'),
                                    value: 'normal'
                                },
                                {
                                    label: trans('invoice.express-q1-a1'),
                                    value: 'quick'
                                }
                            ]}
                            value={type}
                            onChange={(val, name) => {
                                setType(val);
                            }}
                        />
                    </FormRow>

                    <FormRow>
                        {type === 'normal' && (
                            <P>{trans('invoice.express-normal-help')}</P>
                        )}
                        {type === 'quick' && (
                            <P>{trans('invoice.express-quick-help')}</P>
                        )}
                    </FormRow>

                    <FormRow>
                        <FormRadio
                            helpTexts={[
                                'invoice.express-confirmation-1',
                                'invoice.express-confirmation-2'
                            ]}
                            label={trans('invoice.express-q3')}
                            name="confirmation-sender"
                            onTwoLines
                            options={[
                                {
                                    label: trans('invoice.express-q3-a1'),
                                    value: 'eezy'
                                },
                                {
                                    label: trans('invoice.express-q3-a2'),
                                    value: 'me'
                                }
                            ]}
                            value={sender}
                            onChange={(val, name) => {
                                setSender(val);
                            }}
                        />
                    </FormRow>

                    {sender === 'eezy' && (
                        <FormRow>
                            <FormInput
                                error={
                                    emailIsInvalid ? { email: '' } : undefined
                                }
                                label={trans('invoice.express-q4')}
                                name="sender-email"
                                value={email}
                                onChange={(val, name) => setEmail(val)}
                            />
                        </FormRow>
                    )}

                    {sender === 'me' && (
                        <FormRow>
                            <P>{trans('invoice.express-send-yourself-help')}</P>
                        </FormRow>
                    )}
                </Wrapper>
            </ModalContent>
            <ModalActions>
                <EezyButton color="purple" onClick={props.handleModalClose}>
                    {trans('general.cancel')}
                </EezyButton>
                <EezyButton
                    color="purple"
                    dark
                    onClick={handleSave}
                    disabled={emailIsInvalid || !type || !sender}
                >
                    {trans('general.save')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

export default ExpressModal;
