import { ReactNode } from 'react';
import { COLOR_BLUM } from '../../styles/variables';
import NewMessagesBubble from './NewMessagesBubble';
import IconHome from '../../assets/images/menu/home.svg';
import IconProfile from '../../assets/images/menu/profile.svg';
import IconSalaries from '../../assets/images/menu/salaries.svg';
import IconEezyPay from '../../assets/images/menu/eezy-pay.svg';
import IconYel from '../../assets/images/menu/yel.svg';
import IconSalaryCalculator from '../../assets/images/menu/salary-calculator.svg';
import IconDocuments from '../../assets/images/menu/documents.svg';
import IconCustomerService from '../../assets/images/menu/customer-service.svg';
import IconLogout from '../../assets/images/menu/logout.svg';
import IconUploadReceipt from '../../assets/images/menu/upload-receipt.svg';
import IconCampwire from '../../assets/images/menu/campwire.svg';

export interface INavItem {
    color?: string;
    customContent?: () => ReactNode;
    external?: boolean;
    hiddenDesktop?: boolean;
    hidden?: boolean;
    icon?: string;
    text: string;
    openModal?: string; // modal type to open onClick, read more at ModalOpener.tsx
    path?: string;
    pathEn?: string;
    small?: boolean;
    subtext?: string;
}

const navItems: INavItem[] = [
    { text: 'menu.invoices', path: '/invoices', icon: IconHome },
    {
        text: 'menu.eezyPay',
        path: '/sales-pages',
        openModal: 'EEZY_PAY',
        icon: IconEezyPay,
    },
    {
        openModal: 'CAMPWIRE',
        text: 'menu.campwire',
        icon: IconCampwire,
    },
    { text: 'menu.profile', path: '/profile', icon: IconProfile },
    { text: 'menu.salaries', path: '/salaries', icon: IconSalaries },

    { text: 'menu.yel', path: '/yel', icon: IconYel },
    {
        path: '/salary-calculator',
        text: 'menu.salary-calculator',
        icon: IconSalaryCalculator,
    },
    {
        text: 'menu.documents',
        path: '/profile/documents',
        subtext: 'menu.documents-sub',
        icon: IconDocuments,
    },
    {
        hiddenDesktop: true,
        openModal: 'UPLOAD_FILE',
        text: 'menu.upload-receipt',
        icon: IconUploadReceipt,
    },
    { text: 'underline' },
    {
        customContent: () => NewMessagesBubble({ color: COLOR_BLUM }),
        path: '/support',
        text: 'menu.customerSupport',
        icon: IconCustomerService,
    },
    { path: '/logout', text: 'login.logout', icon: IconLogout },
    { text: 'underline' },
    {
        openModal: 'TERMS',
        small: true,
        text: 'menu.termsOfUse',
    },
    {
        openModal: 'EEZY_PAY_TERMS_READ_ONLY',
        small: true,
        text: 'menu.eezyPayTermsOfUse',
    },
];

export { navItems };
