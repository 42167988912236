import React, { useState } from 'react';
import { IInvoiceItem, IInvoiceItemKeys } from '../../../../../shared/src/types/invoice';
import { EezyButton } from '../../../components/Buttons';
import { Flex } from '../../../components/Flex';
import { DatePicker } from '../../../components/form';
import { FormLabel } from '../../../components/form/FormLabel';
import { Modal, ModalActions, ModalContent } from '../../../components/modals/Modal';
import { formatDate, formatDateISO, trans, xNotAfterYInDays } from '../../../utils';

interface IDatesModal {
    handleModalClose: () => void;
    handleSave: (o: any) => void;
    isOpen: boolean;
    startDate?: string;
    endDate?: string;
}

const InvoiceRowDatesModal = (props: IDatesModal) => {
    const [startDate, setStartDate] = useState(props.startDate ? new Date(props.startDate) : null);
    const [endDate, setEndDate] = useState(props.endDate ? new Date(props.endDate) : null);

    const handleClose = () => {
        props.handleModalClose();
    };

    const handleSave = () => {
        props.handleSave({
            endDate: endDate ? formatDateISO(endDate) : null,
            startDate: startDate ? formatDateISO(startDate) : null,
        });
        handleClose();
    };

    const onStartDateChange = (date: Date | null) => {
        setStartDate(date);
    };

    const onEndDateChange = (date: Date | null) => {
        if (!date || (date && (!startDate || xNotAfterYInDays(startDate, date)))) {
            setEndDate(date);
        }
    };

    const datePickerProps = {
        disableToolbar: true,
        popoverProps: {
            anchorOrigin: {
                horizontal: -80,
                vertical: -70,
            },
        },
        useCustomIcon: true,
    };

    return (
        <Modal
            id="invoice-row-dates"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={trans('invoice.tableColumns.time')}
        >
            <ModalContent style={{ minHeight: '250px' }}>
                <Flex center spread style={{ paddingBottom: 30 }}>
                    <FormLabel htmlFor="invoice-row-startDate">{trans('dates.select-startDate')}</FormLabel>

                    <DatePicker
                        {...datePickerProps}
                        id={'invoice-row-startDate'}
                        label={trans('dates.select-startDate')}
                        maxDate={endDate ? new Date(endDate) : undefined}
                        onChange={onStartDateChange}
                        placeholder={trans('dates.select-startDate')}
                        value={startDate || undefined}
                    />
                </Flex>

                <Flex center spread>
                    <FormLabel htmlFor="invoice-row-endDate">{trans('dates.select-endDate')}</FormLabel>

                    <DatePicker
                        {...datePickerProps}
                        id={'invoice-row-endDate'}
                        label={trans('dates.select-endDate')}
                        onChange={onEndDateChange}
                        minDate={startDate ? new Date(startDate) : undefined}
                        placeholder={trans('dates.select-endDate')}
                        value={endDate || undefined}
                    />
                </Flex>
            </ModalContent>
            <ModalActions style={{ height: '90px' }}>
                <EezyButton color="purple" onClick={handleClose}>
                    {trans('general.cancel')}
                </EezyButton>
                <EezyButton color="purple" dark onClick={handleSave}>
                    {trans('general.save')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

const InvoiceDates = ({
    editable,
    error,
    handleUpdate,
    item,
}: {
    editable: boolean;
    error?: any;
    handleUpdate?: (changedProperty: IInvoiceItemKeys) => void;
    item: IInvoiceItem;
}) => {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
            {editable ? (
                <>
                    <span className={error ? 'hasError' : 'light'}>
                        {item.startDate && formatDate(item.startDate)}
                        {item.endDate &&
                            item.endDate !== item.startDate &&
                            `\u00A0- ${formatDate(item.endDate)}`}
                    </span>
                    {modalOpen && (
                        <InvoiceRowDatesModal
                            endDate={item.endDate}
                            startDate={item.startDate}
                            handleModalClose={() => {
                                setModalOpen(false);
                            }}
                            handleSave={handleUpdate!}
                            isOpen={modalOpen}
                        />
                    )}
                </>
            ) : (
                <span className="light">
                    {item.startDate && formatDate(item.startDate)}
                    {item.endDate && item.endDate !== item.startDate && `\u00A0- ${formatDate(item.endDate)}`}
                </span>
            )}
        </>
    );
};
export default InvoiceDates;
