import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IAddress } from '../../../shared/src/types/address';
import { IUserData, IWithholdingTax, UiLanguage } from '../../../shared/src/types/user';
import API from '../utils/API';
import {
    FETCH_USER_BEGIN,
    FETCH_USER_SUCCESS,
    FETCH_WITHHOLDING_TAX_SUCCESS,
    USER_ASYNC_FAILURE,
    USER_RESET,
    USER_SET_COMPANY_NAME,
    USER_SET_STATE,
    YEL_SELF_REPORTED,
    USER_SET_TWO_FACTOR_AUTHENTICATION,
    SAVE_CAR,
} from 'actionTypes';
import { changeLanguage } from 'utils';
import { generalWholePageError } from './auth';

const userPath = '/user';

export const fetchWithholdingTaxSuccess = (userWithholdingTax: IWithholdingTax) => ({
    payload: userWithholdingTax,
    type: FETCH_WITHHOLDING_TAX_SUCCESS,
});

export const fetchUser = () => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(fetchUserBegin());
    await API.get(userPath)
        .then((response) => {
            changeLanguage(response.data.language);
            dispatch(fetchUserSuccess(response.data));
        })
        .catch((err) => {
            dispatch(finishLoading());
            if (err?.response && err?.response.status !== 401) {
                dispatch(generalWholePageError());
            }
        });
};

export const changeUiLang =
    (lang: UiLanguage) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) => {
        changeLanguage(lang);
        dispatch(setUserLanguage(lang));
        if (getState().auth.loggedIn) {
            const langChangePath = `${userPath}/`;
            await API.put(langChangePath, { language: lang })
                .then((response) => response)
                .catch((err) => err);
        }
    };

const finishLoading = () => ({
    payload: { loading: false },
    type: USER_SET_STATE,
});

export const setUserLanguage = (language: UiLanguage) => ({
    payload: { language },
    type: USER_SET_STATE,
});

export const setUserAddress = (mailingAddress: IAddress) => ({
    payload: { mailingAddress },
    type: USER_SET_STATE,
});

export const fetchUserBegin = () => ({
    type: FETCH_USER_BEGIN,
});

export const fetchUserSuccess = (data: IUserData) => ({
    payload: data,
    type: FETCH_USER_SUCCESS,
});

export const setUserCompanyName = (data: string) => ({
    payload: data,
    type: USER_SET_COMPANY_NAME,
});

export const userAsyncFailure = (error: string) => ({
    payload: error,
    type: USER_ASYNC_FAILURE,
});

export const resetUser = () => ({
    type: USER_RESET,
});

export const reportYelToStore = (yelSelfReported: boolean, yelShouldAsk: boolean) => ({
    payload: { yelSelfReported, yelShouldAsk },
    type: YEL_SELF_REPORTED,
});

export const setTwoFactorAuthentication = (input: string) => ({
    payload: input,
    type: USER_SET_TWO_FACTOR_AUTHENTICATION,
});

export const saveCar = (payload: string) => ({
    payload,
    type: SAVE_CAR,
});
