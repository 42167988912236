import React from 'react';
import { INotification } from '../../../../shared/src/types/notifications';
import { getNotification } from '../../utils/notifications/notifications';
import { Notification } from './Notification';
import { NotificationEezyBonus } from './NotificationEezyBonus';

const IMPLEMENTED_NOTIFICATION_TYPES = [
    'alert',
    'bulletin',
    'coworker',
    'expense',
    'eezyBonus'
];

interface IProps {
    notification: INotification;
    onOpen: () => void;
    onArchive?: () => void;
}

export const NotificationDynamic = (props: IProps) => {
    const { notification } = props;

    if (!IMPLEMENTED_NOTIFICATION_TYPES.find(t => t === notification.type)) {
        return null;
    }

    // Custom notifications
    if (notification.type === 'eezyBonus') {
        return (
            <NotificationEezyBonus
                bonus={notification.actionData?.sum || 0}
                key={`notification-${notification.id}`}
            />
        );
    }

    return (
        <Notification
            key={`notification-${notification.id}`}
            {...getNotification(notification)}
            buttonColor='purple'
            onOpen={props.onOpen}
            onArchive={props.onArchive}
        />
    );
};
