import moment, { Moment } from 'moment';
import * as R from 'ramda';

import { toInt } from '../common';
import { ISearchMatch, noMatch } from './search';

export const onlyDateRegex = /^[0-3]?[0-9]\.[0-1]?[0-9]\.$/;
/**
 *
 * @param term
 * @param options options.today should have current date/time as a Moment
 */
export const dateTransformer = (
    term: string,
    options: any
): string | undefined => {
    // Year match
    if (term.length === 4 && toInt(term) > 2000 && toInt(term) < 2100) {
        return term;
    }

    const today = options?.today;

    // Full date and year match (also matches day.month.)
    const date = moment(term, 'D.M.YYYY');

    // A bit weird that a term matches the pattern with year, even when term does not include the year.
    if (date.isValid()) {
        if (today && onlyDateRegex.test(term)) {
            // If the term is only day.month. then return date with a year that is closest to "today"
            return getClosestDate(term, today);
        }
        return date.format('YYYY-MM-DD');
    }

    // Month name match
    const monthTerm = getMonthTerm(term);
    if (monthTerm) {
        return monthTerm;
    }

    // Should we even return anything if we get here. Is there any benefit for "1-11 08:" in matching a date like '2020-01-11 08:15:15' ?
    return undefined;
};

/**
 *
 * @param termEur
 */
export const priceMatcher = (termEur: string, wordCent: string) => {
    const termInt = toInt(termEur);
    if (termInt === null) {
        return false;
    }
    const wordInt = toInt(wordCent) / 100;
    // Magic number match with 2 eur precision
    const priceMatchPrecision = 2;
    return Math.abs(termInt - wordInt) < priceMatchPrecision;
};

export const recipientIdMatcher = (term: string, fieldValue: string) => {
    return fieldValue.startsWith(term + '-');
};

export const getMonthTerm = (month: string) => {
    switch (month.toLowerCase()) {
        case 'tammikuu':
        case 'january':
        case 'januari':
            return '-01-';

        case 'helmikuu':
        case 'februari':
        case 'february':
            return '-02-';

        case 'maaliskuu':
        case 'mars':
        case 'march':
            return '-03-';
        case 'huhtikuu':
        case 'april':
            return '-04-';

        case 'toukokuu':
        case 'maj':
        case 'may':
            return '-05-';

        case 'kesäkuu':
        case 'juni':
        case 'june':
            return '-06-';

        case 'heinäkuu':
        case 'juli':
        case 'july':
            return '-07-';

        case 'elokuu':
        case 'augusti':
        case 'august':
            return '-08-';

        case 'syyskuu':
        case 'september':
            return '-09-';

        case 'lokakuu':
        case 'oktober':
        case 'october':
            return '-10-';

        case 'marraskuu':
        case 'november':
            return '-11-';

        case 'joulukuu':
        case 'december':
            return '-12-';
        default:
            return undefined;
    }
};

export const getMaxIfAllMatch = (scores: ISearchMatch[]): ISearchMatch => {
    const allMatch = R.none(s => s.score === 0, scores);
    if (!allMatch) {
        return noMatch('N/A');
    }

    return getBestMatch(scores);
};

export const getClosestDate = (dateStr: string, nowDate: Moment) => {
    const momentA = moment(moment(dateStr, 'D.M.')).year(nowDate.year());
    const momentB = moment(momentA).subtract(1, 'year');

    if (Math.abs(nowDate.diff(momentA)) < Math.abs(nowDate.diff(momentB))) {
        return momentA.format('YYYY-MM-DD');
    } else {
        return momentB.format('YYYY-MM-DD');
    }
};

export const normalizeSpaces = (str: string): string => {
    return str.replace(/ +/g, ' ').trim();
};

interface ISumMathes {
    [key: string]: ISearchMatch;
}
export const getBestMatch = (matches: ISearchMatch[]): ISearchMatch => {
    // Sum all matches for each field
    const fieldSumMatches: ISumMathes = matches.reduce(
        (res: ISumMathes, currentMatch: ISearchMatch) => {
            if (res[currentMatch.field]) {
                res[currentMatch.field].score += currentMatch.score;
                return res;
            } else {
                res[currentMatch.field] = { ...currentMatch };
                return res;
            }
        },
        {}
    );
    return Object.values(fieldSumMatches).reduce((res, current) => {
        return current.score > res.score ? current : res;
    }, noMatch('N/A'));
};

export const getMatchSum = (matches: ISearchMatch[]): ISearchMatch => {
    const sum = matches.reduce((res, current) => {
        return res + current.score;
    }, 0);
    const bestMatch = matches.reduce((res, current) => {
        return current.score > res.score ? current : res;
    }, noMatch('N/A'));
    return { ...bestMatch, score: sum };
};
