import { Hidden } from '@mui/material';
import React, { CSSProperties } from 'react';
import ColorBox from '../../components/ColorBox';
import { Flex } from '../../components/Flex';
import { Icon } from '../../components/Icon';
import { BodyP, LabelStrong } from '../../components/textElements';
import {
    COLOR_BLACKWATER,
    COLOR_BLUM,
    COLOR_LILA
} from '../../styles/variables';
import { trans } from '../../utils';

interface IProps {
    style?: CSSProperties;
}

const SupportClosed = (props: IProps) => {
    const Note = () => {
        return (
            <Flex column>
                <LabelStrong color={COLOR_BLACKWATER}>
                    {trans('support:contact.closed-title')}
                </LabelStrong>
                <BodyP color={COLOR_BLACKWATER}>
                    {trans('support:contact.closed-text')}
                </BodyP>
            </Flex>
        );
    };
    return (
        <ColorBox
            color={COLOR_LILA}
            disableClose
            style={{ padding: 24, ...props.style }}
        >
            <Hidden mdDown>
                <Flex>
                    <Icon
                        icon={['far', 'frown']}
                        color={COLOR_BLUM}
                        className="big"
                        style={{ marginRight: 24, marginTop: 12 }}
                    />

                    <Note />
                </Flex>
            </Hidden>

            <Hidden mdUp>
                <Note />
            </Hidden>
        </ColorBox>
    );
};

export default SupportClosed;
