import { havingYel } from 'utils/yel';
import { create } from 'zustand';
import { YelPayerType } from '../../../shared/src/types/yel';

export type YelStoreData = {
    dateStarted: string;
    hasSameIncomeAsBefore: boolean | null;
    hasYel: havingYel.HasYELType;
    isStarting: boolean | null; // true if starting from this day, false if started earlier
    numberOfQuotas: number;
    termsAccepted: boolean;
    whoPays: YelPayerType;
    estimatedIncome: number;
    estimatedInvoicing: number;
    estimatedIncomeDate: string;
    occupation: string;
    insuranceCompany: string;
    // Flags to display a message after an action
    justAuthorized?: boolean;
    justTerminated?: boolean;
    justUpdated?: boolean;
    justWithdrew?: boolean;
    // Whether to show specific views or not
    showSummary?: boolean;
    showTerminate?: boolean;
    showWithdrawMoney?: boolean;
};

interface YelState {
    yelData: YelStoreData;
    updateYelStore: (data: Partial<YelStoreData>) => void;
}

export const initialState: YelStoreData = {
    dateStarted: '',
    hasSameIncomeAsBefore: null,
    hasYel: 'Undefined',
    isStarting: null,
    numberOfQuotas: 2,
    termsAccepted: false,
    estimatedIncome: 0,
    estimatedInvoicing: 0,
    estimatedIncomeDate: '',
    occupation: '',
    whoPays: 'eezy',
    insuranceCompany: '',
};

const useYelStore = create<YelState>()((set) => ({
    yelData: initialState,
    updateYelStore: (data: Partial<YelStoreData>) =>
        set((state) => ({ yelData: { ...state.yelData, ...data } })),
}));

export default useYelStore;
