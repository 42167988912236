import { ApolloQueryResult } from '@apollo/client';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { IInvoice } from '../../../../shared/src/types/invoice';
import {
    markAttachmentAsUsed,
    uploadInvoiceAttachment
} from '../../actions/file';
import FileInput from '../../components/form/FileInput';
import { Icon } from '../../components/Icon';
import LoadingSpinner from '../../components/Loading';
import { P } from '../../components/textElements';
import { Tools, ToolsHeader } from '../../components/TogglingTools';
import { ITempFile } from '../../reducers/fileReducer';
import {
    COLOR_BLACKWATER,
    COLOR_IMPORTANT,
    COLOR_WHITE_WALKER
} from '../../styles/variables';
import { trans } from '../../utils';

interface IProps {
    editable: boolean;
    fileLoading: boolean;
    attachmentAsUsed: (id: number) => void;
    justAddedInvoiceAttachment: ITempFile;
    uploadInvoiceAttachment: (data: FormData) => void;
    refetchInvoice?: () => Promise<ApolloQueryResult<any>>;
    invoice?: IInvoice;
}

const InvoiceAttachmentAdd = (props: IProps) => {
    const {
        invoice,
        justAddedInvoiceAttachment,
        refetchInvoice,
        attachmentAsUsed
    } = props;

    const handleUploadAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0 && props.invoice?.id) {
            const newFile = e.target.files[0];
            const uploadData = new FormData();
            uploadData.append('file', newFile);
            uploadData.append('invoiceId', props.invoice?.id?.toString());
            props.uploadInvoiceAttachment(uploadData);
        }
    };

    useEffect(() => {
        if (justAddedInvoiceAttachment && refetchInvoice) {
            refetchInvoice().then(() => {
                attachmentAsUsed(justAddedInvoiceAttachment.id);
            });
        }
    }, [justAddedInvoiceAttachment, refetchInvoice, attachmentAsUsed]);

    return (
        <Tools
            header={
                <ToolsHeader
                    titleTransKey="invoice.attachments-title"
                    disabled={!invoice || !props.editable}
                >
                    <FileInput
                        accept=".pdf"
                        color={
                            props.fileLoading
                                ? COLOR_IMPORTANT
                                : COLOR_WHITE_WALKER
                        }
                        disabled={!invoice || !props.editable}
                        onChange={handleUploadAttachment}
                        style={{
                            borderColor: props.fileLoading
                                ? undefined
                                : COLOR_BLACKWATER,
                            borderRadius: 100,
                            color: COLOR_BLACKWATER,
                            padding: '0 10px'
                        }}
                    >
                        {props.fileLoading ? (
                            <LoadingSpinner color="white" size="1em" />
                        ) : (
                            <Icon icon={['far', 'plus']} color="black" />
                        )}{' '}
                        <span>{trans('form.add')}</span>
                    </FileInput>
                </ToolsHeader>
            }
        >
            <P disabled={!invoice || !props.editable}>
                {trans('invoice.attachments-description')}
            </P>
        </Tools>
    );
};

const mapStateToProps = (state: any) => {
    return {
        fileLoading: state.file.loading,
        justAddedInvoiceAttachment: state.file.invoiceAttachment
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        attachmentAsUsed: (id: number) => {
            dispatch(markAttachmentAsUsed(id));
        },
        uploadInvoiceAttachment: (data: FormData) => {
            dispatch(uploadInvoiceAttachment(data));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceAttachmentAdd);
