import * as React from 'react';
import styled from 'styled-components';
import { MobileRowButton } from '../../components/Buttons';
import { P, PSmall, RowTitle } from '../../components/textElements';
import { COLOR_BLUM, COLOR_GREYHOUND } from '../../styles/variables';
import { trans, changeLanguage } from '../../utils';

interface IProps {
    lang?: 'fi' | 'en';
}

const Section = styled.div`
    margin: 30px 0;

    p {
        color: ${COLOR_GREYHOUND};
        margin-bottom: 5px;
        button {
            color: ${COLOR_BLUM};
            display: inline;
            margin-left: 5px;
        }
    }
    p.info {
        padding-left: 15px;
        &.hidden {
            display: none;
        }
        & {
            margin-bottom: 5px;
        }
        &:last-of-type {
            margin-bottom: 15px;
        }
    }
`;

const TextItem = ({ text, infos }: { text: string; infos: string[] }) => {
    const [visible, setVisible] = React.useState(false);
    return (
        <div>
            <PSmall>
                - {trans(text)}
                <MobileRowButton onClick={() => setVisible(!visible)}>
                    {visible ? trans('dashboard.messages.read-less') : trans('dashboard.messages.read-more')}
                </MobileRowButton>
            </PSmall>
            {infos.map((item: string, i) => {
                return (
                    <PSmall key={`info-${i}`} className={'info' + (visible ? '' : ' hidden')}>
                        {trans(item)}
                    </PSmall>
                );
            })}
        </div>
    );
};

const RestrictedWorks = (props: IProps) => {
    if (props.lang) {
        changeLanguage(props.lang);
    }

    return (
        <>
            <Section>
                <RowTitle color="black">
                    {trans('dashboard.messages.restricted-work.non-invoiceable-work-title')}
                </RowTitle>
                <TextItem
                    text="dashboard.messages.restricted-work.1"
                    infos={['dashboard.messages.restricted-work.1-info']}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.2"
                    infos={['dashboard.messages.restricted-work.2-info']}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.3"
                    infos={['dashboard.messages.restricted-work.3-info']}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.4"
                    infos={['dashboard.messages.restricted-work.4-info']}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.5"
                    infos={['dashboard.messages.restricted-work.5-info']}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.6"
                    infos={['dashboard.messages.restricted-work.6-info']}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.7"
                    infos={['dashboard.messages.restricted-work.7-info']}
                />
            </Section>

            <Section>
                <P>
                    {trans('dashboard.messages.restricted-work.contact')}
                    <a href="mailto:info.kevytyrittajat@eezy.fi">info.kevytyrittajat@eezy.fi</a>.
                </P>
            </Section>

            <Section>
                <RowTitle color="black">
                    {trans('dashboard.messages.restricted-work.invoiceable-work-title')}
                </RowTitle>
                <P>{trans('dashboard.messages.restricted-work.restriction-info')}</P>
                <TextItem
                    text="dashboard.messages.restricted-work.8"
                    infos={[
                        'dashboard.messages.restricted-work.8-info',
                        'dashboard.messages.restricted-work.8-info2',
                        'dashboard.messages.restricted-work.8-info3',
                        'dashboard.messages.restricted-work.8-info4'
                    ]}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.9"
                    infos={['dashboard.messages.restricted-work.9-info']}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.10"
                    infos={[
                        'dashboard.messages.restricted-work.10-info',
                        'dashboard.messages.restricted-work.10-info2'
                    ]}
                />
            </Section>

            <Section>
                <P>
                    {trans('dashboard.messages.restricted-work.end-note')}
                    <a href="mailto:info.kevytyrittajat@eezy.fi">info.kevytyrittajat@eezy.fi</a>.
                </P>
            </Section>
        </>
    );
};

export default RestrictedWorks;
