import React from 'react';

const EezyPayTermsFi = () => {
    return (
        <div>
            <div>
                <p>
                    <b>Sisällys</b>
                </p>
                <ul>
                    <li>
                        <a href="#point1">1. Määritelmät </a>
                    </li>
                    <li>
                        <a href="#point2">2. Yleistä palvelun käyttämisestä</a>
                    </li>
                    <li>
                        <a href="#point3">
                            3. Palveluntarjoaja ja yhteystiedot
                        </a>
                    </li>
                    <li>
                        <a href="#point4">4. Palvelun käyttäminen</a>
                    </li>
                    <ul>
                        <li>
                            <a href="#point4.1">
                                4.1 Palvelun käyttämistä koskevat oikeudet ja
                                rajoitukset
                            </a>
                        </li>
                        <li>
                            <a href="#point4.2">
                                4.2 Palvelun muutokset ja irtisanominen
                            </a>
                        </li>
                        <li>
                            <a href="#point4.3">4.3 Rekisteröityminen</a>
                        </li>
                        <li>
                            <a href="#point4.4">4.4 Laskutus ja palkanmaksu</a>
                        </li>
                        <li>
                            <a href="#point4.5">4.5 Palvelun hinta</a>
                        </li>
                        <li>
                            <a href="#point4.6">4.6 Maksaminen</a>
                        </li>
                        <li>
                            <a href="#point4.7">4.7 Maksutavat</a>
                        </li>
                    </ul>
                    <li>
                        <a href="#point5">5. Vastuunrajoitukset</a>
                    </li>
                    <li>
                        <a href="#point6">
                            6. Ehtojen muutokset ja ilmoitukset
                        </a>
                    </li>
                    <li>
                        <a href="#point7">7. Ylivoimainen este</a>
                    </li>
                    <li>
                        <a href="#point8">8. Muut ehdot</a>
                    </li>
                </ul>
            </div>

            <div id="point1">
                <p>
                    <b>1. Määritelmät</b>
                </p>
                <div className="p1">
                    <p>
                        Näissä ehdoissa seuraavat sanat ja ilmaisut tarkoittavat
                        seuraavia asioita, ellei muuta nimenomaisesta todeta tai
                        asiayhteydestä muuta ilmene:
                    </p>
                    <ul>
                        <li>
                            <p>
                                1. ”Eezy” Tarkoittaa Eezy Kevytyrittäjät Oy:aa
                            </p>
                        </li>
                        <li>
                            <p>2. ”Palvelu” tarkoittaa EezyPay-palvelua</p>
                        </li>
                        <li>
                            <p>
                                3. ”Työnsuorittaja” tarkoittaa Eezyn palvelua
                                käyttävää kevytyrittäjää, joka käyttää
                                EezyPay-palvelua maksujen vastaanottamiseen
                                Toimeksiantajiltaan
                            </p>
                        </li>
                        <li>
                            <p>
                                4. ”Toimeksiantaja” tarkoittaa Työnsuorittajan
                                asiakasta, joka on tilannut palvelun
                                Työnsuorittajalta ja maksaa sen Palvelun kautta
                            </p>
                        </li>
                        <li>
                            <p>
                                ”Ehdot” tarkoittaa Eezy Kevytyrittäjät / EezyPay
                                -palvelun käyttöehtoja
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div id="point2">
                <p>
                    <b>2. Yleistä palvelun käyttämisestä</b>
                </p>
                <div className="p1">
                    <p>
                        Käyttämällä Eezyn Palvelua työnsuorittaja hyväksyy
                        kulloinkin voimassa olevat EezyPay -palvelun käyttöehdot
                        ja sitoutuu noudattamaan niitä sekä Eezy Kevytyrittäjät
                        Palvelun käyttöehtoja ja Palvelussa erikseen annettavia
                        ehtoja ja ohjeita. Työnsuorittajan tulee hyväksyä nämä
                        Ehdot rekisteröitymisen yhteydessä. Työnsuorittaja
                        sitoutuu käyttämään Palvelua ainoastaan siihen
                        tarkoitukseen, johon Palvelu on tarkoitettu.
                    </p>
                    <p>
                        Nämä Ehdot soveltuvat kaikkiin Työnsuorittajan toimiin
                        Palvelussa työnsuorittajan käyttäessä EezyPay-palvelua.
                        Työnsuorittajan on sallittua käyttää Palvelua ainoastaan
                        kulloinkin voimassa olevien Ehtojen ja soveltuvan
                        lainsäädännön sekä hyvän tavan mukaisesti. Ehdot
                        muodostavat sopimuksen Työnsuorittajan ja Eezyn välillä,
                        ja määrittelevät Työnsuorittajan ja Eezyn Palvelua
                        koskevat oikeudet ja velvollisuudet.
                    </p>
                    <p>
                        Palvelun käyttäminen lainvastaisiin, epäasiallisiin tai
                        kilpailullisiin tarkoituksiin on kiellettyä. Eezy varaa
                        oikeuden puuttua havaitsemiinsa oikeudenloukkauksiin.
                    </p>
                </div>
            </div>

            <div id="point3">
                <p>
                    <b>3. Palveluntarjoaja ja yhteystiedot</b>
                </p>
                <div className="p1">
                    <p>Eezy Kevytyrittäjät Oy</p>
                    <p>Y-tunnus: 2197002-2</p>
                    <p>Maistraatinportti 1</p>
                    <p>00240 Helsinki</p>
                    <p>puh. 09 4247 5630</p>
                    <p>info.kevytyrittajat@eezy.fi</p>
                </div>
            </div>

            <div id="point4">
                <p>
                    <b>4. Palvelun käyttäminen</b>
                </p>
                <div className="p1" id="point4.1">
                    <p>
                        <b>
                            4.1 Palvelun käyttämistä koskevat oikeudet ja
                            rajoitukset
                        </b>
                    </p>
                    <div className="p1">
                        <p>
                            Eezy myöntää Työnsuorittajalle näissä Ehdoissa
                            määritellyn rajoitetun, peruutettavissa olevan,
                            ei-yksinomaisen ja henkilökohtaisen oikeuden käyttää
                            Palvelua ja sen sisältöä ainoastaan näiden Ehtojen
                            ja Palvelussa mahdollisesti erikseen annettavien
                            ehtojen ja ohjeiden mukaisesti. Työnsuorittajalla on
                            oikeus käyttää Palvelua ja sen sisältöä ainoastaan
                            Työnsuorittajan omaan käyttöön.
                        </p>
                        <p>
                            Työnsuorittajan tulee toiminnassaan noudattaa
                            Suomessa voimassa olevaa lainsäädäntöä ja
                            viranomaisohjeistuksia. Työnsuorittajan on
                            noudatettava toimintaansa mahdollisesti soveltuvia
                            pakottavia lain määräyksiä kuten
                            työturvallisuusmääräyksiä.
                        </p>
                        <p>
                            Työnsuorittaja ei saa käyttää Palvelua (a)
                            esiintyäkseen Eezyn edustajana; (b) otsikko- tai
                            tunnistetietojen väärentämiseen tai käsittelemiseen
                            tavalla, jolla pyritään peittämään Palvelun kautta
                            välitetyn sisällön alkuperä; (c) muunlaiseen väärien
                            tietojen antamiseen; (d) muiden käyttäjien
                            henkilötietojen tallentamiseen tai keräämiseen; (e)
                            roskapostin tai muun luvattoman tietoliikenteen
                            välittämiseen tai (f) muihin lain tai hyvän tavan
                            vastaisiin toimiin.
                        </p>
                        <p>
                            Palvelun käyttäminen ei ole sallittua rajoitetuilla
                            aloilla. Ajantasainen listaus rajoitetuista
                            toimialoista löytyy osoitteesta:{' '}
                            <a
                                href="https://kevytyrittajat.eezy.fi/rajoitetut-tyot/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://kevytyrittajat.eezy.fi/rajoitetut-tyot/
                            </a>
                        </p>
                        <p>
                            Palvelua käyttävä Työnsuorittaja vastaa maksusivun
                            sisällöstä ja siitä, että maksusivu noudattaa
                            markkinoinnin hyvää tapaa. Maksusivun sisältö ei saa
                            olla hyvän tavan tai hyvän mainontatavan vastainen.
                            Työnsuorittaja vastaa siitä, että ei käytä hyvän
                            tavan vastaista menettelyä käyttäessään Palvelua.
                        </p>
                        <p>
                            Palvelun kautta on sallittua vastaanottaa maksuja
                            ainoastaan Suomessa tehdystä työstä.
                        </p>
                    </div>
                </div>
                <div className="p1" id="point4.2">
                    <p>
                        <b>4.2 Palvelun muutokset ja irtisanominen</b>
                    </p>
                    <div className="p1">
                        <p>
                            Palvelu sisältöineen tarjotaan ”sellaisena kuin se
                            on”. Eezy on oikeutettu milloin tahansa muokkaamaan
                            tai muuttamaan Palvelua tai sen sisältöä ilman
                            korvausvelvollisuutta.
                        </p>
                        <p>
                            Eezyllä on siten oikeus mm. muuttaa tai muokata
                            Palvelussa annettua informaatiota tai siellä
                            tarjottavia tuotteita tai hintoja, lisätä ja poistaa
                            osia Palvelusta sekä muuttaa Palvelun maksuttomia
                            osioita maksullisiksi tai päinvastoin.
                        </p>
                        <p>
                            Eezy ilmoittaa Palvelussa tapahtuvista olennaisista
                            mm. hinnoitteluun liittyvistä muutoksista
                            Työnsuorittajalle 14 vuorokautta ennen muutosten
                            voimaantuloa. Ilmoitusaika ei koske lakimuutoksista
                            tai viranomaisohjeistuksista johtuvia pakollisia
                            muutoksia.
                        </p>
                        <p>
                            Eezyllä on myös oikeus milloin tahansa keskeyttää
                            tai lopettaa Palvelu, sen sisällön tai minkä tahansa
                            osion tarjoaminen kokonaan taikka rajoittaa Palvelun
                            tai sen osioiden käyttämistä.
                        </p>
                        <p>
                            Jos Työnsuorittaja on toiminut näiden Ehtojen,
                            Palvelussa erikseen annettujen ehtojen tai ohjeiden
                            taikka lakien tai hyvien tapojen vastaisesti,
                            Eezyllä on oikeus estää Työnsuorittajan pääsy
                            Palveluun ja kieltää Palvelun käyttäminen jatkossa.
                        </p>
                    </div>
                </div>
                <div className="p1" id="point4.3">
                    <p>
                        <b>4.3 Rekisteröityminen</b>
                    </p>
                    <div className="p1">
                        <p>
                            Edellä kuvatun käyttöoikeuden saaminen edellyttää
                            Työnsuorittajan rekisteröitymistä Palveluun Eezy
                            Kevytyrittäjät käyttöehtojen mukaisesti.
                            Työnsuorittaja on vastuussa omien tietojensa
                            ajantasaisuudesta, salasanan huolellisesta
                            säilyttämisestä ja salassapidosta ja vastaa kaikesta
                            käyttäjätunnuksillaan tapahtuvasta asioinnista
                            Palvelussa.
                        </p>
                    </div>
                </div>
                <div className="p1" id="point4.4">
                    <p>
                        <b>4.4 Laskutus ja palkanmaksu</b>
                    </p>
                    <div className="p1">
                        <p>
                            Työnsuorittajan palkanmaksu perustuu Työnsuorittajan
                            Eezyn kautta laskuttamaan tai EezyPay palvelun
                            kautta maksettuun arvonlisäverottomaan
                            veloitussummaan. Palkanmaksuprosessi on kuvattuna
                            tarkemmin Eezy Kevytyrittäjien Palvelun
                            käyttöehdoissa
                        </p>
                        <p>
                            Työnsuorittaja vastaa kaikista Palveluunsa antamien
                            tietojen oikeellisuudesta mm. maksusivua luodessa ja
                            kulukorvauksia hakiessa.
                        </p>
                        <p>
                            Eezy pidättää oikeuden olla maksamatta palkkaa
                            veloituksista / työsuorituksista, jotka ovat lain
                            tai Palvelun käyttöehtojen vastaisia.
                        </p>
                        <p>
                            Eezyllä on oikeus poistaa Työnsuorittajan
                            maksusivut, joiden kautta ei ole tullut
                            maksusuorituksia 1 vuoteen.
                        </p>
                        <p>
                            Työnsuorittajan on määriteltävä oman työnsä hinta
                            siten, että siitä määräytyvä bruttopalkka on
                            mahdollisten soveltuvien määräysten mukainen ja
                            vastaa samalla alalla toimivien yrittäjien yleistä
                            ansiotasoa.
                        </p>
                        <p>
                            Mikäli Eezyllä on saatavia Työnsuorittajalta, on
                            Eezyllä oikeus kuitata ne palkanmaksun yhteydessä
                            Työnsuorittajalle maksettavasta nettopalkasta.
                        </p>
                    </div>
                </div>
                <div className="p1" id="point4.5">
                    <p>
                        <b>4.5 Palvelun hinta</b>
                    </p>
                    <div className="p1">
                        <p>
                            Palvelun hinta määräytyy Eezyn kulloinkin voimassa
                            olevan hinnaston mukaan. Ajantasainen hinnasto
                            löytyy osoitteesta:{' '}
                            <a
                                href="https://kevytyrittajat.eezy.fi/hinnasto/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://kevytyrittajat.eezy.fi/hinnasto/
                            </a>
                            . Eezy pidättää oikeuden hinnanmuutoksiin.
                        </p>
                    </div>
                </div>
                <div className="p1" id="point4.6">
                    <p>
                        <b>4.6 Maksaminen</b>
                    </p>
                    <div className="p1">
                        <p>
                            Maksuvälittäjänä toimii Vismapay, joka on
                            rekisteröity Finanssivalvonnan ylläpitämään
                            maksulaitosrekisteriin. Maksamiseen siirrytään
                            Vismapay-verkkopalvelun kautta. EezyPay välittää
                            maksut Työnsuorittajalle. Maksaminen on turvallista,
                            sillä kaikki maksutapahtumaa koskevat tiedot
                            välitetään salattua yhteyttä käyttäen niin ettei
                            kukaan ulkopuolinen taho näe maksutapahtuman
                            tietoja.
                        </p>
                    </div>
                </div>
                <div className="p1" id="point4.7">
                    <p>
                        <b>4.7 Maksutavat</b>
                    </p>
                    <div className="p1">
                        <p>
                            Käytettävissä ovat yleisimmät VismaPay:n tukemat
                            maksutavat.
                        </p>
                    </div>
                </div>
            </div>

            <div id="point5">
                <p>
                    <b>5. Vastuunrajoitukset</b>
                </p>
                <div className="p1">
                    <p>
                        Työnsuorittajan on sovittava mahdolliset työsuoritustaan
                        koskevat erimielisyydet suoraan Toimeksiantajansa
                        kanssa.
                    </p>
                    <p>
                        Eezy vastaa kaikissa olosuhteissa vain omasta
                        toiminnastaan ja vain näiden ehtojen mukaisesti
                        sovitusta Palvelusta. Eezy ei ole vastuussa mistään
                        suorista, epäsuorista, välittömistä tai välillisistä
                        vahingoista tai erityisvahingoista, mukaan lukien
                        liiketoiminnan, sopimuksen, tuoton tai tietojen
                        menettämisestä tai liiketoiminnan keskeytymisestä
                        aiheutuvat vahingot, jotka johtuvat Työnsuorittajan
                        Eezyn kautta veloitetusta työstä taikka näistä Ehdoista,
                        riippumatta siitä, perustuvatko vahingot sopimukseen,
                        oikeudenloukkaukseen, huolimattomuuteen tai muuhun
                        perusteeseen, vaikka Eezylle olisi etukäteen ilmoitettu
                        tällaisten vahinkojen mahdollisuudesta. Kaikissa
                        tapauksissa Eezyn vastuu rajoittuu aina korkeintaan
                        pakottavan lainsäädännön mukaiseen vastuuseen.
                    </p>
                    <p>
                        Työnsuorittaja vapauttaa Eezyn ja kaikki sen
                        työntekijät, johtajat ja sen lukuun toimivat vastuusta
                        kaikkien sellaisten kanteiden ja vaateiden osalta, jotka
                        aiheutuvat Työnsuorittajan toimeksiannoista ja Palvelun
                        käytöstä.
                    </p>
                    <p>
                        Toimeksiantajan tulee esittää kaikkia Palvelun kautta
                        veloittamia palveluita/työsuorituksia koskevat
                        reklamaatiot ja tiedustelut (kuten peruutukset,
                        aikataulumuutokset, puutteet toimeksiannossa, työn
                        suorituksessa tai laadussa yms.) suoraan
                        Työnsuorittajalle.
                    </p>
                    <p>
                        Mikäli Toimeksiantaja on maksanut tilatun työsuorituksen
                        etänä Palvelun kautta voi Toimeksiantaja olla tietyissä
                        tilanteissa oikeutettu saamaan maksun palautuksen,
                        mikäli Työnsuorittaja ei ole toimittanut työsuoritusta
                        sovitussa ajassa tai jos Toimeksiantaja on muutoin
                        oikeutettu lakisääteiseen tai erikseen solmittuun maksun
                        palautukseen ennen työsuoritusta. Tällöin reklamaation
                        esittäjän tulee olla ensisijaisesti yhteydessä
                        Työnsuorittajaan. Eezy voi palauttaa maksun, mikäli
                        Toimeksiantajalla on oikeus maksun palautukseen ja
                        työsuorituksesta ei ole vielä maksettu palkkaa
                        Työnsuorittajalle.
                    </p>
                    <p>
                        Palvelun käyttöä koskevat tapahtumareklamaatiot tulee
                        esittää Eezylle 14 päivän sisällä tapahtuman
                        havaitsemisesta.
                    </p>
                </div>
            </div>

            <div id="point6">
                <p>
                    <b>6. Ehtojen muutokset ja ilmoitukset</b>
                </p>
                <div className="p1">
                    <p>
                        Eezy pidättää oikeuden muuttaa näitä Ehtoja harkintansa
                        mukaan ilmoittamalla siitä Työnsuorittajalle
                        sähköpostitse tai Palvelun välityksellä. Ehtojen
                        muutokset tulevat voimaan, kun niistä on ilmoitettu.
                        Palvelun käyttämistä ilmoitushetken jälkeen pidetään
                        ilmoituksena siitä, että Työnsuorittaja hyväksyy
                        Ehtoihin tehdyt muutokset.
                    </p>
                </div>
            </div>

            <div id="point7">
                <p>
                    <b>7. Ylivoimainen este</b>
                </p>
                <div className="p1">
                    <p>
                        Eezy ei vastaa Palvelun viivästyksistä ja vahingoista,
                        jotka johtuvat Eezyn vaikutusmahdollisuuksien
                        ulkopuolella olevasta esteestä, jota Eezy ei kohtuudella
                        voida edellyttää ottaneen huomioon
                        sopimuksentekohetkellä ja jonka seurauksia
                        Palveluntarjoaja ei myöskään kohtuudella olisi voinut
                        välttää tai voittaa. Ylivoimaisena esteenä pidetään
                        muiden seikkojen ohella Palvelusta riippumatonta
                        häiriötä tietoliikenneyhteyksissä tai muussa sähköisessä
                        viestinnässä sekä tulipalon tai muun onnettomuuden
                        aiheuttamaa keskeytystä tai viivästystä Palvelun
                        toiminnassa. Lakko, sulku, boikotti ja muu
                        työtaistelutoimenpide katsotaan ylivoimaiseksi esteeksi
                        myös silloin, kun Eezy on itse sen kohteena tai siihen
                        osallisena.
                    </p>
                </div>
            </div>

            <div id="point8">
                <p>
                    <b>8. Muut ehdot</b>
                </p>
                <div className="p1">
                    <p>
                        Muilta osin sovelletaan Eezy Kevytyrittäjät
                        käyttöehtoja, ellei osapuolten välillä ole toisin
                        sovittu.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default EezyPayTermsFi;
