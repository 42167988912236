import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { COLOR_BLUM } from 'styles/variables';

export const Icon = styled(FontAwesomeIcon)`
    color: ${(props: { color?: string }) =>
        props.color ? props.color : COLOR_BLUM};
    && {
        font-size: 20px;
        height: 20px;
        width: 20px;
    }

    &.regular {
        font-size: 25px;
        height: 25px;
        width: 25px;
    }

    &.small {
        font-size: 15px;
        height: 15px;
        width: 15px;
    }

    &.medium {
        font-size: 20px;
        height: 30px;
        width: 30px;
    }

    &.big {
        font-size: 35px;
        height: 35px;
        width: 35px;
    }

    &.xl {
        font-size: 40px;
        height: 40px;
        width: 40px;
    }

    &.xxl {
        font-size: 48px;
        height: 48px;
        width: 48px;
    }
`;
