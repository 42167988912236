import { IconProp } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';
import { CSSProperties } from 'react';

import { daysBetween, trans } from '../';
import { IBriefInvoice, IInvoice } from '../../../../shared/src/types/invoice';
import {
    COLOR_BLACKWATER,
    COLOR_GREYHOUND,
    COLOR_GREYJOY,
    COLOR_IMPORTANT,
    COLOR_STATUS_DONE,
    COLOR_STATUS_WAITING
} from '../../styles/variables';
import { formatDate } from '../time';
import { costInvoiceNotSent, isCreditableStatus } from './invoiceLogic';

export interface IInvoiceBriefStatus {
    bigNumber: {
        amount: number;
        color?: string;
    };
    creditRequested: boolean;
    eezyExpress: boolean;
    exclamation?: boolean;
    dueDateToday?: boolean;
    icon: IconProp;
    iconColor: string;
    reclaimed?: boolean;
    smallNumber?: {
        amount: number;
        color: string;
        lineThrough?: boolean;
    };
    style?: CSSProperties;
    textColor?: string;
    title: string;
    titleLong?: string;
}

//  utils

const formatRejectedExpress = (text: string) => {
    return `${text} - ${trans('invoice.express-rejected')}`;
};

const baseStatus = (
    invoiceBrief: IBriefInvoice
): { creditRequested: boolean; eezyExpress: boolean } => ({
    creditRequested:
        !!invoiceBrief.creditRequested &&
        isCreditableStatus(invoiceBrief.status),
    eezyExpress: invoiceBrief.eezyExpress || false
});

const costInvoiceStatusData = (invoiceBrief: IBriefInvoice) =>
    invoiceBrief.costInvoiceStatus === 'turned_back'
        ? {
              icon: ['fal', 'exclamation-triangle'] as IconProp,
              iconColor: COLOR_IMPORTANT
          }
        : costInvoiceNotSent(invoiceBrief)
        ? {
              exclamation: true
          }
        : {};

export const daysInvoiceLate = (dueDate?: string) => {
    return dueDate ? daysBetween(moment(), dueDate) : null;
};

// status checkers

export const isExpressRejected = (invoiceBrief: IBriefInvoice) =>
    invoiceBrief.eezyExpressStatus === 'rejected';

export const isPartiallyPaid = (invoiceBrief: IBriefInvoice) =>
    invoiceBrief.paidAmount &&
    !invoiceBrief.reimbursedAmount &&
    invoiceBrief.paidAmount > 0 &&
    invoiceBrief.paidAmount !== invoiceBrief.totalWithVat;

export const isDueDateToday = (invoiceBrief: IBriefInvoice) => {
    if (invoiceBrief.dueDate) {
        return moment(invoiceBrief.dueDate).isSame(moment(), 'day');
    } else {
        return false;
    }
};

export const isPartiallyPaidInDebt = (invoiceBrief: IBriefInvoice) =>
    invoiceBrief.inDebtCollection && isPartiallyPaid(invoiceBrief);

export const isPaidAndReimbursed = (invoiceBrief: IBriefInvoice) =>
    invoiceBrief.paidAmount &&
    invoiceBrief.reimbursedAmount &&
    invoiceBrief.paidAmount + invoiceBrief.reimbursedAmount ===
        invoiceBrief.totalWithVat;

// status objects

export const incomplete = (
    invoiceBrief: IBriefInvoice
): IInvoiceBriefStatus => ({
    ...baseStatus(invoiceBrief),
    bigNumber: {
        amount: invoiceBrief.totalWithVat
    },
    icon: ['fal', 'pencil-alt'],
    iconColor: COLOR_GREYJOY,
    title: trans('invoice.statuses.incomplete'),
    ...costInvoiceStatusData(invoiceBrief)
});

export const sendingPending = (
    invoiceBrief: IBriefInvoice
): IInvoiceBriefStatus => ({
    ...baseStatus(invoiceBrief),
    bigNumber: {
        amount: invoiceBrief.totalWithVat
    },
    icon: ['far', 'spinner'],
    iconColor: COLOR_GREYJOY,
    title: trans('invoice.statuses.sendingPending'),
    ...costInvoiceStatusData(invoiceBrief)
});

export const unaccepted = (
    invoiceBrief: IBriefInvoice
): IInvoiceBriefStatus => ({
    ...baseStatus(invoiceBrief),
    bigNumber: {
        amount: invoiceBrief.totalWithVat
    },
    icon: ['fal', 'clock'],
    iconColor: COLOR_STATUS_WAITING,
    title: trans('invoice.statuses.unaccepted'),
    ...costInvoiceStatusData(invoiceBrief)
});

export const waitingForDispatch = (
    invoiceBrief: IBriefInvoice,
    invoice?: IInvoice
): IInvoiceBriefStatus => ({
    ...baseStatus(invoiceBrief),
    bigNumber: {
        amount: invoiceBrief.totalWithVat
    },
    icon: ['fal', 'clock'],
    iconColor: COLOR_STATUS_WAITING,
    title: invoice?.dispatchDate
        ? trans('invoice.statuses.waitingForDispatch', {
              date: formatDate(invoice.dispatchDate)
          })
        : trans('invoice.statuses.waitingForDispatch'),
    ...costInvoiceStatusData(invoiceBrief)
});

export const sendingFailed = (
    invoiceBrief: IBriefInvoice
): IInvoiceBriefStatus => ({
    ...baseStatus(invoiceBrief),
    bigNumber: {
        amount: invoiceBrief.totalWithVat
    },
    icon: ['fal', 'exclamation-triangle'],
    iconColor: COLOR_IMPORTANT,
    textColor: COLOR_IMPORTANT,
    title: trans('invoice.statuses.sending_failed'),
    ...costInvoiceStatusData(invoiceBrief)
});

export const turnedBack = (
    invoiceBrief: IBriefInvoice
): IInvoiceBriefStatus => ({
    ...baseStatus(invoiceBrief),
    bigNumber: {
        amount: invoiceBrief.totalWithVat,
        color: COLOR_IMPORTANT
    },
    icon: ['fal', 'exclamation-triangle'],
    iconColor: COLOR_IMPORTANT,
    textColor: COLOR_IMPORTANT,
    title: trans('invoice.statuses.turned_back'),
    ...costInvoiceStatusData(invoiceBrief)
});

export const completed = (
    invoiceBrief: IBriefInvoice
): IInvoiceBriefStatus => ({
    ...baseStatus(invoiceBrief),
    bigNumber: {
        amount: invoiceBrief.totalWithVat
    },
    icon: ['fal', 'clock'],
    iconColor: COLOR_STATUS_WAITING,
    title: trans('invoice.statuses.completed'),
    titleLong: isExpressRejected(invoiceBrief)
        ? formatRejectedExpress(trans('invoice.statuses.completed'))
        : undefined,
    ...costInvoiceStatusData(invoiceBrief)
});

export const finalityConfirmationSent = (
    invoiceBrief: IBriefInvoice
): IInvoiceBriefStatus => ({
    ...completed(invoiceBrief),
    titleLong: `${trans('invoice.finality-confirmation-send')}`
});

export const inDebtCollectionStatus = (
    invoiceBrief: IBriefInvoice
): IInvoiceBriefStatus => ({
    ...baseStatus(invoiceBrief),
    bigNumber: {
        amount: invoiceBrief.totalWithVat
    },
    icon: ['far', 'user-cowboy'],
    iconColor: COLOR_BLACKWATER,
    title: trans('invoice.statuses.inDebtCollection'),
    titleLong: isExpressRejected(invoiceBrief)
        ? formatRejectedExpress(trans('invoice.statuses.inDebtCollection'))
        : undefined,
    ...costInvoiceStatusData(invoiceBrief)
});

export const overdued = (invoiceBrief: IBriefInvoice): IInvoiceBriefStatus => {
    const daysLate = daysInvoiceLate(invoiceBrief.dueDate);
    const overdueMessage = daysLate
        ? trans('invoice.statuses.overdued-days', {
              days: daysLate
          })
        : trans('invoice.statuses.overdued');
    return {
        ...baseStatus(invoiceBrief),
        bigNumber: {
            amount: invoiceBrief.totalWithVat
        },
        icon: ['fal', 'clock'],
        iconColor: COLOR_IMPORTANT,
        title: trans('invoice.statuses.overdued'),
        titleLong: isExpressRejected(invoiceBrief)
            ? formatRejectedExpress(overdueMessage)
            : overdueMessage,
        ...costInvoiceStatusData(invoiceBrief)
    };
};

export const dueDateToday = (
    invoiceBrief: IBriefInvoice
): IInvoiceBriefStatus => {
    return {
        ...baseStatus(invoiceBrief),
        bigNumber: {
            amount: invoiceBrief.totalWithVat
        },
        icon: ['far', 'calendar-alt'],
        dueDateToday: true,
        iconColor: COLOR_STATUS_WAITING,
        title: trans('invoice.statuses.dueDateToday'),
        titleLong: trans('invoice.statuses.dueDateToday'),
        ...costInvoiceStatusData(invoiceBrief)
    };
};

export const credited = (invoiceBrief: IBriefInvoice): IInvoiceBriefStatus => ({
    ...baseStatus(invoiceBrief),
    bigNumber: {
        amount: invoiceBrief.totalWithVat,
        color: COLOR_GREYJOY
    },
    icon: ['fal', 'times'],
    iconColor: COLOR_GREYJOY,
    style: { textDecoration: 'line-through' },
    textColor: COLOR_GREYJOY,
    title: trans('invoice.statuses.credited'),
    ...costInvoiceStatusData(invoiceBrief)
});

export const inDebtPaid = (
    invoiceBrief: IBriefInvoice
): IInvoiceBriefStatus => ({
    ...baseStatus(invoiceBrief),
    bigNumber: {
        amount: invoiceBrief.totalWithVat
    },
    icon: ['far', 'user-cowboy'],
    iconColor: COLOR_STATUS_DONE,
    reclaimed: invoiceBrief.hasBeenReclaimed,
    title: trans('invoice.statuses.paidInDebt'),
    ...costInvoiceStatusData(invoiceBrief)
});

export const paidStatus = (
    invoiceBrief: IBriefInvoice
): IInvoiceBriefStatus => ({
    ...baseStatus(invoiceBrief),
    bigNumber: {
        amount: invoiceBrief.totalWithVat
    },
    icon: ['fal', 'check'],
    iconColor: COLOR_STATUS_DONE,
    reclaimed: invoiceBrief.hasBeenReclaimed,
    title: trans('invoice.statuses.paid'),
    ...costInvoiceStatusData(invoiceBrief)
});

export const reclaimed = (
    invoiceBrief: IBriefInvoice
): IInvoiceBriefStatus => ({
    ...baseStatus(invoiceBrief),
    bigNumber: {
        amount: invoiceBrief.totalWithVat,
        color: COLOR_IMPORTANT
    },
    icon: ['fal', 'exclamation-triangle'],
    iconColor: COLOR_IMPORTANT,
    textColor: COLOR_IMPORTANT,
    title: trans('invoice.statuses.reclaimed'),
    titleLong: trans('invoice.statuses.reclaimed-long')
});

export const partiallyPaid = (
    invoiceBrief: IBriefInvoice
): IInvoiceBriefStatus => ({
    ...baseStatus(invoiceBrief),
    bigNumber: {
        amount: invoiceBrief.totalWithVat
    },
    exclamation: true,
    icon: ['fal', 'clock'],
    iconColor: COLOR_STATUS_WAITING,
    smallNumber: invoiceBrief.paidAmount
        ? {
              amount: invoiceBrief.totalWithVat - invoiceBrief.paidAmount,
              color: COLOR_IMPORTANT
          }
        : undefined,
    title: trans('invoice.statuses.partiallyPaid'),
    ...costInvoiceStatusData(invoiceBrief)
});

export const partiallyPaidInDebt = (
    invoiceBrief: IBriefInvoice
): IInvoiceBriefStatus => ({
    ...baseStatus(invoiceBrief),
    bigNumber: {
        amount: invoiceBrief.totalWithVat
    },
    exclamation: true,
    icon: ['far', 'user-cowboy'],
    iconColor: COLOR_STATUS_DONE,
    smallNumber: invoiceBrief.paidAmount
        ? {
              amount: invoiceBrief.totalWithVat - invoiceBrief.paidAmount,
              color: COLOR_IMPORTANT
          }
        : undefined,
    title: trans('invoice.statuses.partiallyPaidInDebt'),
    ...costInvoiceStatusData(invoiceBrief)
});

export const paidAndReimbursed = (
    invoiceBrief: IBriefInvoice
): IInvoiceBriefStatus => ({
    ...baseStatus(invoiceBrief),
    bigNumber: {
        amount: invoiceBrief.totalWithVat - (invoiceBrief.reimbursedAmount || 0)
    },
    icon: ['fal', 'check'],
    iconColor: COLOR_STATUS_DONE,
    smallNumber: {
        amount: invoiceBrief.totalWithVat,
        color: COLOR_GREYHOUND,
        lineThrough: true
    },
    title: trans('invoice.statuses.partiallyPaidAndReimbursed'),
    ...costInvoiceStatusData(invoiceBrief)
});
