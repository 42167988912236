import levenshtein from 'fast-levenshtein';
import { Moment } from 'moment';
import * as R from 'ramda';

import { IBriefInvoice } from '../../../../shared/src/types/invoice';
import {
    dateTransformer,
    getBestMatch,
    getMaxIfAllMatch,
    normalizeSpaces,
    priceMatcher,
    recipientIdMatcher
} from './searchUtils';

interface IScorerOptions {
    exactMatch?: boolean;
    field: string;
    fuzzyScore?: number;
    lens: any;
    maxLevDist: number;
    score: number;
}

export const getLevDist = (target: string, term: string): number => {
    return Math.min(
        ...target.split(' ').map(word => {
            return levenshtein.get(word, term);
        })
    );
};

export interface ISearchMatch {
    // The score for the match
    score: number;
    // The field name of the field that was matched
    field: string;
    // The actual object that was matched
    object?: any;
    // The value of the field that was matched
    value?: string;
}
export const noMatch = (field: string) => ({ field, score: 0 });

export interface IInvoiceSearchResult {
    // The score for the match
    score: number;
    // The field name of the field that was matched
    field: string;
    // The actual object that was matched
    object: IBriefInvoice;
    // The value of the field that was matched
    value: string;
}

/**
 * Returns a function that returns the given score if the term matches the prop
 * returned by the given lens.
 * @param opt
 * @param transformer
 */
const scorer = (
    opt: IScorerOptions,
    transformer: (term: string, options?: any) => string | undefined = val =>
        '' + val,
    matcher?: (word: string, term: string) => boolean
) => {
    return (
        searchTerm: string,
        object: object,
        options?: any
    ): ISearchMatch => {
        const currentNoMatch = noMatch(opt.field);
        const term = transformer(
            searchTerm,
            options?.transformer
        )?.toLowerCase();
        if (!term) {
            return currentNoMatch;
        }
        const partialRegEx = RegExp(term);
        const exactRegEx = RegExp('^' + term.trim() + '$');
        const propValue: string = '' + R.view(opt.lens, object);
        const prop = propValue?.toLowerCase();
        if (matcher && matcher(searchTerm, prop)) {
            return {
                field: opt.field,
                object,
                score: opt.score,
                value: propValue
            };
        }
        // Should be considered if matcher not matching should return no-match.
        // Can be pretty confusing, if scorer has a matcher set up which does not match
        // and still search returns a hit for exact or partial match.

        if (opt.exactMatch) {
            return exactRegEx.test(prop)
                ? {
                      field: opt.field,
                      object,
                      score: opt.score,
                      value: propValue
                  }
                : currentNoMatch;
        }

        if (partialRegEx.test(prop)) {
            return {
                field: opt.field,
                object,
                score: opt.score,
                value: propValue
            };
        }
        if (opt.maxLevDist === 0 || opt.exactMatch) {
            return currentNoMatch;
        }
        if (term.length < 3) {
            return currentNoMatch;
        }
        const levDist = getLevDist(prop, term);

        if (
            levDist <= opt.maxLevDist &&
            levDist < term.length && // With short terms levDist of 2 can match anything. levDist(yy, xx) == 2 or levDist(x, xyy) == 2
            levDist < prop.length
        ) {
            return {
                field: opt.field,
                object,

                score: opt.fuzzyScore || 1,
                value: propValue
            };
        }
        return currentNoMatch;
    };
};

export const nameScorer = scorer({
    field: 'name',
    fuzzyScore: 2,
    // @ts-ignore
    lens: R.lensProp('name'),
    maxLevDist: 2,
    score: 10
});
export const companyScorer = scorer({
    field: 'company',
    fuzzyScore: 2,
    // @ts-ignore
    lens: R.lensProp('company'),
    maxLevDist: 2,
    score: 10
});
export const idScorer = scorer({
    exactMatch: true,
    field: 'id',
    // @ts-ignore
    lens: R.lensProp('id'),
    maxLevDist: 0,
    score: 20
});
export const duedateScorer = scorer(
    {
        field: 'dueDate',
        // @ts-ignore
        lens: R.lensProp('dueDate'),
        maxLevDist: 0,
        score: 2
    },
    dateTransformer
);
export const invoiceDateScorer = scorer(
    {
        field: 'invoiceDate',
        // @ts-ignore
        lens: R.lensProp('invoiceDate'),
        maxLevDist: 0,
        score: 8
    },
    dateTransformer
);
export const descriptionScorer = scorer({
    field: 'description',
    fuzzyScore: 2,
    // @ts-ignore
    lens: R.lensProp('description'),
    maxLevDist: 2,
    score: 5
});
export const priceScorer = scorer(
    {
        exactMatch: true,
        field: 'total',
        // @ts-ignore
        lens: R.lensProp('total'),
        maxLevDist: 0,
        score: 4
    },
    undefined,
    priceMatcher
);
export const priceVatScorer = scorer(
    {
        exactMatch: true,
        field: 'totalWithVat',
        // @ts-ignore
        lens: R.lensProp('totalWithVat'),
        maxLevDist: 0,
        score: 4
    },
    undefined,
    priceMatcher
);
export const invoiceNumberScorer = scorer({
    exactMatch: true,
    field: 'invoiceNumber',
    // @ts-ignore
    lens: R.lensProp('invoiceNumber'),
    maxLevDist: 0,
    score: 20
});
export const draftNumberScorer = scorer({
    exactMatch: true,
    field: 'draftNumber',
    // @ts-ignore
    lens: R.lensProp('draftNumber'),
    maxLevDist: 0,
    score: 20
});
export const recipientIdScorer = scorer(
    {
        exactMatch: true,
        field: 'recipientId',
        // @ts-ignore
        lens: R.lensProp('recipientId'),
        maxLevDist: 0,
        score: 20
    },
    undefined,
    recipientIdMatcher
);

const scorers = [
    companyScorer,
    descriptionScorer,
    draftNumberScorer,
    duedateScorer,
    idScorer,
    invoiceDateScorer,
    invoiceNumberScorer,
    nameScorer,
    priceScorer,
    priceVatScorer,
    recipientIdScorer
];

export const sortScored = (scoredObjects: ISearchMatch[]) => {
    return R.sortWith(
        [
            R.descend(R.prop('score')),
            // @ts-ignore
            R.descend(R.path(['object', 'updateDate']))
        ],
        scoredObjects
    );
};

export const searchInvoices = (
    invoices: IBriefInvoice[],
    searchTerm: string,
    today: Moment
): IInvoiceSearchResult[] => {
    const searchTerms = normalizeSpaces(searchTerm).split(' ');

    const results: ISearchMatch[] = invoices.map(invoice => {
        // Run each scorer for each term in searchTerms
        const termScores: ISearchMatch[] = searchTerms.map(term => {
            const scorerMatches = scorers.map(s =>
                s(term, invoice, { transformer: { today } })
            );
            return getBestMatch(scorerMatches);
        });
        // All terms must match. If do, return best match
        return getMaxIfAllMatch(termScores);
    });

    const filteredRes = sortScored(results.filter(s => s.score > 0));
    return filteredRes.map(match => match as IInvoiceSearchResult);
};
