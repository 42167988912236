import React from 'react';
import styled from 'styled-components';
import { FormNumberInput, FormRow, SplitRow } from '../../components/form';
import { FormLabel } from '../../components/form/FormLabel';
import { SCREEN_S } from '../../styles/variables';
import { trans } from '../../utils';
import { round } from '../../utils/calc';
import { ICostComponentProps } from '../../utils/costs/costLogic';

const Wrapper = styled.div`
    @media (min-width: ${SCREEN_S}px) {
        display: flex;
        justify-content: flex;
        margin-left: auto;
        padding-left: 5px;
    }
    margin: auto;
`;

export const TravelDistance = (props: ICostComponentProps) => {
    const { dispatch } = props;

    const handleKmUpdate = React.useCallback(
        (val: number) => {
            dispatch({
                payload: { reimbursedKMs: { quantity: round(val) } },
                type: 'SAVE_TRAVEL',
            });
        },
        [dispatch]
    );

    const handleTrailerKmUpdate = (val: number) => {
        dispatch({
            payload: { reimbursedTrailerKMs: { quantity: round(val) } },
            type: 'SAVE_TRAVEL',
        });
    };

    return (
        <Wrapper>
            <SplitRow style={{ width: '100%' }}>
                <FormRow style={{ flexGrow: 1 }}>
                    <FormLabel id="travel-distance" value={props.currentTravel?.reimbursedKMs}>
                        {trans('costs.travel-km')}
                    </FormLabel>
                    <div
                        style={{
                            alignItems: 'flex-end',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <FormNumberInput
                            endAdornment="km"
                            forceUpdateOnFocus
                            name="travel-distance"
                            onChange={(val) => {
                                if (typeof val === 'number') {
                                    handleKmUpdate(val);
                                } else {
                                    handleKmUpdate(parseInt(val, 10));
                                }
                            }}
                            required
                            style={{ width: 'calc(100% - 24px)' }}
                            value={props.currentTravel?.reimbursedKMs?.quantity}
                        />
                    </div>
                </FormRow>
                <FormRow style={{ flexGrow: 1 }}>
                    <FormLabel
                        id="travel-trailer"
                        error={
                            props.currentTravel &&
                            props.currentTravel?.reimbursedTrailerKMs?.quantity >
                                props.currentTravel?.reimbursedKMs?.quantity
                        }
                        title={trans('costs.travel-km-trailer-note')}
                    >
                        {trans('costs.travel-km-trailer')}
                    </FormLabel>
                    <div
                        style={{
                            alignItems: 'flex-end',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <FormNumberInput
                            endAdornment="km"
                            name="travel-trailer"
                            onChange={(val) => {
                                if (typeof val === 'number') {
                                    handleTrailerKmUpdate(val);
                                } else {
                                    handleTrailerKmUpdate(parseInt(val, 10));
                                }
                            }}
                            style={{ width: 'calc(100% - 24px)' }}
                            value={props.currentTravel?.reimbursedTrailerKMs?.quantity}
                        />
                    </div>
                </FormRow>
            </SplitRow>
        </Wrapper>
    );
};
