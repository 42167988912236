import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { hideError, logout } from 'actions/auth';
import { default as duck404 } from '../../assets/images/duck404.svg';
import { logoFull } from '../../assets/images/eezylogo';
import { EezyButton } from 'components/Buttons';
import { Flex } from 'components/Flex';
import { P, UniqueTitle } from 'components/textElements';
import { COLOR_LIGHT_BG, SCREEN_S } from 'styles/variables';
import { IClientError } from 'utils/error';

const ErrorPageWrapper = styled.div`
    background-color: ${COLOR_LIGHT_BG};
    height: 100vh;
    margin: auto;
    padding: 40px 20px 20px 20px;

    h1,
    p {
        padding-bottom: 10px;
    }

    && button {
        min-height: 40px;
        padding: 0 20px;
    }

    h2 {
        font-size: 18px;
        font-weight: bold;
    }

    img {
        width: 200px;
        @media (min-width: ${SCREEN_S}px) {
            width: 300px;
        }
    }

    .logo {
        margin-top: 50px;
    }
`;

interface IErrorProps {
    wholePage?: boolean;
    yelPage?: boolean;
}

const ErrorView = (props: IErrorProps) => {
    const { wholePage, yelPage } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();

    const error: IClientError | null = useSelector((state: IRootState) => state.auth.error);
    const loggedIn: boolean  | null = useSelector((state: IRootState) => state.auth.loggedIn);

    const [displayErrorPage, setDisplayErrorPage] = useState<boolean>(false);

    useEffect(() => {
        if (error &&
            (yelPage && error.place === 'yelPage' ||
            wholePage && error?.place === 'wholePage')
        ) {
            setDisplayErrorPage(true);
        } else setDisplayErrorPage(false);
    }, [error, yelPage, wholePage])

    const handleBackClick = () => {
        dispatch(hideError());

        if (error?.backButtonUrl) {
            navigate(error.backButtonUrl);
        } else if (error?.backButtonUrlExternal) {
            window.location.href = error.backButtonUrlExternal;
        }
    };

    return (
        displayErrorPage ? (
            <ErrorPageWrapper>
                <Flex column center>
                    <img
                        src={duck404}
                        alt=""
                        style={{ marginBottom: 30 }}
                        width={300}
                    />
                    <UniqueTitle>{error?.title}</UniqueTitle>
                    {error?.description && <P>{error.description}</P>}
                    {error?.helpText && <P>{error.helpText}</P>}

                    {error?.backButtonText ? (
                        <EezyButton
                            color="purple"
                            onClick={handleBackClick}
                            style={{
                                marginTop: '30px'
                            }}
                            transparent
                        >
                            {error.backButtonText}
                        </EezyButton>
                    ) : (
                        <EezyButton
                            color="purple"
                            transparent
                            onClick={() => {
                                window.location.reload();
                            }}
                            style={{
                                marginTop: '30px'
                            }}
                        >
                            {t('errors.refresh-page')}
                        </EezyButton>
                    )}

                    {loggedIn && (
                        <EezyButton
                            color="purple"
                            transparent
                            onClick={() => {
                                dispatch(logout());
                            }}
                            style={{
                                marginTop: '30px',
                                border: 'none'
                            }}
                        >
                            {t('login.logout')}
                        </EezyButton>
                    )}
                    <img className="logo" src={logoFull} alt="" />
                </Flex>
            </ErrorPageWrapper>
        ) : null
    );
};

export default ErrorView;

