import { gql } from '@apollo/client';

const GET_CAMPWIRE_SALES_PAGES = gql`
    query getSalesPages($searchTerm: String) {
        sales_pages(
            order_by: { id: desc }
            where: {
                _and: {
                    deleted: { _is_null: true }
                    serviceName: { _ilike: $searchTerm }
                    thirdParty: { _is_null: false }
                }
            }
        ) {
            id
            serviceName
            servicePrice
            serviceDescription
            numberOfPayments
            creationDate
            publicPath
            state
            transactions_aggregate(where: { status: { _eq: "COMPLETE" } }) {
                aggregate {
                    count
                }
            }
        }
    }
`;

export default GET_CAMPWIRE_SALES_PAGES;
