import styled from 'styled-components';
import {
    INVOICE_WIDTH,
    TOPBAR_HEIGHT,
    FOOTER_HEIGHT,
    FOOTER_HEIGHT_MOBILE,
    SCREEN_M
} from 'styles/variables';

export const TwoColumnView = styled.div`
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT_MOBILE}px);
    @media (min-width: ${SCREEN_M}px) {
        min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT}px);
    }

    & > div {
        padding: 32px 0;
    }

    & > div:first-child {
        /* The left side */
        margin: 0 20px 0 0;
        width: 100%;
        min-width: 300px;
        max-width: 550px;
    }
    & > div:nth-child(2) {
        /* The right side */
        min-width: ${INVOICE_WIDTH}px;
        width: ${INVOICE_WIDTH}px;
        overflow-y: auto;
        min-height: auto;
    }
`;
