import { useQuery } from '@apollo/client';
import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { EezyButton } from 'components/Buttons';
import { Flex } from 'components/Flex';
import { Icon } from 'components/Icon';
import { BORDER_RADIUS, COLOR_BLUM, COLOR_LIGHT_BG, COLOR_WHITE_WALKER } from 'styles/variables';
import { formatCents, formatDate, getMonthName, isMobile } from 'utils';
import { GET_PAYMENTS } from './queries';
import BackButtonWithTitle from '../../components/BackButtonWithTitle';
import {
    AccordionPanel,
    AccordionPanelDetails,
    AccordionPanelSummary,
    AccordionTypography,
} from 'components/AccordionPanel';
import { TwoColumnView } from 'components/layout/TwoColumnView';
import { Fade, Hidden } from '@mui/material';
import { List } from 'components/layout/List';
import { filterTransactions, getTransactionsMonthList, IMonthYear } from 'utils/eezyPay/eezyPayUtils';
import { TextDivider } from 'components/TextDivider';
import { Bold, UniqueTitle } from 'components/textElements';
import { EmptyListPlaceholder } from 'components/EmptyListPlaceholder';
import TransactionDetails from './TransactionDetails';
import LoadingSpinner from '../../components/Loading';
import TransactionsListItem from './TransactionsListItem';
import TransactionsSearch from './TransactionsSearch';
import config from 'config';
import { useTranslation } from 'react-i18next';
import Switch from '../../components/Switch';
import GET_THIRD_PARTY_PAYMENTS from './queries/getThirdPartyPayments';

interface ITransactionsProps {
    searchQuery: string;
    userId: number;
    accountId: number;
}

export interface ITransaction {
    orderNumber: string;
    serviceName: string;
    firstName?: string;
    lastName?: string;
    paymentBrand: string;
    servicePrice: number;
    completed: Date;
    serviceVat: number;
}

const AccordionSummaryContainer = styled.div`
    padding-right: 10px;
    p {
        font-size: 15px;
        padding: 0;
    }
`;

const AccordionActions = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
`;

const ServiceName = styled.p`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    margin-bottom: 6px;
    width: 100%;
`;

const AccordionPanelSummaryStyled = styled(AccordionPanelSummary)`
    cursor: auto !important;
    .MuiAccordionSummary-content {
        min-width: 0;
    }
`;

const Wrapper = styled.div<{
    showScroll?: boolean;
}>`
    border-radius: ${BORDER_RADIUS};
    background-color: ${COLOR_WHITE_WALKER};
    padding: ${(props) => (props.showScroll ? '0 35px 20px 20px' : '0 20px 20px 20px')};
`;

const BackButtonWrapper = styled.div`
    margin-top: 30px;
`;

const TotalWrapper = styled.div`
    border-radius: ${BORDER_RADIUS};
    background-color: ${COLOR_WHITE_WALKER};
    padding: 10px;
    margin-bottom: 20px;
    color: ${COLOR_BLUM};
    font-size: 16px;
    font-weight: 600;
`;

const CampwireWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    border-radius: ${BORDER_RADIUS};
    padding: 0;
    margin-bottom: 20px;
    color: ${COLOR_BLUM};
    font-size: 16px;
    font-weight: 600;
`;

const SwitchTitle = styled.div`
    padding-right: 10px;
`;

const Transactions = (props: ITransactionsProps) => {
    const { t } = useTranslation();

    const [token, setToken] = useState(sessionStorage.getItem('jwt'));
    const [selectedId, setSelectedId] = useState('');
    const [expanded, setExpanded] = useState('');
    const [totalPayments, setTotalPayments] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [campwireFilterActive, setCampwireFilterActive] = useState(false);
    const [showCampwireSwitch, setShowCampwireSwitch] = useState(false);
    const { data: transactionsData } = useQuery(
        campwireFilterActive ? GET_THIRD_PARTY_PAYMENTS : GET_PAYMENTS,
        {
            context: { clientName: 'eezyPayHasura' },
            fetchPolicy: 'cache-and-network',
            variables: {
                searchTerm: `%${props.searchQuery}%`,
                thirdParty: campwireFilterActive ? 'campwire' : undefined,
            },
            skip: !token,
            onCompleted: (data) => {
                setTotalPayments(data.transactions.length);
                setTotalAmount(
                    data.transactions.reduce(
                        (prev: number, curr: ITransaction) => prev + curr.servicePrice,
                        0,
                    ),
                );
            },
        },
    );

    const { transactions } = transactionsData || {};
    const campwireTransaction = useMemo(
        () => transactions?.find((transaction: any) => transaction.thirdParty === 'campwire'),
        [transactions],
    );
    if (!!campwireTransaction && !showCampwireSwitch) {
        setShowCampwireSwitch(true);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (!token) {
                setToken(sessionStorage.getItem('jwt'));
            } else {
                clearInterval(interval);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [token]);

    const ITEM_DELAY = 20;
    const FADE_TIMEOUT = 200;
    const MIN_ITEMS_TO_SHOW_SCROLL = 6;

    let animationCounter = 0;

    const monthList = getTransactionsMonthList(transactionsData?.transactions || []);
    const numberOfVisibleTransactions = transactionsData?.transactions?.length || 0;
    const hasNoTransactions = numberOfVisibleTransactions === 0 && props.searchQuery === '';
    const showScroll = !isMobile() && numberOfVisibleTransactions >= MIN_ITEMS_TO_SHOW_SCROLL;

    const handleExpandChange = (panel: string) => {
        setExpanded(panel === expanded ? '' : panel);
        setSelectedId(panel);
    };

    const handlePageItemClose = (e: MouseEvent) => {
        e.stopPropagation();
        setSelectedId('');
    };

    const handleDownloadClick = (transaction: ITransaction) => {
        const { orderNumber, firstName, lastName, serviceName, servicePrice } = transaction;

        const downloadUrl = `${config.eezyPayApiHost}/receipt/seller-receipt-download?userId=${props.userId}&accountId=${props.accountId}&orderNumber=${orderNumber}&firstName=${firstName}&lastName=${lastName}&serviceName=${serviceName}&servicePrice=${servicePrice}`;
        const win = window.open(downloadUrl, '_blank');
        win?.focus();
    };

    const listTransactions = (items: ITransaction[], delay: number) => {
        return (
            <ul>
                {items.map((transaction, index) => {
                    const handleClick = () => {
                        setSelectedId(transaction.orderNumber);
                    };
                    const selected = selectedId === transaction.orderNumber;
                    return (
                        <li
                            className={selected ? 'selected' : ''}
                            key={`${index}-transaction-${transaction.orderNumber}`}
                            tabIndex={0}
                            onClick={handleClick}
                            onKeyPress={handleClick}
                        >
                            <Fade
                                in={true}
                                timeout={FADE_TIMEOUT}
                                style={{
                                    transitionDelay: `${ITEM_DELAY * index + delay}ms`,
                                }}
                            >
                                <div>
                                    <TransactionsListItem
                                        key={transaction.orderNumber}
                                        transaction={transaction}
                                        selected={selected}
                                        handleClose={handlePageItemClose}
                                    />
                                </div>
                            </Fade>
                        </li>
                    );
                })}
            </ul>
        );
    };

    const getSelectedTransaction = () => {
        if (!transactionsData?.transactions) {
            return;
        }

        const transactionsCopy = JSON.parse(JSON.stringify(transactionsData?.transactions));

        const selectedTransaction = transactionsCopy.filter(
            (transaction: ITransaction) => transaction.orderNumber === selectedId,
        );

        return selectedTransaction[0];
    };

    const renderTransactionDetails = (transaction: ITransaction) => {
        if (!transaction) {
            return;
        }

        return <TransactionDetails transaction={transaction} downloadReceipt={handleDownloadClick} />;
    };

    return (
        <>
            {/* MOBILE */}
            <Hidden mdUp>
                <>
                    <BackButtonWithTitle title={t('eezyPay:payments.title') || ''} />

                    <TotalWrapper>
                        <div>
                            {t('eezyPay:payments.total-payments')}: {t('form.x-pcs', { x: totalPayments })}
                        </div>
                        <div>
                            {t('eezyPay:payments.total-amount')}:{' '}
                            {t('form.eurs', {
                                eurs: formatCents(totalAmount, true),
                            })}
                        </div>
                    </TotalWrapper>

                    {showCampwireSwitch && (
                        <CampwireWrapper>
                            <SwitchTitle>{t('eezyPay:campwire-filter')}</SwitchTitle>
                            <Switch
                                checked={campwireFilterActive}
                                onChange={() => {
                                    setCampwireFilterActive(!campwireFilterActive);
                                }}
                            />
                        </CampwireWrapper>
                    )}

                    {!hasNoTransactions && (
                        <div style={{ marginBottom: 10 }}>
                            <TransactionsSearch />
                        </div>
                    )}

                    {!token && (
                        <div style={{ textAlign: 'center', marginTop: '60px' }}>
                            <LoadingSpinner size="30px" color={COLOR_BLUM} />
                        </div>
                    )}

                    {hasNoTransactions && token ? (
                        <div style={{ padding: '10px 0' }}>
                            <EmptyListPlaceholder
                                textColor={COLOR_BLUM}
                                text={t('eezyPay:payments.no-payments')}
                            />
                        </div>
                    ) : (
                        <ul>
                            {transactionsData?.transactions?.map((transaction: ITransaction) => (
                                <AccordionPanel
                                    expanded={expanded === transaction.orderNumber}
                                    key={transaction.orderNumber}
                                >
                                    <AccordionPanelSummaryStyled>
                                        <AccordionSummaryContainer
                                            style={{
                                                margin: '0',
                                                minWidth: 0,
                                            }}
                                        >
                                            <ServiceName>
                                                {transaction.firstName} {transaction.lastName}
                                            </ServiceName>

                                            <AccordionTypography>
                                                {transaction.serviceName}
                                            </AccordionTypography>
                                        </AccordionSummaryContainer>
                                        <AccordionActions>
                                            <EezyButton
                                                style={{
                                                    borderWidth: 2,
                                                    padding: 0,
                                                    minWidth: 'auto',
                                                    width: 32,
                                                    height: 32,
                                                    marginRight: 8,
                                                    verticalAlign: 'top',
                                                }}
                                                color="purple"
                                                transparent
                                                onClick={() => {
                                                    handleExpandChange(transaction.orderNumber);
                                                }}
                                            >
                                                <Icon
                                                    style={{
                                                        cursor: 'pointer',
                                                        verticalAlign: 'middle',
                                                        width: 18,
                                                        height: 18,
                                                    }}
                                                    icon={[
                                                        'fas',
                                                        expanded === transaction.orderNumber
                                                            ? 'chevron-up'
                                                            : 'chevron-down',
                                                    ]}
                                                />
                                            </EezyButton>
                                        </AccordionActions>
                                    </AccordionPanelSummaryStyled>
                                    <AccordionPanelDetails>
                                        <div
                                            style={{
                                                marginBottom: '5px',
                                            }}
                                        >
                                            <div>
                                                {t('eezyPay:payments.service-price')}:{' '}
                                                {t('form.eurs', {
                                                    eurs: formatCents(transaction.servicePrice, true),
                                                })}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                marginBottom: '5px',
                                            }}
                                        >
                                            <div>
                                                {t('eezyPay:payments.date')}:{' '}
                                                {formatDate(transaction.completed)}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                marginBottom: '5px',
                                            }}
                                        >
                                            <div>
                                                {t('eezyPay:payments.method')}: {transaction.paymentBrand}
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 20 }}>
                                            <EezyButton
                                                style={{ width: '100%' }}
                                                color="purple"
                                                onClick={() => {
                                                    handleDownloadClick(transaction);
                                                }}
                                            >
                                                {t('eezyPay:payments.download-receipt')}
                                            </EezyButton>
                                        </div>
                                    </AccordionPanelDetails>
                                </AccordionPanel>
                            ))}
                        </ul>
                    )}
                </>
            </Hidden>

            {/* DESKTOP */}
            <Hidden mdDown>
                <BackButtonWrapper>
                    <BackButtonWithTitle style={{ paddingBottom: 0 }} />
                </BackButtonWrapper>
                <TwoColumnView>
                    <div>
                        <Wrapper showScroll={showScroll}>
                            <Fade in={true} timeout={FADE_TIMEOUT}>
                                <Flex style={{ padding: '27px 0 23px 0' }}>
                                    <UniqueTitle>{t('eezyPay:payments.title')}</UniqueTitle>
                                </Flex>
                            </Fade>

                            <TotalWrapper style={{ padding: 0 }}>
                                <div>
                                    {t('eezyPay:payments.total-payments')}:{' '}
                                    {t('form.x-pcs', { x: totalPayments })}
                                </div>
                                <div>
                                    {t('eezyPay:payments.total-amount')}:{' '}
                                    {t('form.eurs', {
                                        eurs: formatCents(totalAmount, true),
                                    })}
                                </div>
                            </TotalWrapper>

                            {showCampwireSwitch && (
                                <CampwireWrapper>
                                    <SwitchTitle>{t('eezyPay:campwire-filter')}</SwitchTitle>
                                    <Switch
                                        checked={campwireFilterActive}
                                        onChange={() => {
                                            setCampwireFilterActive(!campwireFilterActive);
                                        }}
                                    />
                                </CampwireWrapper>
                            )}

                            <Fade in={true} timeout={FADE_TIMEOUT} style={{ transitionDelay: '100ms' }}>
                                {!hasNoTransactions ? (
                                    <div style={{ marginBottom: 4 }}>
                                        <TransactionsSearch />
                                    </div>
                                ) : (
                                    <div />
                                )}
                            </Fade>

                            {!token && (
                                <div style={{ textAlign: 'center' }}>
                                    <LoadingSpinner size="30px" color={COLOR_LIGHT_BG} />
                                </div>
                            )}

                            {hasNoTransactions && token ? (
                                <div style={{ padding: '65px 0' }}>
                                    <EmptyListPlaceholder
                                        textColor="#C2BCD1"
                                        text={t('eezyPay:payments.no-payments')}
                                    />
                                </div>
                            ) : (
                                <List
                                    className={showScroll ? 'show-scroll' : 'hide-scroll'}
                                    style={{
                                        top: 180,
                                    }}
                                >
                                    {monthList.map(({ month, year }: IMonthYear, index) => {
                                        const transactions = filterTransactions(
                                            transactionsData?.transactions,
                                            month,
                                            year,
                                        );
                                        animationCounter += transactions.length;
                                        const delay = (animationCounter - transactions.length) * ITEM_DELAY;
                                        return (
                                            <React.Fragment key={`${index}-${month}-${year}`}>
                                                <Fade
                                                    in={true}
                                                    timeout={FADE_TIMEOUT}
                                                    style={{
                                                        transitionDelay: `${delay}ms`,
                                                    }}
                                                >
                                                    <div>
                                                        <TextDivider>
                                                            <Bold color={COLOR_BLUM}>
                                                                {getMonthName(month)} {year}
                                                            </Bold>
                                                        </TextDivider>
                                                    </div>
                                                </Fade>
                                                {listTransactions(transactions, delay)}
                                            </React.Fragment>
                                        );
                                    })}
                                </List>
                            )}
                        </Wrapper>
                    </div>
                    <div className="hide-scroll">
                        {selectedId !== '' && renderTransactionDetails(getSelectedTransaction())}
                    </div>
                </TwoColumnView>
            </Hidden>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        searchQuery: state.eezyPay.transactionsSearchQuery,
        userId: state.user.id,
        accountId: state.user.accountId,
    };
};

export default connect(mapStateToProps)(Transactions);
