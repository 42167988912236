import { gql } from "@apollo/client";

const REPLY_TO_MESSAGE_THREAD = gql`
    mutation ReplyToMessage($message: MessageReplyInput!, $lang: LanguageId!) {
        replyToMessage(reply: $message, lang: $lang) {
            id
        }
    }
`;

export default REPLY_TO_MESSAGE_THREAD;
