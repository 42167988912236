import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Hidden } from '@mui/material';
import { Card, CardHeader } from '.';
import { EezyButton } from '../Buttons';
import { Flex } from '../Flex';
import LoadingSpinner from '../Loading';

interface IProps {
    defaultOpen?: boolean;
    editableContent?: ReactNode;
    mobileViewContent?: ReactNode;
    onClose: () => void;
    onSave: (callback: () => void) => void;
    route?: string;
    saveLoading?: boolean;
    viewContent?: ReactNode;
    title: string;
}

export const ManualCard = (props: IProps) => {
    const [editable, setEditable] = useState(false);

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (props.defaultOpen) {
            setEditable(true);
        }
    }, [props.defaultOpen]);

    const handleSave = () => {
        props.onSave(() => {
            handleClose();
        });
    };

    const location = useLocation();

    const handleClose = () => {
        props.onClose();
        setEditable(false);

        if (location.pathname === `/profile/${props.route}`) {
            // clean up e.g. /profile/salary direct url
            navigate('/profile');
        }
    };

    const handleEdit = () => {
        setEditable(true);
    };

    return (
        <Card>
            <CardHeader
                title={props.title}
                editModeOn={editable}
                onClose={() => handleClose()}
                onEdit={() => handleEdit()}
            />
            {editable && props.editableContent}

            {!editable && props.mobileViewContent && (
                <Hidden smUp>{props.mobileViewContent}</Hidden>
            )}

            {!editable &&
                props.viewContent &&
                (props.mobileViewContent ? (
                    <Hidden smDown>{props.viewContent}</Hidden>
                ) : (
                    props.viewContent
                ))}

            {!editable && (
                <Hidden smUp>
                    <Flex column style={{ marginTop: 30 }}>
                        <EezyButton
                            color="purple"
                            dark
                            fullWidth
                            onClick={handleEdit}
                        >
                            {t('general.edit')}
                        </EezyButton>
                    </Flex>
                </Hidden>
            )}

            {editable && (
                <>
                    <Hidden smUp>
                        <Flex column style={{ marginTop: 30 }}>
                            <EezyButton
                                color="important"
                                dark
                                fullWidth
                                onClick={handleSave}
                            >
                                {props.saveLoading ? (
                                    <LoadingSpinner size="1em" color="white" />
                                ) : (
                                    t('general.save')
                                )}
                            </EezyButton>
                            <EezyButton
                                color="purple"
                                fullWidth
                                onClick={handleClose}
                                style={{ margin: '10px 0 0 0' }}
                            >
                                {t('general.cancel')}
                            </EezyButton>
                        </Flex>
                    </Hidden>
                    <Hidden smDown>
                        <Flex posEnd style={{ marginTop: 30 }}>
                            <EezyButton
                                color="important"
                                dark
                                onClick={handleSave}
                                width={120}
                            >
                                {props.saveLoading ? (
                                    <LoadingSpinner size="1em" color="white" />
                                ) : (
                                    t('general.save')
                                )}
                            </EezyButton>
                        </Flex>
                    </Hidden>
                </>
            )}
        </Card>
    );
};
