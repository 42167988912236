import { SALARY_SEARCH_QUERY } from '../actionTypes';

export interface ISalaryState {
    searchQuery: string;
}

const initialState: ISalaryState = {
    searchQuery: ''
};

// eslint-disable-next-line
export default (state = initialState, action: {
    payload: { searchQuery: string},
    type: string
}) => {
    switch (action.type) {
        case SALARY_SEARCH_QUERY:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};
